import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { Axios, BaseForm as Form } from 'component/base'
import { useUnmounted } from 'component/hook'

export const Address = props => {
  const { onFocus, onBlur, value: _value, ...other } = props || {}

  const unmounted = useUnmounted()
  const [source] = useState(axios.CancelToken.source())
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [param, setParam] = useState('')

  const [value, setValue] = useState(_value)
  const [isFocus, setIsFocus] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setValue(_value)
  }, [_value])

  const ajax = async param => {
    if (!param || param.length < 3) return
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/user/gps/search/address`,
      {
        params: {
          name: param
            .replace(/\(|\)|\||&|:|\*|!/g, '')
            .replace(/(^(\s|　)+)|((\s|　)+$)/g, ''),
        },
        cancelToken: source.token,
      }
    )
    if (unmounted.current) return
    setLoading(false)

    if (error) return
    setOptions(data || [])
  }

  useEffect(() => {
    ajax(param)
  }, [param])

  useEffect(() => {
    if (unmounted.current) source.cancel('your request was canceled')
  }, [unmounted])

  const handleChange = useCallback((_e, { value }) => {
    setIsEditing(true)
    debouncedHandleChange(value)
  }, [])

  const debouncedHandleChange = _.debounce(value => {
    if (unmounted.current) return
    setValue(value)
    setParam(value && value.length >= 3 ? value : null)
  }, 800)

  const handleBlur = (...args) => {
    setIsFocus(false)
    setIsEditing(false)
    if (onBlur) onBlur(...args)
  }

  const handleResultSelect = (_e, data) => {
    setIsEditing(false)
    setValue(data?.result?.title)
  }

  return (
    <Form.Search.Standard
      {...other}
      open={isFocus && isEditing && value && value.length >= 3 ? true : false}
      onSearchChange={handleChange}
      loading={loading}
      icon='map pin'
      results={_(options)
        .map((row, i) => ({
          ...row,
          key: i,
          title: row.name,
        }))
        .value()}
      value={value}
      onFocus={() => setIsFocus(true)}
      onBlur={handleBlur}
      onResultSelect={handleResultSelect}
    />
  )
}
