import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form as SUIForm,
  Radio as SUIRadio,
  Checkbox as SUICheckbox,
  Button as SUIButton,
  Dropdown as SUIDropdown,
  Dimmer,
  Loader,
} from 'semantic-ui-react'
import Formsy from '@akihirotakamura/formsy-react'
import { Form as FormsySemanticUIForm } from '@akihirotakamura/formsy-semantic-ui-react'
import * as Textbox from './Textbox'
import * as Dropdown from './Dropdown'
import * as Checkbox from './Checkbox'
import * as Search from './Search'
import * as Radio from './Radio'
import * as Button from './Button'
import * as Popup from './Popup'
import * as Hidden from './Hidden'
import './style.css'

class BaseForm extends Component {
  static propTypes = {
    /*
      basic props
    */
    children: PropTypes.node,

    /*
      semantic-ui props
      https://react.semantic-ui.com/collections/form/
    */
    /** An element type to render as (string or function). */
    as: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.symbol,
    ]),

    /** The HTML form action */
    action: PropTypes.string,

    /** Additional classes. */
    className: PropTypes.string,

    /** Automatically show any error Message children. */
    error: PropTypes.bool,

    /** A form can have its color inverted for contrast. */
    inverted: PropTypes.bool,

    /** Automatically show a loading indicator. */
    loading: PropTypes.bool,

    /** A comment can contain a form to reply to a comment. This may have arbitrary content. */
    reply: PropTypes.bool,

    /** A form can vary in size. */
    size: PropTypes.oneOf([
      'mini',
      'tiny',
      'small',
      'large',
      'big',
      'huge',
      'massive',
    ]),

    /** Automatically show any success Message children. */
    success: PropTypes.bool,

    /** A form can prevent itself from stacking on mobile. */
    unstackable: PropTypes.bool,

    /** Automatically show any warning Message children. */
    warning: PropTypes.bool,

    /** Forms can automatically divide fields to be equal width. */
    widths: PropTypes.oneOf(['equal']),

    /*
      formsy-react props
      https://github.com/formsy/formsy-react/blob/master/API.md#getvalue
    */

    /** Use mapping to change the data structure of your input elements. This structure is passed to the submit hooks. */
    mapping: PropTypes.func,

    /** You can manually pass down errors to your form. In combination with onChange you are able to validate using an external validator. */
    validationErrors: PropTypes.object,

    /** Takes a function to run when the submit button has been clicked. */
    /** The first argument is the data of the form. */
    /** The second argument will reset the form. */
    /** The third argument will invalidate the form by taking an object that maps to inputs. */
    onSubmit: PropTypes.func,

    /** Whenever the form becomes invalid the "onInvalid" handler is called. Use it to for example revert "onValid" state. */
    onValid: PropTypes.func,

    /** Whenever the form becomes invalid the "onInvalid" handler is called. Use it to for example revert "onValid" state. */
    onInvalid: PropTypes.func,

    /** Triggers when form is submitted with a valid state. The arguments are the same as on onSubmit. */
    onValidSubmit: PropTypes.func,

    /** Triggers when form is submitted with an invalid state. The arguments are the same as on onSubmit. */
    onInvalidSubmit: PropTypes.func,

    /** triggers when setValue is called on your form elements. It is also triggered when dynamic form elements have been added to the form. */
    onChange: PropTypes.func,

    /*
      custom props
    */
    autoComplete: PropTypes.oneOf(['on', 'off']),

    gridRowMode: PropTypes.bool,
  }

  static defaultProps = {
    mapping: () => {},
    validationErrors: {},
    onSubmit: () => {},
    onValid: () => {},
    onInvalid: () => {},
    onValidSubmit: () => {},
    onInvalidSubmit: () => {},
    onChange: () => {},
    autoComplete: 'off',
    gridRowMode: false,
  }

  /** Grouping Component */
  /** Form Layout Component */
  static Group = props => <SUIForm.Group {...props} />
  static Field = props => <SUIForm.Field {...props} />

  /** Textbox Component */
  static Textbox = {
    Standard: props => <Textbox.Standard {...props} />,
    Alpha: props => <Textbox.Alpha {...props} />,
    Alphanumeric: props => <Textbox.Alphanumeric {...props} />,
    DatePicker: props => <Textbox.DatePicker {...props} />,
    Decimal: props => <Textbox.Decimal {...props} />,
    Email: props => <Textbox.Email {...props} />,
    Float: props => <Textbox.Float {...props} />,
    FullWidth: props => <Textbox.FullWidth {...props} />,
    HalfWidth: props => <Textbox.HalfWidth {...props} />,
    Int: props => <Textbox.Int {...props} />,
    Numeric: props => <Textbox.Numeric {...props} />,
    SpecialWords: props => <Textbox.SpecialWords {...props} />,
    Url: props => <Textbox.Url {...props} />,
    Words: props => <Textbox.Words {...props} />,
    Y: props => <Textbox.Y {...props} />,
    YM: props => <Textbox.YM {...props} />,
    YMD: props => <Textbox.YMD {...props} />,
    HM: props => <Textbox.HM {...props} />,
    DateTime: props => <Textbox.DateTime {...props} />,
    Time: props => <Textbox.Time {...props} />,
    Money: props => <Textbox.Money {...props} />,
    Phone: props => <Textbox.Phone {...props} />,
    Postal: props => <Textbox.Postal {...props} />,
    Equal: props => <Textbox.Equal {...props} />,
    SuggestWrapper: props => <Textbox.SuggestWrapper {...props} />,
    SuggestWrapperAPI: props => <Textbox.SuggestWrapperAPI {...props} />,
    AutoHeightTextArea: props => <Textbox.AutoHeightTextArea {...props} />,
    HalfKana: props => <Textbox.HalfKana {...props} />,
    TekikakuInvoiceNo: props => <Textbox.TekikakuInvoiceNo {...props} />,
    Lat: props => <Textbox.Lat {...props} />,
    Lng: props => <Textbox.Lng {...props} />,
    Zoom: props => <Textbox.Zoom {...props} />,
  }

  /** Dropdown Component */
  static Dropdown = {
    Standard: props => <Dropdown.Standard {...props} />,
    Multiple: props => <Dropdown.Multiple {...props} />,
    WithAPI: props => <Dropdown.WithAPI {...props} />,
    Menu: props => <SUIDropdown.Menu {...props} />,
    Item: props => <SUIDropdown.Item {...props} />,
    Divider: props => <SUIDropdown.Divider {...props} />,
    Header: props => <SUIDropdown.Header {...props} />,
  }

  /** Checkbox Component */
  static Checkbox = {
    Standard: props => <Checkbox.Standard {...props} />,
    Toggle: props => <Checkbox.Toggle {...props} />,
    Slider: props => <Checkbox.Slider {...props} />,
    Buttony: props => <Checkbox.Buttony {...props} />,
  }

  /** Radio Component */
  static Radio = {
    Group: props => <Radio.Group {...props} />,
    Standard: props => <SUIRadio {...props} />,
    Toggle: props => <SUIRadio {...props} toggle />,
    Slider: props => <SUIRadio {...props} slider />,
    CheckboxStyle: props => <SUICheckbox {...props} />,
    Buttony: props => <Radio.Buttony {...props} />,
  }

  static Search = {
    Standard: props => <Search.Standard {...props} />,
  }

  static Button = {
    Standard: props => <Button.Standard {...props} />,
    Primary: props => <Button.Primary {...props} />,
    Secondary: props => <Button.Secondary {...props} />,
    Content: props => <SUIButton.Content {...props} />,
    Group: props => <SUIButton.Group {...props} />,
    Or: props => <SUIButton.Or {...props} />,
  }

  static Popup = {
    Kilo: props => <Popup.Kilo {...props} />,
  }

  static Hidden = {
    Standard: props => <Hidden.Standard {...props} />,
  }

  updateInputsWithError = errors =>
    this.formsySemanticUIForm.updateInputsWithError(errors)

  reset = mapping => this.formsySemanticUIForm.reset(mapping)
  submit = event =>
    this && this.formsySemanticUIForm && this.formsySemanticUIForm.submit
      ? this.formsySemanticUIForm.submit(event)
      : (() => {})()

  // if hit Enter -> avoid form Submit
  avoidEnter = event => {
    // enter and !textarea
    if (event.which === 13 && !event.target.type.includes('textarea')) {
      event.preventDefault()
    }
  }

  render() {
    const { gridRowMode, loading, color, ...rest } = this.props

    const {
      as, // eslint-disable-line
      error, // eslint-disable-line
      inverted, // eslint-disable-line
      reply, // eslint-disable-line
      size, // eslint-disable-line
      success, // eslint-disable-line
      warning, // eslint-disable-line
      width, // eslint-disable-line
    } = rest

    return gridRowMode ? (
      <>
        <Formsy
          noValidate
          ref={ref => (this.formsySemanticUIForm = ref)}
          onKeyPress={this.avoidEnter}
          {...rest}
          className={`row tbody ui form gridForm ${size} ${color && color}`}
        >
          {this.props.children}
          <Dimmer active={loading} inverted>
            <Loader inverted />
          </Dimmer>
        </Formsy>
      </>
    ) : (
      <FormsySemanticUIForm
        ref={ref => (this.formsySemanticUIForm = ref)}
        loading={loading}
        onKeyPress={this.avoidEnter}
        {...rest}
      />
    )
  }
}

export default BaseForm
