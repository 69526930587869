import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'
import { useUserInfo } from 'component/hook'

export const Busho = props => {
  const {
    value,
    enableAuthValue,
    multiple,
    is_member,
    level_list,
    ...other
  } = props

  const { active_busho_id } = useUserInfo()

  const url = useMemo(() =>
    is_member ? '/api/member/master/busho' : '/api/staff/master/busho'
  )

  return (
    <Form.Dropdown.WithAPI
      url={url}
      params={{
        level_list,
      }}
      keyName='id'
      valueName='id'
      textName='busho_name'
      sort={['busho_cd']}
      multiple={multiple}
      value={enableAuthValue ? (value ? value : active_busho_id) : value}
      {...other}
    />
  )
}

Busho.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  enableAuthValue: PropTypes.bool,
  is_member: PropTypes.bool,
  level_list: PropTypes.array,
}

Busho.defaultProps = {
  name: 'busho_id',
  label: '所管部署',
  value: undefined,
  enableAuthValue: true,
  is_member: false,
  level_list: undefined,
}
