import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Axios } from 'component/base'
import SuggestWrapper from './SuggestWrapper'

const SuggestWrapperAPI = props => {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])

  const { url, ...other } = props || {}

  const ajax = async () => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(url, {})
    if (error) {
      setLoading(false)
      //todo: error handling
      return
    }
    setOptions(data || [])
    setLoading(false)
  }

  useEffect(() => {
    ajax()
  }, [])

  return <SuggestWrapper {...other} loading={loading} datalist={options} />
}
SuggestWrapperAPI.propTypes = {
  url: PropTypes.string.isRequired,
}

export default SuggestWrapperAPI
