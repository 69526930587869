import React, { useState } from 'react'
import { Grid } from 'component/base'
import { Custom } from 'component/pieces'

export const GinkoShiten = props => {
  const { kinyukikan, shiten, required } = props

  const { value } = kinyukikan || {}

  const [selectedId, setSelectedId] = useState(value)

  const handleKinyukikanChange = (e, { name, value }) => setSelectedId(value)

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Custom.Dropdown.Kinyukikan
            {...kinyukikan}
            onChange={handleKinyukikanChange}
            required={required}
          />
        </Grid.Column>
        <Grid.Column>
          <Custom.Dropdown.KinyukikanShiten
            {...shiten}
            kinyukikan_id={selectedId}
            required={required}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
