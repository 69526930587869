import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Input,
  Text,
  Popup,
  Label,
} from 'component/base'

export const Hikisari = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/kumiaiin/hikisari/list',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='hikisari'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Hikisari.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Hikisari.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hikisari_jotai_kbn' ? direction : null}
        onClick={() => handleSort('hikisari_jotai_kbn')}
        content='状態'
      />
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'taisho_date' ? direction : null}
        onClick={() => handleSort('taisho_date')}
        content='締め年月'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'hikisari_date' ? direction : null}
        onClick={() => handleSort('hikisari_date')}
        content='引去日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kinyukikan_name' ? direction : null}
        onClick={() => handleSort('kinyukikan_name')}
        content='金融機関'
      />
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'kensu' ? direction : null}
        onClick={() => handleSort('kensu')}
        content='対象者数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hikisari_gaku' ? direction : null}
        onClick={() => handleSort('hikisari_gaku')}
        content='引去金額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hikisarimae_keshikomi_gaku' ? direction : null}
        onClick={() => handleSort('hikisarimae_keshikomi_gaku')}
        content='引去前消込金額'
      />
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'hikisari_shutsuryoku_kensu' ? direction : null}
        onClick={() => handleSort('hikisari_shutsuryoku_kensu')}
        content='引去出力件数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hikisari_shutsuryoku_gaku' ? direction : null}
        onClick={() => handleSort('hikisari_shutsuryoku_gaku')}
        content='引去出力金額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'data_sakusei_date' ? direction : null}
        onClick={() => handleSort('data_sakusei_date')}
        content='データ作成日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kekka_torikomi_date' ? direction : null}
        onClick={() => handleSort('kekka_torikomi_date')}
        content='結果取込日'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    taisho_date,
    hikisari_date,
    kinyukikan_name,
    data_sakusei_date,
    hikisari_gaku,
    kekka_torikomi_date,
    kensu,
    hikisari_jotai_kbn,
    hikisarimae_keshikomi_gaku,
    hikisari_shutsuryoku_kensu,
    hikisari_shutsuryoku_gaku,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          hikisari_jotai_kbn === 'new' ? (
            <Popup
              content='引去ファイルを作成していません'
              trigger={<Label color='green' content='ファイル未作成' />}
            />
          ) : hikisari_jotai_kbn === 'downloaded' ? (
            <Popup
              content='引去結果の取り込みを行ってください'
              trigger={<Label color='orange' content='ファイル作成済' />}
            />
          ) : hikisari_jotai_kbn === 'uploaded' ? (
            <Popup
              content='引去結果の取り込みが完了しています'
              trigger={<Label color='red' content='取込済み' />}
            />
          ) : (
            <></>
          )
        }
      />
      <Table.Cell style={{ width: '6.0rem' }} content={taisho_date} />
      <Table.Cell style={{ width: '8.0rem' }} content={hikisari_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={kinyukikan_name} />
      <Table.Cell style={{ width: '6.0rem' }} content={kensu} />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={hikisari_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={hikisarimae_keshikomi_gaku} />}
      />
      <Table.Cell
        style={{ width: '6.0rem' }}
        content={hikisari_shutsuryoku_kensu}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={hikisari_shutsuryoku_gaku} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={data_sakusei_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={kekka_torikomi_date} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const { taisho_date_from, taisho_date_to } = condition || {}

  return (
    <>
      <Grid.Column>
        <Form.Textbox.YM
          inputAs={Input}
          value={taisho_date_from}
          label='対象年月from'
          name='taisho_date_from'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.YM
          inputAs={Input}
          value={taisho_date_to}
          label='対象年月to'
          name='taisho_date_to'
        />
      </Grid.Column>
    </>
  )
}
