import { useEffect, useRef } from 'react'

export const useMounted = () => {
  const isMounted = useRef(false)

  useEffect(() => {
    if (!isMounted.current) isMounted.current = true
  }, [])
  return isMounted.current
}
