import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Segment } from 'semantic-ui-react'
import {
  MapContainer,
  LayersControl,
  TileLayer,
  Marker,
  Popup,
  LayerGroup,
  Circle,
} from 'react-leaflet'
import { getConfigGlobal } from 'config'

export const BaseMap = props => {
  const {
    mapStyle,
    latitude,
    longitude,
    accuracy,
    accuracyCircle,
    arroundRadius,
    nowOnMarker,
    dragging,
    mapLayers,
    zoom,
    markers,
    lines,
    loading,
  } = props || {}

  const { BaseLayer, Overlay } = LayersControl

  const position = [latitude, longitude]

  const [map, setMap] = useState(null)

  useEffect(() => {
    if (!map || !map.setView) return
    if (!latitude || !longitude) return
    map.setView([latitude, longitude], map.getZoom())
  }, [map, latitude, longitude])

  return (
    <>
      <Segment loading={loading} basic style={{ padding: 0 }}>
        <MapContainer
          center={position}
          zoom={zoom}
          style={mapStyle}
          dragging={dragging}
          whenCreated={setMap}
        >
          {markers}
          {lines}
          <LayersControl position='topright'>
            {mapLayers ? (
              mapLayers
            ) : (
              <BaseLayer name='カラーMAP' checked>
                <TileLayer
                  url={`${getConfigGlobal().tileEndpoint}/tile/{z}/{x}/{y}.png`}
                />
              </BaseLayer>
            )}

            {nowOnMarker && (
              <Overlay checked name='Marker'>
                <Marker position={position}>
                  <Popup>I'm Here</Popup>
                </Marker>
              </Overlay>
            )}

            <Overlay checked name='Layer group with circles'>
              {accuracyCircle ? (
                <LayerGroup>
                  <Circle
                    center={position}
                    color='blue'
                    fill={false}
                    radius={accuracy}
                  />
                </LayerGroup>
              ) : (
                <></>
              )}

              {arroundRadius > 0 ? (
                <LayerGroup>
                  <Circle
                    center={position}
                    color='red'
                    fill={true}
                    radius={arroundRadius}
                  />
                </LayerGroup>
              ) : (
                <></>
              )}
            </Overlay>
          </LayersControl>
        </MapContainer>
      </Segment>
    </>
  )
}

BaseMap.propTypes = {
  // latitude from gps
  latitude: PropTypes.number,

  // longitude from gps
  longitude: PropTypes.number,

  // optional, define map css style ex.height, width
  mapStyle: PropTypes.object,

  zoom: PropTypes.number,

  // optional, for various map
  mapLayers: PropTypes.object,

  // optional, from gps
  accuracy: PropTypes.number,

  // render accuracy range circle(needs props accuracy)
  accuracyCircle: PropTypes.bool,

  // if number(meters) set, render arround circle
  arroundRadius: PropTypes.number,

  // gps position marker
  nowOnMarker: PropTypes.bool,

  // If true, allows the map to be draggable with mouse/touch.
  // Defaults to true.
  dragging: PropTypes.bool,

  // whether show Route Line or not
  // if true, needs roads props
  showRoute: PropTypes.bool,

  // route start point and end point
  roads: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      start: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
        iconUrl: PropTypes.string,
        iconSize: PropTypes.array,
        popup: PropTypes.string,
      }),
      end: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
        iconUrl: PropTypes.string,
        iconSize: PropTypes.array,
        popup: PropTypes.string,
      }),
    })
  ),

  // Maker Components from react-leaflet
  markers: PropTypes.node,

  // Polyline Components from react-leaflet
  lines: PropTypes.node,
}

BaseMap.defaultProps = {
  latitude: 0,
  longitude: 0,
  zoom: 16,
  mapStyle: {
    width: '100%',
    height: '50vh',
  },
  mapLayers: null,
  accuracy: 0,
  accuracyCircle: true,
  arroundRadius: 0,
  nowOnMarker: true,
  dragging: true,
  roads: [],
  markers: null,
  lines: null,
}
