import React, { useState, useEffect } from 'react'
import { BaseForm as Form, Button, Popup } from 'component/base'
import { useUserInfo } from 'component/hook'

export const UserName = props => {
  const { value: _value, ...other } = props

  const [value, setValue] = useState(_value)
  const [reset, setReset] = useState(0)

  useEffect(() => {
    setValue(_value)
  }, [_value])

  const { given_name, family_name } = useUserInfo()

  const handleMeClick = () => {
    setValue(family_name + given_name)
    setReset(reset => ++reset)
  }

  return (
    <Form.Group inline>
      <Form.Textbox.Standard {...other} key={reset} width={14} value={value} />
      <Form.Field width={2}>
        <Popup
          content='私が担当'
          trigger={
            <Button
              as='span'
              circular
              icon='user'
              size='mini'
              onClick={handleMeClick}
            />
          }
        />
      </Form.Field>
    </Form.Group>
  )
}
