import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Dropdown,
  Icon,
  useKeycloak,
  Menu,
  S3Avatar,
  Grid,
} from 'component/base'
import { getConfig } from 'config'

const AuthStatus = () => {
  const { keycloak } = useKeycloak()

  const handleLoginClick = () =>
    (window.location.href = keycloak.createLoginUrl())

  return keycloak.authenticated ? (
    <AuthenticatedStatus keycloak={keycloak} />
  ) : (
    <Menu.Item name='Sign in' onClick={handleLoginClick} />
  )
}

export default AuthStatus

const AuthenticatedStatus = props => {
  const history = useHistory()
  const { keycloak } = useKeycloak()

  const [userInfo, setUserInfo] = useState({})

  const {
    // email,
    // email_verified,
    family_name,
    given_name,
    // locale,
    // name, // 氏名が入っている。ただし姓名の順
    // preferred_username,  // ログインIDが入っている
    // 上記以外にもcustom attributeをつけているとそれも入ってくる
  } = userInfo || {}

  useEffect(() => {
    keycloak.loadUserInfo().success(userInfo => setUserInfo(userInfo))
  }, [])

  return (
    <>
      <Dropdown
        item
        icon=''
        floating
        pointing='top right'
        trigger={
          <>
            <S3Avatar avatar />
            <Grid>
              <Grid.Row only='computer'>
                <Grid.Column>
                  <span>{`${family_name} ${given_name}`}</span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        }
      >
        <Dropdown.Menu>
          <S3Avatar
            size='medium'
            circular
            centered
            style={{ padding: '30px' }}
          />
          <Dropdown.Item
            content={
              <>
                <Icon name='user' />
                <span>{`${family_name} ${given_name}`}</span>
              </>
            }
          />
          {/*
          <Dropdown.Item
            content={
              <>
                <Icon name='help' />
                <span>ヘルプ</span>
              </>
            }
          />
          <Dropdown.Item
            content={
              <>
                <Icon name='comments' />
                <span>要望・問題のお問い合わせ</span>
              </>
            }
          />
          */}
          <Dropdown.Item onClick={() => history.push('/term_of_use')}>
            <Icon name='user' />
            <span>利用規約</span>
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              keycloak.accountManagement()
            }}
          >
            <Icon name='user' />
            <span>アカウント設定</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              keycloak.logout({
                redirectUri: getConfig().top_url,
              })
            }}
          >
            <Icon name='sign-out' />
            <span>ログアウト</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}
