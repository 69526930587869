import React, {
  createElement,
  cloneElement,
  useEffect,
  useState,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withFormsy } from '@akihirotakamura/formsy-react'
import { Form, Input, TextArea, Label } from 'semantic-ui-react'

const FormsyInput = props => {
  const {
    setValue,
    onChange,
    instantValidation,
    onBlur,

    id,
    inputAs,
    inputClassName,
    required,
    label,
    defaultValue,
    getValue,
    isValid,
    isPristine,
    getErrorMessage,
    errorLabel,
    isFormSubmitted,
    value,
    // Form.Field props
    as,
    width,
    className,
    disabled,
    inline,
    passRequiredToField,
    suppressLabel,
  } = props

  const [allowError, setAllowError] = useState(false)

  useEffect(() => {
    if (defaultValue) setValue(defaultValue)
  }, [])

  useEffect(() => {
    setValue(value)
  }, [value])

  useEffect(() => {
    setAllowError(isFormSubmitted() ? true : false)
  }, [isFormSubmitted()])

  const error = useMemo(() => !isPristine() && !isValid() && allowError, [
    isPristine(),
    isValid(),
    allowError,
  ])

  const shortHandMode = useMemo(
    () => inputAs === Form.Input || inputAs === Form.TextArea,
    [inputAs]
  )

  const inputNode = useMemo(
    () => (shortHandMode ? createElement(inputAs).props.control : inputAs),
    [shortHandMode, inputAs]
  )

  const handleChange = (e, data) => {
    const { value } = data
    setValue(value)
    if (onChange) onChange(e, data, isValid())
    if (instantValidation) setAllowError(true)
  }

  const handleBlur = e => {
    setAllowError(true)
    if (onBlur) onBlur(e, { value: e.target.value }, isValid())
  }

  return (
    <Form.Field
      as={as}
      className={className}
      required={required && passRequiredToField}
      error={!disabled && error}
      width={width}
      inline={inline}
      disabled={disabled}
      style={{ position: 'relative' }}
    >
      {shortHandMode && label && <label htmlFor={id}> {label} </label>}
      {createElement(inputNode, {
        ...filterSuirElementProps(props),
        value: getValue() || (isPristine() && defaultValue) || '',
        onChange: handleChange,
        onBlur: handleBlur,
        className: inputClassName,
        error:
          shortHandMode && inputAs === Form.TextArea
            ? undefined
            : !disabled && error,
        label: shortHandMode ? undefined : _.isString(label) ? (
          <Label
            content={label}
            style={{
              backgroundColor: required ? '#fbdfab' : null,
            }}
          />
        ) : (
          label
        ),
        id,
      })}
      {!disabled &&
        error &&
        errorLabel &&
        !suppressLabel &&
        cloneElement(errorLabel, {}, getErrorMessage())}
    </Form.Field>
  )
}

export default withFormsy(FormsyInput)

const filterSuirElementProps = props => {
  const {
    as, // eslint-disable-line
    instantValidation, // eslint-disable-line
    error, // eslint-disable-line
    defaultChecked, // eslint-disable-line
    defaultSelected, // eslint-disable-line
    rootClassName, // eslint-disable-line
    rootStyle, // eslint-disable-line
    defaultValue, // eslint-disable-line
    rootElement, // eslint-disable-line
    errorLabel, // eslint-disable-line
    formRadioGroup, // eslint-disable-line
    getValue, // eslint-disable-line
    isPristine, // eslint-disable-line
    isValid, // eslint-disable-line
    getErrorMessage, // eslint-disable-line
    setValidations, // eslint-disable-line
    setValue, // eslint-disable-line
    resetValue, // eslint-disable-line
    hasValue, // eslint-disable-line
    getErrorMessages, // eslint-disable-line
    isFormDisabled, // eslint-disable-line
    isFormSubmitted, // eslint-disable-line
    isRequired, // eslint-disable-line
    showRequired, // eslint-disable-line
    showError, // eslint-disable-line
    isValidValue, // eslint-disable-line
    validations, // eslint-disable-line
    validationError, // eslint-disable-line
    validationErrors, // eslint-disable-line
    width, // eslint-disable-line
    passRequiredToField, // eslint-disable-line
    inputAs, // eslint-disable-line
    innerRef, //eslint-disable-line
    inline, // eslint-disable-line
    suppressLabel, // eslint-disable-line
    ...suirProps
  } = props

  return suirProps
}

FormsyInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.number,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  passRequiredToField: PropTypes.bool,
  inputAs: PropTypes.oneOf([Input, TextArea, Form.Input, Form.TextArea]),
  errorLabel: PropTypes.element,
  required: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  instantValidation: PropTypes.bool,
  defaultValue: PropTypes.string,
  onBlur: PropTypes.func,
  isValid: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isPristine: PropTypes.func.isRequired,
  getErrorMessage: PropTypes.func.isRequired,
  validationError: PropTypes.string,
  validationErrors: PropTypes.object,
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  suppressLabel: PropTypes.bool,
}

FormsyInput.defaultProps = {
  inputAs: Input,
  passRequiredToField: true,
  suppressLabel: false,
}
