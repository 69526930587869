import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Dropdown from 'component/base/Form/Dropdown'
import { Axios } from 'component/base'

export const Sharyo = props => {
  const { kumiaiin_cd, ...other } = props

  const [options, setOptions] = useState([])

  const getOptions = async () => {
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/kumiaiinSharyo?kumiaiin_cd=${kumiaiin_cd}`
    )
    if (error) return
    const customOptions = data.map(d => {
      return {
        key: d.key,
        value: d.id,
        text: d.shatai_no,
      }
    })
    setOptions(customOptions)
  }

  useEffect(() => {
    getOptions()
  }, [kumiaiin_cd])

  return <Dropdown.Standard {...other} options={options} />
}

Sharyo.propTypes = {
  kumiaiin_cd: PropTypes.string.isRequired,
}

Sharyo.defaultProps = {
  kumiaiin_cd: '',
}
