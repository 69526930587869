import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Grid, Segment, Table, Dimmer, Loader } from 'semantic-ui-react'
import { StickyTable } from './StickyTable'
import { DownloadCsvButton } from './DownloadCsvButton'

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const DataTableSkeleton = props => {
  const {
    data,
    originalData,
    tableHeader: TableHeader,
    tableRow: TableRow,
    filterBox,
    pagination,
    paginationDisplay,
    pageLimitSelector,
    topControl,
    bottomControl,
    loading,
    scrollX,
    tableHeight,
    size,
    style,
    selectedIds,
    orderOptions,
  } = props || {}

  const defaultHandleSort = () => {}
  const prevState = usePrevious({
    data,
    selectedIds,
    orderOptions,
  })
  const [rows, setRows] = useState(null)

  useEffect(() => {
    if (
      !prevState ||
      _.isEqual(prevState, {
        data,
        selectedIds,
        orderOptions,
      })
    )
      return

    setRows(
      _(data)
        .map((row, i) => (
          <TableRow key={i} data={row} selectedIds={selectedIds} />
        ))
        .value()
    )
  }, [data, selectedIds, orderOptions])

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <div style={style}>
        <Segment
          attached='top'
          style={{
            padding: '1em 1em 0em 1em',
            border: 'none',
          }}
        >
          <Grid doubling columns={1}>
            <Grid.Row>
              <Grid.Column style={{ paddingLeft: 0 }}>
                {topControl}
                {filterBox}
                {paginationDisplay}
                {pageLimitSelector}
                {pagination}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <StickyTable
          celled
          selectable
          sortable
          xy={scrollX}
          height={tableHeight}
          size={size}
        >
          <StickyTable.Header>
            <TableHeader
              column={null}
              direction={null}
              handleSort={defaultHandleSort}
            />
          </StickyTable.Header>
          <Table.Body>{rows}</Table.Body>
        </StickyTable>

        <Segment
          attached='bottom'
          style={{
            border: 'none',
          }}
        >
          <Grid doubling columns={2}>
            <Grid.Row>
              <Grid.Column>
                <DownloadCsvButton data={data} originalData={originalData} />
              </Grid.Column>
              <Grid.Column>{bottomControl}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    </>
  )
}

DataTableSkeleton.propTypes = {
  data: PropTypes.array,
  originalData: PropTypes.array,
  tableHeader: PropTypes.func.isRequired,
  tableRow: PropTypes.func.isRequired,
  filterBox: PropTypes.element,
  pagination: PropTypes.element,
  pageLimitSelector: PropTypes.element,
  paginationDisplay: PropTypes.element,
  topControl: PropTypes.element,
  bottomControl: PropTypes.element,
  loading: PropTypes.bool,
  scrollX: PropTypes.bool,
  tableHeight: PropTypes.string,
  selectedIds: PropTypes.array,
}

DataTableSkeleton.defaultProps = {
  data: [],
  originalData: [],
  filterBox: <></>,
  pagination: <></>,
  pageLimitSelector: <></>,
  paginationDisplay: <></>,
  topControl: <></>,
  bottomControl: <></>,
  loading: false,
  scrollX: false,
  tableHeight: '70vh',
  selectedIds: [],
}
