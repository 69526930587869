import { useState, useEffect } from 'react'
import _ from 'lodash'
import { Axios, notice } from 'component/base'

export const useWebKyusha = (id, refresh) => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    haisha_draft: [],
    web_kyusha: {},
    web_kyusha_haisha: {},
    web_kyusha_keijiban: {},
    web_kyusha_mail: {},
  })

  const {
    haisha_draft,
    web_kyusha,
    web_kyusha_haisha,
    web_kyusha_keijiban,
    web_kyusha_mail,
  } = state || {}

  const BASE_URL = '/api/staff/haisha/webrequire'

  const ajax_get = async () => {
    if (!id) {
      setState(EMPTY_ROW)
      return
    }

    setLoading(true)
    const { data, error } = await Axios.ajax.get(`${BASE_URL}/${id}`)
    setLoading(false)

    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      return { error }
    }
    setState(data)
  }

  const ajax_post = async (_data, callback) => {
    setLoading(true)

    // idを削除 mock用。apiではid入ってきても無視する
    const body = _.assign({}, state, _data)
    delete body.id

    const { data, error } = await Axios.ajax.post(BASE_URL, body)
    setLoading(false)
    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      if (callback) callback({ error })
      return
    }

    notice({
      title: '登録しました',
      type: 'info',
    })
    setState(data)
    if (callback) callback({ data })
  }

  const ajax_patch = async (_data, callback) => {
    const {
      haisha_draft: _haisha_draft,
      web_kyusha: _web_kyusha,
      web_kyusha_keijiban: _web_kyusha_keijiban,
      web_kyusha_mail: _web_kyusha_mail,
      boshu_hoho_keijiban,
      boshu_hoho_mail,
    } = _data || {}

    if (!id) return
    setLoading(true)
    const { data, error } = await Axios.ajax.patch(`${BASE_URL}`, {
      haisha_draft: _(_haisha_draft)
        .map(row =>
          _.assign(
            {},
            row,
            _(haisha_draft).find(
              org =>
                row.web_kyusha_id === org.web_kyusha_id &&
                row.haisha_id === org.haisha_id &&
                row.haisha_draft_id === org.haisha_draft_id
            )
          )
        )
        .values(),
      web_kyusha: _.assign({}, web_kyusha, _web_kyusha),
      web_kyusha_haisha,
      web_kyusha_keijiban:
        _.values(_web_kyusha_keijiban).length === 0
          ? {}
          : _.assign({}, web_kyusha_keijiban, _web_kyusha_keijiban),
      web_kyusha_mail:
        _.values(_web_kyusha_mail).length === 0
          ? {}
          : _.assign({}, web_kyusha_mail, _web_kyusha_mail),
      boshu_hoho_keijiban,
      boshu_hoho_mail,
    })
    setLoading(false)
    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      if (callback) callback({ error })
      return
    }
    notice({
      title: '更新しました',
      type: 'info',
    })
    setState(data)
    if (callback) callback({ data })
  }

  const ajax_delete = async callback => {
    if (!id) return
    setLoading(true)
    const { error } = await Axios.ajax.delete(`${BASE_URL}/${id}`)
    setLoading(false)
    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      if (callback) callback({ error })
      return
    }
    notice({
      title: '削除しました',
      type: 'info',
    })
    if (callback) callback({})
  }

  useEffect(() => {
    ajax_get()
  }, [id, refresh])

  return {
    loading,
    ajax_post,
    ajax_patch,
    ajax_delete,
    web_kyusha_data: state,
    haisha_draft,
    web_kyusha,
    web_kyusha_haisha,
    web_kyusha_keijiban,
    web_kyusha_mail,
  }
}

const EMPTY_ROW = {
  haisha_draft: [],
  web_kyusha: {
    id: null,
    biko: '',
    koshin_datetime: '',
    kyusha_appeal: '',
    name: '',
    preferred_username: '',
    r_web_kyusha_id: null,
    tanso_cd: '',
    unsohin_name: '',
    is_ichigen: false,
    iraisha_id: null,
    iraisha_kbn: null,
    iraisha_name: null,
  },
  web_kyusha_haisha: [],
  web_kyusha_keijiban: {
    id: null,
    area_id_list: [],
    is_chusen_mode: false,
    is_tanso_zentai_kokai: false,
    keiji_kokai_datetime: '',
    web_kyusha_id: null,
  },
  web_kyusha_mail: {
    id: null,
    hatchi_area_id_list: [],
    kumiaiin_id_list: [],
    kyusha_mail_keishiki_kbn: '',
    kyusha_mail_send_datetime: '',
    select_bin_komoku_id_list: [],
    team_id_list: [],
    web_kyusha_id: 0,
  },
}
