import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  BaseForm as Form,
  Checkbox,
  DataTable,
  Grid,
  Table,
  ConditionWrapper,
  Segment,
  useKeycloak,
} from 'component/base'
import { Custom } from 'component/pieces'
import { useUserInfo } from 'component/hook'

export const KumiaiinMaintain = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const { active_busho_id } = useUserInfo()

  const { keycloak } = useKeycloak()

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {
      is_dattai: false,
      zokusei_shozoku_busho_id:
        keycloak && keycloak.hasRealmRole('manage_kumiaiin')
          ? undefined
          : active_busho_id,
    }
  }, [active_busho_id, keycloak && keycloak.hasRealmRole('manage_kumiaiin')])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [displayData, setdisplayData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )
  const [isRecentOnly, setIsRecentOnly] = useState(true)

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
          ? [selectedId]
          : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id)),
      data
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/kumiaiin/kumiaiin/list',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return

    setData(data.map(record => ({
      ...record,
      formattedTorokuNo:
        typeof record.zokusei_tekikaku_invoice_toroku_no === 'string'
          ? record.zokusei_tekikaku_invoice_toroku_no.replace(/-/g, '')
          : ''
    })))
    setSelectedIds([])
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  useEffect(
    () =>
      setdisplayData(
        isRecentOnly
          ? _(data)
            .orderBy(['kumiaiin_id', 'tekiyo_start_date'], ['asc', 'desc'])
            .uniqBy('kumiaiin_id')
            .value()
          : data
      ),
    [isRecentOnly, data]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
          size='large'
        >
          <DataTable
            name='kumiaiinMaintain'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={displayData}
            topControl={
              <Checkbox
                toggle
                checked={isRecentOnly}
                label='最新世代のみ表示'
                onChange={(e, { checked }) => setIsRecentOnly(checked)}
              />
            }
            bottomControl={<></>}
            selectedIds={selectedIds}
            size='large'
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

KumiaiinMaintain.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

KumiaiinMaintain.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => { },
}

const TableHeader = props => {
  const { column, direction, withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kumiaiin_cd' ? direction : null}
        onClick={() => handleSort('kumiaiin_cd')}
        content='組合員コード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tekiyo_start_date' ? direction : null}
        onClick={() => handleSort('tekiyo_start_date')}
        content='適用開始日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tekiyo_end_date' ? direction : null}
        onClick={() => handleSort('tekiyo_end_date')}
        content='適用終了日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zen_kumiaiin_cd' ? direction : null}
        onClick={() => handleSort('zen_kumiaiin_cd')}
        content='全組合員コード'
      />
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'zokusei_kumiaiin_name' ? direction : null}
        onClick={() => handleSort('zokusei_kumiaiin_name')}
        content='組合員名'
      />
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'zokusei_kumiaiin_kana' ? direction : null}
        onClick={() => handleSort('zokusei_kumiaiin_kana')}
        content='組合員名カナ'
      />
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'zokusei_unsoten_name' ? direction : null}
        onClick={() => handleSort('zokusei_unsoten_name')}
        content='運送店名'
      />
      <Table.HeaderCell
        style={{ width: '22.0rem' }}
        sorted={column === 'formattedTorokuNo' ? direction : null}
        onClick={() => handleSort('formattedTorokuNo')}
        content='適格請求書発行事業者登録番号'
      />
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'zokusei_unsoten_kana' ? direction : null}
        onClick={() => handleSort('zokusei_unsoten_kana')}
        content='運送店名カナ'
      />
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'zokusei_unsoten_ryaku_name' ? direction : null}
        onClick={() => handleSort('zokusei_unsoten_ryaku_name')}
        content='運送店略称'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'zokusei_kanyu_date' ? direction : null}
        onClick={() => handleSort('zokusei_kanyu_date')}
        content='加入日'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'zokusei_dattai_date' ? direction : null}
        onClick={() => handleSort('zokusei_dattai_date')}
        content='脱退日'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={
          column === 'zokusei_isekimoto_zen_kumiaiin_cd' ? direction : null
        }
        onClick={() => handleSort('zokusei_isekimoto_zen_kumiaiin_cd')}
        content='移籍元組合員コード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zokusei_shozoku_busho_name' ? direction : null}
        onClick={() => handleSort('zokusei_shozoku_busho_name')}
        content='所属部署'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={
          column === 'haisha_settei_shozoku_haiso_center_name'
            ? direction
            : null
        }
        onClick={() => handleSort('haisha_settei_shozoku_haiso_center_name')}
        content='配送センター'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    upload_files_id,
    kumiaiin_cd,
    tekiyo_start_date,
    tekiyo_end_date,
    zen_kumiaiin_cd,
    zokusei_kumiaiin_name,
    zokusei_kumiaiin_kana,
    zokusei_unsoten_name,
    zokusei_unsoten_kana,
    zokusei_unsoten_ryaku_name,
    zokusei_kanyu_date,
    zokusei_dattai_date,
    zokusei_isekimoto_zen_kumiaiin_cd,
    zokusei_shozoku_busho_name,
    haisha_settei_shozoku_haiso_center_name,
    formattedTorokuNo
  } = data || {}


  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          <>
            <Custom.Image.KumiaiinAvator
              id={upload_files_id}
              kumiaiin_cd={kumiaiin_cd}
              avatar
            />
            {kumiaiin_cd}
          </>
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={tekiyo_start_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={tekiyo_end_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={zen_kumiaiin_cd} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={zokusei_kumiaiin_name}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={zokusei_kumiaiin_kana}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={zokusei_unsoten_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={formattedTorokuNo} />
      <Table.Cell style={{ width: '10.0rem' }} content={zokusei_unsoten_kana} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={zokusei_unsoten_ryaku_name}
      />
      <Table.Cell style={{ width: '8.0rem' }} content={zokusei_kanyu_date} />
      <Table.Cell style={{ width: '8.0rem' }} content={zokusei_dattai_date} />
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={zokusei_isekimoto_zen_kumiaiin_cd}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={zokusei_shozoku_busho_name}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={haisha_settei_shozoku_haiso_center_name}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const {
    haisha_settei_shozoku_haiso_center_id,
    zokusei_shozoku_busho_id,
    zokusei_kumiaiin_name,
    zokusei_kumiaiin_kana,
    zokusei_unsoten_name,
    zokusei_unsoten_kana,
    zokusei_shozoku_chiku_id,
    zokusei_shozoku_meiboyo_chiku_id,
    haisha_settei_haisha_chui_jiko,
    kumiaiin_cd,
    tel,
    mobile_tel,
    toroku_no,
    seibetsu,
    is_dattai: _is_dattai,
  } = condition

  const [is_dattai, set_is_dattai] = useState(_is_dattai)
  const [busho_id, set_busho_id] = useState(zokusei_shozoku_busho_id)
  const [chiku_id, set_chiku_id] = useState(zokusei_shozoku_chiku_id)
  const [meiboyo_chiku_id, set_meiboyo_chiku_id] = useState(
    zokusei_shozoku_meiboyo_chiku_id
  )
  const [chiku_reset, set_chiku_reset] = useState(0)
  const [meiboyo_chiku_reset, set_meiboyo_chiku_reset] = useState(0)
  const [is_sodai, set_is_sodai] = useState()
  const [is_tekikaku_invoice, set_is_tekikaku_invoice] = useState()

  useEffect(() => {
    set_is_dattai(_is_dattai)
  }, [_is_dattai])

  useEffect(() => {
    set_chiku_reset(reset => ++reset)
  }, [busho_id])

  useEffect(() => {
    set_meiboyo_chiku_reset(reset => ++reset)
  }, [busho_id])

  useEffect(() => {
    set_busho_id(zokusei_shozoku_busho_id)
  }, [zokusei_shozoku_busho_id])

  useEffect(() => {
    set_chiku_id(zokusei_shozoku_chiku_id)
  }, [zokusei_shozoku_chiku_id])

  useEffect(() => {
    set_meiboyo_chiku_id(zokusei_shozoku_meiboyo_chiku_id)
  }, [zokusei_shozoku_meiboyo_chiku_id])

  useEffect(() => {
    set_is_sodai(condition.is_sodai)
  }, [condition.is_sodai])

  useEffect(() => {
    set_is_tekikaku_invoice(condition.is_tekikaku_invoice)
  }, [condition.is_tekikaku_invoice])

  return (
    <>
      <Grid.Column>
        <Custom.Dropdown.Busho
          value={busho_id}
          label='所属部署'
          name='zokusei_shozoku_busho_id'
          clearable
          enableAuthValue={false}
          level_list={[0, 1, 2]}
          onChange={(e, { value }) => {
            console.log('onchange!!')
            console.log(value)
            set_busho_id(value)
            set_chiku_id(null)
            set_meiboyo_chiku_id(null)
          }}
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Chiku
          key={chiku_reset}
          value={chiku_id}
          label='所属地区'
          name='zokusei_shozoku_chiku_id'
          multiple
          clearable
          busho_id_list={[busho_id]}
          disabled={!busho_id}
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.MeiboyoChiku
          key={meiboyo_chiku_reset}
          value={meiboyo_chiku_id}
          label='所属名簿用地区'
          name='zokusei_shozoku_meiboyo_chiku_id'
          multiple
          clearable
          busho_id_list={[busho_id]}
          disabled={!busho_id}
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.HaisoCenter
          value={haisha_settei_shozoku_haiso_center_id}
          label='所属配送センター'
          name='haisha_settei_shozoku_haiso_center_id'
          multiple
          clearable
          enableAuthValue={false}
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          value={zokusei_kumiaiin_name}
          label='組合員名'
          name='zokusei_kumiaiin_name'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          value={zokusei_kumiaiin_kana}
          label='組合員名カナ'
          name='zokusei_kumiaiin_kana'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          value={zokusei_unsoten_name}
          label='運送店名'
          name='zokusei_unsoten_name'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          value={zokusei_unsoten_kana}
          label='運送店名カナ'
          name='zokusei_unsoten_kana'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          value={kumiaiin_cd}
          label='組合員コード'
          name='kumiaiin_cd'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Phone value={tel} label='電話番号' name='tel' />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Phone
          value={mobile_tel}
          label='携帯電話番号'
          name='mobile_tel'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          value={toroku_no}
          label='車番下４桁'
          name='toroku_no'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Seibetsu
          value={seibetsu}
          label='性別'
          name='seibetsu'
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          value={haisha_settei_haisha_chui_jiko}
          label='配車注意事項'
          name='haisha_settei_haisha_chui_jiko'
        />
      </Grid.Column>
      <Grid.Column width={5}>
        <Form.Group>
          <Form.Checkbox.Buttony
            checked={is_dattai}
            label={
              _.isBoolean(is_dattai) && is_dattai
                ? '脱退者のみ'
                : _.isBoolean(is_dattai) && !is_dattai
                  ? '脱退者以外'
                  : '脱退者指定なし'
            }
            name='is_dattai'
            size='mini'
            onChange={(_e, { checked }) => set_is_dattai(checked)}
            insertEmptyLabel
            enableIndeterminate
          />

          <Form.Checkbox.Buttony
            checked={is_sodai}
            label={
              is_sodai === true
                ? '総代のみ'
                : is_sodai === false
                  ? '総代以外'
                  : '総代指定なし'
            }
            name='is_sodai'
            size='mini'
            onChange={(_e, { checked }) => set_is_sodai(checked)}
            insertEmptyLabel
            enableIndeterminate
          />

          <Form.Checkbox.Buttony
            checked={is_tekikaku_invoice}
            label={
              is_tekikaku_invoice === true
                ? '適格請求書発⾏事業者のみ'
                : is_tekikaku_invoice === false
                  ? '適格請求書発⾏事業者以外'
                  : '適格請求書発⾏事業者指定なし'
            }
            name='is_tekikaku_invoice'
            size='mini'
            onChange={(_e, { checked }) => set_is_tekikaku_invoice(checked)}
            insertEmptyLabel
            enableIndeterminate
          />
        </Form.Group>
      </Grid.Column>
    </>
  )
}
