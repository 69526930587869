import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import moment from 'moment'
import {
  Axios,
  DataTable,
  Grid,
  Table,
  ConditionWrapper,
  BaseForm as Form,
  Segment,
  Text,
  Checkbox,
} from 'component/base'
import { Custom } from 'component/pieces'
import { useUserInfo } from 'component/hook'

export const Ukebarai = withRouter(props => {
  const { history, enableQueryCondition } = props

  const { active_busho_id } = useUserInfo()

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {
      hyoji_month: moment().format('YYYY/MM'),
      busho_id: active_busho_id,
    }
  }, [active_busho_id])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [displayData, setDisplayData] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )
  const [isGroupByShohin, setIsGroupByShohin] = useState(true)

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    if (!data || !_.isArray(data)) return
    if (!isGroupByShohin) {
      setDisplayData(data)
      return
    }
    setDisplayData(
      _(data)
        .groupBy(row => [row.shohin_id, row.busho_id])
        .map((rows, key) => {
          const row = rows[0] || {}
          return {
            ...row,
            zeinuki_gaku: _.max(_.map(rows, 'zeinuki_gaku')),
            zengetsu_zan_su: _.sumBy(rows, 'zengetsu_zan_su'),
            zengetsu_zan_gaku: _.sumBy(rows, 'zengetsu_zan_gaku'),
            togetsu_shiire_su: _.sumBy(rows, 'togetsu_shiire_su'),
            togetsu_shiire_gaku: _.sumBy(rows, 'togetsu_shiire_gaku'),
            togetsu_uriage_su: _.sumBy(rows, 'togetsu_uriage_su'),
            togetsu_uriage_gaku: _.sumBy(rows, 'togetsu_uriage_gaku'),
            togetsu_uriage_shiire_gaku: _.sumBy(
              rows,
              'togetsu_uriage_shiire_gaku'
            ),
            togetsu_zaiko_ido_su: _.sumBy(rows, 'togetsu_zaiko_ido_su'),
            togetsu_zaiko_ido_gaku: _.sumBy(rows, 'togetsu_zaiko_ido_gaku'),
            togetsu_zaiko_chosei_su: _.sumBy(rows, 'togetsu_zaiko_chosei_su'),
            togetsu_zaiko_chosei_gaku: _.sumBy(
              rows,
              'togetsu_zaiko_chosei_gaku'
            ),
            togetsu_shiire_henpin_su: _.sumBy(rows, 'togetsu_shiire_henpin_su'),
            togetsu_shiire_henpin_gaku: _.sumBy(
              rows,
              'togetsu_shiire_henpin_gaku'
            ),
            togetsu_uriage_henpin_su: _.sumBy(rows, 'togetsu_uriage_henpin_su'),
            togetsu_uriage_henpin_gaku: _.sumBy(
              rows,
              'togetsu_uriage_henpin_gaku'
            ),
            yokugetsu_kurikoshi_su: _.sumBy(rows, 'yokugetsu_kurikoshi_su'),
            yokugetsu_kurikoshi_gaku: _.sumBy(rows, 'yokugetsu_kurikoshi_gaku'),
          }
        })
        .value()
    )
  }, [data, isGroupByShohin])

  useEffect(() => {
    callApi(condition)
  }, [condition])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/buppan/ukebarai/list',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const TableRowWithProps = useCallback(
    props => <TableRow {...props} history={history} />,
    [history]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='ukebarai'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={displayData}
            topControl={
              <Checkbox
                toggle
                checked={isGroupByShohin}
                label='ロットを集約して表示'
                onChange={(e, { checked }) => setIsGroupByShohin(checked)}
              />
            }
            bottomControl={<></>}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Ukebarai.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Ukebarai.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => { },
}

const TableHeader = props => {
  const { column, direction, withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'busho_id' ? direction : null}
        onClick={() => handleSort('busho_id')}
        content='部署コード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'busho_name' ? direction : null}
        onClick={() => handleSort('busho_name')}
        content='部署名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shohin_cd' ? direction : null}
        onClick={() => handleSort('shohin_cd')}
        content='商品コード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shohin_name' ? direction : null}
        onClick={() => handleSort('shohin_name')}
        content='商品名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zeinuki_gaku' ? direction : null}
        onClick={() => handleSort('zeinuki_gaku')}
        content='仕入原価（税抜）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zengetsu_zan_su' ? direction : null}
        onClick={() => handleSort('zengetsu_zan_su')}
        content='前月残数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zengetsu_zan_gaku' ? direction : null}
        onClick={() => handleSort('zengetsu_zan_gaku')}
        content='前月残額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_shiire_su' ? direction : null}
        onClick={() => handleSort('togetsu_shiire_su')}
        content='当月仕入数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_shiire_gaku' ? direction : null}
        onClick={() => handleSort('togetsu_shiire_gaku')}
        content='当月仕入額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_shiire_henpin_su' ? direction : null}
        onClick={() => handleSort('togetsu_shiire_henpin_su')}
        content='当月仕入返品数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_shiire_henpin_gaku' ? direction : null}
        onClick={() => handleSort('togetsu_shiire_henpin_gaku')}
        content='当月仕入返品額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_uriage_su' ? direction : null}
        onClick={() => handleSort('togetsu_uriage_su')}
        content='当月売上数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_uriage_gaku' ? direction : null}
        onClick={() => handleSort('togetsu_uriage_gaku')}
        content='当月売上額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_uriage_shiire_gaku' ? direction : null}
        onClick={() => handleSort('togetsu_uriage_shiire_gaku')}
        content='当月売上原価額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_uriage_henpin_su' ? direction : null}
        onClick={() => handleSort('togetsu_uriage_henpin_su')}
        content='当月売上返品数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_uriage_henpin_gaku' ? direction : null}
        onClick={() => handleSort('togetsu_uriage_henpin_gaku')}
        content='当月売上返品額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_uriage_henpin_shiire_gaku' ? direction : null}
        onClick={() => handleSort('togetsu_uriage_henpin_shiire_gaku')}
        content='当月売上返品原価額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_zaiko_ido_su' ? direction : null}
        onClick={() => handleSort('togetsu_zaiko_ido_su')}
        content='当月在庫移動数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_zaiko_ido_gaku' ? direction : null}
        onClick={() => handleSort('togetsu_zaiko_ido_gaku')}
        content='当月在庫移動額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_zaiko_chosei_su' ? direction : null}
        onClick={() => handleSort('togetsu_zaiko_chosei_su')}
        content='当月在庫調整数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'togetsu_zaiko_chosei_gaku' ? direction : null}
        onClick={() => handleSort('togetsu_zaiko_chosei_gaku')}
        content='当月在庫調整額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'yokugetsu_kurikoshi_su' ? direction : null}
        onClick={() => handleSort('yokugetsu_kurikoshi_su')}
        content='翌月繰越数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'yokugetsu_kurikoshi_gaku' ? direction : null}
        onClick={() => handleSort('yokugetsu_kurikoshi_gaku')}
        content='翌月繰越額'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data } = props || {}

  const {
    busho_id,
    busho_name,
    // shohin_id,
    shohin_cd,
    shohin_name,
    zeinuki_gaku,
    zengetsu_zan_su,
    zengetsu_zan_gaku,
    togetsu_shiire_su,
    togetsu_shiire_gaku,
    togetsu_shiire_henpin_su,
    togetsu_shiire_henpin_gaku,
    togetsu_uriage_su,
    togetsu_uriage_gaku,
    togetsu_uriage_shiire_gaku,
    togetsu_uriage_henpin_su,
    togetsu_uriage_henpin_gaku,
    togetsu_uriage_henpin_shiire_gaku,
    togetsu_zaiko_ido_su,
    togetsu_zaiko_ido_gaku,
    togetsu_zaiko_chosei_su,
    togetsu_zaiko_chosei_gaku,
    yokugetsu_kurikoshi_su,
    yokugetsu_kurikoshi_gaku,
  } = data || {}

  return withOrder(
    <Table.Row>
      <Table.Cell style={{ width: '10.0rem' }} content={busho_id} />
      <Table.Cell style={{ width: '10.0rem' }} content={busho_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={shohin_cd} />
      <Table.Cell style={{ width: '10.0rem' }} content={shohin_name} />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zeinuki_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zengetsu_zan_su} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zengetsu_zan_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_shiire_su} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_shiire_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_shiire_henpin_su} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_shiire_henpin_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_uriage_su} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_uriage_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_uriage_shiire_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_uriage_henpin_su} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_uriage_henpin_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_uriage_henpin_shiire_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_zaiko_ido_su} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_zaiko_ido_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_zaiko_chosei_su} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={togetsu_zaiko_chosei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={yokugetsu_kurikoshi_su} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={yokugetsu_kurikoshi_gaku} />}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const {
    hyoji_month,
    busho_id,
    shohin_id,
    is_sub_include: _is_sub_include,
    is_sub_aggregate: _is_sub_aggregate,
  } = condition

  const [is_sub_include, set_is_sub_include] = useState(false)
  const [is_sub_aggregate, set_is_sub_aggregate] = useState(false)

  useEffect(() => {
    set_is_sub_include(_is_sub_include || false)
    set_is_sub_aggregate(_is_sub_aggregate || false)
  }, [_is_sub_include, _is_sub_aggregate])

  return (
    <>
      <Grid.Column>
        <Form.Textbox.YM
          value={hyoji_month}
          label='表示月'
          name='hyoji_month'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Busho
          value={busho_id}
          label='部署'
          name='busho_id'
          required
          enableAuthValue={false}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <Form.Group>
          <Form.Field>
            <label>　</label>
            <Form.Checkbox.Buttony
              checked={is_sub_include}
              label='配下を含める'
              name='is_sub_include'
              size='tiny'
              onChange={(e, { checked }) => {
                set_is_sub_include(checked)
                set_is_sub_aggregate(false)
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>　</label>
            <Form.Checkbox.Buttony
              checked={is_sub_aggregate}
              label='配下を集計して表示'
              name='is_sub_aggregate'
              size='tiny'
              readOnly={!is_sub_include}
              onChange={(e, { checked }) => set_is_sub_aggregate(checked)}
            />
          </Form.Field>
        </Form.Group>
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Shohin
          value={shohin_id}
          label='商品'
          name='shohin_id'
          clearable
        />
      </Grid.Column>
    </>
  )
}
