import React from 'react'
import { ApiLabel } from 'component/base'
import { StaticLabel } from './StaticLabel'

const Empty = props => <>-</>

export const HaisoCenter = props => {
  const { id } = props
  if (!id) return <Empty />

  return (
    <ApiLabel
      url={`api/staff/master/haiso_center/${id}`}
      textName='haiso_center_name'
    />
  )
}

export const Kinyukikan = props => {
  const { id } = props
  if (!id) return <Empty />

  return (
    <ApiLabel
      url={`api/staff/master/kinyukikan/${id}`}
      textName='kinyukikan_name'
    />
  )
}

export const Busho = props => {
  const { id } = props
  if (!id) return <Empty />

  return <ApiLabel url={`api/staff/master/busho/${id}`} textName='busho_name' />
}

export const Shohin = props => {
  const { id } = props
  if (!id) return <Empty />

  return (
    <ApiLabel url={`api/staff/master/shohin/${id}`} textName='shohin_name' />
  )
}

export const Iraisha = props => {
  const { id } = props
  if (!id) return <Empty />

  return (
    <ApiLabel url={`api/staff/master/iraisha/${id}`} textName='iraisha_name' />
  )
}

export const Shashu = props => {
  const { id } = props
  if (!id) return <Empty />

  return <ApiLabel url={`api/staff/master/shashu/${id}`} textName='name' />
}

export const TorihikisakiKbn = props => {
  return <StaticLabel kubun_name='torihikisaki_kbn' value={props.value} />
}

export const HaishaHoho = props => {
  return <StaticLabel kubun_name='haisha_hoho' value={props.value} />
}

export const JuchuShubetsu = props => {
  return <StaticLabel kubun_name='juchu_shubetsu' value={props.value} />
}

export const KosokuRiyo = props => {
  return <StaticLabel kubun_name='kosoku_riyo' value={props.value} />
}

export const Seikyu = props => {
  return <StaticLabel kubun_name='seikyu_kbn' value={props.value} />
}

export const Seikyusaki = props => {
  return <StaticLabel kubun_name='seikyusaki_kbn' value={props.value} />
}

export const Shukin = props => {
  return <StaticLabel kubun_name='shukin_kbn' value={props.value} />
}

export const BloodType = props => {
  return <StaticLabel kubun_name='blood_type' value={props.value} />
}

export const Daisha = props => {
  return <StaticLabel kubun_name='daisha_kbn' value={props.value} />
}

export const HasuShori = props => {
  return (
    <StaticLabel
      kubun_name='seikyu_tani_zei_hasu_shori_kbn'
      value={props.value}
    />
  )
}

export const Hoken = props => {
  return <StaticLabel kubun_name='hoken' value={props.value} />
}

export const Jotai = props => {
  return <StaticLabel kubun_name='jotai' value={props.value} />
}

export const Kaishutsuki = props => {
  return <StaticLabel kubun_name='kaishutsuki' value={props.value} />
}

export const Kenchiku = props => {
  return <StaticLabel kubun_name='kenchiku_kbn' value={props.value} />
}

export const Kouzou = props => {
  return <StaticLabel kubun_name='kouzou' value={props.value} />
}

export const Shakokouzou = props => {
  return <StaticLabel kubun_name='shako_kouzou' value={props.value} />
}

export const Shako = props => {
  return <StaticLabel kubun_name='shako' value={props.value} />
}

export const KurikoshiUmu = props => {
  return <StaticLabel kubun_name='kurikoshi_umu_kbn' value={props.value} />
}

export const NyukinKinshu = props => {
  return <StaticLabel kubun_name='nyukin_kinshu_kbn' value={props.value} />
}

export const Seibetsu = props => {
  return <StaticLabel kubun_name='seibetsu' value={props.value} />
}

export const Tokuisaki = props => {
  return <StaticLabel kubun_name='tokuisaki_kbn' value={props.value} />
}

export const TorihikiKbn = props => {
  return <StaticLabel kubun_name='torihiki' value={props.value} />
}

export const HanbaisakiKbn = props => {
  return <StaticLabel kubun_name='hanbaisaki_kbn' value={props.value} />
}

export const WarimashiRate = props => {
  return <StaticLabel kubun_name='warimashi_rate' value={props.value} />
}

export const WarimashiRyokin = props => {
  return <StaticLabel kubun_name='warimashi_ryokin' value={props.value} />
}

export const Zeikeisan = props => {
  return <StaticLabel kubun_name='zei_keisan_kbn' value={props.value} />
}

export const KumiaiinGetsujiShimeKbn = props => {
  return (
    <StaticLabel kubun_name='kumiaiin_getsuji_shime_kbn' value={props.value} />
  )
}

export const GpsTaikiJotaiKbn = props => {
  return <StaticLabel kubun_name='gps_taiki_jotai_kbn' value={props.value} />
}

export const HaishaKiboKbn = props => {
  return <StaticLabel kubun_name='haisha_kibo_kbn' value={props.value} />
}

export const Shokuin = props => {
  return <StaticLabel kubun_name='shokuin_kbn' value={props.value} />
}

export const ShohinTaniKbn = props => {
  return <StaticLabel kubun_name='shohin_tani_kbn' value={props.value} />
}

export const ShintaiKbn = props => {
  return <StaticLabel kubun_name='shintai_kbn' value={props.value} />
}

export const KaokuKbn = props => {
  return <StaticLabel kubun_name='kaoku_kbn' value={props.value} />
}

export const HaishaHasseiKbn = props => {
  return <StaticLabel kubun_name='haisha_hassei_kbn' value={props.value} />
}

export const YumekukanKeijibanKbn = props => {
  return <StaticLabel kubun_name='yumekukan_keijiban_kbn' value={props.value} />
}

export const WarimashiHasuShoriKbn = props => {
  return (
    <StaticLabel kubun_name='warimashi_hasu_shori_kbn' value={props.value} />
  )
}

export const GyoshuKbn = props => {
  return <StaticLabel kubun_name='gyoshu_kbn' value={props.value} />
}

export const HikisariKekkaKbn = props => {
  return <StaticLabel kubun_name='hikisari_kekka_kbn' value={props.value} />
}

export const SaikenMeimokuKbn = props => {
  return <StaticLabel kubun_name='saiken_meimoku_kbn' value={props.value} />
}

export const SaimuMeimokuKbn = props => {
  return <StaticLabel kubun_name='saimu_meimoku_kbn' value={props.value} />
}

export const YokinKbn = props => {
  return <StaticLabel kubun_name='yokin_kbn' value={props.value} />
}

export const JikanShiteiKbn = props => {
  return <StaticLabel kubun_name='jikan_shitei_kbn' value={props.value} />
}

export const FurikomiTesuryoFutanKbn = props => {
  return (
    <StaticLabel kubun_name='furikomi_tesuryo_futan_kbn' value={props.value} />
  )
}

export const OshiraseHyojiTaishoKbn = props => {
  return (
    <StaticLabel kubun_name='oshirase_hyoji_taisho_kbn' value={props.value} />
  )
}

export const AkabouhelpShurui = props => {
  return <StaticLabel kubun_name='help_shurui' value={props.value} />
}

export const MenkyoshoAddressKakuninKbn = props => {
  return (
    <StaticLabel
      kubun_name='menkyosho_address_kakunin_kbn'
      value={props.value}
    />
  )
}

export const ShinseiShubetsu = props => {
  return <StaticLabel kubun_name='shinsei_shubetsu' value={props.value} />
}
