import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Standard from './Standard'
import moment from 'moment'

const TekikakuInvoiceNo = props => {
  const {
    validations,
    onChange,
    onBlur,
    value: _value,
    required,
    ...other
  } = props

  const [value, setValue] = useState(_value)

  useEffect(() => {
    setValue(_value)
  }, [_value])

  const handleOnChange = (e, { value }, valid) => {
    const editedValue = edit(value)
    setValue(editedValue)
    onChange(e, { value: editedValue }, isValid(value))
  }

  const handleOnBlur = (e, { value }, valid) => {
    const editedValue = edit(value)
    setValue(editedValue)
    onBlur(e, { value: editedValue }, isValid(value))
  }

  const isValid = val => {
    if (required && (!val || val === '')) return false
    if (!val || val === '') return true

    const plain = val.toString().replace(/-|T/g, '')
    return plain.match(/^[0-9]{13}$/) ? true : false
  }

  const edit = val => {
    if (!isValid(val)) return val || ''
    if (!val || val === '') return val
    return val
      .toString()
      .replace(/-|T/g, '')
      .replace(/^(\d{1})(\d{4})(\d{4})(\d{4})$/, 'T$1-$2-$3-$4')
  }

  return (
    <Standard
      {...other}
      validations={_.merge({}, validations, {
        isTekikakuInvoiceNo: true,
        maxLength: 17,
      })}
      required={required}
      value={value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  )
}

export default TekikakuInvoiceNo

TekikakuInvoiceNo.propTypes = {
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
}

TekikakuInvoiceNo.defaultProps = {
  validations: {},
  onChange: () => {},
  onBlur: () => {},
  value: '',
  required: false,
}
