import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'
import { Custom } from 'component/pieces'

export const Kumiaiin = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/kumiaiin/list/slim'
      keyName='id'
      valueName='torihikisaki_id'
      textName='unsoten_name'
      editOptions={options =>
        _(options)
          .map(option => ({
            key: option.id,
            value: option.torihikisaki_id,
            text: `${option.kumiaiin_cd || '-'}：${option.unsoten_name || '-'}`,
            kumiaiin_cd: option.kumiaiin_cd,
            dattai_date: option.dattai_date,
            label: {
              color: option.dattai_date ? 'red' : 'green',
              empty: true,
              circular: true,
            },
            image: (
              <Custom.Image.KumiaiinAvator
                key={option.id}
                id={option.upload_files_id}
                avatar
              />
            ),
          }))
          .value()
      }
      sort={['dattai_date', 'kumiaiin_cd']}
      sortOrders={['desc', 'asc']}
      {...props}
    />
  )
}

Kumiaiin.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

Kumiaiin.defaultProps = {
  name: 'torihikisaki_id',
  label: '組合員',
  value: undefined,
}
