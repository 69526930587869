import React, { Component } from 'react'
import Standard from './Standard'

class Slider extends Component {
  render = () => {
    return <Standard {...this.props} slider />
  }
}

export default Slider
