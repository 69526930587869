import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
} from 'component/base'
import { Custom } from 'component/pieces'

export const SosaiKekka = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/kumiaiin/sosai/list',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='kumiaiinMail'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

SosaiKekka.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

SosaiKekka.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'saiken_taisho_date_from' ? direction : null}
        onClick={() => handleSort('saiken_taisho_date_from')}
        content='対象日FROM（債権）'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'saiken_taisho_date_to' ? direction : null}
        onClick={() => handleSort('saiken_taisho_date_to')}
        content='対象日TO（債権）'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'saimu_taisho_date_from' ? direction : null}
        onClick={() => handleSort('saimu_taisho_date_from')}
        content='対象日FROM（債務）'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'saimu_taisho_date_to' ? direction : null}
        onClick={() => handleSort('saimu_taisho_date_to')}
        content='対象日TO（債務）'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={
          column === 'saiken_shiwake_pattern_torihiki_name' ? direction : null
        }
        onClick={() => handleSort('saiken_shiwake_pattern_torihiki_name')}
        content='相殺対象項目(債権)'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={
          column === 'saimu_shiwake_pattern_torihiki_name' ? direction : null
        }
        onClick={() => handleSort('saimu_shiwake_pattern_torihiki_name')}
        content='相殺対象項目(債務)'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'torihikisaki_kbn' ? direction : null}
        onClick={() => handleSort('torihikisaki_kbn')}
        content='取引先区分'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'sakusei_date' ? direction : null}
        onClick={() => handleSort('sakusei_date')}
        content='作成日'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'preferred_username' ? direction : null}
        onClick={() => handleSort('preferred_username')}
        content='作成者'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    saiken_taisho_date_from,
    saiken_taisho_date_to,
    saimu_taisho_date_from,
    saimu_taisho_date_to,
    saiken_shiwake_pattern_torihiki_name,
    saimu_shiwake_pattern_torihiki_name,
    torihikisaki_kbn_name,
    sakusei_date,
    preferred_username,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={saiken_taisho_date_from}
      />
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={saiken_taisho_date_to}
      />
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={saimu_taisho_date_from}
      />
      <Table.Cell style={{ width: '12.0rem' }} content={saimu_taisho_date_to} />
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={saiken_shiwake_pattern_torihiki_name}
      />
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={saimu_shiwake_pattern_torihiki_name}
      />
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={torihikisaki_kbn_name}
      />
      <Table.Cell style={{ width: '12.0rem' }} content={sakusei_date} />
      <Table.Cell style={{ width: '12.0rem' }} content={preferred_username} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const {
    saiken_shiwake_pattern_torihiki_id,
    saimu_shiwake_pattern_torihiki_id,
    torihikisaki_kbn,
  } = condition || {}

  return (
    <>
      <Grid.Column>
        <Custom.Dropdown.ShiwakePatternTorihiki
          name='saiken_shiwake_pattern_torihiki_id'
          label='相殺対象項目(債権)'
          value={saiken_shiwake_pattern_torihiki_id}
          major_kbn_list='kumiaiin'
          middle_kbn_list='sosai'
          hassei_moto_kbn='off'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.ShiwakePatternTorihiki
          name='saimu_shiwake_pattern_torihiki_id'
          label='相殺対象項目(債務)'
          value={saimu_shiwake_pattern_torihiki_id}
          major_kbn_list='kumiaiin'
          middle_kbn_list='sosai'
          hassei_moto_kbn='on'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.TorihikisakiKbn
          name='torihikisaki_kbn'
          label='取引先区分'
          value={torihikisaki_kbn}
        />
      </Grid.Column>
    </>
  )
}
