import { addValidationRule } from '@akihirotakamura/formsy-react'

addValidationRule('isProhibited', (values, value) => {
  if (!value || value === '') return true
  return (
    !/"/.test(value) &&
    !/'/.test(value) &&
    !/</.test(value) &&
    !/>/.test(value) &&
    !/&/.test(value)
  )
})
