import React, { useState, useEffect } from 'react'
import { Axios, Image, Text, Grid } from 'component/base'
import axios from 'axios'
import { useUnmounted } from 'component/hook'
import { getConfig } from 'config'

export const UploadImage = props => {
  const NO_IMAGE_URL = '/images/noimage.jpg'

  const unmounted = useUnmounted()
  const [blob, setBlob] = useState(null)
  const [loading, setLoading] = useState(false)
  const [source] = useState(axios.CancelToken.source())
  const [name, setName] = useState('')

  const { id, ...other } = props

  const { api_endpoint } = getConfig()

  useEffect(() => {
    const callApi = async () => {
      const { data, error } = await Axios.ajax.get(
        `${api_endpoint}/api/staff/upload_files/info/${id}`
      )

      if (error) return
      getBlob(id, data.mime_type, data.original_name)
    }

    callApi()
  }, [id])

  useEffect(() => {
    if (unmounted.current) source.cancel('your request was canceled')
  }, [unmounted])

  const getBlob = async (id, mime_type, original_name) => {
    if (!id) {
      setBlob(null)
      return
    }

    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `${api_endpoint}/api/staff/upload_files/${id}`,
      {
        responseType: 'blob',
        cancelToken: source.token,
        params: {},
      }
    )
    if (unmounted.current) return
    setLoading(false)

    if (error || !data) return
    if (
      mime_type !== 'image/png' &&
      mime_type !== 'image/jpeg' &&
      typeof original_name === 'string'
    ) {
      setName(getFileName(original_name))
      return
    }
    setBlob(new Blob([data], { type: data.type }))
  }

  const getFileName = contentDisposition => {
    return decodeURI(
      contentDisposition
        .substring(
          contentDisposition.indexOf("''") + 1,
          contentDisposition.length
        )
        .replace(/\+/g, ' ')
    )
  }

  return (
    <>
      {loading ? (
        <Image
          src={NO_IMAGE_URL}
          onError={e => {
            e.target.onerror = null
            e.target.src = NO_IMAGE_URL
          }}
          {...other}
        />
      ) : blob ? (
        <Image
          src={(window.URL || window.webkitURL).createObjectURL(blob)}
          onError={e => {
            e.target.onerror = null
            e.target.src = NO_IMAGE_URL
          }}
          {...other}
        />
      ) : name ? (
        <Grid>
          <Grid.Row></Grid.Row>
          <Grid.Row>
            <Text.Basic
              content={
                '　　　画像以外の添付ファイルはプレビュー表示できません（' +
                name +
                '）'
              }
            />
          </Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
      ) : (
        <Image
          src={NO_IMAGE_URL}
          onError={e => {
            e.target.onerror = null
            e.target.src = NO_IMAGE_URL
          }}
          {...other}
        />
      )}
    </>
  )
}
