import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Button,
  Grid,
  Segment,
  DataTable,
  BaseForm as Form,
  Table,
  Checkbox,
  ConditionWrapper,
  Label,
} from 'component/base'
import { Custom } from 'component/pieces'

export const KumiaiinTorokuShinsei = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [shinseiShubetsu, setShinseiShubetsu] = useState('')
  const [kumiaiinId, setKumiaiinId] = useState()
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )
  const [is_shinsei_kekka, set_is_shinsei_kekka] = useState()

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(selectedIds, shinseiShubetsu, kumiaiinId)
  }, [selectedIds, shinseiShubetsu, kumiaiinId])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition, is_shinsei_kekka)
    setSelectedIds([])
  }, [condition, is_shinsei_kekka, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async (condition, is_shinsei_kekka) => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/kumiaiin/kumiaiin_shinsei',
      {
        params: {
          ...condition,
          is_shinsei_kekka,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (
    selectedId,
    selected,
    selectedShubetsu,
    kumiaiin_id
  ) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
    setShinseiShubetsu(selectedShubetsu)
    setKumiaiinId(kumiaiin_id)
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
        selectedShubetsu={shinseiShubetsu}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='oshirase'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            topControl={
              <Button.Group fluid attached size='huge'>
                <Button
                  basic
                  color={is_shinsei_kekka === false ? 'orange' : 'blue'}
                  content='受付中'
                  onClick={() => set_is_shinsei_kekka(false)}
                />

                <Button
                  basic
                  color={is_shinsei_kekka === true ? 'orange' : 'blue'}
                  content='処理済み'
                  onClick={() => set_is_shinsei_kekka(true)}
                />
              </Button.Group>
            }
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

KumiaiinTorokuShinsei.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

KumiaiinTorokuShinsei.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'kumiaiin_name' ? direction : null}
        onClick={() => handleSort('kumiaiin_name')}
        content='組合員'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
        content='運送店'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'shinsei_shubetsu' ? direction : null}
        onClick={() => handleSort('shinsei_shubetsu')}
        content='申請種別'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'shinsei_datetime' ? direction : null}
        onClick={() => handleSort('shinsei_datetime')}
        content='申請日時'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'toroku_shinsei_kekka_kbn_name' ? direction : null}
        onClick={() => handleSort('toroku_shinsei_kekka_kbn_name')}
        content='申請結果'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect, selectedShubetsu } =
    props || {}

  const {
    id,
    kumiaiin_id,
    kumiaiin_name,
    unsoten_name,
    shinsei_shubetsu,
    shinsei_datetime,
    toroku_shinsei_kekka_kbn_name,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 && selectedShubetsu === shinsei_shubetsu
      ? true
      : false
  )

  const checkboxChange = (event, { value, shubetsu, checked, kumiaiin_id }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked, shubetsu, kumiaiin_id)
  }

  return withOrder(
    <Table.Row positive={isSelected}>
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox
            checked={isSelected}
            onChange={checkboxChange}
            value={id}
            shubetsu={shinsei_shubetsu}
            kumiaiin_id={kumiaiin_id}
          />
        }
      />
      <Table.Cell style={{ width: '9.0rem' }} content={kumiaiin_name} />
      <Table.Cell style={{ width: '9.0rem' }} content={unsoten_name} />
      <Table.Cell
        style={{ width: '9.0rem' }}
        content={<Custom.Label.ShinseiShubetsu value={shinsei_shubetsu} />}
      />
      <Table.Cell style={{ width: '9.0rem' }} content={shinsei_datetime} />
      <Table.Cell
        style={{ width: '9.0rem' }}
        content={toroku_shinsei_kekka_kbn_name}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const [busho_id, set_busho_id] = useState(null)
  const [chiku_id, set_chiku_id] = useState(null)
  const [chiku_reset, set_chiku_reset] = useState(0)

  useEffect(() => {
    set_busho_id(condition.busho_id)
  }, [condition])

  useEffect(() => {
    set_chiku_id(condition.chiku_id)
  }, [condition.chiku_id])

  useEffect(() => {
    set_chiku_reset(reset => ++reset)
  }, [busho_id])

  return (
    <>
      <Grid.Column>
        <Custom.Dropdown.Busho
          value={busho_id}
          label='部署'
          name='busho_id'
          multiple
          clearable
          enableAuthValue={false}
          level_list={[0, 1, 2]}
          onChange={(e, { value }) => {
            set_busho_id(value)
            set_chiku_id(null)
          }}
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Chiku
          disabled={!busho_id}
          key={chiku_reset}
          value={chiku_id}
          label='地区'
          name='chiku_id'
          busho_id_list={[busho_id]}
          onChange={(e, { value }) => {
            set_chiku_id(value)
          }}
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.ShinseiShubetsu
          value={condition.shinsei_shubetsu}
          label='申請種別'
          name='shinsei_shubetsu'
          clearable
        />
      </Grid.Column>
    </>
  )
}
