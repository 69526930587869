import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Polyline } from 'react-leaflet'
import { useOsrm } from 'component/hook'

export const OsrmPolyLine = props => {
  const {
    baseLatLng,
    targetLatLng,
    distanceFirst,
    enableToll,
    ...other
  } = props

  const options = useMemo(
    () => ({
      exclude: enableToll ? undefined : 'toll',
    }),
    [enableToll]
  )

  const {
    loading,
    shortestDistanceRoute,
    shortestDurationRoute,
    getCoordinates,
  } = useOsrm(baseLatLng, targetLatLng, options)

  const coodinates = useMemo(
    () =>
      getCoordinates(
        distanceFirst ? shortestDistanceRoute : shortestDurationRoute
      ),
    [
      distanceFirst,
      getCoordinates,
      shortestDistanceRoute,
      shortestDurationRoute,
    ]
  )

  return !loading && coodinates && coodinates.length !== 0 ? (
    <Polyline {...other} positions={coodinates} />
  ) : (
    <></>
  )
}

OsrmPolyLine.propTypes = {
  baseLatLng: PropTypes.array,
  targetLatLng: PropTypes.array,
}

OsrmPolyLine.defaultProps = {
  baseLatLng: [],
  targetLatLng: [],
}
