import { useState, useEffect } from 'react'
import _ from 'lodash'
import { Axios, useKeycloak } from 'component/base'

export const useSystemSetteiShokichi = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const { keycloak } = useKeycloak()

  const ajax = async key => {
    setLoading(true)

    const { data, error } = await Axios.ajax.get(
      keycloak.hasRealmRole('shokuin')
        ? `/api/staff/master/system_settei_shokichi`
        : `/api/member/master/system_settei_shokichi`,
      {}
    )
    if (error) {
      setLoading(false)
      return
    }

    setData(_.isArray(data) ? data[0] : data)
    setLoading(false)
  }

  useEffect(() => {
    ajax()
  }, [])

  return {
    data,
    loading,
  }
}
