import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Input,
  Text,
} from 'component/base'

export const MadoguchiMeimokuMajor = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
          ? [selectedId]
          : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/madoguchi_meimoku_major',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='madoguchi_meimoku_major'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

MadoguchiMeimokuMajor.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

MadoguchiMeimokuMajor.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => { },
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'major_name' ? direction : null}
        onClick={() => handleSort('major_name')}
        content='大分類名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'is_azukarikin' ? direction : null}
        onClick={() => handleSort('is_azukarikin')}
        content='預り金'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('zeikomi_gaku')}
        content='税込金額'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'zei_gaku' ? direction : null}
        onClick={() => handleSort('zei_gaku')}
        content='消費税金額'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihiki_name' ? direction : null}
        onClick={() => handleSort('torihiki_name')}
        content='取引種類'
      />
      <Table.HeaderCell
        style={{ width: '30.0rem' }}
        sorted={column === 'biko' ? direction : null}
        onClick={() => handleSort('biko')}
        content='備考'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    major_name,
    is_azukarikin,
    zeikomi_gaku,
    zei_gaku,
    torihiki_name,
    biko,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '15.0rem' }} content={major_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={is_azukarikin ? '-' : '対象'}
      />
      <Table.Cell
        style={{
          width: '12.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zeikomi_gaku} />}
      />
      <Table.Cell
        style={{
          width: '12.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zei_gaku} />}
      />
      <Table.Cell style={{ width: '15.0rem' }} content={torihiki_name} />
      <Table.Cell style={{ width: '30.0rem' }} content={biko} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const { major_name } = condition

  return (
    <>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={major_name}
          label='大分類名'
          name='major_name'
        />
      </Grid.Column>
    </>
  )
}
