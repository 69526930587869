import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Axios, Modal, Button } from 'component/base'
import { PageContainer } from 'component/pieces'
import { New } from 'component/page/Staff/Kumiaiin/Mail/New'

export const MailModal = props => {
  const { isOpenModal, onClickCancel, unsoten_id, setShowModal } = props

  const [kumiaiinData, setKumiaiinData] = useState({})
  const handleOnOpen = () => {
    getApiUnsoten()
  }

  const getApiUnsoten = async () => {
    if (!unsoten_id) return
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/unsoten/${unsoten_id}`
    )
    if (error) return
    setKumiaiinData(data)
  }

  //データをリスト変換
  const list = {
    unsoten_name: kumiaiinData.unsoten_name,
    oshirase_mail: [kumiaiinData.mail],
  }

  const mailList = useMemo(() => [list], [kumiaiinData])

  return (
    <Modal
      dimmer='inverted'
      open={isOpenModal}
      onMount={handleOnOpen}
      onClose={onClickCancel}
      closeOnEscape={true}
      closeOnDimmerClick={false}
      size='large'
    >
      <Modal.Header content={'お知らせメール作成'} />

      <Modal.Content>
        <Modal.Description>
          <PageContainer style={{ width: '90vw' }}>
            <New
              mode={'modal'}
              setKumiaiinData={setKumiaiinData}
              kumiaiinData={mailList}
              setShowMailModal={setShowModal}
            />
          </PageContainer>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => onClickCancel(false)} content='閉じる' />
      </Modal.Actions>
    </Modal>
  )
}

MailModal.propTypes = {
  data: PropTypes.object,
  isOpenModal: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickDecision: PropTypes.func,
}

MailModal.defaultProps = {
  data: {},
  isOpenModal: false,
  onClickCancel: () => {},
  onClickDecision: () => {},
}
