import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Text,
} from 'component/base'
import { Custom } from 'component/pieces'

export const AzukarikinDetail = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
    torihikisaki_id,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {
      torihikisaki_id,
    }
  }, [torihikisaki_id])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/kumiaiin/azukarikin/list',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  const edited_data = useMemo(
    () =>
      _(data)
        .map(row => ({
          ...row,
          zangaku: (row.kingaku || 0) - (row.keshikomizumi_kingaku || 0),
        }))
        .value(),
    [data]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='kumiaiin-azukarikin-detail'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={edited_data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

AzukarikinDetail.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

AzukarikinDetail.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kumiaiin_cd' ? direction : null}
        onClick={() => handleSort('kumiaiin_cd')}
        content='組合員コード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kumiaiin_name' ? direction : null}
        onClick={() => handleSort('kumiaiin_name')}
        content='組合員名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
        content='運送店名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kingaku' ? direction : null}
        onClick={() => handleSort('kingaku')}
        content='預り金額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'keshikomizumi_kingaku' ? direction : null}
        onClick={() => handleSort('keshikomizumi_kingaku')}
        content='消込済み額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zangaku' ? direction : null}
        onClick={() => handleSort('zangaku')}
        content='残額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tekiyo' ? direction : null}
        onClick={() => handleSort('tekiyo')}
        content='摘要'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    azukarikin_id: id,
    kumiaiin_cd,
    kumiaiin_name,
    unsoten_name,
    kingaku,
    keshikomizumi_kingaku,
    zangaku,
    tekiyo,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={kumiaiin_cd} />
      <Table.Cell style={{ width: '10.0rem' }} content={kumiaiin_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={unsoten_name} />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={kingaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={keshikomizumi_kingaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zangaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Text.Basic content={tekiyo} />}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  return (
    <>
      <Grid.Column
        style={{
          display: !nav || condition.torihikisaki_id ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Kumiaiin
          value={condition.torihikisaki_id}
          label='組合員'
          name='torihikisaki_id'
          disabled
        />
      </Grid.Column>

      <Grid.Column
        style={{
          display: !nav || condition.includes_keshikomizumi ? 'block' : 'none',
        }}
      >
        <Form.Field>
          <label>　</label>
          <Form.Checkbox.Buttony
            name='includes_keshikomizumi'
            label='残額がないデータも表示する'
            checked={condition.includes_keshikomizumi}
            size='small'
            enableIndeterminate
          />
        </Form.Field>
      </Grid.Column>
    </>
  )
}
