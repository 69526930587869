import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Axios, BaseForm as Form } from 'component/base'
import { useUnmounted } from 'component/hook'

export const SelectBin = props => {
  const unmounted = useUnmounted()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [category, setCategory] = useState([])

  const { focus, ...other } = props || {}

  useEffect(() => {
    get_select_bin_komoku()
  }, [])

  const get_select_bin_komoku = async () => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/select_bin_komoku`
    )
    if (unmounted.current) return

    if (error) {
      setLoading(false)
      return
    }

    setCategory(_.uniqBy(data, 'category'))
    setLoading(false)
  }

  useEffect(() => {
    setOptions(category || [])
  }, [category])

  return (
    <Form.Search.Standard
      {...other}
      loading={loading}
      icon='search'
      open={focus}
      results={_(options)
        .map((row, i) => ({
          ...row,
          key: i,
          title: row.category,
        }))
        .value()}
    />
  )
}
