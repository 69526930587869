import React from 'react'
import { Icon } from 'component/base'

export const BoolLabel = props => {
  const { value } = props

  const signal = value
    ? value === 'true'
      ? true
      : value === 'false'
        ? false
        : value
    : false

  return <Icon name={signal ? 'check' : 'minus'} />
}
