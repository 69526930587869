import { useState, useEffect } from 'react'
import moment from 'moment'
import { Axios } from 'component/base'

export const useShohizei = (criteria_date = moment().format('YYYY/MM/DD')) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const ajax = async criteria_date => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(`/api/staff/master/shohizei`, {
      params: {
        criteria_date,
      },
    })
    setLoading(false)
    if (error) return
    setData(data[0] || {})
  }

  useEffect(() => {
    ajax(criteria_date)
  }, [criteria_date])

  return {
    data,
    loading,
  }
}
