import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'
import { getConfig } from 'config'

export const Tanso = props => {
  const { value: _value, enableAll, ...other } = props

  const config = useMemo(() => getConfig(), [])

  const { tanso_cd } = config || {}

  const value = useMemo(
    () =>
      enableAll
        ? !!_value || _value === 0
          ? _value
          : tanso_cd
        : _value
        ? _value
        : tanso_cd,
    [enableAll, _value, tanso_cd]
  )

  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/tanso'
      keyName='id'
      valueName='tanso_cd'
      textName='ryaku_name'
      enableAll={enableAll}
      value={value}
      {...other}
    />
  )
}

Tanso.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  enableAll: PropTypes.bool,
}

Tanso.defaultProps = {
  name: 'tanso_cd',
  label: '単組',
  value: undefined,
  enableAll: false,
}
