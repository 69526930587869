import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Axios } from 'component/base'
import { useUnmounted } from 'component/hook'
import { Standard } from './Standard'

export const WithAPI = props => {
  const {
    url,
    disabled,
    keyName,
    valueName,
    textName,
    editOptions,
    params,
    ...other
  } = props

  const unmounted = useUnmounted()
  const [refresh, setRefresh] = useState(0)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [source] = useState(axios.CancelToken.source())

  useEffect(() => {
    callApi(url)
  }, [url])

  useEffect(() => {
    if (unmounted.current) source.cancel('your request was canceled')
  }, [unmounted])

  const callApi = async url => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(url, {
      cancelToken: source.token,
      params,
    })
    if (unmounted.current) return

    setLoading(false)

    if (error) {
      setOptions([])
      return
    }
    setRefresh(refresh => ++refresh)
    setOptions(editOptions ? editOptions(data) : edit(data))
  }

  const edit = options =>
    _.map(options, option => ({
      key: option[keyName],
      value: option[valueName],
      text: option[textName],
    }))

  return (
    <Standard
      {...other}
      key={refresh}
      options={options}
      disabled={loading || disabled}
      loading={loading}
    />
  )
}

WithAPI.propTypes = {
  url: PropTypes.string.isRequired,
  keyName: PropTypes.string,
  textName: PropTypes.string,
  valueName: PropTypes.string,
  editOptions: PropTypes.func,
  params: PropTypes.object,
}

WithAPI.defaultProps = {
  keyName: 'key',
  textName: 'text',
  valueName: 'value',
  params: {},
}

export default WithAPI
