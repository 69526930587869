import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'

export const RengokaiYakushoku = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/rengokai_yakushoku'
      keyName='id'
      valueName='id'
      textName='name'
      sort={['sort']}
      {...props}
    />
  )
}

RengokaiYakushoku.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

RengokaiYakushoku.defaultProps = {
  name: 'rengokai_yakushoku_id_list',
  label: '連合会役職',
  value: undefined,
}
