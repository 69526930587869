import { useRef, useEffect } from 'react'

/*
  useage

  const prevState = usePrevious(data)

  useEffect(() => {
    if (
      !prevState ||
      _.isEqual(prevState, data)
    ) return

    // do something

  }, [data])

*/
export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
