import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'
import kubun from 'config/kubun.json'

export const ChikuWarimashi = props => {
  const { onBlur } = props || {}
  const [open, setOpen] = useState(false)
  const options = useMemo(() => {
    return _(kubun.chiku_warimashi)
      .map((row, i) => ({
        key: i,
        title: _.toString(row.value),
      }))
      .value()
  }, [])

  return (
    <Form.Search.Standard
      {...props}
      results={options}
      onFocus={() => setOpen(true)}
      onBlur={e => {
        onBlur(e)
        setOpen(false)
      }}
      open={open}
      alignRight
    />
  )
}
