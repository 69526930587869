import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import {
  Axios,
  BaseForm as Form,
  Checkbox,
  DataTable,
  Grid,
  Table,
  ConditionWrapper,
  Segment,
  Text,
  Input,
  Dropdown,
  Button,
  Print,
  Statistic,
  Util,
} from 'component/base'
import { useUserInfo } from 'component/hook'
import { Custom } from 'component/pieces'

export const CounterHistory = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    handleDelete,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const { active_busho_id } = useUserInfo()

  const defaultCondition = useMemo(() => {
    return {
      keijo_date: moment().format('YYYY/MM/DD'),
      busho_id: active_busho_id,
    }
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [summary, setSummary] = useState({})
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )
  const [printRefresh, setPrintRefresh] = useState(0)
  const [showPrint, setShowPrint] = useState(false)

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const onClickPrint = () => {
    setPrintRefresh(refresh => ++refresh)
    setShowPrint(true)
  }

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get('/api/staff/counter/history', {
      params: { ...condition },
    })
    setLoading(false)
    if (error) return

    const { list, summary } = data || {}

    setData(list)
    setSummary(summary)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  const { zenzan, sum_nyukin_zeikomi_gaku, sum_shukkin_zeikomi_gaku, touzan } =
    summary || {}

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
        >
          <DataTable
            name='counter-history'
            size='mini'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={data}
            selectedIds={selectedIds}
            topControl={
              <>
                <Button.Group>
                  <Button
                    content='領収書再発行'
                    disabled={selectedIds.length !== 1}
                    onClick={onClickPrint}
                  />
                  <Button
                    content='取消（削除）'
                    secondary
                    disabled={selectedIds.length !== 1}
                    onClick={handleDelete}
                  />
                </Button.Group>
              </>
            }
            bottomControl={
              <>
                <Segment>
                  <Statistic.Group
                    widths='four'
                    size='mini'
                    items={[
                      {
                        key: 'zenzan',
                        label: '前日残高',
                        value: Util.Convert.intToMoney(zenzan),
                      },
                      {
                        key: 'sum_nyukin_zeikomi_gaku',
                        label: '入金合計',
                        value: Util.Convert.intToMoney(sum_nyukin_zeikomi_gaku),
                      },
                      {
                        key: 'sum_shukkin_zeikomi_gaku',
                        label: '出金合計',
                        value: Util.Convert.intToMoney(
                          sum_shukkin_zeikomi_gaku
                        ),
                      },
                      {
                        key: 'touzan',
                        label: '当日残高',
                        value: Util.Convert.intToMoney(touzan),
                      },
                    ]}
                  />
                </Segment>
              </>
            }
          />
        </ConditionWrapper>
      </Segment>

      {showPrint && (
        <Print
          key={printRefresh}
          url={`api/staff/counter/${selectedIds[0]}/ryoshusho`}
          method='get'
        />
      )}
    </>
  )
})

CounterHistory.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

CounterHistory.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { column, direction, withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }}>#</Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'madoguchi_id' ? direction : null}
        onClick={() => handleSort('madoguchi_id')}
      >
        窓口ID
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'madoguchi_shosai_id' ? direction : null}
        onClick={() => handleSort('madoguchi_shosai_id')}
      >
        窓口詳細ID
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'keijo_date' ? direction : null}
        onClick={() => handleSort('keijo_date')}
      >
        日付
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'busho_name' ? direction : null}
        onClick={() => handleSort('busho_name')}
      >
        部署
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'name' ? direction : null}
        onClick={() => handleSort('name')}
      >
        担当者
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihikisaki_kbn_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_kbn_name')}
      >
        対象者種別
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihikisaki_cd' ? direction : null}
        onClick={() => handleSort('torihikisaki_cd')}
      >
        対象者コード
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
      >
        対象者
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihikisaki_kumiaiin_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_kumiaiin_name')}
      >
        対象者氏名
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'madoguchi_kbn_name' ? direction : null}
        onClick={() => handleSort('madoguchi_kbn_name')}
      >
        窓口種類
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '30.0rem' }}
        sorted={column === 'meimoku' ? direction : null}
        onClick={() => handleSort('meimoku')}
      >
        名目
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'nyukin_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('nyukin_zeikomi_gaku')}
      >
        入金額（税込）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'nyukin_zei_gaku' ? direction : null}
        onClick={() => handleSort('nyukin_zei_gaku')}
      >
        入金額（税額）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'nyukin_keigen_zei_gaku' ? direction : null}
        onClick={() => handleSort('nyukin_keigen_zei_gaku')}
      >
        入金額（軽減税額）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shukkin_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('shukkin_zeikomi_gaku')}
      >
        出金額（税込）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shukkin_zei_gaku' ? direction : null}
        onClick={() => handleSort('shukkin_zei_gaku')}
      >
        出金額（税額）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shukkin_keigen_zei_gaku' ? direction : null}
        onClick={() => handleSort('shukkin_keigen_zei_gaku')}
      >
        出金額（軽減税額）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'ryoshusho_id' ? direction : null}
        onClick={() => handleSort('ryoshusho_id')}
      >
        領収書番号
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hakko_datetime' ? direction : null}
        onClick={() => handleSort('hakko_datetime')}
      >
        最終領収書発行日時
      </Table.HeaderCell>
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    madoguchi_id,
    madoguchi_shosai_id,
    keijo_date,
    busho_name,
    name,
    torihikisaki_kbn_name,
    torihikisaki_cd,
    torihikisaki_name,
    torihikisaki_kumiaiin_name,
    madoguchi_kbn_name,
    meimoku,
    nyukin_zeikomi_gaku,
    nyukin_zei_gaku,
    nyukin_keigen_zei_gaku,
    shukkin_zeikomi_gaku,
    shukkin_zei_gaku,
    shukkin_keigen_zei_gaku,
    ryoshusho_id,
    hakko_datetime,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(madoguchi_id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(madoguchi_id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox
            checked={isSelected}
            onChange={checkboxChange}
            value={madoguchi_id}
          />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={madoguchi_id} />
      <Table.Cell style={{ width: '10.0rem' }} content={madoguchi_shosai_id} />
      <Table.Cell style={{ width: '10.0rem' }} content={keijo_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={busho_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={torihikisaki_kbn_name}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={torihikisaki_cd} />
      <Table.Cell style={{ width: '10.0rem' }} content={torihikisaki_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={torihikisaki_kumiaiin_name}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={madoguchi_kbn_name} />
      <Table.Cell style={{ width: '30.0rem' }} content={meimoku} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Text.Comma content={nyukin_zeikomi_gaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Text.Comma content={nyukin_zei_gaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Text.Comma content={nyukin_keigen_zei_gaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Text.Comma content={shukkin_zeikomi_gaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Text.Comma content={shukkin_zei_gaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Text.Comma content={shukkin_keigen_zei_gaku} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={ryoshusho_id} />
      <Table.Cell style={{ width: '10.0rem' }} content={hakko_datetime} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const { keijo_date, busho_id } = condition || {}

  return (
    <>
      <Grid.Column>
        <Form.Textbox.DatePicker
          name='keijo_date'
          value={keijo_date}
          label='日付'
          inputAs={Input}
          required
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Busho
          name='busho_id'
          value={busho_id}
          label='部署'
          inputAs={Dropdown}
          enableAuthValue={false}
          required
        />
      </Grid.Column>
    </>
  )
}
