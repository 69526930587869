import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import queryString from 'query-string'
import {
  Dimmer,
  Loader,
  Login,
  PublicRoute,
  SecretRoute,
  Switch,
} from 'component/base'
import { Container } from 'component/pieces'
import * as Transition from 'component/base/Util/Transition'
import Authenticate from './Authenticate'
import {
  TermsOfUseFilter,
  KumiaiinFilter,
  ShokuinFilter,
  TokuisakiFilter,
  GpsTrackingFilter,
  RoleFilter,
} from 'component/filter'
import { useLocalStorage } from 'component/hook'

const Loading = () => (
  <Dimmer active page blurring='true'>
    <Loader>Loading</Loader>
  </Dimmer>
)

const Top = Loadable({
  loader: () => import('./component/page/Top'),
  loading: Loading,
})

const Error = Loadable({
  loader: () => import('./component/page/Error'),
  loading: Loading,
})

const Member = Loadable({
  loader: () => import('./component/page/Member'),
  loading: Loading,
})

const Tokuisaki = Loadable({
  loader: () => import('./component/page/Tokuisaki'),
  loading: Loading,
})

const Sample = Loadable({
  loader: () => import('./component/page/Sample'),
  loading: Loading,
})

const Staff = Loadable({
  loader: () => import('./component/page/Staff'),
  loading: Loading,
})

const query = queryString.parse(window.location.search, {
  parseNumbers: true,
  parseBooleans: true,
})

const Router = () => {

  const { busy, setLocalState } = useLocalStorage(
    'webview'
  )

  useEffect(() => {
    setLocalState({ fromWebView: query?.webview ? true : false })
  }, [])

  if (busy) return <></>

  return (
    <BrowserRouter>
      <Authenticate>
        <TermsOfUseFilter>
          <KumiaiinFilter>
            <ShokuinFilter>
              <TokuisakiFilter>
                <GpsTrackingFilter>
                  <Container>
                    <Switch>
                      <PublicRoute
                        exact
                        path='/'
                        component={Transition.ForRouter(Top)}
                      />
                      <PublicRoute
                        path='/error'
                        component={Transition.ForRouter(Error)}
                      />
                      <PublicRoute
                        path='/login'
                        component={Transition.ForRouter(Login)}
                      />
                      <RoleFilter>
                        <SecretRoute
                          path='/menu'
                          component={Transition.ForRouter(Top)}
                        />
                        <SecretRoute
                          path='/term_of_use'
                          component={Transition.ForRouter(Top)}
                        />
                        <SecretRoute
                          path='/member'
                          component={Transition.ForRouter(Member)}
                        />
                        <SecretRoute
                          path='/tokuisaki'
                          component={Transition.ForRouter(Tokuisaki)}
                        />
                        <SecretRoute
                          path='/sample'
                          component={Transition.ForRouter(Sample)}
                        />
                        <SecretRoute
                          path='/staff'
                          component={Transition.ForRouter(Staff)}
                        />
                      </RoleFilter>
                    </Switch>
                  </Container>
                </GpsTrackingFilter>
              </TokuisakiFilter>
            </ShokuinFilter>
          </KumiaiinFilter>
        </TermsOfUseFilter>
      </Authenticate>
    </BrowserRouter>
  )
}

export default Router
