import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import axios from 'axios'
import Keycloak from 'keycloak-js'
import {
  Dimmer,
  Loader,
  notice,
  List,
  Icon,
  KeycloakProvider,
  useKeycloak,
} from 'component/base'
import { useLocalStorage } from 'component/hook'
// ----------------------------------
// customize for akb multiple realm
// ----------------------------------
import { getConfig } from './config'

const { realm, clientId, clientSecret, auth_endpoint } = getConfig()

const keycloak = Keycloak({
  url: auth_endpoint,
  realm,
  clientId,
  credentials: {
    secret: clientSecret,
  },
})

const initOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
}

// Custom Axios Error handler
// @usage const { data, error } = await Axios.ajax.get(url, request)
// @see https://zukucode.com/2017/08/asynct-await-ajax-error.html
axios.interceptors.response.use(
  response => {
    return Promise.resolve({
      data: response.data,
      response,
    })
  },
  error => {
    const { response, message } = error || {}

    const { data } = response || {}

    const { status, message: api_message, details } = data || {}

    notice({
      type: 'warning',
      title: `${api_message || message} ${status || ''}`,
      description: (
        <List>
          {_(details)
            .map(detail => (
              <List.Item>{`${detail.message}(${detail.target})`}</List.Item>
            ))
            .value()}
        </List>
      ),
      time: 0,
    })

    return Promise.resolve({
      error: error.response ? error.response : error,
    })
  }
)

// Connect keycloak to axios
axios.interceptors.request.use(
  config =>
    new Promise((resolve, reject) =>
      keycloak.authenticated
        ? keycloak
            .updateToken(5)
            .success(() => {
              config.headers.Authorization = `Bearer ${keycloak.token}`
              config.headers.Realm = realm
              resolve(config)
            })
            .error(() => {
              keycloak.login()
            })
        : resolve(config)
    )
)

const Authenticate = props => (
  <KeycloakProvider
    authClient={keycloak}
    initOptions={initOptions}
    LoadingComponent={<InitializeDimmer />}
  >
    <UserInfoLayer>{props.children}</UserInfoLayer>
  </KeycloakProvider>
)

const UserInfoLayer = props => {
  const { children } = props
  const { keycloak } = useKeycloak()

  const [userInfo, setUserInfo] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [loading, setLoading] = useState(true)

  const { busy, setLocalState } = useLocalStorage('user-active-info', {})

  useEffect(() => {
    const load = async () => {
      try {
        const userInfo = await keycloak.loadUserInfo()
        setUserInfo(userInfo)
        setRefresh(refresh => ++refresh)
      } catch (error) {
        setLoading(false)
        setRefresh(refresh => ++refresh)
        return
      }
    }

    setTimeout(load, 500)
  }, [])

  useEffect(() => {
    if (refresh === 0) return
    setLocalState(state => _.assign({}, state, userInfo))
    setLoading(false)
  }, [refresh])

  const elem = useMemo(
    () =>
      busy || loading ? (
        <Dimmer active page blurring='true'>
          <Loader>Initializing User Information...</Loader>
        </Dimmer>
      ) : (
        children
      ),
    [children, busy, loading]
  )

  return <>{elem}</>
}

export default Authenticate

export const InitializeDimmer = () => (
  <Dimmer active page>
    <Icon loading name='spinner' />
    Initializing....
  </Dimmer>
)
