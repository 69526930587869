import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withFormsy } from '@akihirotakamura/formsy-react'
import _ from 'lodash'
import { Icon, Modal, Button, BaseForm as Form } from 'component/base'
import { PageContainer, Custom } from 'component/pieces'

export const Shohin = withFormsy(props => {
  const {
    // Formsy props
    isPristine,
    errorLabel,
    getErrorMessage,
    setValue,
    getValue,

    // other
    defaultValue,
    value,
    icon,
    multiple,
    onChange,
    clearable,
    ...other
  } = props

  const [showModal, setShowModal] = useState(false)
  const [savedValue, setSavedValue] = useState(value)
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    if (defaultValue || value) {
      setValue(defaultValue || value)
      setSavedValue(defaultValue || value)
    }
  }, [])

  useEffect(() => {
    if (!_.isEqual(value, savedValue)) {
      setValue(value)
      setSavedValue(value)
    }
  }, [value])

  const handleDecision = () => {
    setShowModal(false)
    setValue(multiple ? selectedId : selectedId[0])
    if (onChange)
      onChange(null, { value: multiple ? selectedId : selectedId[0] })
  }

  const handleChange = (e, data) => {
    setValue(data ? data.value : '')
    if (onChange) onChange(e, data, true)
  }

  return (
    <>
      <Form.Dropdown.WithAPI
        defaultValue={defaultValue}
        value={getValue()}
        url='/api/staff/master/shohin'
        icon={
          <Icon
            name='search'
            inverted
            circular
            link
            onClick={() => {
              if (clearable && getValue()) return
              setShowModal(true)
            }}
          />
        }
        multiple={multiple}
        onChange={handleChange}
        clearable={clearable}
        editOptions={options =>
          _(options)
            .map(option => ({
              key: option.id,
              value: option.id,
              text: `${option.shohin_cd}：${option.shohin_name}`,
              shohin_cd: option.shohin_cd,
            }))
            .value()
        }
        sort={['shohin_cd']}
        {...other}
      />
      {showModal && (
        <Modal
          dimmer='inverted'
          open={showModal}
          closeOnDimmerClick={false}
          size='fullscreen'
        >
          <Modal.Header content='商品検索' />
          <Modal.Content image scrolling>
            <Modal.Description>
              <PageContainer style={{ width: '90vw' }}>
                <Custom.DataTable.Shohin
                  onSelectChange={selectedId => setSelectedId(selectedId)}
                  multiple={multiple}
                  selectedId={getValue()}
                />
              </PageContainer>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setShowModal(false)} content='キャンセル' />
            <Button
              icon='checkmark'
              labelPosition='right'
              content='選択'
              onClick={handleDecision}
              primary
            />
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
})

Shohin.propTypes = {
  keyName: PropTypes.string,
  valueName: PropTypes.string,
  textName: PropTypes.string,
}

Shohin.defaultProps = {
  keyName: 'id',
  valueName: 'id',
  textName: 'shohin_name',
  validationErrors: {
    isDefaultRequiredValue: '必須です',
  },
}
