import _ from 'lodash'
import * as Address from './Address'
import * as Transition from './Transition'
import * as Convert from './Convert'
import * as Moji from './Moji'
import { LowDB } from './LowDB'

export class Util {
  // use like Util.Address.split
  static Address = Address
  static Transition = Transition
  static Convert = Convert
  static Moji = Moji
  static LowDB = LowDB

  /*
    push Value to Dom Element with React Handling
  */
  static pushInput = (element, value) => {
    if (!element) {
      console.warn('pushInput: element is undefined')
      return
    }
    Util.setValueThroughCustomEvent(
      element.tagName.toLowerCase(),
      element,
      value
    )
  }

  static pushInputForce = (element, value) => {
    if (!element) {
      console.warn('pushInputForce: element is undefined')
      return
    }
    Util.setValueThroughCustomEvent(element.tagName.toLowerCase(), element, '')
    Util.setValueThroughCustomEvent(
      element.tagName.toLowerCase(),
      element,
      value
    )
  }

  /*
    private method
  */
  static setValueThroughCustomEvent = (type, element, value) => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set
    const prototype = Object.getPrototypeOf(element)
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(
      prototype,
      'value'
    ).set

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value)
    } else {
      valueSetter.call(element, value)
    }
    element.dispatchEvent(new Event(type, { bubbles: true }))
  }

  /*
    light weight random string id(like UUID)
  */
  static getRandomID = (length = 16) => {
    const arr = _.times(length, i => {
      const random = (Math.random() * 16) | 0
      return (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random) // eslint-disable-line
        .toString(16)
    })
    return arr.join('')
  }

  /*
    light weight random string id(like UUID)
  */
  static getRandomID = (length = 16) => {
    const arr = _.times(length, i => {
      const random = (Math.random() * 16) | 0
      return (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random) // eslint-disable-line
        .toString(16)
    })
    return arr.join('')
  }

  /*
    get Locale from Browser
  */
  static getLocale = () => {
    // IE
    if (window.navigator.userLanguage) return window.navigator.userLanguage
    // Firefox, Opera, Other
    if (window.navigator.language) return window.navigator.language
    // IE, Opera, Other
    if (window.navigator.browserLanguage)
      return window.navigator.browserLanguage
    return undefined
  }

  /*
    Deep diff between two object
  */
  static deepObjectDiff = (base, object) =>
    _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? Util.deepObjectDiff(value, base[key])
            : value
      }
    })
}
