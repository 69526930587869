import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import Standard from './Standard'

const YMD = props => {
  const {
    validations,
    onChange,
    onBlur,
    value: _value,
    allowFuture,
    required,
    ...other
  } = props

  const [value, setValue] = useState(_value)

  useEffect(() => {
    setValue(_value)
  }, [_value])

  const handleOnChange = (e, { value }, valid) => {
    const editedValue = edit(value)
    setValue(editedValue)
    onChange(e, { value: editedValue }, isValid(value))
  }

  const handleOnBlur = (e, { value }, valid) => {
    const editedValue = edit(value)
    setValue(editedValue)
    onBlur(e, { value: editedValue }, isValid(value))
  }

  const isValid = val => {
    if (required && (!val || val === '')) return false
    if (!val || val === '') return true

    const plain = val.toString().replace(/\//g, '')
    return plain.match(/[0-9]{8}/) && moment(plain, 'YYYYMMDD').isValid()
      ? true
      : false
  }

  const edit = val => {
    if (!isValid(val)) return val || ''
    if (!val || val === '') return val
    return moment(val.toString().replace(/\//g, ''), 'YYYYMMDD').format(
      'YYYY/MM/DD'
    )
  }

  return (
    <Standard
      {...other}
      type='tel'
      validations={_.merge(
        {},
        validations,
        {
          isYMD: true,
          maxLength: 10,
        },
        allowFuture ? {} : { isUnfutureYMD: true }
      )}
      required={required}
      value={value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  )
}

export default YMD

YMD.propTypes = {
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  allowFuture: PropTypes.bool,
  required: PropTypes.bool,
}

YMD.defaultProps = {
  validations: {},
  onChange: () => {},
  onBlur: () => {},
  value: '',
  allowFuture: true,
  required: false,
}
