import React from 'react'
import PropTypes from 'prop-types'
import { Message } from 'semantic-ui-react'
import withTransition from './withTransition'

const icons = {
  info: 'info circle',
  success: 'checkmark',
  error: 'remove',
  warinig: 'warning circle',
}

const SemanticNotification = props => {
  const { type, title, description, onClose, onClick } = props

  const icon = props.icon || icons[type]

  const onDismiss = e => {
    e.stopPropagation()
    onClose()
  }

  return (
    <Message
      {...{ [type]: true }}
      onClick={onClick}
      onDismiss={onDismiss}
      header={title}
      content={description}
      icon={icon}
      size='large'
      floating
      style={{ paddingRight: '32px' }}
    />
  )
}

SemanticNotification.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'error', 'warning']).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onCLick: PropTypes.func,
  onClose: PropTypes.func,
}

SemanticNotification.defaultProps = {
  onClick: undefined,
  onClose: undefined,
  icon: undefined,
}

export default withTransition(SemanticNotification)
