import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Input from './FormsyInput'
import {
  Label as SemanticUILabel,
  Form as SemanticUIForm,
  Input as SemanticUIInput,
  TextArea as SemanticUITextArea,
} from 'semantic-ui-react'
import './style.css'

const Standard = props => {
  const {
    required,
    prohibited,
    outsideLabel,
    textAreaMode,
    validations,
    readOnly,
    className,
    alignRight,
    label,
    disabled,
    value,
    defaultValue,
    error,
    onFocus,
    inputAs: _inputAs,
    name,
    suppressLabel,
    ...other
  } = props

  if (prohibited) validations.isProhibited = true

  const inputAs = useMemo(
    () =>
      _inputAs
        ? _inputAs
        : textAreaMode && outsideLabel
        ? SemanticUIForm.TextArea
        : textAreaMode && !outsideLabel
        ? SemanticUITextArea
        : !textAreaMode && !outsideLabel
        ? SemanticUIInput
        : SemanticUIForm.Input,
    [_inputAs, textAreaMode, outsideLabel]
  )

  //TODO: i18n
  const validationErrors = useMemo(
    () => ({
      minLength: validations.minLength
        ? `${validations.minLength}文字以上にしてください`
        : '',
      maxLength: validations.maxLength
        ? `${validations.maxLength}文字以下にしてください`
        : '',
      isDefaultRequiredValue: required ? '必須です' : '',
      isProhibited: validations.isProhibited
        ? '使用できない文字が含まれています'
        : '',
      isNumeric: validations.isNumeric ? '数値を入力してください' : '',
      isEmail: validations.isEmail ? 'メールアドレスではありません' : '',
      isUrl: validations.isUrl ? 'URLではありません' : '',
      isAlpha: validations.isAlpha ? 'Not Alpha' : '',
      isAlphanumeric: validations.isAlphanumeric ? 'Not Alphanumeric' : '',
      isInt: validations.isInt ? '整数を入力してください' : '',
      isFloat: validations.isFloat ? '数値を入力してください' : '',
      isWords: validations.isWords ? '文章を入力してください' : '',
      isSpecialWords: validations.isSpecialWords ? 'Not SpecialWords' : '',
      isDecimal: validations.isDecimal ? '少数を入力してください' : '',
      isFullWidth: validations.isFullWidth ? 'Not FullWidth' : '',
      isHalfWidth: validations.isHalfWidth ? 'Not HalfWidth' : '',
      isY: validations.isY
        ? '年数のみ入力してください\n例）2020年の場合は 2020 '
        : '',
      isYM: validations.isYM
        ? '年月を入力してください\n例）2020年4月の場合は 202004 '
        : '',
      isYMD: validations.isYMD
        ? `年月日を入力してください\n例）2020年4月1日の場合は 20200401`
        : '',
      isHM: validations.isHM
        ? '時分を入力してください\n例）9時5分の場合は 0905'
        : '',
      isMoney: validations.isMoney ? '金額を入力してください' : '',
      isPhone: validations.isPhone ? '電話番号ではありません' : '',
      isPostal: validations.isPostal ? '郵便番号ではありません' : '',
      isEqual: validations.isEqual ? '同じ言葉を入力してください' : '',
      isDateTime: validations.isDateTime
        ? '年月日と時分を入力してください\n例）2020年4月1日9時5分の場合は202004010905'
        : '',
      isDateTimeSecond: validations.isDateTimeSecond
        ? '年月日と時分秒を入力してください\n例）2020年4月1日9時3分5秒の場合は20200401090305'
        : '',
      isTime: validations.isTime
        ? '時間を入力してください\n例）3時間5分の場合は 0305'
        : '',
      isUnfutureYMD: validations.isUnfutureYMD
        ? '現在日より未来の日付は入力できません'
        : '',
      isHalfKana: validations.isHalfKana
        ? '半角カナ以外の文字が含まれています'
        : '',
      isTekikakuInvoiceNo: validations.isTekikakuInvoiceNo
        ? '適格請求書発行事業者登録番号を数字13桁かT数字13桁で入力してください\n例）T9-9999-9999-9999の場合は 9999999999999 かT9999999999999'
        : '',
      isLat: validations.isLat ? '-90以上90以下で入力してください' : '',
      isLng: validations.isLng ? '-180以上180以下で入力してください' : '',
      isZoom: validations.isZoom ? '1から18の整数を入力してください' : '',
    }),
    [validations, required]
  )

  return (
    <Input
      {...other}
      name={name}
      inputAs={inputAs}
      onFocus={onFocus}
      required={required}
      validations={validations}
      validationErrors={validationErrors}
      disabled={disabled || readOnly}
      label={
        inputAs === SemanticUIForm.Input
          ? !label || label === ''
            ? '　'
            : label
          : label
      }
      className={`
        ${readOnly ? 'readOnly' : ''}
        ${alignRight ? 'alignRight' : ''}
        ${className || ''}
        `}
      value={value && value !== '' ? value.toString() : undefined}
      defaultValue={
        defaultValue && defaultValue !== ''
          ? defaultValue.toString()
          : undefined
      }
      suppressLabel={suppressLabel}
    />
  )
}

Standard.propTypes = {
  /*
    semantic-ui-react/formsy-semantic-ui-react props
    https://react.semantic-ui.com/collections/form/
    https://github.com/zabute/formsy-semantic-ui-react/blob/master/src/FormsyInput.js
  */
  /*
    Form.Field props
  */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  /*
    Input/TextArea props
  */
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  /** className for input */
  inputClassName: PropTypes.string,
  /** Input or TextArea selectable */
  inputAs: PropTypes.oneOf([
    SemanticUIForm.Input,
    SemanticUIForm.TextArea,
    SemanticUIInput,
    SemanticUITextArea,
  ]),
  /** error label */
  errorLabel: PropTypes.element,
  /** label name or element */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  /** required */
  required: PropTypes.bool,
  /** onBlur callback */
  onBlur: PropTypes.func,
  /** onChange callback */
  onChange: PropTypes.func,
  /** default value */
  defaultValue: PropTypes.string,

  /*
    formsy-react props
    https://github.com/formsy/formsy-react/blob/master/API.md
  */
  /** validate on input change */
  instantValidation: PropTypes.bool,
  /** The message that will show when the form input component is invalid. It will be used as a default error. */
  validationError: PropTypes.string,
  /** The message that will show when the form input component is invalid. */
  /** You can combine this with validationError. Keys not found in validationErrors defaults to the general error message. */
  validationErrors: PropTypes.object,
  /** A comma separated list with validation rules */
  /** Default validation is here -> https://github.com/formsy/formsy-react/blob/master/API.md#validators */
  /** useage -> https://github.com/formsy/formsy-react/blob/master/API.md#validations */
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /*
    custom props
  */
  /** validate contains prohibited character */
  prohibited: PropTypes.bool,
  /** Label position */
  outsideLabel: PropTypes.bool,
  /** whitch text or textArea */
  textAreaMode: PropTypes.bool,

  /** switch readOnly mode */
  readOnly: PropTypes.bool,

  /** switch text-align right **/
  alignRight: PropTypes.bool,

  /** suppress error label **/
  suppressLabel: PropTypes.bool,
}

Standard.defaultProps = {
  errorLabel: (
    <SemanticUILabel
      color='red'
      pointing
      floating
      as='pre'
      className='input-error-label'
    />
  ),
  required: false,
  prohibited: true,
  validationError: 'this is default invalid message',
  validations: {},
  instantValidation: true,
  outsideLabel: true,
  textAreaMode: false,
  readOnly: false,
  alignRight: false,
  suppressLabel: false,
}

export default Standard
