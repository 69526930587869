import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Standard from './Standard'

const HarfKana = props => {
  const {
    validations,
    onChange,
    onBlur,
    value: _value,
    required,
    ...other
  } = props

  const [value, setValue] = useState(_value)

  useEffect(() => {
    setValue(_value)
  }, [_value])

  const handleOnChange = (e, { value }, valid) => {
    const editedValue = edit(value)
    setValue(editedValue)
    onChange(e, { value: editedValue }, isValid(value))
  }

  const handleOnBlur = (e, { value }, valid) => {
    const editedValue = edit(value)
    setValue(editedValue)
    onBlur(e, { value: editedValue }, isValid(value))
  }

  const isValid = val => {
    if (required && (!val || val === '')) return false
    if (!val || val === '') return true
    return val.match(/^[\uff66-\uff9f]*$/) ? true : false
  }

  const edit = val => val

  return (
    <Standard
      {...other}
      type='text'
      validations={_.merge({}, validations, {
        isHalfKana: true,
      })}
      required={required}
      value={value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  )
}

export default HarfKana

HarfKana.propTypes = {
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
}

HarfKana.defaultProps = {
  validations: {},
  onChange: () => {},
  onBlur: () => {},
  value: '',
  required: false,
}
