import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import L from 'leaflet'
import { Marker, Tooltip, Popup } from 'react-leaflet'

export const OsrmMarker = props => {
  const { latLng, tooltip, popup, iconUrl, ...other } = props

  const MarkerIcon = useMemo(() => {
    return new L.Icon({
      iconUrl, //指定アイコンのパス
      iconSize: [60, 60], // アイコンのサイズ
      iconAnchor: [30, 43], //指定座標とアイコンの表示相対位置
      popupAnchor: [0, -50], //地点からのポップアップの表示相対位置
      tooltipAnchor: [30, -15], //地点からのツールチップの表示相対位置
    })
  }, [])

  return (
    <Marker position={latLng} icon={MarkerIcon} {...other}>
      {tooltip && <Tooltip permanent={true}>{tooltip}</Tooltip>}
      {popup && <Popup closeButton={false}>{popup}</Popup>}
    </Marker>
  )
}

OsrmMarker.propTypes = {
  tooltip: PropTypes.object,
  popup: PropTypes.object,
  iconUrl: PropTypes.string,
}

OsrmMarker.defaultProps = {
  iconUrl: '/images/akabokun.png',
}
