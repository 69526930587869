import React, { useEffect, useMemo, useReducer, useState, useRef }  from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import _ from 'lodash'
import {
  GraphQLProvider,
  ChatModal,
  PushNotification,
} from 'component/pieces'
import { useUserInfo } from 'component/hook'
import {
  Dropdown,
  Label,
  Icon,
  Comment,
  useKeycloak,
  Button
} from 'component/base'
import { WatchConversation } from 'component/pieces'
import { subscribeToNewUCs } from 'graphql/subscriptions'
import { getConfigGlobal } from 'config'

require('isomorphic-fetch')

const myConversations = /* GraphQL */ `
  query myConversations($first: Int) {
    me {
      conversations {
        userConversations {
          conversationId
          userId
          lastReadTime
          unreadCount
          conversation {
            name
            id
            messages(first: $first) {
              messages {
                id
                author {
                  id
                  displayName
                }
                content
                createdAt
              }
            }
          }
        }
      }
    }
  }
`

const gqlMyConversations = gql(myConversations)
const gplSubscribeToNewUCs = gql(subscribeToNewUCs)

const MenuList = props => {

  const { userInfo } = useUserInfo()

  const reducer = (_state, action) => {
    switch (action.mode) {
      case 'list':
        return { listOpen: true, tabOpen: false }
      case 'chat':
        return { listOpen: false, tabOpen: true, conversationId: action.conversationId }   
      case 'close':
        return { listOpen: false, tabOpen: false }
      default:
        return { listOpen: false, tabOpen: false }
    }
  }
  const LIST_CHUNK_SIZE = 5
  const [ listSize, setListSize ] = useState(LIST_CHUNK_SIZE)
  const [queryResult, setQueryResult] = useState([])
  const [ state, dispatch ] = useReducer(reducer, {listOpen: false, tabOpen: false})
  const { listOpen, tabOpen, conversationId } = state
  const [ unreadTotalCount, setUnreadTotalCount ] = useState(0)

  const { data, loading, refetch, subscribeToMore } = useQuery(
    gqlMyConversations,
    { variables: { first: listSize } }
  )

  const subscribeToNewUC = () => subscribeToMore({
    document: gplSubscribeToNewUCs,
    variables: { userId: userInfo.sub },
    updateQuery: (prev) => {
      refetch({ variables: { first: listSize } })
      return prev
    }
  })

  const defaultConversationId = useMemo(
    () =>
      !loading && data.me.conversations.userConversations && data.me.conversations.userConversations.length > 1 ?
        data.me.conversations.userConversations[0].conversationId
        : '',
    [loading]
  )
  

  useEffect(()=>{
    if (!loading && data) {
      const { me: { conversations: { userConversations } } } = data
      setUnreadTotalCount(userConversations.reduce((prev,current) => prev + current.unreadCount,0))
      setQueryResult(data)
    }
  }, [loading, data])

  useEffect(()=>{
    if (!loading) {
      subscribeToNewUC()
    }
  }, [loading])

  useEffect(()=>{
    const params = new URLSearchParams(window.location.search)
    if (params.get('conversationId')) dispatch({ mode: 'chat', conversationId: params.get('conversationId') })
  }, [])

  const loadMore = () => {
    if (loading) return
    setListSize(listSize + LIST_CHUNK_SIZE)
  }

  return (
    <>
      <Dropdown
        item
        floating
        icon=''
        pointing='top right'
        className='icon'
        open={listOpen}
        onOpen={() => dispatch({ mode: 'list' })}
        closeOnBlur={false}
        closeOnChange={false}
        closeOnEscape={true}
        scrolling
        trigger={
          <>
            <Icon name='bell' />
            {unreadTotalCount ? (
              <Label floating circular style={{ top: 0 }}>
                {unreadTotalCount}
              </Label>
            ) : (
              <></>
            )}
          </>
        }
      >
        <Dropdown.Menu style={{ maxHeight: '80vh', maxWidth: '40vh' }}>
          <Button icon fluid onClick={() => dispatch({ mode: 'close' })}>
            <Icon name='close' />
          </Button>
          <Dropdown.Item
            id='-1'
            key='-1'
            onClick={() =>
              dispatch({ mode: 'chat', conversationId: defaultConversationId })
            }
          >
            <Dropdown.Text>通知を開く</Dropdown.Text>
          </Dropdown.Item>
          {_(
            _(queryResult?.me?.conversations?.userConversations)
              .map(uc =>
                _(uc?.conversation?.messages?.messages)
                  .map(message => ({
                    conversationId: uc?.conversationId,
                    lastReadTime: uc?.lastReadTime,
                    messageId: message?.id,
                    senderDisplayName: message?.author?.displayName,
                    senderId: message?.author?.id,
                    createdAt: message?.createdAt,
                    content: message?.content,
                  }))
                  .filter(w => w.senderId !== userInfo.sub) // filter my message
                  .value()
              )
              .flatten()
              .partition(
                m =>
                  m?.createdAt && m.createdAt < (m.lastReadTime || 9999999999)
              )
              .reverse()
              .value()
          )
            .map((messages, i) => (
              <>
                <Dropdown.Header
                  key={i}
                  icon='tags'
                  content={
                    i === 0
                      ? `新しいメッセージ ${messages.length}件`
                      : '過去のメッセージ'
                  }
                />
                {_(messages)
                  .orderBy(['createdAt'], ['desc'])
                  .map(w => (
                    <Dropdown.Item
                      id={w.messageId}
                      key={w.messageId}
                      onClick={() =>
                        dispatch({
                          mode: 'chat',
                          conversationId: w.conversationId,
                        })
                      }
                    >
                      <Comment.Group>
                        <Comment>
                          <Comment.Avatar
                            src={`${getConfigGlobal().avatarEndpoint}${
                              w.sender
                            }`}
                            onError={e =>
                              (e.target.src = '/images/noimage.jpg')
                            }
                          />
                          <Comment.Content>
                            <Comment.Author>
                              {w.senderDisplayName}
                            </Comment.Author>
                            {w.content ? (
                              w.content
                                .split('\n')
                                .map(text => (
                                  <Comment.Text>{text}</Comment.Text>
                                ))
                            ) : (
                              <Comment.Text>　</Comment.Text>
                            )}
                          </Comment.Content>
                        </Comment>
                      </Comment.Group>
                    </Dropdown.Item>
                  ))
                  .value()}
              </>
            ))
            .value()}
          <Button fluid onClick={() => loadMore()}>
            さらに読み込む
          </Button>
        </Dropdown.Menu>
      </Dropdown>
      {loading ? (
        <></>
      ) : (
        <ChatModal
          open={tabOpen}
          onOpen={() => dispatch({ mode: 'chat' })}
          onClose={() => dispatch({ mode: 'close' })}
          onReturn={() => dispatch({ mode: 'list' })}
          defaultConversationId={conversationId}
          handleRefresh={refetch}
        />
      )}
      {_(queryResult?.me?.conversations?.userConversations)
        .map(v => v?.conversationId)
        .uniq()
        .map(conversationId =>
          conversationId ? (
            <WatchConversation
              key={conversationId}
              conversationId={conversationId}
              onMessageReceived={() => {
                refetch()
              }}
            />
          ) : (
            <></>
          )
        )
        .value()}
      {!navigator.userAgent.match(/iPhone|Android.+Mobile/) ? (
        <PushNotification />
      ) : (
        ''
      )}
    </>
  )
}


export const NotificationMenu = props => {
  const { keycloak } = useKeycloak()
  
  if (!navigator.userAgent.match(/iPhone|Android.+Mobile/) && keycloak.hasRealmRole('kumiaiin') && 'Notification' in window) {
    Notification.requestPermission().then((result) => {
      console.log(result);
    })
  }

  return (  
    <GraphQLProvider>
      <MenuList/>
    </GraphQLProvider>
  )
}


