import React from 'react'
import $ from 'jquery'
import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'
import datepickerFactory from 'jquery-datepicker'
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja'
import Router from './Router'
import { NotificationContainer, mediaStyles } from 'component/base'
import './App.css'
import 'component/base/Validator/Rule'

import 'jquery-ui/themes/base/sortable.css'
import 'jquery-ui/themes/base/resizable.css'
window.jQuery = $
require('jquery-ui/ui/widgets/sortable')
require('jquery-ui/ui/widgets/resizable')

datepickerFactory($)
datepickerJAFactory($)
$.datepicker.setDefaults($.extend($.datepicker.regional['ja']))

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/'

const App = () => (
  <>
    <style>{mediaStyles}</style>
    <Router />
    <NotificationContainer />
  </>
)

export default App
