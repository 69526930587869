import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form, useKeycloak } from 'component/base'

export const Shashu = props => {
  const { keycloak } = useKeycloak()

  return (
    <Form.Dropdown.WithAPI
      url={
        keycloak.hasRealmRole('shokuin')
          ? '/api/staff/master/shashu'
          : '/api/member/master/shashu'
      }
      keyName='id'
      valueName='id'
      textName='name'
      sort={['sort']}
      {...props}
    />
  )
}

Shashu.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

Shashu.defaultProps = {
  name: 'shashu',
  label: '車種',
  value: undefined,
}
