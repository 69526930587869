import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withFormsy } from '@akihirotakamura/formsy-react'
import _ from 'lodash'
import { Icon, Button, BaseForm as Form, Modal } from 'component/base'
import { PageContainer, Custom } from 'component/pieces'

export const Unsoten = withFormsy(props => {
  const {
    // Formsy props
    isPristine,
    errorLabel,
    getErrorMessage,
    setValue,
    getValue,

    // other
    defaultValue,
    value,
    icon,
    multiple,
    onChange,
    name,
    label,
    ichigen,
    clearable,
    showInvoice,
    ...other
  } = props

  const [showModal, setShowModal] = useState(false)
  const [savedValue, setSavedValue] = useState(value)
  const [selectedId, setSelectedId] = useState([])

  useEffect(() => {
    if (defaultValue || value) {
      setValue(defaultValue || value)
      setSavedValue(defaultValue || value)
    }
  }, [])

  useEffect(() => {
    if (!_.isEqual(value, savedValue)) {
      setValue(value)
      setSavedValue(value)
    }
  }, [value])

  const handleDecision = () => {
    setShowModal(false)
    setValue(multiple ? selectedId : selectedId[0])
    if (onChange)
      onChange(null, { value: multiple ? selectedId : selectedId[0] })
  }

  const handleChange = (e, data) => {
    setValue(data ? data.value : '')
    if (onChange) onChange(e, data, true)
  }

  return (
    <>
      <Form.Dropdown.WithAPI
        name={name}
        label={label}
        defaultValue={defaultValue}
        value={getValue()}
        url='/api/staff/master/unsoten/slim'
        icon={
          <Icon
            name='search'
            inverted
            circular
            link
            onClick={() => {
              if (clearable && getValue()) return
              setShowModal(true)
            }}
          />
        }
        multiple={multiple}
        onChange={handleChange}
        clearable={clearable}
        editOptions={options =>
          _(options)
            .map(option => ({
              key: option.id,
              value: option.id,
              text: showInvoice && !_.isEmpty(option.tekikaku_invoice_toroku_no)
                ? `適格：${option.unsoten_cd}：${option.unsoten_name}`
                : `${option.unsoten_cd}：${option.unsoten_name}`,
              unsoten_cd: option.unsoten_cd,
              dattai_date: option.dattai_date,
              label: {
                color: option.dattai_date ? 'red' : 'green',
                empty: true,
                circular: true,
              },
              image: (
                <Custom.Image.KumiaiinAvator
                  key={option.id}
                  id={option.upload_files_id}
                  kumiaiin_cd={option.unsoten_cd}
                  avatar
                />
              ),
            }))
            .value()
        }
        sort={['dattai_date', 'unsoten_cd']}
        sortOrders={['desc', 'asc']}
        {...other}
      />
      {showModal && (
        <Modal
          dimmer='inverted'
          open={showModal}
          closeOnDimmerClick
          size='fullscreen'
        >
          <Modal.Header content='運送店検索' />
          <Modal.Content image scrolling>
            <Modal.Description>
              <PageContainer style={{ width: '90vw' }}>
                <Custom.Block.Unsoten
                  multiple={multiple}
                  setSelectedId={setSelectedId}
                  selectedId={getValue()}
                />
              </PageContainer>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setShowModal(false)} content='キャンセル' />
            <Button
              icon='checkmark'
              labelPosition='right'
              content='選択'
              onClick={handleDecision}
              primary
            />
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
})

Unsoten.propTypes = {
  keyName: PropTypes.string,
  valueName: PropTypes.string,
  textName: PropTypes.string,
}

Unsoten.defaultProps = {
  keyName: 'id',
  valueName: 'id',
  textName: 'unsoten_name',
  validationErrors: {
    isDefaultRequiredValue: '必須です',
  },
}
