import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Layout, List } from 'component/base'

export const FriendsList = props => {
  const { friends, handleVote } = props

  return (
    <div>
      <List divided>
        {friends.map(friend => (
          <List.Item key={friend.id}>
            <Link to={`/sample/friends/${friend.id}`}>{friend.nameJa}</Link>
            <Layout.Button onClick={() => handleVote(friend.id)} content='+' />
          </List.Item>
        ))}
      </List>
    </div>
  )
}

FriendsList.propTypes = {
  friends: PropTypes.array,
  handleVote: PropTypes.func,
}

FriendsList.defaultProps = {
  friends: [],
  handleVote: () => {},
}
