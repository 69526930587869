import { addValidationRule } from '@akihirotakamura/formsy-react'
import moment from 'moment'

addValidationRule('isYM', (values, value) => {
  if (!value || value === '') return true
  return (
    value.replace(/\u002f/g, '').match(/[0-9]{6}/) &&
    moment(value.replace(/\u002f/g, ''), 'YYYYMM').isValid()
  )
})
