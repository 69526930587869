import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  Children,
  cloneElement,
  forwardRef,
} from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import _ from 'lodash'
import $ from 'jquery'
import { PaginativeTable } from './PaginativeTable'
import { useLocalStorage } from 'component/hook'

export const ResizableTable = props => {
  const {
    tableHeader: TableHeader,
    tableRow: TableRow,
    defaultOrder,
    resizable,
    name,
    ...other
  } = props || {}

  const ref = useRef()

  const { localState: options, setLocalState: setOptions } = useLocalStorage(
    `datatable-${name}-resizable`,
    _.times(100, i => ({
      cellIndex: i,
    }))
  )

  const withOrder = useCallback(Components => makeRows(Components, options), [
    options,
  ])

  const makeRows = (Components, options) =>
    !_.isArray(options) || options.length === 0
      ? Components
      : Children.map(Components, (Component, i) =>
          makeRow(Component, options, i)
        )

  const makeRow = (Component, options, key) =>
    !Component.props.children
      ? cloneElement(Component, {
          ...Component.props,
          key,
        })
      : cloneElement(Component, {
          ...Component.props,
          key,
          children: _.map(options, (option, j) =>
            makeCell(Component.props.children, option, j)
          ),
        })

  const makeCell = (Components, option, key) =>
    Components[option.cellIndex] ? (
      cloneElement(Components[option.cellIndex], {
        ...Components[option.cellIndex].prop,
        key,
        style: option.width
          ? _.merge({}, Components[option.cellIndex].props.style, {
              width: option.width,
              maxWidth: option.width,
            })
          : _.merge({}, Components[option.cellIndex].props.style, {
              maxWidth: Components[option.cellIndex].props.style.width,
            }),
      })
    ) : (
      <React.Fragment key={key}></React.Fragment>
    )

  const HeaderWithProps = useCallback(
    props => <TableHeader {...props} withOrder={withOrder} />,
    [TableHeader, withOrder]
  )

  const RowWithProps = useCallback(
    props => <TableRow {...props} withOrder={withOrder} />,
    [TableRow, withOrder]
  )

  const exchangeArray = (array, from, to) => {
    const arr = _.cloneDeep(array)
    arr.splice(from, 1)
    arr.splice(from, 0, array[to])
    arr.splice(to, 1)
    arr.splice(to, 0, array[from])
    console.log(arr)
    return arr
  }

  const orderChange = (from, to) => {
    if (options.length === 0) return
    setOptions(options => exchangeArray(options, from, to))
  }

  const sortableOption = useMemo(
    () => ({
      axis: 'x',
      placeholder: 'ui-state-highlight',
      helper: 'clone',
      tolerance: 'pointer',
      delay: 200,
      stop: (e, ui) => {
        const to = ui.item.index()
        $(findDOMNode(ref.current))
          .parent()
          .find('table thead tr')
          .sortable('cancel')
        const from = ui.item.index()
        orderChange(from, to)
      },
    }),
    []
  )

  const resizableOption = useMemo(() => ({
    handles: 'e',
    stop: (e, ui) =>
      setOptions(options =>
        _.map(options, (elem, i) => {
          if (i === ui.element[0].cellIndex) {
            elem.width = ui.size.width
            return elem
          }
          return elem
        })
      ),
  }))

  useEffect(() => {
    if (options.length === 0) return
    $(findDOMNode(ref.current))
      .parent()
      .find('table thead tr')
      .sortable(sortableOption)

    if (!resizable) return
    $(findDOMNode(ref.current))
      .parent()
      .find('table thead tr')
      .find('th')
      .resizable(resizableOption)
  }, [props, options])

  const PaginativeTableRef = useCallback(
    forwardRef((props, ref) => (
      <div ref={ref}>
        <PaginativeTable {...props} />
      </div>
    )),
    []
  )

  return (
    <PaginativeTableRef
      ref={ref}
      {...other}
      name={name}
      tableHeader={HeaderWithProps}
      tableRow={RowWithProps}
      orderOptions={_.cloneDeep(options)}
    />
  )
}

ResizableTable.propTypes = {
  data: PropTypes.array,
  tableHeader: PropTypes.func.isRequired,
  tableRow: PropTypes.func.isRequired,
  defaltFilterText: PropTypes.string,
  loading: PropTypes.bool,
  defaultOrder: PropTypes.array,
  resizable: PropTypes.bool,
}

ResizableTable.defaultProps = {
  data: [],
  defaltFilterText: '',
  loading: false,
  defaultOrder: undefined,
  resizable: true,
}
