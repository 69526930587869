import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useDropzone } from 'react-dropzone'
import {
  Segment,
  Button,
  Header,
  Icon,
  Card,
  Media,
  MediaContextProvider,
} from 'component/base'
import { FileCard } from './FileCard'

const FileUploader = props => {
  const {
    url,
    icon,
    onSave,
    multiple,
    disableVideoUpload,
    limitedExtents,
  } = props

  const {
    acceptedFiles: _acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone()

  const [uploadedIds, setUploadedIds] = useState([])
  const [acceptedFiles, setAcceptedFiles] = useState([])

  const handleSave = () => {
    onSave(uploadedIds, acceptedFiles)
  }

  useEffect(() => {
    setAcceptedFiles(acceptedFiles =>
      multiple
        ? [...acceptedFiles, ..._acceptedFiles]
        : !_.isArray(_acceptedFiles) || !_acceptedFiles[0]
        ? []
        : [_acceptedFiles[0]]
    )
  }, [_acceptedFiles])

  const totalFileBytes = useMemo(() => _(acceptedFiles).sumBy('size'), [
    acceptedFiles,
  ])

  const onCancel = path =>
    setAcceptedFiles(acceptedFiles =>
      _.filter(acceptedFiles, row => row.path !== path)
    )

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Segment
          placeholder
          inverted={isDragActive}
          color={isDragActive ? 'orange' : null}
        >
          <Header icon>
            <Icon name={icon} />
            {isDragActive ? (
              <p>ファイルをドロップ</p>
            ) : (
              <>
                <h2>ファイルをドラッグ＆ドロップ</h2>
                <h4>またはクリックしてファイルを選択</h4>
              </>
            )}
          </Header>
        </Segment>
      </div>

      <Segment basic>
        {_.isArray(acceptedFiles) && acceptedFiles.length > 0 && (
          <MediaContextProvider>
            <Segment basic>
              <Media greaterThanOrEqual='computer'>
                <Card.Group itemsPerRow={3}>
                  {_(acceptedFiles)
                    .map(file => (
                      <FileCard
                        key={file.path}
                        file={file}
                        url={url}
                        setIds={setUploadedIds}
                        onCancel={onCancel}
                        disableVideoUpload={disableVideoUpload}
                        MediaContextProvider
                        limitedExtents={limitedExtents}
                      />
                    ))
                    .value()}
                </Card.Group>
              </Media>

              <Media at='tablet'>
                <Card.Group itemsPerRow={2}>
                  {_(acceptedFiles)
                    .map(file => (
                      <FileCard
                        key={file.path}
                        file={file}
                        url={url}
                        setIds={setUploadedIds}
                        onCancel={onCancel}
                        disableVideoUpload={disableVideoUpload}
                        limitedExtents={limitedExtents}
                      />
                    ))
                    .value()}
                </Card.Group>
              </Media>

              <Media at='mobile'>
                <Card.Group itemsPerRow={1}>
                  {_(acceptedFiles)
                    .map(file => (
                      <FileCard
                        key={file.path}
                        file={file}
                        url={url}
                        setIds={setUploadedIds}
                        onCancel={onCancel}
                        disableVideoUpload={disableVideoUpload}
                        limitedExtents={limitedExtents}
                      />
                    ))
                    .value()}
                </Card.Group>
              </Media>
            </Segment>

            <p>
              合計サイズ：
              {_.isInteger(totalFileBytes) && totalFileBytes > 0
                ? _.round(totalFileBytes / 1000)
                : 0}
              Kbytes
            </p>

            <Button
              as='span'
              icon='cloud upload'
              size='big'
              attached
              content='OK'
              primary
              fluid
              onClick={handleSave}
            />
          </MediaContextProvider>
        )}
      </Segment>
    </>
  )
}

FileUploader.propTypes = {
  // file upload endpoint url
  url: PropTypes.string.isRequired,

  // returns uploaded file id list
  // onSave = uploadedIds => ...
  onSave: PropTypes.func,
  multiple: PropTypes.bool,
  disableVideoUpload: PropTypes.bool,
}

FileUploader.defaultProps = {
  onSave: () => {},
  multiple: true,
  disableVideoUpload: false,
}

export default FileUploader
