import React, { useState, useMemo, useEffect, useRef } from 'react'
import L from 'leaflet'
import {
  MapContainer,
  LayersControl,
  TileLayer,
  Marker,
  Tooltip,
} from 'react-leaflet'
import {
  Axios,
  Segment,
  Button,
  Modal,
  Message,
  Icon,
} from 'component/base'
import { getConfigGlobal } from 'config'

const Block = props => {
  const { haisha_id, onMapClose, data } = props

  const [lat, setLat] = useState()
  const [lng, setLng] = useState()

  return (
    <>
      <Modal open size='fullscreen'>
        <Modal.Header content={<span>運送店現在位置</span>} />

        <Modal.Description
          content={
            <ApiGps
              type='haisha'
              dataKey={haisha_id}
              setLat={setLat}
              setLng={setLng}
            />
          }
        />

        <Modal.Content scrolling>
          <Segment>
            {lat && lng ? (
              <Map latitude={lat} longitude={lng} data={data} />
            ) : (
              <Message
                icon={<Icon name='circle notched' loading />}
                header='運送店の現在地を探しています'
                list={['運送店からのGPS位置情報を受信すると地図が開きます']}
              />
            )}
          </Segment>
        </Modal.Content>

        <Modal.Actions>
          <Button.Group fluid size='big'>
            <Button content='閉じる' onClick={onMapClose} />
          </Button.Group>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export const UnsotenGpsMap = Block

const ApiGps = props => {
  const { dataKey, setLat, setLng } = props

  const [refresh, setRefresh] = useState(0)

  useEffect(() => {

    const forward = async () => {
      const { data, error } = await Axios.ajax.get('/api/user/gps/unsoten/haisha', {
        params: {
          haisha_id: dataKey
        }
      })

      if (error) {
        console.error('gps tracking error:', error)
      }
      if (!data) return
      setLat(data?.latitude)
      setLng(data?.longitude)
    }

    if (!dataKey) return

    forward()

  }, [dataKey, refresh])

  useEffect(() => {
    const interval = setInterval(async () => {
      setRefresh(refresh => ++refresh)
    }, 3000) // per 3 second

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Message
      icon={<Icon name='circle notched' loading />}
      compact
      style={{ borderRadius: 'unset' }}
      header='GPS受信中'
      list={[
        '運送店が貨物を運送中でGPSが有効な間、現在地が表示されます',
        '電波状況等で表示位置が正確でない場合があります',
      ]}
    />
  )

}

// スマホアプリと合わせてwebsocketを廃止
// const WebsocketGps = props => {
//   const { type, dataKey, setLat, setLng } = props

//   const { keycloak } = useKeycloak()
//   const { token } = keycloak || {}

//   const didUnmount = useRef(false)

//   const url = useMemo(() => {
//     const env = getEnv()
//     const base_url = getConfigGlobal().websocketEndpoint
//     return `wss://${env === 'development' ? 'dev.' : env === 'stage' ? 'stage.' : ''
//       }${base_url}`
//   }, [getEnv, getConfigGlobal])

//   const socketUrl = useMemo(
//     () => (token && url ? `${url}?Authorization=${token}` : null),
//     [token, url]
//   )

//   const { lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(
//     socketUrl,
//     {
//       onOpen: () => {
//         console.log('websocket connected')
//         sendJsonMessage({
//           action: 'subscript',
//           data: {
//             type: type,
//             key: dataKey,
//           },
//         })
//       },
//       onClose: () => console.log('websocket closed'),
//       shouldReconnect: _closeEvent => didUnmount.current === false,
//       recconectAttempts: 10,
//       reconnectInterval: 3000,
//     }
//   )

//   const status = useMemo(
//     () => ({
//       wsStatus:
//         readyState === ReadyState.CONNECTING
//           ? '接続中...'
//           : readyState === ReadyState.OPEN
//             ? 'GPS受信中'
//             : readyState === ReadyState.CLOSING
//               ? '接続解除中...'
//               : readyState === ReadyState.CLOSED
//                 ? '接続されていません'
//                 : readyState === ReadyState.UNINSTANTIATED
//                   ? '初期化中...'
//                   : '',
//     }),
//     [readyState]
//   )

//   useEffect(() => {
//     return () => {
//       didUnmount.current = true
//     }
//   }, [])

//   useEffect(() => {
//     const { latitude, longitude } = lastJsonMessage || {}
//     setLat(latitude)
//     setLng(longitude)
//   }, [lastJsonMessage, setLat, setLng])

//   return (
//     <Message
//       icon={<Icon name='circle notched' loading />}
//       compact
//       style={{ borderRadius: 'unset' }}
//       header={status.wsStatus}
//       list={[
//         '運送店が貨物を運送中でGPSが有効な間、現在地が表示されます',
//         '電波状況等で表示位置が正確でない場合があります',
//       ]}
//     />
//   )
// }

const Map = props => {
  const { latitude, longitude, data } = props

  const { unsoten_name } = data || {}

  const [map, setMap] = useState(null)
  const markerRef = useRef(null)

  useEffect(() => {
    if (!map || !map.setView) return
    if (!latitude || !longitude) return
    map.panTo([latitude, longitude], { animate: true })
  }, [map, latitude, longitude])

  useEffect(() => {
    if (!latitude || !longitude) return
    if (!markerRef.current) return
    markerRef.current.setLatLng([latitude, longitude])
  }, [map, markerRef, latitude, longitude])

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={[0, 0]}
        zoom={15}
        scrollWheelZoom={false}
        whenCreated={setMap}
        style={{
          height: '50vh',
          width: '100%',
        }}
      >
        <LayersControl position='topright'>
          <TileLayer
            url={`${getConfigGlobal().tileEndpoint}/tile/{z}/{x}/{y}.png`}
          />

          <LayersControl.Overlay checked name='Marker'>
            <Marker
              position={[0, 0]}
              ref={markerRef}
              icon={
                new L.Icon({
                  iconUrl: '/images/akabokun.png', //指定アイコンのパス
                  iconSize: [60, 60], // アイコンのサイズ
                  iconAnchor: [30, 43], //指定座標とアイコンの表示相対位置
                  popupAnchor: [0, -50], //地点からのポップアップの表示相対位置
                  tooltipAnchor: [30, -15], //地点からのツールチップの表示相対位置
                })
              }
            >
              {unsoten_name && <Tooltip permanent>{unsoten_name}</Tooltip>}
            </Marker>
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>
    ),
    []
  )

  return <>{displayMap}</>
}
