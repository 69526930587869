import React, { Component } from 'react'
import { Popup as SUIPopup } from 'semantic-ui-react'

class Kilo extends Component {
  state = { isOpen: false }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render = () => {
    return (
      <SUIPopup
        {...this.props}
        basic
        open={this.state.isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        verticalOffset={-10}
      >
        {this.props.children}
      </SUIPopup>
    )
  }
}
export default Kilo
