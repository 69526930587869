import React, { Component } from 'react'
import { withFormsy } from '@akihirotakamura/formsy-react'

class Standard extends Component {
  changeValue = e => this.props.setValue(e.currentTarget.value)

  render() {
    return (
      <input
        type='hidden'
        onChange={this.changeValue}
        value={this.props.getValue() || ''}
      />
    )
  }
}

export default withFormsy(Standard)
