import React, { useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  Axios,
  notice,
  BaseForm as Form,
  Grid,
  Button,
  Toolbar,
  Message,
  Segment,
  Header,
  Icon,
  Transition,
  Modal,
  FileUploader,
  Menu,
} from 'component/base'
import { Confirm, PageContainer, Custom } from 'component/pieces'
import { getConfig } from 'config'
import { useUserInfo } from 'component/hook'

export const New = props => {
  const { history, kumiaiinData, setKumiaiinData, mode, setShowMailModal } =
    props || {}

  const ref = useRef()

  const [showModal, setShowModal] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [fileIds, setFileIds] = useState([])
  const [files, setFiles] = useState([])
  const [model, setModel] = useState({})
  const [soshin_yoyaku_datetime, set_soshin_yoyaku_datetime] = useState(
    moment().format('YYYY/MM/DD HH:mm')
  )

  const attempt_message =
    '一部の端末では添付ファイルを表示できない場合があります'

  const mapModel = inputs => ({
    ...inputs,
    honbun: _.isEmpty(fileIds)
      ? inputs.honbun
      : `${inputs.honbun}\n${attempt_message}`,
  })

  const totalFileBytes = useMemo(() => _(files).sumBy('size'), [files.length])

  const handleSendMail = model => {
    setModel(model)
    setShowModal(true)
  }

  const handleCancel = () => setShowModal(false)
  const mailList = useMemo(
    () => _.flatten(_.map(kumiaiinData, 'oshirase_mail')),
    [kumiaiinData]
  )

  const { active_busho_id } = useUserInfo()

  const handleConfirm = async () => {
    const { error } = await Axios.ajax.post(
      '/api/staff/master/mail_soshin_yoyaku_queue',
      {
        mail_bcc_list: mailList,
        mail_kbn: 'oshirase',
        tenpu_file_id_list: fileIds,
        busho_id: active_busho_id,
        ...model,
        soshin_yoyaku_datetime,
      }
    )

    if (error) return

    notice({
      title: 'Success',
      description: 'メールを送信しました',
      type: 'success',
    })
    setKumiaiinData([])
    if (mode !== 'modal') {
      history.push('/staff/kumiaiin/mail/list/simple')
    } else {
      setShowMailModal(false)
    }
  }

  const onDelete = id => {
    const targetIndex = _.findIndex(fileIds, i => i === id)
    setFileIds(_.difference(fileIds, [id]))

    if (targetIndex < 0) return
    setFiles(files => {
      files.splice(targetIndex, 1)
      return files
    })
  }

  return (
    <>
      {mode !== 'modal' && (
        <Toolbar
          title='お知らせメール作成'
          menuItems={
            <>
              <Menu.Item>
                <Button.Group>
                  <Button
                    content='送信先選択に戻る'
                    primary
                    as={Link}
                    to={'/staff/kumiaiin/mail/list/simple'}
                  />
                </Button.Group>
              </Menu.Item>
            </>
          }
        />
      )}

      <PageContainer>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Message
                icon='envelope'
                header='以下の宛先にメールを送信します'
                content={<KumiaiinMailList kumiaiins={kumiaiinData} />}
                info
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Form mapping={mapModel} ref={ref} onValidSubmit={handleSendMail}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Textbox.Standard name='kenmei' label='件名' required />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Textbox.Standard
                  name='honbun'
                  label='本文'
                  textAreaMode
                  rows='20'
                  required
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column stretched>
                <Custom.Cards.UploadFile
                  ids={fileIds}
                  mode='edit'
                  onDelete={onDelete}
                />
              </Grid.Column>
            </Grid.Row>
            {totalFileBytes > 5000000 && (
              <Grid.Row>
                <Grid.Column>
                  <Message negative header='添付ファイルサイズが大きすぎます' />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row columns='2'>
              <Grid.Column>
                <Form.Textbox.DateTime
                  name='soshin_yoyaku_datetime'
                  value={soshin_yoyaku_datetime}
                  label='送信予約日時'
                  required
                  onChange={(e, { value }, isValid) => {
                    if (!isValid) return
                    set_soshin_yoyaku_datetime(value)
                  }}
                />
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row columns='2'>
              <Grid.Column>
                <Button
                  primary
                  as='span'
                  onClick={() => setShowUploadModal(true)}
                  content='ファイル添付'
                  icon='folder open outline'
                  size='big'
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  secondary
                  floated='right'
                  content='送信'
                  icon='paper plane outline'
                  size='big'
                  disabled={totalFileBytes > 5000000 ? true : false}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </PageContainer>

      <Confirm
        cancelButton='キャンセル'
        confirmButton='送信'
        content='メールを送信します。よろしいですか？'
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        open={showModal}
      />
      <FileUploadModal
        isOpenModal={showUploadModal}
        fileIds={fileIds}
        setFileIds={setFileIds}
        setFiles={setFiles}
        setShowUploadModal={setShowUploadModal}
      />
    </>
  )
}

const KumiaiinMailList = props => {
  const { kumiaiins } = props || {}

  const target_count = useMemo(() => Object.keys(kumiaiins).length, [kumiaiins])

  const [visible, setVisible] = useState(false)
  const toggleVisible = () => setVisible(visible => !visible)

  return (
    <Segment basic style={{ padding: '0' }}>
      <Header attached='top' onClick={toggleVisible}>
        <Header.Content content={`送信先  ${target_count}名`} />
        <Icon
          name={visible ? 'caret down' : 'caret up'}
          style={{ float: 'right' }}
        />
      </Header>
      <Transition.Group animation='slide down' duration={500}>
        {visible && (
          <Segment attached>
            <Grid celled>
              {_.map(kumiaiins, (data, i) => (
                <Grid.Row key={i} columns={2}>
                  <Grid.Column>{data.unsoten_name}</Grid.Column>
                  <Grid.Column>{data.oshirase_mail.join(', ')}</Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          </Segment>
        )}
      </Transition.Group>
    </Segment>
  )
}

const FileUploadModal = props => {
  const {
    isOpenModal,
    fileIds,
    setFileIds,
    setFiles,
    setShowUploadModal,
  } = props

  const { api_endpoint } = getConfig()

  const handleFilesSave = (ids, files) => {
    setFileIds(_.concat(fileIds, ids))
    setFiles(f => _.concat(f, files))
    setShowUploadModal(false)
  }
  return (
    <Modal
      closeOnEscape={true}
      closeOnDimmerClick={true}
      dimmer='inverted'
      open={isOpenModal}
      size='large'
    >
      <Modal.Header>ファイル添付</Modal.Header>
      <Modal.Content image scrolling>
        <Grid style={{ width: '100%' }}>
          <Grid.Row>
            <Grid.Column>
              <Header attached='top' content='Files Uploader' inverted />
              <Segment attached='bottom'>
                <FileUploader
                  url={`${api_endpoint}/api/staff/upload_files`}
                  onSave={handleFilesSave}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => setShowUploadModal(false)}
          content='キャンセル'
        />
      </Modal.Actions>
    </Modal>
  )
}
