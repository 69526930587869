import { useState, useEffect } from 'react'
import { Axios, useKeycloak } from 'component/base'

export const useIraisha = (_key, iraisha_reset) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const { keycloak } = useKeycloak()

  useEffect(() => {
    if (iraisha_reset) setData({})
  }, [iraisha_reset])

  const ajax = async key => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      keycloak.hasRealmRole('shokuin')
        ? `/api/staff/master/iraisha/${key}`
        : `/api/member/master/iraisha/${key}`,
      {}
    )
    if (error) {
      setLoading(false)
      return
    }
    setData(data || {})
    setLoading(false)
  }

  useEffect(() => {
    _key && ajax(_key)
  }, [_key])

  return {
    data,
    loading,
  }
}
