import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Axios } from 'component/base'

export const Print = props => {
  const { url, body, method } = props

  const ref = useRef()
  const [blob, setBlob] = useState(null)

  useEffect(() => {
    if (method === 'get') ajax_get(url, body)
    if (method === 'post') ajax_post(url, body)
  }, [])

  const ajax_get = async (url, body) => {
    const { data, error } = await Axios.ajax.get(`${url}`, {
      responseType: 'blob',
      params: body,
    })
    if (error || !data) return

    setBlob(new Blob([data], { type: data.type }))
  }

  const ajax_post = async (url, body) => {
    const { data, error } = await Axios.ajax.post(`${url}`, body, {
      responseType: 'blob',
    })
    if (error || !data) return

    setBlob(new Blob([data], { type: data.type }))
  }

  useEffect(() => {
    if (!blob) return
    ref.current.contentWindow.print()
  }, [blob])

  return blob ? (
    <iframe
      ref={ref}
      id='print-frame'
      title='print frame'
      style={{
        display: 'none',
      }}
      src={(window.URL || window.webkitURL).createObjectURL(blob)}
    />
  ) : (
    <></>
  )
}

Print.propTypes = {
  url: PropTypes.string,
  body: PropTypes.object,
  method: PropTypes.string,
}

Print.defaultProps = {
  url: null,
  body: {},
  method: null,
}
