import React, { useEffect } from 'react'
import { useSubscription, gql } from '@apollo/client'

const subscribeToNewMessage = /* GraphQL */ `
  subscription SubscribeToNewMessage($conversationId: ID!) {
    subscribeToNewMessage(conversationId: $conversationId) {
      id
      conversationId
      content
      sender
      data {
        url
        web
      }
    }
  }
`
export const WatchConversation = props => {
  const { conversationId, onMessageReceived } = props

  const { data } = useSubscription(gql(subscribeToNewMessage), {
    variables: {
      conversationId
    }
  })

  useEffect(() => {
    if (!data) return
    onMessageReceived(data.subscribeToNewMessage)
  }, [data])

  return <></>
}

export default WatchConversation
