import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SemanticNotification from './SemanticNotification'
import { store } from './notice'

import './style.css'

/* eslint-disable no-useless-computed-key */
const closeAnimations = {
  ['top-right']: 'fly left',
  ['top-center']: 'fly down',
  ['top-left']: 'fly right',
  ['bottom-right']: 'fly left',
  ['bottom-center']: 'fly up',
  ['bottom-left']: 'fly right',
}

export default class NotificationContainer extends Component {
  state = {
    notifications: [],
  }

  componentDidMount() {
    store.subscribe(this.updateNotifications)
  }

  componentWillUnmount() {
    store.unsubscribe(this.updateNotifications)
  }

  updateNotifications = () =>
    this.setState({
      notifications: store.data,
    })

  onClose = notificationId => {
    const notification = this.state.notifications.find(
      value => value.id === notificationId
    )
    if (!notification) return

    store.remove(notification)

    if (notification.onClose) notification.onClose()
    this.updateNotifications()
  }

  render() {
    const { animation: containerAnimation, position, className } =
      this.props || {}

    return (
      <div
        className={`ui-alerts ${position} ${className}`}
        style={{ padding: 0, margin: 0 }}
      >
        {this.state.notifications.map(notification => {
          const {
            id,
            type = 'info',
            title = '',
            description = '',
            icon,
            time,
            onClick,
            animation,
          } = notification

          return (
            <SemanticNotification
              key={id}
              notificationId={id}
              type={type}
              title={title}
              description={description}
              icon={icon}
              openAnimation={animation || containerAnimation || 'pulse'}
              closeAnimation={closeAnimations[position]}
              time={time}
              onClick={onClick}
              onClose={this.onClose}
            />
          )
        })}
      </div>
    )
  }
}
NotificationContainer.propTypes = {
  position: PropTypes.oneOf([
    'top-right',
    'top-center',
    'top-left',
    'bottom-right',
    'bottom-center',
    'bottom-left',
  ]),
  animation: PropTypes.string,
  className: PropTypes.string,
}
NotificationContainer.defaultProps = {
  position: 'top-right',
  animation: null,
  className: '',
}
