import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'semantic-ui-react'

export const PaginationDisplay = props => {
  const { total, start, end } = props || {}

  const displayText =
    end > total
      ? `全${total}件中${total}件を表示`
      : `全${total}件中${start}件〜${props.end}件を表示`

  return (
    <>
      <Label content={displayText} size='mini' />
    </>
  )
}

PaginationDisplay.propTypes = {
  total: PropTypes.number,
  start: PropTypes.number,
  end: PropTypes.number,
}

PaginationDisplay.defaultProps = {
  total: 0,
  start: 0,
  end: 0,
}
