import React, { Component } from 'react'
import Standard from './Standard'

class Primary extends Component {
  render = () => {
    return <Standard {...this.props} primary />
  }
}

export default Primary
