import React from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import Standard from './Standard'

class Zoom extends React.Component {
  static propTypes = {
    validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    allowDefaultZero: PropTypes.bool,
  }

  static defaultProps = {
    validations: {},
    allowDefaultZero: true,
  }

  render = () => {
    const { validations, value, allowDefaultZero, ...other } = this.props

    return (
      <Standard
        type='text'
        alignRight
        value={value}
        validations={merge({}, validations, { isZoom: true })}
        {...other}
      />
    )
  }
}

export default Zoom
