import { useState, useEffect, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { Axios } from 'component/base'

export const useOsrm = (fromLatLng, toLatLng, _options, refresh) => {
  const [loading, setLoading] = useState(false)
  const [routes, setRoutes] = useState([])

  const defaults = {
    alternatives: 1, // 複数ルート検索。最大3
    steps: true,
    geometries: 'geojson',
    overview: 'full',
    continue_straight: true,
    exclude: undefined,
    annotations: false, // 'toll'を指定すると有料道路を除外
  }

  const ajax = async params => {
    const [start_lat, start_lng] = fromLatLng
    const [target_lat, target_lng] = toLatLng

    setLoading(true)
    const { data, error } = await Axios.osrm.get(
      `/route/v1/driving/${start_lng},${start_lat};${target_lng},${target_lat}`,
      {
        params,
      }
    )
    setLoading(false)

    if (error) return

    const { routes } = data || {}

    setRoutes(routes)
    setLoading(false)
  }

  useEffect(() => {
    if (!_.isArray(fromLatLng) || !_.isArray(toLatLng)) return
    if (fromLatLng.length < 2 || toLatLng.length < 2) return
    if (!fromLatLng[0] || fromLatLng[0] === 0) return
    if (!fromLatLng[1] || fromLatLng[1] === 0) return
    if (!toLatLng[0] || toLatLng[0] === 0) return
    if (!toLatLng[1] || toLatLng[1] === 0) return
    ajax(_.assign({}, defaults, _options))
  }, [fromLatLng, toLatLng, _options, refresh])

  const shortestDistanceRoute = useMemo(() => {
    return _(routes).orderBy(['distance'], ['asc']).value()[0]
  }, [routes])

  const shortestDurationRoute = useMemo(
    () => _(routes).orderBy(['duration'], ['asc']).value()[0],
    [routes]
  )

  const getCoordinates = useCallback(route => {
    const { geometry } = route || {}

    const { coordinates } = geometry || {}

    return _.map(coordinates, coord => coord.reverse())
  }, [])

  const getSteps = useCallback(route => {
    const { legs } = route || {}

    const { steps } = _.isArray(legs) ? legs[0] || {} : {}

    return steps
  }, [])

  return {
    loading,
    routes,
    shortestDistanceRoute,
    shortestDurationRoute,
    getCoordinates,
    getSteps,
  }
}
