import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tab } from 'component/base'
import { Custom } from 'component/pieces'

export const Torihikisaki = props => {
  const { multiple, setSelectedId, selectedId } = props

  const [loading, setLoading] = useState(false)

  return (
    <Tab
      panes={[
        {
          menuItem: '一般得意先',
          render: () => (
            <Tab.Pane>
              <Custom.DataTable.Tokuisaki
                mode='iraisha'
                onSelectChange={selectedId => setSelectedId(selectedId)}
                multiple={multiple}
                selectedId={selectedId}
                loading={loading}
                setLoading={setLoading}
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: '運送店',
          render: () => (
            <Tab.Pane>
              <Custom.DataTable.Kumiaiin
                mode='iraisha'
                onSelectChange={selectedId => setSelectedId(selectedId)}
                multiple={multiple}
                selectedId={selectedId}
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: '単組他',
          render: () => (
            <Tab.Pane>
              <Custom.DataTable.TansoSeikyuShiharaisaki
                mode='torihikisaki'
                onSelectChange={selectedId => setSelectedId(selectedId)}
                multiple={multiple}
                selectedId={selectedId}
                loading={loading}
                setLoading={setLoading}
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: '販売先（外部）',
          render: () => (
            <Tab.Pane>
              <Custom.DataTable.Hanbaisaki
                mode='hanbaisaki'
                onSelectChange={selectedId => setSelectedId(selectedId)}
                multiple={multiple}
                selectedId={selectedId}
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: '仕入先',
          render: () => (
            <Tab.Pane>
              <Custom.DataTable.Shiiresaki
                mode='dropdown'
                onSelectChange={selectedId => setSelectedId(selectedId)}
                multiple={multiple}
                selectedId={selectedId}
              />
            </Tab.Pane>
          ),
        },
      ]}
    />
  )
}

Torihikisaki.propTypes = {
  multiple: PropTypes.bool,
  setSelectedId: PropTypes.func,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Torihikisaki.defaultProps = {
  multiple: false,
  setSelectedId: () => {},
  selectedId: undefined,
}
