import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'

export const KumiaiYakushoku = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/kumiai_yakushoku'
      keyName='id'
      valueName='id'
      textName='name'
      sort={['sort']}
      {...props}
    />
  )
}

KumiaiYakushoku.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

KumiaiYakushoku.defaultProps = {
  name: 'kumiai_yakushoku_id_list',
  label: '組合役職',
  value: undefined,
}
