import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'

export const MatchingMailRyokin = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/ryokin'
      keyName='id'
      valueName='id'
      textName='ryokin_name'
      {...props}
    />
  )
}

MatchingMailRyokin.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

MatchingMailRyokin.defaultProps = {
  name: 'matching_mail_ryokin_id_list',
  label: 'マッチングメール料金',
  value: undefined,
}
