import { useState, useEffect } from 'react'
import { Util } from 'component/base'

export const useLocalStorage = (key, initial = {}) => {
  const [localState, setLocalState] = useState(() => {
    const restore = Util.LowDB.get(key).value()
    return restore ? restore : initial
  })

  const [busy, setBusy] = useState(false)

  useEffect(() => {
    setBusy(true)
    Util.LowDB.set(key, localState).write()
    setBusy(false)
  }, [localState])

  return {
    busy,
    localState,
    setLocalState,
  }
}
