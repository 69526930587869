import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Header, Icon } from 'component/base'

export const Segment = props => {
  const { headerType, icon, content, ...other } = props

  return (
    <Divider horizontal section {...other}>
      <Header as={headerType}>
        <Icon name={icon} />
        {content}
      </Header>
    </Divider>
  )
}

Segment.propTypes = {
  headerType: PropTypes.string,
  icon: PropTypes.string,
  content: PropTypes.any,
}

Segment.defaultProps = {
  headerType: 'h3',
  icon: 'pencil',
  content: 'default title',
}
