import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'
import queryString from 'query-string'

export const MeiboyoChiku = props => {
  const { busho_id_list, is_member, ...other } = props
  const url = useMemo(
    () =>
      is_member
        ? '/api/member/master/meiboyo_chiku'
        : '/api/staff/master/meiboyo_chiku',
    []
  )
  return (
    <>
      {busho_id_list ? (
        <ListBase
          url={`${url}?${queryString.stringify({ busho_id_list })}`}
          {...other}
        />
      ) : (
        <Form.Dropdown.WithAPI
          url={url}
          keyName='id'
          valueName='id'
          textName='meiboyo_chiku_name'
          sort={['sort']}
          {...other}
        />
      )}
    </>
  )
}

MeiboyoChiku.propsTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
  is_member: PropTypes.bool,
}

MeiboyoChiku.defaultProps = {
  name: 'meiboyo_chiku',
  label: '名簿用地区',
  value: undefined,
  is_member: false,
}

const ListBase = props => {
  const { url, ...other } = props

  return (
    <Form.Dropdown.WithAPI
      url={url}
      keyName='id'
      valueName='id'
      textName='meiboyo_chiku_name'
      sort={['sort']}
      {...other}
    />
  )
}
