import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import withProps from 'recompose/withProps'
import { Link as RouterLink } from 'react-router-dom'
import {
  useKeycloak,
  Image,
  Menu,
  Dropdown,
  Button,
  AuthStatus,
  Header
} from 'component/base'
import fullMenu from 'config/menu.json'
import { getConfig, getHikkoshiUrl } from 'config'
import { NotificationMenu } from './NotificationMenu'
import { ShozokuMenu } from './ShozokuMenu'
import './style.css'

export const MyNavItems = props => {
  const { keycloak } = useKeycloak()

  const roles =
    keycloak && keycloak.realmAccess ? keycloak.realmAccess.roles || [] : []

  const { hero, sidebar, handleToggle } = props

  const hikkoshiUrl = getHikkoshiUrl()

  const dropdownStyle = sidebar
    ? {
      left: '0%',
      top: '100%',
      width: '90%',
    }
    : {}

  const Link = withProps(props => ({
    ...props,
    onClick: handleToggle,
  }))(RouterLink)

  const generateMenu = key =>
    _(fullMenu[key])
      .filter(menu => !menu.disabled)
      .filter(menu => menu.restrict_realms ? _(menu.restrict_realms).includes(keycloak.realm) : true)
      .filter(menu => _.find(roles, role => menu[role]))
      .value()

  return (
    <>
      {hero ? (
        <Menu.Item as={Link} to='/' header>
          <Image size='tiny' src='/images/nais_logo_outline_white.png' />
          <Header
            content={getConfig().name}
            size='small'
            style={{ marginTop: '7px', marginLeft: '5px' }}
            color='yellow'
          />
        </Menu.Item>
      ) : (
        <></>
      )}

      {_.map(generateMenu('top'), (menu, i) =>
        menu.submenu ? (
          <Dropdown key={i} pointing item text={menu.content}>
            <Dropdown.Menu style={dropdownStyle}>
              {_.map(generateMenu(menu.submenu), (submenu, i) => (
                <Dropdown.Item
                  key={i}
                  content={submenu.content}
                  disabled={submenu.disabled}
                  to={submenu.url}
                  icon={submenu.icon}
                  as={Link}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : menu.external ? (
          <Menu.Item
            key={i}
            content={menu.content}
            style={{ display: menu.disabled ? 'none' : null }}
            as='a'
            href={
              menu.param_token
                ? `${menu.url}?token=${keycloak.token}`
                : menu.url
            }
            target='_blank'
          />
        ) : menu.hikkoshi ? (
          <Menu.Item
            key={i}
            content={menu.content}
            style={{ display: menu.disabled ? 'none' : null }}
            as='a'
            href={`${hikkoshiUrl}/manage`}
            target='_blank'
          />
        ) : (
          <Menu.Item
            key={i}
            content={menu.content}
            style={{ display: menu.disabled ? 'none' : null }}
            to={menu.url}
            as={Link}
          />
        )
      )}

      {process.env.NODE_ENV === 'development' && (
        <Dropdown pointing item text='Dev'>
          <Dropdown.Menu style={dropdownStyle}>
            <Dropdown.Header icon='tags' content='サンプル画面' />
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to='/sample/friends'>
              Friends
            </Dropdown.Item>
            <Dropdown.Item as={Link} to='/sample/responsive'>
              Responsive
            </Dropdown.Item>
            <Dropdown.Item as={Link} to='/sample/leaflet'>
              Leaflet
            </Dropdown.Item>
            <Dropdown.Item as={Link} to='/sample/myroute'>
              My Route
            </Dropdown.Item>
            <Dropdown.Item as={Link} to='/sample/customElement'>
              Custom Element
            </Dropdown.Item>
            <Dropdown.Item as={Link} to='/sample/customFormsy'>
              Custom Formsy
            </Dropdown.Item>
            <Dropdown.Item as={Link} to='/sample/formElement'>
              Form Element
            </Dropdown.Item>
            <Dropdown.Item as={Link} to='/sample/fileupload'>
              File Upload
            </Dropdown.Item>

            <Dropdown.Header icon='tags' content='その他' />
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to='/'>
              Other
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}

      {sidebar ? (
        <Menu.Item>
          <Button color='grey' fluid onClick={handleToggle} content='閉じる' />
        </Menu.Item>
      ) : (
        <></>
      )}
    </>
  )
}

MyNavItems.propTypes = {
  hero: PropTypes.bool,
  sidebar: PropTypes.bool,
  handleToggle: PropTypes.func,
}

MyNavItems.defaultProps = {
  hero: false,
  sidebar: false,
  handleToggle: () => { },
}

export const MyRightNavItems = props => {
  const { keycloak } = useKeycloak()
  const { fixed } = props

  return (
    <>
      <Menu.Menu position='right'>
        {keycloak.hasRealmRole('shokuin') ? <></> : <NotificationMenu />}
        <ShozokuMenu />
        <AuthStatus inverted={!fixed} />
      </Menu.Menu>
    </>
  )
}

MyRightNavItems.propTypes = {
  fixed: PropTypes.bool,
}
