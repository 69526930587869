import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Input,
  Dropdown,
  Text,
} from 'component/base'
import { Custom } from 'component/pieces'

export const Uriage = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/buppan/uriage/list',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='kumiaiinMail'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Uriage.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Uriage.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'id' ? direction : null}
        onClick={() => handleSort('id')}
        content='売上番号'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'uriage_date' ? direction : null}
        onClick={() => handleSort('uriage_date')}
        content='売上日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'busho_name' ? direction : null}
        onClick={() => handleSort('busho_name')}
        content='販売部署'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihiki_kbn' ? direction : null}
        onClick={() => handleSort('torihiki_kbn')}
        content='取引種類'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hanbaisaki_kbn' ? direction : null}
        onClick={() => handleSort('hanbaisaki_kbn')}
        content='販売先区分'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
        content='販売先'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'seikyusaki_torihikisaki_name' ? direction : null}
        onClick={() => handleSort('seikyusaki_torihikisaki_name')}
        content='請求先'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'gokei_gaku' ? direction : null}
        onClick={() => handleSort('gokei_gaku')}
        content='合計金額'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    uriage_date,
    busho_name,
    torihiki_name,
    torihikisaki_cd,
    hanbaisaki_kbn,
    torihikisaki_name,
    seikyusaki_torihikisaki_name,
    gokei_kingaku,
    upload_files_id,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={id} />
      <Table.Cell style={{ width: '12.0rem' }} content={uriage_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={busho_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={torihiki_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.HanbaisakiKbn value={hanbaisaki_kbn} />}
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={
          <>
            <Custom.Image.KumiaiinAvator
              id={upload_files_id}
              kumiaiin_cd={torihikisaki_cd}
              avatar
            />
            {torihikisaki_name}
          </>
        }
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={seikyusaki_torihikisaki_name}
      />
      <Table.Cell
        style={{ width: '10.0rem', textAligj: 'right' }}
        content={<Text.Comma content={gokei_kingaku} />}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const {
    id,
    uriage_date_from,
    uriage_date_to,
    busho_id,
    shiwake_pattern_torihiki_id,
    torihikisaki_id,
  } = condition || {}

  return (
    <>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={id}
          label='売上番号'
          name='id'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          inputAs={Input}
          value={uriage_date_from}
          label='売上日FROM'
          name='uriage_date_from'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          inputAs={Input}
          value={uriage_date_to}
          label='売上日TO'
          name='uriage_date_to'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Busho
          inputAs={Dropdown}
          value={busho_id}
          label='販売部署'
          name='busho_id'
          enableAuthValue={false}
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.ShiwakePatternTorihiki
          inputAs={Dropdown}
          value={shiwake_pattern_torihiki_id}
          label='取引種類'
          name='shiwake_pattern_torihiki_id'
          clearable
          major_kbn_list={['buppan']}
          middle_kbn_list={['uriage']}
          hassei_moto_kbn='on'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Hanbaisaki
          inputAs={Dropdown}
          value={torihikisaki_id}
          label='販売先'
          name='torihikisaki_id'
          clearable
        />
      </Grid.Column>
    </>
  )
}
