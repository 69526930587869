import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  ConditionWrapper,
  BaseForm as Form,
  Input,
  Dropdown,
  Button,
  notice,
} from 'component/base'
import { Confirm, Custom } from 'component/pieces'

export const ServiceSchedule = withRouter(props => {
  const { history, enableQueryCondition } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {
      hikitori_date: moment().format('YYYY/MM/DD'),
      include_gps: true,
      include_yokujitsu_haisha_kibo: true,
      include_kumiai_haisha: true,
      include_unsoten_schedule: true,
    }
  }, [])

  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)

  const [memo_target_kumiaiin_id, set_memo_target_kumiaiin_id] = useState()
  const [showMemoModal, setShowMemoModal] = useState(false)

  const [gpsTaikiId, setGpsTaikiId] = useState()

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/haisha/haisha/unkohyo/list',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const [showConfirmGPSDelete, setshowConfirmGPSDelete] = useState(false)
  const handleConfirmGPSDelete = async () => {
    const { error } = await Axios.ajax.delete(
      `/api/staff/haisha/gps_taiki/${gpsTaikiId}`,
      {
        headers: { clearCacheEntry: true },
      }
    )

    if (error) return
    notice({
      title: 'Success',
      description: '解除しました',
      type: 'success',
    })

    setshowConfirmGPSDelete(false)
    setRefresh(refresh => ++refresh)
  }

  const editedData = useMemo(
    () =>
      _(data)
        .groupBy('unsoten_id')
        .map(group => ({
          ...group[0],
          shashu_name_list: _.isArray(group[0].shashu_name_list)
            ? group[0].shashu_name_list.join(',')
            : '-',
          yokujitsu_haisha_kibo_kbn: _.isArray(
            group[0].yokujitsu_haisha_kibo_kbn
          )
            ? group[0].yokujitsu_haisha_kibo_kbn.join(',')
            : '-',
          team_name_list: _.isArray(group[0].team_name_list)
            ? group[0].team_name_list.join(',')
            : '-',
          meisai_data: group,
        }))
        .value(),
    [data]
  )

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        setRefresh={setRefresh}
        set_kumiaiin_id={set_memo_target_kumiaiin_id}
        setShowMemoModal={setShowMemoModal}
        setGpsTaikiId={setGpsTaikiId}
        setshowConfirmGPSDelete={setshowConfirmGPSDelete}
      />
    ),
    [history, setRefresh]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='service_schedule'
            size='mini'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={editedData}
            bottomControl={<></>}
          />
        </ConditionWrapper>
      </Segment>

      <Custom.Modal.GpsTojitsuMemoModal
        isOpenModal={showMemoModal}
        setShowMemoModal={setShowMemoModal}
        onClickCancel={() => setShowMemoModal(false)}
        kumiaiin_id={memo_target_kumiaiin_id}
        onEdited={() => setRefresh(state => ++state)}
      />

      <Confirm
        cancelButton='キャンセル'
        confirmButton='解除'
        content='解除します。よろしいですか？'
        onCancel={() => setshowConfirmGPSDelete(false)}
        onConfirm={handleConfirmGPSDelete}
        open={showConfirmGPSDelete}
      />
    </>
  )
})

ServiceSchedule.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

ServiceSchedule.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'unsoten_cd' ? direction : null}
        onClick={() => handleSort('unsoten_cd')}
        content='運送店コード'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
        content='運送店名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'gps_taiki_jotai_kbn_name' ? direction : null}
        onClick={() => handleSort('gps_taiki_jotai_kbn_name')}
        content='GPS待機状態'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'gps_taiki_start_datetime' ? direction : null}
        onClick={() => handleSort('gps_taiki_start_datetime')}
        content='GPS待機開始時間'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'gps_taiki_address' ? direction : null}
        onClick={() => handleSort('gps_taiki_address')}
        content='GPS待機住所'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'gps_taiki_biko' ? direction : null}
        onClick={() => handleSort('gps_taiki_biko')}
        content='GPS待機備考'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'shashu_name_list' ? direction : null}
        onClick={() => handleSort('shashu_name_list')}
        content='車種'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'yokujitsu_haisha_kibo_kbn' ? direction : null}
        onClick={() => handleSort('yokujitsu_haisha_kibo_kbn')}
        content='翌日配車希望'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={
          column === 'yokujitsu_haisha_kibo_koshin_datetime' ? direction : null
        }
        onClick={() => handleSort('yokujitsu_haisha_kibo_koshin_datetime')}
        content='翌日配車希望更新日時'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'gps_tojitsu_memo_naiyo' ? direction : null}
        onClick={() => handleSort('gps_tojitsu_memo_naiyo')}
        content='当日メモ'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'shokuin_biko' ? direction : null}
        onClick={() => handleSort('shokuin_biko')}
        content='職員備考'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'haisha_chui_jiko' ? direction : null}
        onClick={() => handleSort('haisha_chui_jiko')}
        content='配車注意事項'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tel' ? direction : null}
        onClick={() => handleSort('tel')}
        content='電話番号１'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tel2' ? direction : null}
        onClick={() => handleSort('tel2')}
        content='電話番号２'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'is_hikkoshi_member' ? direction : null}
        onClick={() => handleSort('is_hikkoshi_member')}
        content='引越'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'team_name_list' ? direction : null}
        onClick={() => handleSort('team_name_list')}
        content='チーム'
      />
      <Table.HeaderCell
        style={{ width: '93.0rem', padding: '0rem' }}
        content={
          <Grid
            celled
            verticalAlign='middle'
            style={{ width: '93.0rem', margin: '0rem' }}
          >
            <Grid.Row>
              <Grid.Column width={2}>詳細</Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
              <Grid.Column style={{ width: '10.0rem' }}>配車種類</Grid.Column>
              <Grid.Column style={{ width: '8.0rem' }}>配車状態</Grid.Column>
              <Grid.Column style={{ width: '7.0rem' }}>引取日</Grid.Column>
              <Grid.Column style={{ width: '6.0rem' }}>引取時間</Grid.Column>
              <Grid.Column style={{ width: '7.0rem' }}>完了日</Grid.Column>
              <Grid.Column style={{ width: '6.0rem' }}>完了時間</Grid.Column>
              <Grid.Column style={{ width: '15.0rem' }}>依頼者名</Grid.Column>
              <Grid.Column style={{ width: '17.0rem' }}>発地住所１</Grid.Column>
              <Grid.Column style={{ width: '17.0rem' }}>着地住所１</Grid.Column>
            </Grid.Row>
          </Grid>
        }
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const {
    withOrder,
    data,
    set_kumiaiin_id,
    setShowMemoModal,
    setGpsTaikiId,
    setshowConfirmGPSDelete,
  } = props || {}

  const {
    kumiaiin_id,
    unsoten_cd,
    unsoten_name,
    gps_taiki_jotai_kbn_name,
    gps_taiki_start_datetime,
    gps_taiki_biko,
    shashu_name_list,
    yokujitsu_haisha_kibo_kbn,
    yokujitsu_haisha_kibo_koshin_datetime,
    gps_tojitsu_memo_naiyo,
    shokuin_biko,
    haisha_chui_jiko,
    tel,
    tel2,
    is_hikkoshi_member,
    team_name_list,
    meisai_data,
    upload_files_id,
    gps_taiki_id,
    gps_taiki_address,
  } = data || {}

  const handleOnClickMemo = e => {
    e.stopPropagation()
    set_kumiaiin_id(kumiaiin_id)
    setShowMemoModal(true)
  }

  const handleOnClickGps = e => {
    e.stopPropagation()
    setGpsTaikiId(gps_taiki_id)
    setshowConfirmGPSDelete(true)
  }

  return withOrder(
    <Table.Row>
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          <>
            <Custom.Image.KumiaiinAvator
              id={upload_files_id}
              kumiaiin_cd={unsoten_cd}
              avatar
            />
            {unsoten_cd}
          </>
        }
      />
      <Table.Cell style={{ width: '15.0rem' }} content={unsoten_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          <>
            <Button
              disabled={!gps_taiki_jotai_kbn_name}
              onClick={handleOnClickGps}
              icon='trash alternate outline'
              size='mini'
              primary
            />
            {gps_taiki_jotai_kbn_name}
          </>
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={gps_taiki_start_datetime}
      />
      <Table.Cell style={{ width: '15.0rem' }} content={gps_taiki_address} />
      <Table.Cell style={{ width: '15.0rem' }} content={gps_taiki_biko} />
      <Table.Cell style={{ width: '8.0rem' }} content={shashu_name_list} />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={yokujitsu_haisha_kibo_kbn}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={yokujitsu_haisha_kibo_koshin_datetime}
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={
          <>
            <Button
              onClick={handleOnClickMemo}
              icon='pencil'
              size='mini'
              primary
            />
            {gps_tojitsu_memo_naiyo}
          </>
        }
      />
      <Table.Cell style={{ width: '15.0rem' }} content={shokuin_biko} />
      <Table.Cell style={{ width: '15.0rem' }} content={haisha_chui_jiko} />
      <Table.Cell style={{ width: '15.0rem' }} content={tel} />
      <Table.Cell style={{ width: '15.0rem' }} content={tel2} />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={is_hikkoshi_member ? '対応可' : '-'}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={team_name_list} />
      <Table.Cell
        style={{ width: '93.0rem', padding: '0rem' }}
        content={
          <Grid
            celled
            verticalAlign='middle'
            style={{ width: '93.0rem', margin: '0rem' }}
          >
            {_(meisai_data)
              .map((data, i) => <DetailsRow data={data} key={i} />)
              .value()}
          </Grid>
        }
      />
    </Table.Row>
  )
}

const DetailsRow = props => {
  const { data } = props

  const {
    is_haisha_data,
    haisha_jotai_kbn_name,
    hikitori_date,
    hikitori_time,
    hikitori_jikan_shitei_kbn,
    kanryo_date,
    kanryo_time,
    kanryo_jikan_shitei_kbn,
    iraisha_name,
    hatchi_address1,
    chakuchi_address1,
  } = data || {}

  return (
    <>
      <Grid.Row coulumns='equal'>
        <Grid.Column
          style={{
            width: '10.0rem',
            maxWidth: '10.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {is_haisha_data ? '組合配車' : '運送店スケジュール'}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '8.0rem',
            maxWidth: '8.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {haisha_jotai_kbn_name}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '7.0rem',
            maxWidth: '7.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {hikitori_date}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '6.0rem',
            maxWidth: '6.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {(hikitori_time ? hikitori_time : '') + ' '}
          {hikitori_time && hikitori_jikan_shitei_kbn && (
            <Custom.Label.JikanShiteiKbn value={hikitori_jikan_shitei_kbn} />
          )}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '7.0rem',
            maxWidth: '7.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {kanryo_date}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '6.0rem',
            maxWidth: '6.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {(kanryo_time ? kanryo_time : '') + ' '}
          {kanryo_time && kanryo_jikan_shitei_kbn && (
            <Custom.Label.JikanShiteiKbn value={kanryo_jikan_shitei_kbn} />
          )}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '15.0rem',
            maxWidth: '15.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {iraisha_name}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '17.0rem',
            maxWidth: '17.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {hatchi_address1}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '17.0rem',
            maxWidth: '17.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {chakuchi_address1}
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

const SearchElement = props => {
  const { condition } = props

  const {
    haiso_center_id: _haiso_center_id,
    shozoku_area_id: _shozoku_area_id,
    hikitori_date,
    hikitori_time_from,
    hikitori_time_to,
    kanryo_date,
    kanryo_time_from,
    kanryo_time_to,
    team_id_list,
    yokujitsu_haisha_kibo_kbn_list,
    shashu_id_list,
    include_gps,
    include_yokujitsu_haisha_kibo,
    include_kumiai_haisha,
    include_unsoten_schedule,
  } = condition || {}

  const [haiso_center_id, set_haiso_center_id] = useState(_haiso_center_id)
  const [shozoku_area_id, set_shozoku_area_id] = useState(_shozoku_area_id)
  const [area_refresh, set_area_refresh] = useState(0)

  useEffect(() => {
    set_haiso_center_id(_haiso_center_id)
    set_shozoku_area_id(_shozoku_area_id)
  }, [_haiso_center_id, _shozoku_area_id])

  useEffect(() => {
    set_area_refresh(refresh => ++refresh)
  }, [haiso_center_id])

  const [isYokujitsuOn, setIsYokujitsuOn] = useState(true)

  useEffect(() => {
    setIsYokujitsuOn(include_yokujitsu_haisha_kibo)
  }, [include_yokujitsu_haisha_kibo])

  const [isHaishaOn, setIsHaishaOn] = useState(true)
  const [isScheduleOn, setIsScheduleOn] = useState(true)

  useEffect(() => {
    setIsHaishaOn(include_kumiai_haisha)
    setIsScheduleOn(include_unsoten_schedule)
  }, [include_kumiai_haisha, include_unsoten_schedule])

  return (
    <>
      <Grid.Column>
        <Custom.Dropdown.HaisoCenter
          inputAs={Dropdown}
          name='haiso_center_id'
          label='配送センター'
          value={haiso_center_id}
          enableAuthValue={false}
          clearable
          onChange={(e, { value }) => {
            set_haiso_center_id(value)
            set_shozoku_area_id(null)
          }}
        />
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.AreaAll
          key={area_refresh}
          inputAs={Dropdown}
          value={shozoku_area_id}
          name='shozoku_area_id'
          label='エリア'
          fluid
          onChange={(e, { value }) => set_shozoku_area_id(value)}
          haiso_center_id={haiso_center_id}
          enableAll
        />
      </Grid.Column>

      <Grid.Column>
        <Form.Textbox.DatePicker
          inputAs={Input}
          value={isHaishaOn || isScheduleOn ? hikitori_date : null}
          label='引取日'
          name='hikitori_date'
          readOnly={isHaishaOn || isScheduleOn ? false : true}
        />
      </Grid.Column>

      <Grid.Column>
        <Form.Group>
          <Form.Textbox.HM
            inputAs={Input}
            value={isHaishaOn || isScheduleOn ? hikitori_time_from : null}
            label='引取時間'
            name='hikitori_time_from'
            width={9}
            readOnly={isHaishaOn || isScheduleOn ? false : true}
          />
          <Form.Field width={1} style={{ paddingTop: '9px' }}>
            〜
          </Form.Field>
          <Form.Textbox.HM
            inputAs={Input}
            value={isHaishaOn || isScheduleOn ? hikitori_time_to : null}
            label={null}
            name='hikitori_time_to'
            width={6}
            readOnly={isHaishaOn || isScheduleOn ? false : true}
          />
        </Form.Group>
      </Grid.Column>

      <Grid.Column>
        <Form.Textbox.DatePicker
          inputAs={Input}
          value={isHaishaOn || isScheduleOn ? kanryo_date : null}
          label='完了日'
          name='kanryo_date'
          readOnly={isHaishaOn || isScheduleOn ? false : true}
        />
      </Grid.Column>

      <Grid.Column>
        <Form.Group>
          <Form.Textbox.HM
            inputAs={Input}
            value={isHaishaOn || isScheduleOn ? kanryo_time_from : null}
            label='完了時間'
            name='kanryo_time_from'
            width={9}
            readOnly={isHaishaOn || isScheduleOn ? false : true}
          />
          <Form.Field width={1} style={{ paddingTop: '9px' }}>
            〜
          </Form.Field>
          <Form.Textbox.HM
            inputAs={Input}
            value={isHaishaOn || isScheduleOn ? kanryo_time_to : null}
            label={null}
            name='kanryo_time_to'
            width={6}
            readOnly={isHaishaOn || isScheduleOn ? false : true}
          />
        </Form.Group>
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.Team
          inputAs={Dropdown}
          value={team_id_list}
          name='team_id_list'
          label='配送チーム'
          clearable
          multiple
          haiso_center_id={haiso_center_id}
          is_haiso_team_only={true}
        />
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.HaishaKiboKbn
          inputAs={Dropdown}
          value={isYokujitsuOn ? yokujitsu_haisha_kibo_kbn_list : null}
          name='yokujitsu_haisha_kibo_kbn_list'
          label='翌日配車希望'
          clearable
          multiple
          readOnly={isYokujitsuOn ? false : true}
        />
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.Shashu
          inputAs={Dropdown}
          value={shashu_id_list}
          name='shashu_id_list'
          label='車種'
          clearable
          multiple
        />
      </Grid.Column>

      <Grid.Column />

      <Grid.Column>
        <Form.Checkbox.Buttony
          checked={include_gps}
          name='include_gps'
          label='GPS待機情報を表示'
          size='mini'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          checked={include_yokujitsu_haisha_kibo}
          name='include_yokujitsu_haisha_kibo'
          label='翌日配車希望情報を表示'
          size='mini'
          onChange={(e, { checked }) => setIsYokujitsuOn(checked)}
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          checked={include_kumiai_haisha}
          name='include_kumiai_haisha'
          label='組合配車情報を表示'
          size='mini'
          onChange={(e, { checked }) => setIsHaishaOn(checked)}
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          checked={include_unsoten_schedule}
          name='include_unsoten_schedule'
          label='運送店スケジュール情報を表示'
          size='mini'
          onChange={(e, { checked }) => setIsScheduleOn(checked)}
        />
      </Grid.Column>
    </>
  )
}
