import React from 'react'
import { LayersControl, TileLayer } from 'react-leaflet'
import { BaseMap } from './BaseMap'

// 国土地理院のMAPS
// http://maps.gsi.go.jp/development/ichiran.html を参考に追加できる
const options = require('./maps.json')

const Skeleton = props => {
  const { mapName, mapUrl, minZoom, maxZoom, zoom, ...other } = props || {}

  return (
    <BaseMap
      {...other}
      mapLayers={
        <LayersControl.BaseLayer name={mapName} checked>
          <TileLayer url={mapUrl} />
        </LayersControl.BaseLayer>
      }
      zoom={zoom ? zoom : Math.floor((minZoom + maxZoom) / 2)}
      minZoom={minZoom}
      maxZoom={maxZoom}
    />
  )
}

export const StdMap = props => <Skeleton {...props} {...options.std} />

export const PaleMap = props => <Skeleton {...props} {...options.pale} />

export const EnglishMap = props => <Skeleton {...props} {...options.english} />

export const LCM25K2012Map = props => (
  <Skeleton {...props} {...options.lcm25k_2012} />
)

export const LCM25KMap = props => <Skeleton {...props} {...options.lcm25k} />

export const CCM1Map = props => <Skeleton {...props} {...options.ccm1} />

export const VBMMap = props => <Skeleton {...props} {...options.vbm} />

export const VBMCOLORRELMap = props => (
  <Skeleton {...props} {...options.vbm_colorrel} />
)

export const VBMPMMap = props => <Skeleton {...props} {...options.vbm_pm} />

export const VBMVLCDMap = props => <Skeleton {...props} {...options.vbm_vlcd} />

export const LUM200KMap = props => <Skeleton {...props} {...options.lum200k} />
