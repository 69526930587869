import React, { useRef } from 'react'
import {
  BaseForm as Form,
  Sidebar,
  Menu,
  Grid,
  Segment,
  Header,
  Icon,
} from 'component/base'

export const SearchSidebar = props => {
  const { visible, onSubmit, onClear, onCancel, condition, FormElement } = props

  const handleClickClose = () => onCancel()

  return (
    <Sidebar
      as={Menu}
      animation='overlay'
      icon='labeled'
      vertical
      visible={visible}
      style={{ minWidth: '20rem', width: '85%' }}
    >
      <Header
        as='h3'
        color='blue'
        attached='top'
        style={{
          textAlign: 'left',
          backgroundColor: '#efefef',
        }}
      >
        <Header.Content>
          <Icon name='arrow left' onClick={handleClickClose} />
          Filter
        </Header.Content>
      </Header>
      <Segment attached>
        <SidebarForm
          onSubmit={onSubmit}
          onClear={onClear}
          condition={condition}
          FormElement={FormElement}
        />
      </Segment>
    </Sidebar>
  )
}

const SidebarForm = props => {
  const { onSubmit, onClear, condition, FormElement } = props

  const formRef = useRef()

  const mapModel = input => input

  return (
    <Form onValidSubmit={onSubmit} mapping={mapModel} ref={formRef}>
      <Grid>
        <Grid.Row columns={3}>
          <FormElement condition={condition} sidebar={true} />
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column>
            <Form.Button.Primary
              name='search'
              content='検索'
              type='button'
              fluid
              onClick={() => formRef.current.submit()}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Button.Standard
              name='clearSearch'
              content='リセット'
              type='button'
              onClick={onClear}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}
