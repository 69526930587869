import { createMedia } from '@artsy/fresnel'

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    computer: 501,
  },
})

export const mediaStyles = AppMedia.createMediaStyle()
export const { Media, MediaContextProvider } = AppMedia
