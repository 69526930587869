import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { Axios, DataTable, Table, Segment } from 'component/base'

export const HanbaiTankaRireki = withRouter(props => {
  const { shohin_id } = props

  const [loading, setLoading] = useState(false)
  const [hanbai_tanka_data, set_hanbai_tanka_data] = useState([])

  useEffect(() => {
    get_hanbai_tanka(shohin_id)
  }, [shohin_id])

  const get_hanbai_tanka = async shohin_id => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/shohin_hanbai_tanka`,
      {
        params: { shohin_id },
      }
    )
    if (error) return
    setLoading(false)

    set_hanbai_tanka_data(data)
  }

  const TableRowWithProps = useCallback(props => <TableRow {...props} />, [])

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <DataTable
          name='hanbai_tanka_rireki'
          sortable
          filterable
          tableHeight='50vh'
          tableHeader={StaticWidthTableHeader}
          tableRow={TableRowWithProps}
          data={hanbai_tanka_data}
          bottomControl={<></>}
        />
      </Segment>
    </>
  )
})

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        onClick={() => handleSort('tekiyo_start_date')}
        content='適用開始日'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        onClick={() => handleSort('tanso_tanka')}
        content='単組単価'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        onClick={() => handleSort('kumiaiin_tanka')}
        content='組合員単価'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        onClick={() => handleSort('hikumiaiin_tanka')}
        content='非組合員単価'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data } = props || {}

  const { tekiyo_start_date, tanso_tanka, kumiaiin_tanka, hikumiaiin_tanka } =
    data || {}

  return withOrder(
    <Table.Row>
      <Table.Cell style={{ width: '8.0rem' }} content={tekiyo_start_date} />
      <Table.Cell style={{ width: '8.0rem' }} content={tanso_tanka} />
      <Table.Cell style={{ width: '8.0rem' }} content={kumiaiin_tanka} />
      <Table.Cell style={{ width: '8.0rem' }} content={hikumiaiin_tanka} />
    </Table.Row>
  )
}
