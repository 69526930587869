import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Dimmer, Loader, Axios, useKeycloak } from 'component/base'
import { TermOfUse } from 'component/page/Top/TermOfUse'

export const TermsOfUseFilter = props => {
  const { children } = props

  const { keycloak } = useKeycloak()

  const [loading, setLoading] = useState(true)
  const [accepted, setAccepted] = useState(false)
  const [data, setData] = useState({})

  const { riyo_kiyaku_id, riyo_kiyaku_doi_id } = data || {}

  useEffect(() => {
    callApi()
  }, [])

  const callApi = async () => {
    // through when not authenticated yet
    if (!keycloak.authenticated) {
      setLoading(false)
      setAccepted(true)
      return
    }

    const { data, error } = await Axios.ajax.get(
      '/api/user/riyo_kiyaku_doi',
      {}
    )
    if (error) {
      setLoading(false)
      return
    }

    if (_.isEmpty(data)) {
      setLoading(false)
      return
    }
    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    // no terms data
    if (_.isEmpty(data) || !riyo_kiyaku_id) {
      setAccepted(true)
      return
    }

    setAccepted(riyo_kiyaku_doi_id ? true : false)
  }, [data])

  return (
    <>
      {loading ? (
        <Dimmer active page blurring='true'>
          <Loader>Checking Terms of Use Status...</Loader>
        </Dimmer>
      ) : accepted ? (
        children
      ) : (
        <>
          <TermOfUse />
        </>
      )}
    </>
  )
}
