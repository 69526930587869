import { addValidationRule } from '@akihirotakamura/formsy-react'
import moment from 'moment'

addValidationRule('isHM', (values, value) => {
  if (!value || value === '') return true
  return (
    value.replace(/\u003a/g, '').match(/[0-9]{4}/) &&
    moment(value.replace(/\u003a/g, ''), 'HHmm').isValid()
  )
})
