import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button as SemanticUIButton } from 'semantic-ui-react'

class Standard extends Component {
  static propTypes = {
    /** An element type to render as (string or function). */
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /** A button can show it is currently the active user selection. */
    active: PropTypes.bool,

    /** A button can animate to show hidden content. */
    animated: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['fade', 'vertical']),
    ]),

    /** A button can be attached to other content. */
    attached: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    ]),

    /** A basic button is less pronounced. */
    basic: PropTypes.bool,

    /** A button can be circular. */
    circular: PropTypes.bool,

    /** Additional classes. */
    className: PropTypes.string,

    /** A button can have different colors */
    color: PropTypes.oneOf([
      'red',
      'orange',
      'yellow',
      'olive',
      'green',
      'teal',
      'blue',
      'violet',
      'purple',
      'pink',
      'brown',
      'grey',
      'black',
      'facebook',
      'google plus',
      'instagram',
      'linkedin',
      'twitter',
      'vk',
      'youtube',
    ]),

    /** A button can reduce its padding to fit into tighter spaces. */
    compact: PropTypes.bool,

    /** Shorthand for primary content. */
    content: PropTypes.node,

    /** A button can show it is currently unable to be interacted with. */
    disabled: PropTypes.bool,

    /** A button can be aligned to the left or right of its container. */
    floated: PropTypes.oneOf(['left', 'right']),

    /** A button can take the width of its container. */
    fluid: PropTypes.bool,

    /** Add an Icon by name, props object, or pass an <Icon />. */
    icon: PropTypes.node,

    /** A button can be formatted to appear on dark backgrounds. */
    inverted: PropTypes.bool,

    /** Add a Label by text, props object, or pass a <Label />. */
    label: PropTypes.oneOf([
      PropTypes.string,
      PropTypes.object,
      PropTypes.element,
    ]),

    /** A labeled button can format a Label or Icon to appear on the left or right. */
    labelPosition: PropTypes.oneOf(['right', 'left']),

    /** A button can show a loading indicator. */
    loading: PropTypes.bool,

    /** A button can hint towards a negative consequence. */
    negative: PropTypes.bool,

    /**
     * Called after user's click.
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onClick: PropTypes.func,

    /** A button can hint towards a positive consequence. */
    positive: PropTypes.bool,

    /** A button can be formatted to show different levels of emphasis. */
    primary: PropTypes.bool,

    /** The role of the HTML element. */
    role: PropTypes.string,

    /** A button can be formatted to show different levels of emphasis. */
    secondary: PropTypes.bool,

    /** A button can have different sizes. */
    size: PropTypes.oneOf([
      'mini',
      'tiny',
      'small',
      'medium',
      'large',
      'big',
      'huge',
      'massive',
    ]),

    /** A button can receive focus. */
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /** A button can be formatted to toggle on and off. */
    toggle: PropTypes.bool,
  }

  static defaultProps = {
    as: 'button',
    role: 'button',
  }

  render = () => {
    return <SemanticUIButton {...this.props} />
  }
}

export default Standard
