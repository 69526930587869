import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'
import queryString from 'query-string'

export const Team = props => {
  const { haiso_center_id, busho_id, is_haiso_team_only, is_other_only, ...other } = props
  return (
    <Form.Dropdown.WithAPI
      url={`/api/staff/master/team?${queryString.stringify({
        haiso_center_id,
        busho_id,
        is_haiso_team_only,
        is_other_only,
      })}`}
      keyName='id'
      valueName='id'
      textName='name'
      sort={['sort']}
      {...other}
    />
  )
}

Team.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  is_haiso_team_only: PropTypes.bool,
  is_other_only: PropTypes.bool,
}

Team.defaultProps = {
  name: 'team',
  label: 'チーム',
  value: undefined,
  haiso_center_id: undefined,
  busho_id: undefined,
  is_haiso_team_only: false,
  is_other_only: false,
}
