import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Axios,
  Icon,
  Card,
  Progress,
  Header,
  Grid,
  useKeycloak,
  notice,
} from 'component/base'
import { getConfig, getMediaUrl } from 'config'

export const FileCard = props => {
  const {
    file,
    setIds,
    onCancel,
    disableVideoUpload,
    url,
    limitedExtents,
  } = props

  const { path } = file || {}

  const [percent, setPercent] = useState(0)
  const [id, setId] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const { keycloak } = useKeycloak()
  const { token } = keycloak || {}

  const { api_endpoint } = getConfig()

  const mediaUrl = getMediaUrl()

  useEffect(() => {
    const postVideo = async () => {
      // get signed url
      const { data, error } = await Axios.ajax.get(
        `${mediaUrl}/uploadUrl?Authorization=${token}`
      )
      if (error) return { error }

      const { url: signedurl, key } = data

      // upload file to s3
      const { error: uploadError } = await Axios.noauth_ajax.put(
        signedurl,
        file,
        {
          onUploadProgress: progressEvent => {
            setPercent(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          },
        }
      )
      if (uploadError) return { error: uploadError }

      return await Axios.ajax.post(
        `${api_endpoint}/api/${
          keycloak.hasRealmRole('shokuin') ? 'staff' : 'member'
        }/upload_files_video_public`,
        {
          mime_type: file.type,
          original_name: file.name,
          size: file.size,
          uuid: key,
        }
      )
    }

    const postFile = async () => {
      const formdata = new FormData()
      formdata.append('file', file)

      return await Axios.ajax.post(url, formdata, {
        headers: {
          'content-type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          setPercent(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        },
      })
    }

    const post = async () => {
      if (!file || !token) return
      if (
        _.isArray(limitedExtents) &&
        limitedExtents.length > 0 &&
        !limitedExtents.includes(file.type)
      ) {
        setErrorMessage('この形式のファイルはアップロードできません')
        setPercent(100)
        return
      }

      const { data, error } =
        file.type.indexOf('video/') === 0 && !disableVideoUpload
          ? await postVideo()
          : await postFile()
      if (error) {
        error.status === 400
          ? setErrorMessage(error.data.details[0].message)
          : setErrorMessage('ファイルアップロードでエラーが発生しました')
        return
      }
      const { id } = data || {}

      setIds(ids => [...ids, id])
      setId(id)
    }

    post()
  }, [token])

  return (
    <Card key={file.path}>
      <Card.Content>
        <Icon className='right floated' size='big' name='file outline' />
        <Card.Header>{file.path}</Card.Header>
        <Card.Meta>{Math.round(file.size / 1000)} Kbytes</Card.Meta>
      </Card.Content>
      <Card.Content>
        {percent === 100 ? (
          <Grid>
            <Grid.Column width={8}>
              {errorMessage === '' ? (
                <Header
                  as='h4'
                  icon='check'
                  color='green'
                  content='Upload完了'
                />
              ) : (
                <Header
                  as='h4'
                  icon='exclamation circle'
                  color='red'
                  content={errorMessage}
                />
              )}
            </Grid.Column>
            <Grid.Column width={8}>
              <Header
                as='h4'
                icon='remove circle'
                color='red'
                content='取消'
                onClick={() => {
                  setIds(ids => _.without(ids, id))
                  onCancel(path)
                }}
              />
            </Grid.Column>
          </Grid>
        ) : (
          <Progress percent={percent} indicating />
        )}
      </Card.Content>
    </Card>
  )
}
