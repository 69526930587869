import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'

export const Kamoku = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/kanjo_kamoku'
      keyName='id'
      valueName='id'
      textName='kamoku_name'
      {...props}
    />
  )
}

Kamoku.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
}

Kamoku.defaultProps = {
  label: '科目',
  name: 'kamoku_id',
  value: undefined,
}
