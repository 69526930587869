import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Popup,
  List,
  Button,
} from 'component/base'
import { Custom } from 'component/pieces'

export const Busho = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [displayData, setdisplayData] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )
  const [isRecentOnly, setIsRecentOnly] = useState(true)

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id)),
      data
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/busho_zokusei',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  useEffect(
    () =>
      setdisplayData(
        isRecentOnly
          ? _(data)
              .orderBy(['busho_id', 'tekiyo_start_date'], ['asc', 'desc'])
              .uniqBy('busho_id')
              .value()
          : data
      ),
    [isRecentOnly, data]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
        >
          <DataTable
            name='busho'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={displayData}
            topControl={
              <Checkbox
                toggle
                checked={isRecentOnly}
                label='最新世代のみ表示'
                onChange={(e, { checked }) => setIsRecentOnly(checked)}
              />
            }
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Busho.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Busho.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'busho_cd' ? direction : null}
        onClick={() => handleSort('busho_cd')}
        content='部署コード'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'busho_name' ? direction : null}
        onClick={() => handleSort('busho_name')}
        content='部署名称'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'tekiyo_start_date' ? direction : null}
        onClick={() => handleSort('tekiyo_start_date')}
        content='適用開始年月日'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'tekiyo_end_date' ? direction : null}
        onClick={() => handleSort('tekiyo_end_date')}
        content='適用終了年月日'
      />
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'chiku_id_list' ? direction : null}
        onClick={() => handleSort('chiku_id_list')}
        content='地区'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'meiboyo_chiku_id_list' ? direction : null}
        onClick={() => handleSort('meiboyo_chiku_id_list')}
        content='名簿用地区'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'post_cd' ? direction : null}
        onClick={() => handleSort('post_cd')}
        content='郵便番号'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'address1' ? direction : null}
        onClick={() => handleSort('address1')}
        content='住所1'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'address2' ? direction : null}
        onClick={() => handleSort('address2')}
        content='住所2'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tel' ? direction : null}
        onClick={() => handleSort('tel')}
        content='電話番号'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'fax' ? direction : null}
        onClick={() => handleSort('fax')}
        content='FAX番号'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'mail' ? direction : null}
        onClick={() => handleSort('mail')}
        content='メールアドレス'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'url' ? direction : null}
        onClick={() => handleSort('url')}
        content='url'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'soshiki_kaiso_path' ? direction : null}
        onClick={() => handleSort('soshiki_kaiso_path')}
        content='組織階層パス'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    busho_cd,
    busho_name,
    tekiyo_start_date,
    tekiyo_end_date,
    post_cd,
    address1,
    address2,
    tel,
    fax,
    mail,
    url,
    soshiki_kaiso_path,
    chiku_name_list,
    meiboyo_chiku_name_list,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '8.0rem' }} content={busho_cd} />
      <Table.Cell style={{ width: '15.0rem' }} content={busho_name} />
      <Table.Cell style={{ width: '9.0rem' }} content={tekiyo_start_date} />
      <Table.Cell style={{ width: '9.0rem' }} content={tekiyo_end_date} />
      <Table.Cell
        style={{ width: '6.0rem' }}
        content={
          <Popup
            {...props}
            content={<List bulleted items={chiku_name_list} />}
            on='click'
            trigger={
              <Button
                icon='list'
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            }
            onClick={e => {
              e.stopPropagation()
            }}
            position='right center'
            basic
          />
        }
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={
          <Popup
            {...props}
            content={<List bulleted items={meiboyo_chiku_name_list} />}
            on='click'
            trigger={
              <Button
                icon='list'
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            }
            onClick={e => {
              e.stopPropagation()
            }}
            position='right center'
            basic
          />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={post_cd} />
      <Table.Cell style={{ width: '20.0rem' }} content={address1} />
      <Table.Cell style={{ width: '20.0rem' }} content={address2} />
      <Table.Cell style={{ width: '10.0rem' }} content={tel} />
      <Table.Cell style={{ width: '10.0rem' }} content={fax} />
      <Table.Cell style={{ width: '20.0rem' }} content={mail} />
      <Table.Cell style={{ width: '20.0rem' }} content={url} />
      <Table.Cell style={{ width: '12.0rem' }} content={soshiki_kaiso_path} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  return (
    <>
      <Grid.Column
        style={{
          display: !nav || condition.busho_cd ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.busho_cd}
          label='部署コード'
          name='busho_cd'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.busho_name ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.busho_name}
          label='部署名称'
          name='busho_name'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.post_cd ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.post_cd}
          label='郵便番号'
          name='post_cd'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.address1 ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.address1}
          label='住所1'
          name='address1'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.address2 ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.address2}
          label='住所2'
          name='address2'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.tel ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.tel}
          label='電話番号'
          name='tel'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.fax ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard value={condition.fax} label='FAX' name='fax' />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.mail ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.mail}
          label='メールアドレス'
          name='mail'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.url ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard value={condition.url} label='url' name='url' />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.soshiki_kaiso_path ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.soshiki_kaiso_path}
          label='組織階層パス'
          name='soshiki_kaiso_path'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.chiku_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Chiku
          label='地区'
          name='chiku_id_list'
          value={condition.chiku_id_list}
          clearable={true}
          multiple
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.meiboyo_chiku_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.MeiboyoChiku
          label='名簿用地区'
          name='meiboyo_chiku_id_list'
          value={condition.meiboyo_chiku_id_list}
          clearable={true}
          multiple
        />
      </Grid.Column>
    </>
  )
}
