import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Axios, Grid, Segment } from 'component/base'
import { Timeline as PieceTimeline } from 'component/pieces'

export default class Timeline extends Component {
  state = {
    isFetching: false,
    options: [],
  }

  componentDidMount = () => {
    this.getOptions()
  }

  getOptions = async () => {
    const { url, searchkey, value } = this.props

    this.setState({ isFetching: true })

    const getparams = new URLSearchParams()
    getparams.append(searchkey, value)
    const request = { params: getparams }

    const { data, error } = await Axios.ajax.get(url, request)

    if (error) {
      this.setState({ isFetching: false, options: [] })
      return
    }
    this.setState({ isFetching: false, options: data })
  }

  render() {
    const { options, isFetching } = this.state

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment basic loading={isFetching}>
              {(() =>
                options.map((option, i) => (
                  <PieceTimeline
                    key={i}
                    time={option.time}
                    title={option.title}
                    description={option.description}
                  />
                )))()}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

Timeline.propTypes = {
  url: PropTypes.string,
  searchkey: PropTypes.string,
  value: PropTypes.string,
}

Timeline.defaultProps = {
  url: '',
  searchkey: '',
  value: '',
}
