import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Comment, Dimmer, Loader, Icon, Button } from 'component/base'
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import moment from 'moment'
import { updateLastReadTime } from 'graphql/mutations'
import { allMessageConnection } from 'graphql/queries'
import { subscribeToNewMessage } from 'graphql/subscriptions'
import { getConfigGlobal } from 'config'

const gplAllMessageConnection = gql(allMessageConnection)
const gplSubscribeToNewMessage = gql(subscribeToNewMessage)
const NO_IMAGE_URL = '/images/noimage.jpg'

const MessageItem = props => {

  const { id, name, content, createdAt, userId, data } = props
  const { web } = data || {}
  const date = moment.unix(createdAt).isValid() ? moment.unix(createdAt).format("MM/DD HH:mm") : ""

  if (!id) return (<></>)

  return (
    <>
      <Comment>
        <Comment.Avatar
          src={`${getConfigGlobal().avatarEndpoint}${userId}`}
          onError={e => (e.target.src = NO_IMAGE_URL)}
        />
        <Comment.Content>
          <Comment.Author>{name}</Comment.Author>
          <Comment.Metadata>
            <div>{date}</div>
          </Comment.Metadata>
            {content ? content.split('\n').map(text=>(<Comment.Text>{text}</Comment.Text>)): (<Comment.Text>　</Comment.Text>)}
          <Comment.Actions>
            {web ?
              <Comment.Action onClick={()=>{
                window.location.href = window.location.origin + web
              }}>
                <Icon name='linkify' />
                リンク先を開く
              </Comment.Action>
              : <></>
            }
          </Comment.Actions>  
        </Comment.Content>
      </Comment>
    </>
  )
}

export const MessageList = props => {

  const LIST_CHUNK_SIZE = 15
  const { conversationId, open } = props
  const [ hasMore, setHasMore ] = useState(false)
  const [ messages, setMessages ] = useState([])
  const [ nextToken,setNextToken ] = useState(null)
  const [ currentToken, setCurrentToken] = useState(null)
  const [ initialized, setInitialized] = useState(false)
  const { data, loading, subscribeToMore } = useQuery(gplAllMessageConnection, {
    variables: {
      conversationId: conversationId,
      first: LIST_CHUNK_SIZE,
      after: currentToken,
    },
    pollInterval: 0,
  })
  const { allMessageConnection } = data || {}
  const ref = useRef()

  const [updateLRT] = useMutation(gql(updateLastReadTime))
  
  const subscribeToNewComments = () => 
    subscribeToMore({
      document: gplSubscribeToNewMessage,
      variables: { conversationId },
      updateQuery: (prev,{ subscriptionData: { data: { subscribeToNewMessage } } }) => {
        setMessages(...messages, subscribeToNewMessage)
        return {
          ...prev,
          allMessageConnection: {
            ...prev.allMessageConnection,
            messages: [
              ...prev.allMessageConnection.messages,
              subscribeToNewMessage,
            ],
          },
        }
      }
    })

  useEffect(() => {
    if (!loading) {
      setInitialized(true)
      setNextToken(allMessageConnection.nextToken)
      setHasMore(!!allMessageConnection.nextToken)
      setMessages([...allMessageConnection.messages, ...messages])
    }
  }, [loading])

  useEffect(()=>{
    const unLoad = async () => {
      setCurrentToken(null)
      setMessages([])
      setInitialized(false)
      if (conversationId) {
        await updateLRT({
          variables: {
            conversationId: conversationId
          }
        })
      }
    }
    return ()=>unLoad()
  },[conversationId])

  useEffect(() => {
    if (initialized) {
      subscribeToNewComments()
      ref.current.scrollIntoView()
    }
  }, [initialized])

  const loadMore = () => {
    if (loading) return
    setCurrentToken(nextToken)
  }

  const loader = (
    <Dimmer active>
      <Loader />
    </Dimmer>
  )

  if (!open) return (<></>)

  return (
    <>
      <Button fluid onClick={() => loadMore()}>
        さらに読み込む
      </Button>
      <Comment.Group>
        {loading
          ? loader
          : _(messages)
              .orderBy(['createdAt'], ['asc'])
              .map(message => {
                const { id, author, content, createdAt, data } = message
                const { id: userId, displayName } = author || {}
                if (!id) return <></>
                return (
                  <MessageItem
                    key={id}
                    id={id}
                    userId={userId}
                    name={displayName}
                    content={content}
                    createdAt={createdAt}
                    data={data}
                    //
                  />
                )
              })
              .value()}
        <div id='latest' ref={ref} />
      </Comment.Group>
    </>
  )
}