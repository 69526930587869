import React, { useEffect, useState } from 'react'
import { useQuery, useSubscription, gql } from '@apollo/client'
import { WatchConversation } from 'component/pieces'
import { useUserInfo } from 'component/hook'

const subscribeToNewUCs = /* GraphQL */ `
subscription SubscribeToNewUCs($userId: ID!) {
  subscribeToNewUCs(userId: $userId) {
    conversationId
    userId
  }
}
`

const getMyConversation = /* GraphQL */ `
  query myConversations {
    me {
      conversations {
        userConversations {
          conversationId
        }
      }
    }
  }
`

export const PushNotification = props => {
  const { userInfo } = useUserInfo()
  const [ newMessage, setNewMessage ] = useState(null)
  const { data: newUC } = useSubscription(gql(subscribeToNewUCs), {
    variables: {
      userId: userInfo.sub
    }
  })
  const { data, loading, refetch } = useQuery(gql(getMyConversation), { variables: {}, fetchPolicy: 'network-only' })

  const sendNotification = () => {
    const {conversationId, content, sender, data : messageData} = newMessage
    const { web } = messageData || {}
    if (Notification.permission !== 'granted') return
    if (sender === userInfo.sub) return
    const notification = new Notification('nais', { body: content })    
    notification.onclick = (event) => {
      event.preventDefault();
      window.focus()
      if (web) {
        window.location.href = window.location.origin + web
      } 
      else window.location.search = new URLSearchParams({conversationId: conversationId})      
    }
  }

  useEffect(() => {
    if (!newUC) return
    refetch()
  }, [newUC])

  useEffect(()=>{
    if (newMessage) sendNotification()
  }, [newMessage])

  return (
    <>
    {!loading && data.me.conversations.userConversations && data.me.conversations.userConversations.length > 0 ?
      (data.me.conversations.userConversations.map((uc)=>(
        <WatchConversation
          key={uc.conversationId}
          conversationId={uc.conversationId}
          onMessageReceived={setNewMessage}
        /> 
      )))
      : (<></>)
    }
    </>
  )
}

export default PushNotification