import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Text,
} from 'component/base'

export const Kyosaikai = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
          ? [selectedId]
          : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/kumiaiin/kyosaikai_shime',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='kumiaiin-kyosaikai'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            defaultSortColumnName='shime_date'
            defaultSortDirection='descending'
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Kyosaikai.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Kyosaikai.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => { },
}

const TableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shime_date' ? direction : null}
        onClick={() => handleSort('shime_date')}
        content='締日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kumiaiin_su' ? direction : null}
        onClick={() => handleSort('kumiaiin_su')}
        content='組合員数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kyosai_kaihi_gaku' ? direction : null}
        onClick={() => handleSort('kyosai_kaihi_gaku')}
        content='共済会費'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'rengo_kaihi_gaku' ? direction : null}
        onClick={() => handleSort('rengo_kaihi_gaku')}
        content='連合会費'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'gokei_gaku' ? direction : null}
        onClick={() => handleSort('gokei_gaku')}
        content='合計額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'data_sakusei_date' ? direction : null}
        onClick={() => handleSort('data_sakusei_date')}
        content='データ作成日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'name' ? direction : null}
        onClick={() => handleSort('name')}
        content='データ作成者'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    shime_date,
    kumiaiin_su,
    kyosai_kaihi_gaku,
    rengo_kaihi_gaku,
    gokei_gaku,
    data_sakusei_date,
    // preffered_user_name,
    name,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={shime_date} />
      <Table.Cell
        style={{ width: '10.0rem', textAlign: 'right' }}
        content={<Text.Comma content={kumiaiin_su} />}
      />
      <Table.Cell
        style={{ width: '10.0rem', textAlign: 'right' }}
        content={<Text.Comma content={kyosai_kaihi_gaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem', textAlign: 'right' }}
        content={<Text.Comma content={rengo_kaihi_gaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem', textAlign: 'right' }}
        content={<Text.Comma content={gokei_gaku} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={data_sakusei_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={name} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  return (
    <>
      <Grid.Column
        style={{
          display: !nav || condition.shime_date_from ? 'block' : 'none',
        }}
      >
        <Form.Textbox.DatePicker
          value={condition.shime_date_from}
          label='共済会締日FROM'
          name='shime_date_from'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shime_date_to ? 'block' : 'none',
        }}
      >
        <Form.Textbox.DatePicker
          value={condition.shime_date_to}
          label='共済会締日TO'
          name='shime_date_to'
        />
      </Grid.Column>
    </>
  )
}
