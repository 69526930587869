import { Standard } from './Standard'
import { WithAPI } from './WithAPI'

/*
  create uncontrolled dropdown.
  caution:
    this components cannot set value props
    because of be controlled components on React
    when value props supplied.
*/
export class SimpleDropdown {
  static Standard = Standard
  static WithAPI = WithAPI
}
