import React, { useEffect, useReducer }  from 'react'
import {
  Modal,
  Button,
  Grid,
} from 'component/base'
import { ConversationNav } from './ConversationNav'
import { MessageList } from './MessageList'
import { MessageForm } from './MessageForm'
import { CreateConversation } from './CreateConversation'

export const ChatModal = props => {
  
  const { open, onClose, defaultConversationId } = props
  const reducer = (_state, action) => {
    switch (action.mode) {
      case 'messageList':
        if (!action.conversationId) return { messageListOpen: false, createConversationOpen: false }
        return { messageListOpen: true, createConversationOpen: false, conversationId: action.conversationId }   
      case 'createConversation':
        return { messageListOpen: false, createConversationOpen: true, conversationId: action.conversationId, createMode: action.createMode }
      default:
        return { messageListOpen: false, createConversationOpen: false }
    }
  }

  const [ state, dispatch ] = useReducer(reducer, {messageListOpen: true, createConversationOpen: false, conversationId: defaultConversationId})
  const { messageListOpen, createConversationOpen, conversationId, createMode } = state

  useEffect(()=>{
      dispatch({ mode: 'messageList',conversationId: defaultConversationId})
  }
  , [defaultConversationId])

  return (
    <Modal
      onClose={onClose}
      open={open}
      size='large'
      style={{height: '80%'}}
    >
      <Modal.Header>通知</Modal.Header>
      <Grid style={{height: '90%'}}>
        <Grid.Row>
          <Grid.Column width={4}>
            <Modal.Content scrolling>
              <ConversationNav
                conversationId={conversationId}
                onClick={(input)=>dispatch({ mode: 'messageList', conversationId: input})}
                onClickCreateConversation={(createMode)=>dispatch({ mode: 'createConversation', conversationId, createMode})}
              />
            </Modal.Content>
          </Grid.Column>
          <Grid.Column width={12}>
            <Modal.Content scrolling>
              <MessageList
                conversationId={conversationId}
                open={messageListOpen}         
              />
            </Modal.Content>
            {/* 当面チャット利用しないためリプライ入力画面は非表示 
            <Modal.Content
              style={{
                position: 'absolute',
                bottom: 0,
                width: 'inherit'
              }}
            >
              <MessageForm
                conversationId={conversationId}
              />
            </Modal.Content>
            */}
            <CreateConversation
              openMessage={(input)=>dispatch({ mode: 'messageList', conversationId: input})}
              open={createConversationOpen}
              onClose={()=>dispatch({ mode: 'messageList', conversationId})}
              mode={createMode}
            />
          </Grid.Column>

        </Grid.Row>
      </Grid>      
      <Modal.Actions>
        <Button onClick={onClose}>
          閉じる
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
