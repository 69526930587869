import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'
import queryString from 'query-string'

export const ShiwakePatternTorihiki = props => {
  const {
    major_kbn_list,
    middle_kbn_list,
    minor_kbn_list,
    torihiki_kbn,
    taishaku_kbn,
    hassei_moto_kbn,
    ...other
  } = props

  const params = useMemo(() => {
    return {
      major_kbn_list,
      middle_kbn_list,
      minor_kbn_list,
      torihiki_kbn,
      taishaku_kbn,
      hassei_moto_kbn,
    }
  }, [
    major_kbn_list,
    middle_kbn_list,
    minor_kbn_list,
    torihiki_kbn,
    taishaku_kbn,
    hassei_moto_kbn,
  ])

  return (
    <Form.Dropdown.WithAPI
      url={`/api/staff/master/shiwake_pattern/torihiki?${queryString.stringify(
        params
      )}`}
      keyName='id'
      valueName='id'
      textName='torihiki_name'
      editOptions={options =>
        _(options)
          .map(option => ({
            key: option.id,
            value: option.id,
            text: `${option.pattern_name ? option.pattern_name : ''}：${option.torihiki_name
              }`,
          }))
          .value()
      }
      sort={['id']}
      {...other}
    />
  )
}

ShiwakePatternTorihiki.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

ShiwakePatternTorihiki.defaultProps = {
  name: 'shiwake_pattern_torihiki_id',
  label: '取引種類',
  value: undefined,
}
