import React, { useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Util } from 'component/base'

const SuggestWrapper = props => {
  const [randomID] = useState(Util.getRandomID())

  const { loading, datalist, children, ...other } = props || {}

  const childrenWithProps = cloneElement(
    children,
    _.merge(
      {},
      { ...other },
      {
        loading,
        list: randomID,
      }
    )
  )

  return (
    <>
      {childrenWithProps}
      <datalist id={randomID}>
        {_.map(datalist, (row, i) => (
          <option key={i} value={row.value} label={row.label} />
        ))}
      </datalist>
    </>
  )
}

SuggestWrapper.propTypes = {
  loading: PropTypes.bool,
  datalist: PropTypes.array,
}
SuggestWrapper.defaultProps = {
  loading: false,
  datalist: [],
}

export default SuggestWrapper
