import React, { useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  BaseForm as Form,
  Grid,
  Segment,
  Menu,
  Transition,
  Icon,
  Text,
  Button,
} from 'component/base'

export const SearchNav = props => {
  const {
    onSubmit,
    onClear,
    condition,
    FormElement,
    enableSidebar,
    showSidebar,
    setShowSidebar,
    compact,
    maxHeight: _maxHeight,
    extraContent,
    size,
  } = props

  const FORM_ID = 'search-nav-form'

  const [accordion, setAccordion] = useState(true)
  const [maxHeight, setMaxHeight] = useState(_maxHeight)

  useEffect(() => {
    setMaxHeight(_maxHeight)
  }, [_maxHeight])

  const toggleAccordion = () => setAccordion(state => !state)

  const toggleMaxHeight = () => {
    if (maxHeight) {
      setMaxHeight(null)
      return
    }
    setMaxHeight(_maxHeight || '15vh')
  }

  return (
    <>
      {!showSidebar && (
        <>
          <Menu inverted attached='top' size='mini'>
            <Menu.Item
              fitted='vertically'
              onClick={() => {
                enableSidebar && setShowSidebar(true)
              }}
            >
              <Icon name='search' />
              <Text.Basic>Filter</Text.Basic>
            </Menu.Item>

            {compact && (
              <>
                <Menu.Item fitted='vertically'>
                  <Button.Group size='mini'>
                    {/* Caution! if Form in Form(like Unsoten Dropdown) */}
                    {/* FORM_ID submit do not expect action */}
                    {/* it will through submit action to parent form */}
                    {/* please use compact=false mode */}
                    <Button content='検索' form={FORM_ID} />
                    <Button content='リセット' as='span' onClick={onClear} />
                  </Button.Group>
                </Menu.Item>
              </>
            )}

            <Menu.Menu position='right'>
              {accordion && (
                <Menu.Item fitted='vertically' onClick={toggleMaxHeight}>
                  <Icon
                    name='arrows alternate vertical'
                    style={{ float: 'right' }}
                  />
                </Menu.Item>
              )}
              <Menu.Item fitted='vertically' onClick={toggleAccordion}>
                <Icon
                  name={accordion ? 'caret up' : 'caret down'}
                  style={{ float: 'right' }}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>

          <Transition visible={accordion} animation='slide down' duration={500}>
            <>
              <Segment
                attached
                style={{
                  borderWidth: '2px',
                  paddingBottom: '20px',
                  overflowY: maxHeight ? 'scroll' : 'unset',
                  maxHeight,
                }}
              >
                <SearchNavForm
                  FORM_ID={FORM_ID}
                  onSubmit={onSubmit}
                  onClear={onClear}
                  condition={condition}
                  FormElement={FormElement}
                  enableSidebar={enableSidebar}
                  compact={compact}
                  size={size}
                />
              </Segment>
              {extraContent && (
                <Segment basic attached>
                  {extraContent}
                </Segment>
              )}
            </>
          </Transition>
        </>
      )}
    </>
  )
}

SearchNav.propTypes = {
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  condition: PropTypes.object,
  FormElement: PropTypes.func,
  enableSidebar: PropTypes.bool,
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'huge', 'massive']),
}

SearchNav.defaultProps = {
  onSubmit: () => {},
  onClear: () => {},
  condition: {},
  FormElement: () => {},
  enableSidebar: true,
  size: 'small',
}

const SearchNavForm = props => {
  const {
    FORM_ID,
    onSubmit,
    onClear,
    condition,
    FormElement,
    enableSidebar,
    compact,
    size,
    className: _className,
  } = props

  const formRef = useRef()

  const mapModel = input => input

  const className = useMemo(() => `${_className} ${size}`, [_className, size])

  return (
    <>
      <Form
        id={FORM_ID}
        onValidSubmit={onSubmit}
        mapping={mapModel}
        ref={formRef}
      >
        <Grid>
          <Grid.Row
            columns={5}
            style={{
              paddingBottom: '0px',
            }}
            className={className}
          >
            <FormElement
              condition={condition}
              nav={enableSidebar ? true : false}
            />
          </Grid.Row>

          {!compact && (
            <>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Form.Button.Primary
                    type='button'
                    name='search'
                    content='検索'
                    size='mini'
                    fluid
                    onClick={() => formRef.current.submit()}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Form.Button.Standard
                    name='clearSearch'
                    content='リセット'
                    type='button'
                    onClick={onClear}
                    size='mini'
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </Form>
    </>
  )
}
