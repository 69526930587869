import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Dimmer, Loader, Axios, useKeycloak, notice } from 'component/base'
import { useLocalStorage } from 'component/hook'
import { AccessDenied } from 'component/page/Error/AccessDenied'

export const ShokuinFilter = props => {
  const { children } = props

  const { keycloak } = useKeycloak()

  const { busy, localState, setLocalState } = useLocalStorage(
    'user-active-info'
  )

  const { preferred_username } = localState || {}

  const [loading, setLoading] = useState(true)

  const callApi = async preferred_username => {
    const { data, error } = await Axios.ajax.get('/api/staff/master/shokuin', {
      params: {
        shokuin_cd: preferred_username,
      },
    })
    if (error || _.isEmpty(data)) {
      const { status, statusText } = error || {}

      notice({
        title: `Error ${status ? status : ''}`,
        description: `職員マスタが読み込めませんでした\n 職員コード:${preferred_username}\n ${
          statusText ? statusText : ''
        }`,
        type: 'error',
        time: 0,
      })
      setLocalState(state => _.assign({}, state, { shokuin: {} }))
      setLoading(false)
      return
    }

    setLocalState(state => _.assign({}, state, { shokuin: data[0] }))
    setLoading(false)
  }

  useEffect(() => {
    if (!preferred_username || !keycloak.hasRealmRole('shokuin')) {
      setLoading(false)
      return
    }
    callApi(preferred_username)
  }, [])

  return (
    <>
      {busy || loading ? (
        <Dimmer active page blurring='true'>
          <Loader>Initializing Shokuin Information...</Loader>
        </Dimmer>
      ) : keycloak.hasRealmRole('shokuin') && _.isEmpty(localState.shokuin) ? (
        <AccessDenied />
      ) : (
        children
      )}
    </>
  )
}
