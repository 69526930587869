import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Input,
} from 'component/base'
// import {
//   Custom
// } from 'component/pieces'

export const Oshirase = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get('/api/staff/master/oshirase', {
      params: {
        ...condition,
      },
    })
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='oshirase'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Oshirase.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Oshirase.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'yuko_start_date' ? direction : null}
        onClick={() => handleSort('yuko_start_date')}
        content='掲載開始日'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'yuko_end_date' ? direction : null}
        onClick={() => handleSort('yuko_end_date')}
        content='掲載終了日'
      />
      <Table.HeaderCell
        style={{ width: '30.0rem' }}
        sorted={column === 'setsumei' ? direction : null}
        onClick={() => handleSort('setsumei')}
        content='お知らせ内容'
      />
      {/*
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={ column === 'oshirase_hyoji_taisho_kbn' ? direction : null }
        onClick={ () => handleSort('oshirase_hyoji_taisho_kbn') }
        content='表示対象'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={ column === 'busho_name_list' ? direction : null }
        onClick={ () => handleSort('busho_name_list') }
        content='公開範囲'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={ column === 'upload_files_id' ? direction : null }
        onClick={ () => handleSort('upload_files_id') }
        content='添付ファイル'
      />
    */}
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    yuko_start_date,
    yuko_end_date,
    setsumei,
    // oshirase_hyoji_taisho_kbn,
    // busho_name_list,
    // upload_files_id,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '9.0rem' }} content={yuko_start_date} />
      <Table.Cell style={{ width: '9.0rem' }} content={yuko_end_date} />
      <Table.Cell style={{ width: '30.0rem' }} content={setsumei} />
      {/*
      <Table.Cell
        style={{ width: '6.0rem' }}
        content={
          <Custom.Label.OshiraseHyojiTaishoKbn
            value={oshirase_hyoji_taisho_kbn}
          />
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          _.isArray(busho_name_list)
          ? busho_name_list.join(', ')
          : ''
        }
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={
          (!upload_files_id)
            ? '無'
            : '有'
        }
      />
    */}
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const { setsumei, is_yuko_date_only } = condition

  return (
    <>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={setsumei}
          label='お知らせ内容'
          name='setsumei'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          name='is_yuko_date_only'
          label='掲載期間内のデータのみ表示'
          checked={is_yuko_date_only}
          size='small'
        />
      </Grid.Column>
    </>
  )
}
