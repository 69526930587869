import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
} from 'component/base'
import { Custom } from 'component/pieces'
import { useUserInfo } from 'component/hook'

export const Shokuin = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const { active_haiso_center_id, active_busho_id } = useUserInfo()

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {
      shozoku_busho_id: active_busho_id,
      shozoku_haiso_center_id: active_haiso_center_id,
    }
  }, [active_busho_id, active_haiso_center_id])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [displayData, setdisplayData] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )
  const [isRecentOnly, setIsRecentOnly] = useState(true)

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id)),
      data
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/shokuin_zokusei',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  useEffect(
    () =>
      setdisplayData(
        isRecentOnly
          ? _(data)
              .orderBy(['shokuin_id', 'tekiyo_start_date'], ['asc', 'desc'])
              .uniqBy('shokuin_id')
              .value()
          : data
      ),
    [isRecentOnly, data]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
        >
          <DataTable
            name='shokuin'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={displayData}
            topControl={
              <Checkbox
                toggle
                checked={isRecentOnly}
                label='最新世代のみ表示'
                onChange={(e, { checked }) => setIsRecentOnly(checked)}
              />
            }
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Shokuin.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Shokuin.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'shokuin_cd' ? direction : null}
        onClick={() => handleSort('shokuin_cd')}
        content='職員コード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shokuin_name' ? direction : null}
        onClick={() => handleSort('shokuin_name')}
        content='職員名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shokuin_kana' ? direction : null}
        onClick={() => handleSort('shokuin_kana')}
        content='職員カナ'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'tekiyo_start_date' ? direction : null}
        onClick={() => handleSort('tekiyo_start_date')}
        content='適用開始年月日'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'tekiyo_end_date' ? direction : null}
        onClick={() => handleSort('tekiyo_end_date')}
        content='適用終了年月日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'busho_name' ? direction : null}
        onClick={() => handleSort('busho_name')}
        content='所属部署'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haiso_center_name' ? direction : null}
        onClick={() => handleSort('haiso_center_name')}
        content='所属配送センター'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shokuin_kbn' ? direction : null}
        onClick={() => handleSort('shokuin_kbn')}
        content='職員区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tel' ? direction : null}
        onClick={() => handleSort('tel')}
        content='電話番号'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'mobile_tel' ? direction : null}
        onClick={() => handleSort('mobile_tel')}
        content='携帯電話番号'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'mail' ? direction : null}
        onClick={() => handleSort('mail')}
        content='メールアドレス'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'biko' ? direction : null}
        onClick={() => handleSort('biko')}
        content='備考'
      />
      <Table.HeaderCell
        style={{ width: '5.0rem' }}
        sorted={column === 'seibetsu' ? direction : null}
        onClick={() => handleSort('seibetsu')}
        content='性別'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'kyosaikai_kanyu_date' ? direction : null}
        onClick={() => handleSort('kyosaikai_kanyu_date')}
        content='共済会加入年月日'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'kyosaikai_dattai_date' ? direction : null}
        onClick={() => handleSort('kyosaikai_dattai_date')}
        content='共済会脱退年月日'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    tekiyo_start_date,
    tekiyo_end_date,
    shozoku_busho_name,
    shozoku_haiso_center_name,
    shokuin_kbn,
    tel,
    mobile_tel,
    mail,
    biko,
    shokuin_cd,
    shokuin_name,
    shokuin_kana,
    seibetsu,
    kyosaikai_kanyu_date,
    kyosaikai_dattai_date,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '8.0rem' }} content={shokuin_cd} />
      <Table.Cell style={{ width: '10.0rem' }} content={shokuin_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={shokuin_kana} />
      <Table.Cell style={{ width: '9.0rem' }} content={tekiyo_start_date} />
      <Table.Cell style={{ width: '9.0rem' }} content={tekiyo_end_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={shozoku_busho_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={shozoku_haiso_center_name}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.Shokuin value={shokuin_kbn} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={tel} />
      <Table.Cell style={{ width: '10.0rem' }} content={mobile_tel} />
      <Table.Cell style={{ width: '12.0rem' }} content={mail} />
      <Table.Cell style={{ width: '12.0rem' }} content={biko} />
      <Table.Cell
        style={{ width: '5.0rem' }}
        content={<Custom.Label.Seibetsu value={seibetsu} />}
      />
      <Table.Cell style={{ width: '9.0rem' }} content={kyosaikai_kanyu_date} />
      <Table.Cell style={{ width: '9.0rem' }} content={kyosaikai_dattai_date} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  return (
    <>
      <Grid.Column
        style={{
          display: !nav || condition.shokuin_name ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.shokuin_name}
          label='職員名'
          name='shokuin_name'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shokuin_kana ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.shokuin_kana}
          label='職員カナ'
          name='shokuin_kana'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.tel ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Phone value={condition.tel} label='電話番号' name='tel' />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shokuin_kbn ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.ShokuinKbn
          value={condition.shokuin_kbn}
          label='職員区分'
          name='shokuin_kbn'
          clearable
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shokuin_cd ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.shokuin_cd}
          label='職員コード'
          name='shokuin_cd'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shozoku_busho_id ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Busho
          value={condition.shozoku_busho_id}
          label='所属部署'
          name='shozoku_busho_id'
          multiple
          enableAuthValue={false}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shozoku_haiso_center_id ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.HaisoCenter
          value={condition.shozoku_haiso_center_id}
          label='所属配送センター'
          name='shozoku_haiso_center_id'
          multiple
          enableAuthValue={false}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.seibetsu ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Seibetsu
          value={condition.seibetsu}
          label='性別'
          name='seibetsu'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.biko ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.biko}
          label='備考'
          name='biko'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.mail ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Email
          value={condition.mail}
          label='メールアドレス'
          name='mail'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.mobile_tel ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Phone
          value={condition.mobile_tel}
          label='携帯電話番号'
          name='mobile_tel'
        />
      </Grid.Column>
    </>
  )
}
