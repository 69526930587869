import { useEffect, useRef } from 'react'

const noop = () => {}

/*

  useage:

  const [interval, setInterval] = useState(0)

  useInterval(() => {
    setInterval(state => ++state)
  }, 10000)

  useEffect(() => {
    // do something
  }, [interval])

*/
export const useInterval = (callback, delay, immediate) => {
  const savedCallback = useRef(noop)

  // remember the latest callback
  useEffect(() => {
    savedCallback.current = callback
  })

  // Execute callback if immediate is set
  useEffect(() => {
    if (!immediate) return
    if (!delay || delay === false) return
    savedCallback.curren()
  }, [immediate])

  // set up interval
  useEffect(() => {
    if (!delay || delay === false) return undefined
    const tick = () => savedCallback.current()
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
