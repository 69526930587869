import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  ConditionWrapper,
  Dropdown,
} from 'component/base'
import { Custom } from 'component/pieces'

export const HikisariDetails = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    hikisari_kekka_id,
    hikisari_id,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
  }, [condition])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/kumiaiin/hikisari/${hikisari_id}/hikisari_kekka/${hikisari_kekka_id}/hikisari_kekka_shosai`,
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const TableRowWithProps = useCallback(
    props => <TableRow {...props} history={history} />,
    [history]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='hikisariDetails'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

HikisariDetails.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

HikisariDetails.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kumiaiin_cd' ? direction : null}
        onClick={() => handleSort('kumiaiin_cd')}
        content='組合員コード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kumiaiin_name' ? direction : null}
        onClick={() => handleSort('kumiaiin_name')}
        content='組合員名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hikisari_kekka_kbn' ? direction : null}
        onClick={() => handleSort('hikisari_kekka_kbn')}
        content='引去結果'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
        content='運送店'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kinyukikan_name' ? direction : null}
        onClick={() => handleSort('kinyukikan_name')}
        content='金融機関'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hikisari_gaku' ? direction : null}
        onClick={() => handleSort('hikisari_gaku')}
        content='引去金額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kekka_torikomi_date' ? direction : null}
        onClick={() => handleSort('kekka_torikomi_date')}
        content='結果取込日'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data } = props || {}

  const {
    kumiaiin_cd,
    kumiaiin_name,
    hikisari_kekka_kbn,
    unsoten_name,
    kinyukikan_name,
    hikisari_gaku,
    kekka_torikomi_date,
  } = data || {}

  return withOrder(
    <Table.Row>
      <Table.Cell style={{ width: '10.0rem' }} content={kumiaiin_cd} />
      <Table.Cell style={{ width: '10.0rem' }} content={kumiaiin_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.HikisariKekkaKbn value={hikisari_kekka_kbn} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={unsoten_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={kinyukikan_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={hikisari_gaku} />
      <Table.Cell style={{ width: '10.0rem' }} content={kekka_torikomi_date} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const { hikisari_kekka_kbn } = condition || {}

  return (
    <>
      <Grid.Column>
        <Custom.Dropdown.HikisariKekkaKbn
          inputAs={Dropdown}
          name='hikisari_kekka_kbn'
          value={hikisari_kekka_kbn}
          label='引去結果'
          clearable
          fluid
        />
      </Grid.Column>
    </>
  )
}
