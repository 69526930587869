import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Icon } from 'semantic-ui-react'

const Basic = props => {
  const { children, content, loading, ...other } = props

  return (
    <span {...other}>
      {loading ? <Icon loading name='spinner' /> : content || children || '-'}
    </span>
  )
}

const Pre = props => {
  const { children, content, loading, ...other } = props

  return (
    <pre {...other}>
      {loading ? <Icon loading name='spinner' /> : content || children || '-'}
    </pre>
  )
}

const Area = props => {
  const { style } = props

  return (
    <Basic
      {...props}
      style={_.merge({}, style, {
        display: 'block',
        whiteSpace: 'pre',
      })}
    />
  )
}

const Comma = props => {
  const { children, content, ...other } = props

  const edit = val => {
    if (val && val !== '' && val.toString().match(/^[0-9-,]+$/)) {
      const num = parseInt(val.toString().replace(/,/g, ''))
      return num.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    }
    return val || 0
  }

  return <Basic {...other} content={edit(content || children)} />
}

const YMD = props => {
  const { children, content, ...other } = props

  const edit = val => {
    if (!val) return

    const plain = val.toString().replace(/\//g, '')
    if (
      plain &&
      plain !== '' &&
      plain.match(/[0-9]{8}/) &&
      moment(val, 'YYYYMMDD').isValid()
    ) {
      return moment(plain, 'YYYYMMDD').format('YYYY/MM/DD')
    }
    return val
  }

  return <Basic {...other} content={edit(content || children)} />
}

const HM = props => {
  const { children, content, ...other } = props

  const edit = val => {
    if (!val) return

    const plain = val.toString().replace(/:/g, '')
    if (
      plain &&
      plain !== '' &&
      plain.match(/[0-9]{4}/) &&
      moment(val, 'HHmm').isValid()
    ) {
      return moment(plain, 'HHmm').format('HH:mm')
    }
    return val
  }

  return <Basic {...other} content={edit(content || children)} />
}

export class Text {
  static Basic = Basic
  static Pre = Pre
  static Area = Area
  static Comma = Comma
  static YMD = YMD
  static HM = HM
}
