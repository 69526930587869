import React from 'react'
import PropTypes from 'prop-types'

export const Friend = props => {
  const { id } = props.match.params // routerのparameterをprops#matchで取得
  const friend = props.friendById(id)
  const vote = props.votes && props.votes[id] ? props.votes[id] : 0

  if (typeof friend === 'undefined') {
    return (
      <div>
        <p>Friends with id '{id}' does not exist.</p>
      </div>
    )
  }

  const containerStyle = {
    border: '1px gray solid',
    display: 'inline-block',
    padding: 10,
  }
  const contentsStyle = { margin: 0 }

  return (
    <>
      <div style={containerStyle} className={'animated fadeIn'}>
        <p style={contentsStyle}>{friend.family}</p>
        <p style={contentsStyle}>{friend.nameJa}</p>
        <p style={contentsStyle}>{friend.nameEn}</p>
      </div>
      <h1>Vote: {vote}</h1>
    </>
  )
}

Friend.propTypes = {
  match: PropTypes.object.isRequired,
  friendById: PropTypes.func.isRequired,
  votes: PropTypes.object,
}

Friend.defaultProps = {
  votes: {},
}
