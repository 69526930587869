import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon, Segment, Text, Transition, Menu, Label } from 'component/base'

export const Wrapper = props => {
  const {
    id,
    title,
    icon,
    loading,
    children,
    accordion,
    leftControl,
    rightControl,
    warning,
    defaultAccordion,
    suppressErrorLabel,
  } = props

  const [visible, setVisible] = useState(defaultAccordion)

  const toggleVisible = () => setVisible(visible => !visible)

  return (
    <>
      <Menu inverted attached='top' color={warning ? 'red' : null}>
        <Menu.Item>
          {loading ? <Icon name='spinner' loading /> : <Icon name={icon} />}
          <Text.Basic>{title}</Text.Basic>
        </Menu.Item>

        {leftControl ? <Menu.Item>{leftControl}</Menu.Item> : <></>}

        {warning && !suppressErrorLabel ? (
          <Menu.Item>
            <Label
              color='orange'
              icon='exclamation circle'
              content='入力エラーがあります'
            />
          </Menu.Item>
        ) : (
          <></>
        )}

        <Menu.Menu position='right'>
          {rightControl ? <Menu.Item>{rightControl}</Menu.Item> : <></>}

          {accordion ? (
            <Menu.Item onClick={accordion ? toggleVisible : () => {}}>
              <Icon
                name={visible ? 'caret up' : 'caret down'}
                style={{ float: 'right' }}
              />
            </Menu.Item>
          ) : (
            <></>
          )}
        </Menu.Menu>
      </Menu>
      <Transition visible={visible} animation='slide down' duration={500}>
        <Segment
          id={id}
          attached
          loading={loading}
          style={{
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
        >
          {children}
        </Segment>
      </Transition>
    </>
  )
}

Wrapper.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  accordion: PropTypes.bool,
  leftControl: PropTypes.element,
  rightControl: PropTypes.element,
  warning: PropTypes.bool,
  defaultAccordion: PropTypes.bool,
  suppressErrorLabel: PropTypes.bool,
}

Wrapper.defaultProps = {
  id: '',
  title: 'タイトル',
  icon: 'home',
  loading: false,
  accordion: true,
  leftControl: undefined,
  rightControl: undefined,
  warning: false,
  defaultAccordion: true,
  suppressErrorLabel: false,
}
