import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { MyNavItems, MyRightNavItems } from '../Nav'
import {
  Menu,
  Media,
  MediaContextProvider,
  Visibility,
  Sidebar,
  Image,
} from 'component/base/Layout'
import { useLocalStorage } from 'component/hook'

export const ResponsiveContainer = props => {

  const { busy, localState } = useLocalStorage(
    'webview'
  )

  const [sidebarOpened, setSidebarOpened] = useState(false)

  const handlePusherClick = () => sidebarOpened && setSidebarOpened(false)
  const handleToggle = () => setSidebarOpened(state => !state)

  const { children } = props

  // from webview, hide navbar
  if (busy || localState.fromWebView) return <>{children}</>

  return (
    <>
      <MediaContextProvider>
        <Media greaterThanOrEqual='computer'>
          <Visibility
            once={false}
          >
            <Menu
              inverted
              stackable
              size='large'
              style={{ borderRadius: 0, display: '' }}
              className='application-header'
            >
              <MyNavItems hero />
              <MyRightNavItems />
            </Menu>
          </Visibility>

          {children}
        </Media>

        <Sidebar.Pushable as={Media} at='mobile'>
          <Sidebar
            as={Menu}
            size='huge'
            animation='push'
            inverted
            vertical
            visible={sidebarOpened}
          >
            <MyNavItems hero sidebar handleToggle={handleToggle} />
          </Sidebar>

          <Sidebar.Pusher
            dimmed={sidebarOpened}
            onClick={handlePusherClick}
            style={{ minHeight: '100vh' }}
          >
            <Menu
              inverted
              borderless
              size='huge'
              style={{ borderRadius: 0, marginBottom: '0px' }}
              className='application-header'
            >
              <Menu.Item onClick={handleToggle}>
                <Image
                  size='tiny'
                  src='/images/nais_logo_outline_white.png'
                  style={{ marginRight: '1.5em' }}
                />
              </Menu.Item>
              <MyRightNavItems />
            </Menu>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </MediaContextProvider>
    </>
  )
}

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}
