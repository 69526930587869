import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import Standard from './Standard'

const HM = props => {
  const {
    validations,
    onChange,
    onBlur,
    value: _value,
    required,
    allowDefaultZero,
    ...other
  } = props

  const [value, setValue] = useState(_value)

  useEffect(() => {
    setValue(!allowDefaultZero && _value === '00:00' ? '' : _value)
  }, [_value])

  const handleOnChange = (e, { value }, valid) => {
    const editedValue = edit(value)
    setValue(editedValue)
    onChange(e, { value: editedValue }, isValid(value))
  }

  const handleOnBlur = (e, { value }, valid) => {
    const editedValue = edit(value)
    setValue(editedValue)
    onBlur(e, { value: editedValue }, isValid(value))
  }

  const isValid = val => {
    if (required && (!val || val === '')) return false
    if (!val || val === '') return true

    const plain = val.toString().replace(/:/g, '')
    return plain.match(/[0-9]{4}/) && moment(plain, 'HHmm').isValid()
      ? true
      : false
  }

  const edit = val => {
    if (!isValid(val)) return val || ''
    if (!val || val === '') return val
    return moment(val.toString().replace(/:/g, ''), 'HHmm').format('HH:mm')
  }

  return (
    <Standard
      {...other}
      type='tel'
      validations={_.merge({}, validations, {
        isHM: true,
        maxLength: 5,
      })}
      required={required}
      value={value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  )
}

export default HM

HM.propTypes = {
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
}

HM.defaultProps = {
  validations: {},
  onChange: () => {},
  onBlur: () => {},
  value: '',
  required: false,
}
