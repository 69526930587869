import React, { createElement, cloneElement, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withFormsy } from '@akihirotakamura/formsy-react'
import { Form, Checkbox, Radio } from 'semantic-ui-react'

const FormsyCheckbox = props => {
  const {
    inputAs,
    required,
    isValid,
    checked,
    onChange,
    // Formsy props
    isPristine,
    errorLabel,
    getErrorMessage,
    setValue,
    getValue,
    // Form.Field props
    as,
    width,
    className,
    disabled,
    inline,
    passRequiredToField,
  } = props

  // props checkedが変わったら
  useEffect(() => {
    setValue(checked)
  }, [checked])

  const handleChange = (e, data) => {
    const { checked } = data
    setValue(checked)
    onChange(e, data) // keyはvalueでなくcheckedであることに注意。
  }

  const checkboxProps = {
    ...filterSuirElementProps(props),
    defaultChecked: checked, // for componentWillMount
    checked: getValue(),
    onChange: handleChange,
  }

  if (inputAs === Checkbox || inputAs === Radio) delete checkboxProps.error

  return (
    <Form.Field
      as={as}
      className={className}
      required={required && passRequiredToField}
      error={!isPristine() && !isValid()}
      width={width}
      inline={inline}
      disabled={disabled}
      style={{ position: 'relative' }}
    >
      {createElement(inputAs, { ...checkboxProps })}
      {!isPristine() &&
        !isValid() &&
        errorLabel &&
        cloneElement(errorLabel, {}, getErrorMessage())}
    </Form.Field>
  )
}

export default withFormsy(FormsyCheckbox)

const filterSuirElementProps = props => {
  const {
    as, // eslint-disable-line
    instantValidation, // eslint-disable-line
    error, // eslint-disable-line
    defaultChecked, // eslint-disable-line
    defaultSelected, // eslint-disable-line
    rootClassName, // eslint-disable-line
    rootStyle, // eslint-disable-line
    defaultValue, // eslint-disable-line
    rootElement, // eslint-disable-line
    errorLabel, // eslint-disable-line
    formRadioGroup, // eslint-disable-line
    getValue, // eslint-disable-line
    isPristine, // eslint-disable-line
    isValid, // eslint-disable-line
    getErrorMessage, // eslint-disable-line
    setValidations, // eslint-disable-line
    setValue, // eslint-disable-line
    resetValue, // eslint-disable-line
    hasValue, // eslint-disable-line
    getErrorMessages, // eslint-disable-line
    isFormDisabled, // eslint-disable-line
    isFormSubmitted, // eslint-disable-line
    isRequired, // eslint-disable-line
    showRequired, // eslint-disable-line
    showError, // eslint-disable-line
    isValidValue, // eslint-disable-line
    validations, // eslint-disable-line
    validationError, // eslint-disable-line
    validationErrors, // eslint-disable-line
    width, // eslint-disable-line
    passRequiredToField, // eslint-disable-line
    inputAs, // eslint-disable-line
    innerRef, //eslint-disable-line
    inline, // eslint-disable-line
    ...suirProps
  } = props

  return suirProps
}

FormsyCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.number,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  passRequiredToField: PropTypes.bool,
  inputAs: PropTypes.oneOf([Form.Checkbox, Form.Radio, Checkbox, Radio]),
  defaultChecked: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  isValid: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  isPristine: PropTypes.func.isRequired,
  required: PropTypes.bool,
  getErrorMessage: PropTypes.func.isRequired,
  errorLabel: PropTypes.element,
  onChange: PropTypes.func,
}

FormsyCheckbox.defaultProps = {
  inputAs: Checkbox,
  onChange: () => {},
}
