import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Form as SUIForm,
  Dropdown as SUIDropdown,
  Select as SUISelect,
  Label as SUILabel,
} from 'semantic-ui-react'
import FSUIDropdown from './FormsyDropdown'
import { Util } from 'component/base'

import './style.css'

class Standard extends Component {
  static propTypes = {
    /*
      formsy-semantic-ui-react props
      https://github.com/zabute/formsy-semantic-ui-react/blob/master/src/FormsyInput.js
    */
    /*
      Form.Field props
    */
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    width: PropTypes.number,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    passRequiredToField: PropTypes.bool,
    /*
      DropDown props
    */
    inputAs: PropTypes.oneOf([
      SUIDropdown,
      SUISelect,
      SUIForm.Dropdown,
      SUIForm.Select,
    ]),
    id: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    errorLabel: PropTypes.element,
    /*
      semantic-ui-react props
      https://react.semantic-ui.com/collections/form/
    */
    /** Label prefixed to an option added by a user. */
    additionLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /** Position of the `Add: ...` option in the dropdown list ('top' or 'bottom'). */
    additionPosition: PropTypes.oneOf(['top', 'bottom']),
    /** Allow user additions to the list of options (boolean). */
    allowAdditions: PropTypes.bool,
    /** A Dropdown can reduce its complexity. */
    basic: PropTypes.bool,
    /** Format the Dropdown to appear as a button. */
    button: PropTypes.bool,
    /** Primary content. */
    children: PropTypes.element,
    /** Whether or not the menu should close when the dropdown is blurred. */
    closeOnBlur: PropTypes.bool,

    /** customized **/
    /** ignore semantic ui clearable **/
    /** add empty row to list **/
    clearable: PropTypes.bool,

    /**
     * Whether or not the menu should close when a value is selected from the dropdown.
     * By default, multiple selection dropdowns will remain open on change, while single
     * selection dropdowns will close on change.
     */
    closeOnChange: PropTypes.bool,
    /** A compact dropdown has no minimum width. */
    compact: PropTypes.bool,
    /** Whether or not the dropdown should strip diacritics in options and input search */
    deburr: PropTypes.bool,
    /** Initial value of open. */
    defaultOpen: PropTypes.bool,
    /** Initial value of searchQuery. */
    defaultSearchQuery: PropTypes.string,
    /** Currently selected label in multi-select. */
    defaultSelectedLabel: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    /** Initial value of upward. */
    defaultUpward: PropTypes.bool,
    /** Initial value or value array if multiple. */
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ])
      ),
    ]),
    /** A dropdown menu can open to the left or to the right. */
    direction: PropTypes.oneOf(['left', 'right']),
    /** An errored dropdown can alert a user to a problem. */
    error: PropTypes.bool,
    /** A dropdown menu can contain floated content. */
    floating: PropTypes.bool,
    /** A dropdown can take the full width of its parent */
    fluid: PropTypes.bool,
    /** A dropdown menu can contain a header. */
    header: PropTypes.node,
    /** Shorthand for Icon. */
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    /** A dropdown can be formatted as a Menu item. */
    item: PropTypes.bool,
    /** A dropdown can be labeled. */
    labeled: PropTypes.bool,
    /** A dropdown can defer rendering its options until it is open. */
    lazyLoad: PropTypes.bool,
    /** A dropdown can show that it is currently loading data. */
    loading: PropTypes.bool,
    /** The minimum characters for a search to begin showing results. */
    minCharacters: PropTypes.number,
    /** A selection dropdown can allow multiple selections. */
    multiple: PropTypes.bool,
    /** Message to display when there are no results. */
    noResultsMessage: PropTypes.string,
    /**
     * Called when a user adds a new item. Use this to update the options list.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props and the new item's value.
     */
    onAddItem: PropTypes.func,
    /**
     * Called on blur.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onBlur: PropTypes.func,
    /**
     * Called when the user attempts to change the value.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props and proposed value.
     */
    onChange: PropTypes.func,
    /**
     * Called on click.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onClick: PropTypes.func,
    /**
     * Called when a close event happens.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onClose: PropTypes.func,
    /**
     * Called on focus.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onFocus: PropTypes.func,
    /**
     * Called when a multi-select label is clicked.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All label props.
     */
    onLabelClick: PropTypes.func,
    /**
     * Called on mousedown.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onMouseDown: PropTypes.func,
    /**
     * Called when an open event happens.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props.
     */
    onOpen: PropTypes.func,
    /**
     * Called on search input change.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props, includes current value of searchQuery.
     */
    onSearchChange: PropTypes.func,
    /** Controls whether or not the dropdown menu is displayed. */
    open: PropTypes.bool,
    /** Whether or not the menu should open when the dropdown is focused. */
    openOnFocus: PropTypes.bool,
    /** Array of Dropdown.Item props e.g. `{ text: '', value: '' }` */
    options: PropTypes.array,
    /** Placeholder text. */
    placeholder: PropTypes.string,
    /** A dropdown can be formatted so that its menu is pointing. */
    pointing: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf([
        'left',
        'right',
        'top',
        'top left',
        'top right',
        'bottom',
        'bottom left',
        'bottom right',
      ]),
    ]),
    /**
     * Mapped over the active items and returns shorthand for the active item Labels.
     * Only applies to `multiple` Dropdowns.
     *
     * @param {object} item - A currently active dropdown item.
     * @param {number} index - The current index.
     * @param {object} defaultLabelProps - The default props for an active item Label.
     * @returns {*} Shorthand for a Label.
     */
    renderLabel: PropTypes.func,
    /** A dropdown can have its menu scroll. */
    scrolling: PropTypes.bool,
    /**
     * A selection dropdown can allow a user to search through a large list of choices.
     * Pass a function here to replace the default search.
     */
    search: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    /** A shorthand for a search input. */
    searchInput: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.node,
      PropTypes.object,
    ]),
    /** Current value of searchQuery. Creates a controlled component. */
    searchQuery: PropTypes.string,
    /** Define whether the highlighted item should be selected on blur. */
    selectOnBlur: PropTypes.bool,
    /**
     * Whether or not to change the value when navigating the menu using arrow keys.
     * Setting to false will require enter or left click to confirm a choice.
     */
    selectOnNavigation: PropTypes.bool,
    /** Currently selected label in multi-select. */
    selectedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** A dropdown can be used to select between choices in a form. */
    selection: PropTypes.bool,
    /** A simple dropdown can open without Javascript. */
    simple: PropTypes.bool,
    /** A dropdown can receive focus. */
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** The text displayed in the dropdown, usually for the active item. */
    text: PropTypes.string,
    /** Custom element to trigger the menu to become visible. Takes place of 'text'. */
    trigger: PropTypes.node,
    /** Current value or value array if multiple. Creates a controlled component. */
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.string,
          PropTypes.number,
        ])
      ),
    ]),
    /** Controls whether the dropdown will open upward. */
    upward: PropTypes.bool,
    /**
     * A dropdown will go to the last element when ArrowUp is pressed on the first,
     * or go to the first when ArrowDown is pressed on the last( aka infinite selection )
     */
    wrapSelection: PropTypes.bool,

    /*
      formsy-react props
      https://github.com/formsy/formsy-react/blob/master/API.md
    */
    /** validate on input change */
    instantValidation: PropTypes.bool,
    /** The message that will show when the form input component is invalid. It will be used as a default error. */
    validationError: PropTypes.string,
    /** The message that will show when the form input component is invalid. */
    /** You can combine this with validationError. Keys not found in validationErrors defaults to the general error message. */
    validationErrors: PropTypes.object,
    /** A comma separated list with validation rules */
    /** Default validation is here -> https://github.com/formsy/formsy-react/blob/master/API.md#validators */
    /** useage -> https://github.com/formsy/formsy-react/blob/master/API.md#validations */
    validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /** switch view mode */
    readOnly: PropTypes.bool,

    /** add all item */
    enableAll: PropTypes.bool,

    noLabel: PropTypes.bool,

    /** suppress error label **/
    suppressLabel: PropTypes.bool,
  }

  static defaultProps = {
    inputAs: SUIForm.Dropdown,
    passRequiredToField: true,
    additionPosition: 'top',
    clearable: false,
    closeOnBlur: true,
    deburr: false,
    icon: 'dropdown',
    openOnFocus: true,
    selectOnBlur: false,
    selectOnNavigation: true,
    wrapSelection: true,
    selection: true,
    errorLabel: <SUILabel color='red' pointing floating />,
    validationError: 'this is default invalid message',
    validations: {},
    instantValidation: true,
    scrolling: true,
    readOnly: false,
    enableAll: false,
    noLabel: false,
    suppressLabel: false,
  }

  render = () => {
    const {
      required,
      readOnly,
      className,
      label,
      value,
      defaultValue,
      disabled,
      clearable,
      options: _options,
      multiple,
      enableAll,
      noLabel,
      suppressLabel,
      ...other
    } = this.props

    const { style, inline } = other || {}

    const validationErrors = {
      isDefaultRequiredValue: required ? '必須です' : '',
    }

    const options = enableAll
      ? _options
        ? [
            {
              key: Util.getRandomID(),
              text: '全て',
              value: 0,
            },
            ..._options,
          ]
        : [
            {
              key: Util.getRandomID(),
              text: '全て',
              value: 0,
            },
          ]
      : _options

    const selectValue = defaultValue || value

    const handOverValue = multiple
      ? _.isArray(selectValue)
        ? selectValue.length === 0
          ? []
          : [...selectValue]
        : selectValue
        ? [selectValue]
        : []
      : _.isArray(selectValue)
      ? selectValue.length === 0
        ? undefined
        : selectValue[0]
      : selectValue

    return (
      <FSUIDropdown
        {...other}
        required={required}
        validationErrors={validationErrors}
        disabled={disabled || readOnly}
        label={noLabel ? undefined : label ? label : '　'}
        className={readOnly ? `readOnly ${className || ''}` : className}
        value={handOverValue}
        multiple={multiple}
        options={options}
        clearable={clearable}
        style={_.merge(
          {},
          style,
          inline
            ? {
                display: 'inline-block',
              }
            : {}
        )}
        suppressLabel={suppressLabel}
      />
    )
  }
}

export default Standard
