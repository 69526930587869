import React from 'react'
import { Image } from 'component/base'
import { useUserInfo } from 'component/hook'
import { getConfigGlobal } from 'config'
import noimage from './noimage.jpg'

export const S3Avatar = props => {
  const { userInfo } = useUserInfo()

  const { sub: userUUID } = userInfo || {}

  return (
    <>
      {!userUUID ? (
        <></>
      ) : (
        <Image
          {...props}
          src={`${getConfigGlobal().avatarEndpoint}${userUUID}`}
          onError={e => (e.target.src = noimage)}
        />
      )}
    </>
  )
}
