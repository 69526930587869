import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'
import _ from 'lodash'
import queryString from 'query-string'

export const KinyukikanShiten = props => {
  const { kinyukikan_id } = props

  return (
    <Form.Dropdown.WithAPI
      url={
        kinyukikan_id
          ? `/api/staff/master/kinyukikan_shiten?${queryString.stringify({
            kinyukikan_id,
          })}`
          : null
      }
      editOptions={options =>
        _(options)
          .map(option => ({
            key: option.id,
            value: option.id,
            text: `${option.shiten_cd}：${option.shiten_name}`,
            shiten_cd: option.shiten_cd,
          }))
          .value()
      }
      {...props}
    />
  )
}

KinyukikanShiten.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  kinyukikan_id: PropTypes.any,
}

KinyukikanShiten.defaultProps = {
  name: 'kinyukikan_shiten_id',
  label: '支店',
  value: undefined,
  kinyu_kikan_id: undefined,
}
