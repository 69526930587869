import { useState, useEffect } from 'react'
import _ from 'lodash'
import { Axios, useKeycloak } from 'component/base'

export const useKumiaiinList = param => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const { keycloak } = useKeycloak()

  const ajax = async param => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      keycloak.hasRealmRole('shokuin')
        ? `/api/staff/master/kumiaiin/list`
        : `/api/member/master/kumiaiin/list`,
      {
        params: param,
      }
    )
    if (error) {
      setLoading(false)
      return
    }
    setData(data || [])
    setLoading(false)
  }

  useEffect(() => {
    if (_.values(param).length === 0) {
      setData([])
      return
    }
    ajax(param)
  }, [param])

  return {
    data,
    loading,
  }
}
