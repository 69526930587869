import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  ConditionWrapper,
  Text,
  Button,
} from 'component/base'
import { Custom } from 'component/pieces'

export const Saimu = withRouter(props => {
  const { history, enableQueryCondition, refresh } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [condition, setCondition] = useState(_.assign({}, defaultCondition))
  const [summaryData, setSummaryData] = useState([])

  useEffect(() => {
    if (!data || !_.isArray(data)) return
    setSummaryData(
      _(data)
        .groupBy(row => [row.torihikisaki_id, row.saimu_meimoku_kbn])
        .map((rows, key) => {
          const row = rows[0] || {}
          return {
            ...row,
            shiharai_zeikomi_gaku: _.sumBy(rows, 'shiharai_zeikomi_gaku'),
            mibarai_gokei_gaku: _.sumBy(rows, 'mibarai_gokei_gaku'),
            keshikomi_gaku: _.sumBy(rows, 'keshikomi_gaku'),
          }
        })
        .value()
    )
  }, [data])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/kumiaiin/saimu/list',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const TableRowWithProps = useCallback(
    props => <TableRow {...props} history={history} />,
    [history]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='saimu'
            sortable
            scrollX
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={summaryData}
            bottomControl={<></>}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Saimu.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Saimu.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '12.0rem' }} content='支払消込' />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
        content='取引先'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'saimu_meimoku_kbn' ? direction : null}
        onClick={() => handleSort('saimu_meimoku_kbn')}
        content='名目'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'shiharai_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('shiharai_zeikomi_gaku')}
        content='支払額（税込）'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'keshikomi_gaku' ? direction : null}
        onClick={() => handleSort('keshikomi_gaku')}
        content='消込済額'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'mibarai_gokei_gaku' ? direction : null}
        onClick={() => handleSort('mibarai_gokei_gaku')}
        content='債務残'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data } = props || {}

  const {
    torihikisaki_id,
    torihikisaki_name,
    saimu_meimoku_kbn,
    shiharai_zeikomi_gaku,
    mibarai_gokei_gaku,
    keshikomi_gaku,
  } = data || {}

  return withOrder(
    <Table.Row>
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={
          <Button
            content={'支払消込'}
            primary
            as={Link}
            to={`/staff/kumiaiin/saimu/keshikomi/${saimu_meimoku_kbn}/${torihikisaki_id}`}
          />
        }
      />
      <Table.Cell style={{ width: '15.0rem' }} content={torihikisaki_name} />
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={<Custom.Label.SaimuMeimokuKbn value={saimu_meimoku_kbn} />}
      />
      <Table.Cell
        style={{
          width: '12.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={shiharai_zeikomi_gaku} />}
      />
      <Table.Cell
        style={{
          width: '12.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={keshikomi_gaku} />}
      />
      <Table.Cell
        style={{
          width: '12.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={mibarai_gokei_gaku} />}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const { torihikisaki_id, saimu_meimoku_kbn } = condition || {}

  return (
    <>
      <Grid.Column>
        <Custom.Dropdown.Torihikisaki
          label='取引先'
          name='torihikisaki_id'
          value={torihikisaki_id}
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.SaimuMeimokuKbn
          label='名目'
          name='saimu_meimoku_kbn'
          value={saimu_meimoku_kbn}
          clearable
        />
      </Grid.Column>
    </>
  )
}
