import React from 'react'
import * as FormItem from './FormItem'
import * as Dropdown from './Dropdown'
import * as Textbox from './Textbox'
import * as Label from './Label'
import * as DataTable from './DataTable'
import * as Divider from './Divider'
import * as Block from './Block'
import * as Image from './Image'
import * as Cards from './Cards'
import * as Modal from './Modal'
import { DropdownSearchInput } from 'component/base'

export class Custom {
  static Block = {
    Iraisha: props => <Block.Iraisha {...props} />,
    Unsoten: props => <Block.Unsoten {...props} />,
    Hanbaisaki: props => <Block.Hanbaisaki {...props} />,
    Shiiresaki: props => <Block.Shiiresaki {...props} />,
    Torihikisaki: props => <Block.Torihikisaki {...props} />,
  }

  static FormItem = {
    GinkoShiten: props => <FormItem.GinkoShiten {...props} />,
    Tokusei: props => <FormItem.Tokusei {...props} />,
    Selectbin: props => <FormItem.Selectbin {...props} />,
  }

  static Dropdown = {
    AddressFumei: props => <Dropdown.AddressFumei {...props} />,
    AkaboushaShoyuKbn: props => <Dropdown.AkaboushaShoyuKbn {...props} />,
    Area: props => <Dropdown.Area {...props} />,
    AreaAll: props => <Dropdown.AreaAll {...props} />,
    BloodType: props => <Dropdown.BloodType {...props} />,
    Busho: props => <Dropdown.Busho {...props} />,
    BushoCurrent: props => <Dropdown.BushoCurrent {...props} />,
    Chiku: props => <Dropdown.Chiku {...props} />,
    ChikuWarimashi: props => <Dropdown.ChikuWarimashi {...props} />,
    DaishaKbn: props => <Dropdown.DaishaKbn {...props} />,
    DattaiKbn: props => <Dropdown.DattaiKbn {...props} />,
    Dealer: props => <Dropdown.Dealer {...props} />,
    FurikomiTesuryoFutanKbn: props => (
      <Dropdown.FurikomiTesuryoFutanKbn {...props} />
    ),
    GpsTaikiJotaiKbn: props => <Dropdown.GpsTaikiJotaiKbn {...props} />,
    ShozokuArea: props => <Dropdown.ShozokuArea {...props} />,
    HaishaHoho: props => <Dropdown.HaishaHoho {...props} />,
    HaishaKiboKbn: props => <Dropdown.HaishaKiboKbn {...props} />,
    HaisoCenter: props => <Dropdown.HaisoCenter {...props} />,
    HaisoCenterAll: props => <Dropdown.HaisoCenterAll {...props} />,
    HaisoCenterCurrent: props => <Dropdown.HaisoCenterCurrent {...props} />,
    HaisoCenterKoza: props => <Dropdown.HaisoCenterKoza {...props} />,
    Hanbaisaki: props => <Dropdown.Hanbaisaki {...props} />,
    HanbaisakiKbn: props => <Dropdown.HanbaisakiKbn {...props} />,
    HasuShoriKbn: props => <Dropdown.HasuShoriKbn {...props} />,
    HatchiArea: props => <Dropdown.HatchiArea {...props} />,
    HatchiAreaDetail: props => <Dropdown.HatchiAreaDetail {...props} />,
    HatchiAreaDetailCity: props => <Dropdown.HatchiAreaDetailCity {...props} />,
    Henkyakuhoho: props => <Dropdown.Henkyakuhoho {...props} />,
    HelpShurui: props => <Dropdown.HelpShurui {...props} />,
    HikisariKekkaKbn: props => <Dropdown.HikisariKekkaKbn {...props} />,
    Hojin: props => <Dropdown.Hojin {...props} />,
    Hoken: props => <Dropdown.Hoken {...props} />,
    HojoKamoku: props => <Dropdown.HojoKamoku {...props} />,
    Iraisha: props => <Dropdown.Iraisha {...props} />,
    IraishaKbn: props => <Dropdown.IraishaKbn {...props} />,
    JikanShiteiKbn: props => <Dropdown.JikanShiteiKbn {...props} />,
    Jotai: props => <Dropdown.Jotai {...props} />,
    JuchuShubetsu: props => <Dropdown.JuchuShubetsu {...props} />,
    Jushokakunin: props => <Dropdown.Jushokakunin {...props} />,
    KaikeiBumon: props => <Dropdown.KaikeiBumon {...props} />,
    Kaishutsuki: props => <Dropdown.Kaishutsuki {...props} />,
    KakoudataKbn: props => <Dropdown.KakoudataKbn {...props} />,
    Kamoku: props => <Dropdown.Kamoku {...props} />,
    Kariire: props => <Dropdown.Kariire {...props} />,
    KenchikuKbn: props => <Dropdown.KenchikuKbn {...props} />,
    Kinyukikan: props => <Dropdown.Kinyukikan {...props} />,
    KinyukikanShiten: props => <Dropdown.KinyukikanShiten {...props} />,
    KosokuRiyo: props => <Dropdown.KosokuRiyo {...props} />,
    Kouzou: props => <Dropdown.Kouzou {...props} />,
    Kumiaiin: props => <Dropdown.Kumiaiin {...props} />,
    KumiaiinFurikomiKoza: props => <Dropdown.KumiaiinFurikomiKoza {...props} />,
    KumiaiYakushoku: props => <Dropdown.KumiaiYakushoku {...props} />,
    KurikoshiUmuKbn: props => <Dropdown.KurikoshiUmuKbn {...props} />,
    Kudo: props => <Dropdown.Kudo {...props} />,
    MeiboyoChiku: props => <Dropdown.MeiboyoChiku {...props} />,
    Nenkinkikin: props => <Dropdown.Nenkinkikin {...props} />,
    Nenkinshurui: props => <Dropdown.Nenkinshurui {...props} />,
    NyukinKinshuKbn: props => <Dropdown.NyukinKinshuKbn {...props} />,
    Nyukinkoza: props => <Dropdown.Nyukinkoza {...props} />,
    RengokaiYakushoku: props => <Dropdown.RengokaiYakushoku {...props} />,
    Ryokin: props => <Dropdown.Ryokin {...props} />,
    Seibetsu: props => <Dropdown.Seibetsu {...props} />,
    SeikyuKbn: props => <Dropdown.SeikyuKbn {...props} />,
    SeikyusakiKbn: props => <Dropdown.SeikyusakiKbn {...props} />,
    Selectbin: props => <Dropdown.Selectbin {...props} />,
    ShakoKouzou: props => <Dropdown.ShakoKouzou {...props} />,
    Sharyo: props => <Dropdown.Sharyo {...props} />,
    SharyoForMember: props => <Dropdown.SharyoForMember {...props} />,
    Shikuchoson: props => <Dropdown.Shikuchoson {...props} />,
    ShikuchosonAll: props => <Dropdown.ShikuchosonAll {...props} />,
    ShinseiShubetsu: props => <Dropdown.ShinseiShubetsu {...props} />,
    ShiwakePatternTorihiki: props => (
      <Dropdown.ShiwakePatternTorihiki {...props} />
    ),
    ShukinKbn: props => <Dropdown.ShukinKbn {...props} />,
    Shashu: props => <Dropdown.Shashu {...props} />,
    SosaiHohoKbn: props => <Dropdown.SosaiHohoKbn {...props} />,
    Shohin: props => <Dropdown.Shohin {...props} />,
    Shiiresaki: props => <Dropdown.Shiiresaki {...props} />,
    Tanso: props => <Dropdown.Tanso {...props} />,
    TansoSeikyuShiharaisaki: props => (
      <Dropdown.TansoSeikyuShiharaisaki {...props} />
    ),
    Team: props => <Dropdown.Team {...props} />,
    Tokuisaki: props => <Dropdown.Tokuisaki {...props} />,
    TorihikisakiKbn: props => <Dropdown.TorihikisakiKbn {...props} />,
    Torihiki: props => <Dropdown.Torihiki {...props} />,
    Torihikisaki: props => <Dropdown.Torihikisaki {...props} />,
    Unsoten: props => <Dropdown.Unsoten {...props} />,
    UnsotenKbn: props => <Dropdown.UnsotenKbn {...props} />,
    UriageTorihiki: props => <Dropdown.UriageTorihiki {...props} />,
    WarimashiHasuShoriKbn: props => (
      <Dropdown.WarimashiHasuShoriKbn {...props} />
    ),
    WarimashiRate: props => <Dropdown.WarimashiRate {...props} />,
    WarimashiRyokin: props => <Dropdown.WarimashiRyokin {...props} />,
    ZeikeisanKbn: props => <Dropdown.ZeikeisanKbn {...props} />,
    ShohintaniKbn: props => <Dropdown.ShohintaniKbn {...props} />,
    TodokedeRikuunKbn: props => <Dropdown.TodokedeRikuunKbn {...props} />,
    NyuryokuFileFormatKbn: props => (
      <Dropdown.NyuryokuFileFormatKbn {...props} />
    ),
    Kazei: props => <Dropdown.Kazei {...props} />,
    HaishaJotai: props => <Dropdown.HaishaJotai {...props} />,
    KumiaiinGetsujiShimeKbn: props => (
      <Dropdown.KumiaiinGetsujiShimeKbn {...props} />
    ),
    KyushaMailFormatKbn: props => <Dropdown.KyushaMailFormatKbn {...props} />,
    KokuminNenkinKikin: props => <Dropdown.KokuminNenkinKikin {...props} />,
    MenkyoshoAddressKakunin: props => (
      <Dropdown.MenkyoshoAddressKakunin {...props} />
    ),
    Yokin: props => <Dropdown.Yokin {...props} />,
    HikisariYokinShumokuKbn: props => (
      <Dropdown.HikisariYokinShumokuKbn {...props} />
    ),
    UnsotenSharyo: props => <Dropdown.UnsotenSharyo {...props} />,
    ShokuinKbn: props => <Dropdown.ShokuinKbn {...props} />,
    HaisoKanrenChushutsuTaishobiKbn: props => (
      <Dropdown.HaisoKanrenChushutsuTaishobiKbn {...props} />
    ),
    KomokuKbn: props => <Dropdown.KomokuKbn {...props} />,
    HikisariTaishoKamoku: props => <Dropdown.HikisariTaishoKamoku {...props} />,
    FurikomiTaishoKamoku: props => <Dropdown.FurikomiTaishoKamoku {...props} />,
    MatchingMailRyokin: props => <Dropdown.MatchingMailRyokin {...props} />,
    GyoshuKbn: props => <Dropdown.GyoshuKbn {...props} />,
    KumiaiinTokusei: props => <Dropdown.KumiaiinTokusei {...props} />,
    YumekukanKumiaiKohoKbn: props => (
      <Dropdown.YumekukanKumiaiKohoKbn {...props} />
    ),
    KaokuKbn: props => <Dropdown.KaokuKbn {...props} />,
    OenKanoKbn: props => <Dropdown.OenKanoKbn {...props} />,
    OshiraseHyojiTaishoKbn: props => (
      <Dropdown.OshiraseHyojiTaishoKbn {...props} />
    ),
    ShintaiKbn: props => <Dropdown.ShintaiKbn {...props} />,
    YoKyujoKbn: props => <Dropdown.YoKyujoKbn {...props} />,
    YumekukanKeijibanKbn: props => <Dropdown.YumekukanKeijibanKbn {...props} />,
    SaikenMeimokuKbn: props => <Dropdown.SaikenMeimokuKbn {...props} />,
    SaimuMeimokuKbn: props => <Dropdown.SaimuMeimokuKbn {...props} />,
    LevelKbn: props => <Dropdown.LevelKbn {...props} />,
  }

  static Textbox = {
    Address: props => <Textbox.Address {...props} />,
    ChikuWarimashi: props => <Textbox.ChikuWarimashi {...props} />,
    UserName: props => <Textbox.UserName {...props} />,
    SelectBin: props => <Textbox.SelectBin {...props} />,
  }

  static Label = {
    // Master Label
    Busho: props => <Label.Busho {...props} />,
    BloodType: props => <Label.BloodType {...props} />,
    Daisha: props => <Label.Daisha {...props} />,
    HaisoCenter: props => <Label.HaisoCenter {...props} />,
    HaishaHoho: props => <Label.HaishaHoho {...props} />,
    HasuShori: props => <Label.HasuShori {...props} />,
    HanbaisakiKbn: props => <Label.HanbaisakiKbn {...props} />,
    Hoken: props => <Label.Hoken {...props} />,
    Iraisha: props => <Label.Iraisha {...props} />,
    JikanShiteiKbn: props => <Label.JikanShiteiKbn {...props} />,
    Jotai: props => <Label.Jotai {...props} />,
    JuchuShubetsu: props => <Label.JuchuShubetsu {...props} />,
    Kaishutsuki: props => <Label.Kaishutsuki {...props} />,
    Kenchiku: props => <Label.Kenchiku {...props} />,
    KosokuRiyo: props => <Label.KosokuRiyo {...props} />,
    Kouzou: props => <Label.Kouzou {...props} />,
    KurikoshiUmu: props => <Label.KurikoshiUmu {...props} />,
    NyukinKinshu: props => <Label.NyukinKinshu {...props} />,
    Seibetsu: props => <Label.Seibetsu {...props} />,
    Seikyu: props => <Label.Seikyu {...props} />,
    Seikyusaki: props => <Label.Seikyusaki {...props} />,
    Shako: props => <Label.Shako {...props} />,
    Shakokouzou: props => <Label.Shakokouzou {...props} />,
    Shukin: props => <Label.Shukin {...props} />,
    Tokuisaki: props => <Label.Tokuisaki {...props} />,
    TorihikiKbn: props => <Label.TorihikiKbn {...props} />,
    WarimashiRate: props => <Label.WarimashiRate {...props} />,
    WarimashiRyokin: props => <Label.WarimashiRyokin {...props} />,
    WarimashiHasuShoriKbn: props => <Label.WarimashiHasuShoriKbn {...props} />,
    Zeikeisan: props => <Label.Zeikeisan {...props} />,
    KumiaiinGetsujiShimeKbn: props => (
      <Label.KumiaiinGetsujiShimeKbn {...props} />
    ),
    Shashu: props => <Label.Shashu {...props} />,
    GpsTaikiJotaiKbn: props => <Label.GpsTaikiJotaiKbn {...props} />,
    HaishaKiboKbn: props => <Label.HaishaKiboKbn {...props} />,
    Shokuin: props => <Label.Shokuin {...props} />,
    TorihikisakiKbn: props => <Label.TorihikisakiKbn {...props} />,
    ShintaiKbn: props => <Label.ShintaiKbn {...props} />,
    KaokuKbn: props => <Label.KaokuKbn {...props} />,
    HaishaHasseiKbn: props => <Label.HaishaHasseiKbn {...props} />,
    HikisariKekkaKbn: props => <Label.HikisariKekkaKbn {...props} />,
    SaikenMeimokuKbn: props => <Label.SaikenMeimokuKbn {...props} />,
    SaimuMeimokuKbn: props => <Label.SaimuMeimokuKbn {...props} />,
    OshiraseHyojiTaishoKbn: props => (
      <Label.OshiraseHyojiTaishoKbn {...props} />
    ),
    MenkyoshoAddressKakuninKbn: props => (
      <Label.MenkyoshoAddressKakuninKbn {...props} />
    ),
    // Other Label
    BoolLabel: props => <Label.BoolLabel {...props} />,
    Kinyukikan: props => <Label.Kinyukikan {...props} />,
    ShohinTaniKbn: props => <Label.ShohinTaniKbn {...props} />,
    YumekukanKeijibanKbn: props => <Label.YumekukanKeijibanKbn {...props} />,
    GyoshuKbn: props => <Label.GyoshuKbn {...props} />,
    YokinKbn: props => <Label.YokinKbn {...props} />,
    FurikomiTesuryoFutanKbn: props => (
      <Label.FurikomiTesuryoFutanKbn {...props} />
    ),
    AkabouhelpShurui: props => <Label.AkabouhelpShurui {...props} />,
    ShinseiShubetsu: props => <Label.ShinseiShubetsu {...props} />,
  }

  static Divider = {
    Segment: props => <Divider.Segment {...props} />,
  }

  static DataTable = {
    Azukarikin: props => <DataTable.Azukarikin {...props} />,
    AzukarikinDetail: props => <DataTable.AzukarikinDetail {...props} />,
    Gps: props => <DataTable.Gps {...props} />,
    Haitokin: props => <DataTable.Haitokin {...props} />,
    HaitokinDetail: props => <DataTable.HaitokinDetail {...props} />,
    DaigaeEnjokin: props => <DataTable.DaigaeEnjokin {...props} />,
    DaigaeEnjokinDetail: props => <DataTable.DaigaeEnjokinDetail {...props} />,
    Kumiaiin: props => <DataTable.Kumiaiin {...props} />,
    Shohin: props => <DataTable.Shohin {...props} />,
    TansoSeikyuShiharaisaki: props => (
      <DataTable.TansoSeikyuShiharaisaki {...props} />
    ),
    Tokuisaki: props => <DataTable.Tokuisaki {...props} />,
    Haisha: props => <DataTable.Haisha {...props} />,
    HaishaSeikyuShiharai: props => (
      <DataTable.HaishaSeikyuShiharai {...props} />
    ),
    SosaRireki: props => <DataTable.SosaRireki {...props} />,
    KumiaiinMaintain: props => <DataTable.KumiaiinMaintain {...props} />,
    KumiaiinMail: props => <DataTable.KumiaiinMail {...props} />,
    KumiaiinTorokuShinsei: props => (
      <DataTable.KumiaiinTorokuShinsei {...props} />
    ),
    Kyosaikai: props => <DataTable.Kyosaikai {...props} />,
    KumiaihiSeikyu: props => <DataTable.KumiaihiSeikyu {...props} />,
    KumiaihiSeikyuDetail: props => (
      <DataTable.KumiaihiSeikyuDetail {...props} />
    ),
    Hikisari: props => <DataTable.Hikisari {...props} />,
    HikisariDetails: props => <DataTable.HikisariDetails {...props} />,
    Saiken: props => <DataTable.Saiken {...props} />,
    Saimu: props => <DataTable.Saimu {...props} />,
    Sosai: props => <DataTable.Sosai {...props} />,
    SosaiKekka: props => <DataTable.SosaiKekka {...props} />,
    KeshikomizumiSaiken: props => <DataTable.KeshikomizumiSaiken {...props} />,
    KeshikomizumiSaimu: props => <DataTable.KeshikomizumiSaimu {...props} />,
    SonotaSaikenSaimu: props => <DataTable.SonotaSaikenSaimu {...props} />,

    SeikyuShimeFind: props => <DataTable.SeikyuShimeFind {...props} />,
    SeikyuShime: props => <DataTable.SeikyuShime {...props} />,
    Ryokin: props => <DataTable.Ryokin {...props} />,
    KeiriKamokuHenko: props => <DataTable.KeiriKamokuHenko {...props} />,

    ShiharaiShimeFind: props => <DataTable.ShiharaiShimeFind {...props} />,
    ShiharaiShime: props => <DataTable.ShiharaiShime {...props} />,

    ServiceSchedule: props => <DataTable.ServiceSchedule {...props} />,

    Kinyukikan: props => <DataTable.Kinyukikan {...props} />,
    KinyukikanShiten: props => <DataTable.KinyukikanShiten {...props} />,

    HaisoCenter: props => <DataTable.HaisoCenter {...props} />,
    HaisoCenterKoza: props => <DataTable.HaisoCenterKoza {...props} />,
    Selectbin: props => <DataTable.Selectbin {...props} />,
    MadoguchiMeimokuMajor: props => (
      <DataTable.MadoguchiMeimokuMajor {...props} />
    ),
    Busho: props => <DataTable.Busho {...props} />,
    Oshirase: props => <DataTable.Oshirase {...props} />,

    Haisosaki: props => <DataTable.Haisosaki {...props} />,
    WebRequire: props => <DataTable.WebRequire {...props} />,

    Shokuin: props => <DataTable.Shokuin {...props} />,
    Topics: props => <DataTable.Topics {...props} />,
    KumiaiKoho: props => <DataTable.KumiaiKoho {...props} />,
    CounterHistory: props => <DataTable.CounterHistory {...props} />,

    Hanbaisaki: props => <DataTable.Hanbaisaki {...props} />,
    Shiiresaki: props => <DataTable.Shiiresaki {...props} />,

    ZaikoIdo: props => <DataTable.ZaikoIdo {...props} />,
    ZaikoChosei: props => <DataTable.ZaikoChosei {...props} />,
    Getsujishime: props => <DataTable.Getsujishime {...props} />,
    ShiireList: props => <DataTable.ShiireList {...props} />,
    Uriage: props => <DataTable.Uriage {...props} />,
    Ukebarai: props => <DataTable.Ukebarai {...props} />,
    ShiireHenpin: props => <DataTable.ShiireHenpin {...props} />,
    UriageHenpin: props => <DataTable.UriageHenpin {...props} />,

    Team: props => <DataTable.Team {...props} />,

    ShiireTankaRireki: props => <DataTable.ShiireTankaRireki {...props} />,
    HanbaiTankaRireki: props => <DataTable.HanbaiTankaRireki {...props} />,
  }

  static Image = {
    KumiaiinAvator: props => <Image.KumiaiinAvator {...props} />,
    UploadImage: props => <Image.UploadImage {...props} />,
  }

  static Cards = {
    UploadFile: props => <Cards.UploadFile {...props} />,
  }

  static Modal = {
    GpsTojitsuMemoModal: props => <Modal.GpsTojitsuMemoModal {...props} />,
    MailModal: props => <Modal.MailModal {...props} />,
    KumiaiinDetailModal: props => <Modal.KumiaiinDetailModal {...props} />,
  }
}
