import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Text,
} from 'component/base'

export const HaitokinDetail = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
    haitokin_id,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row =>
        _.find(selectedIds, id => row.haitokin_shosai_id === id)
      )
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/kumiaiin/haitokin/${haitokin_id}/haitokin_shosai`,
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='kumiaiin-haitokin-detail'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

HaitokinDetail.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

HaitokinDetail.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihikisaki_cd' ? direction : null}
        onClick={() => handleSort('torihikisaki_cd')}
        content='組合員コード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
        content='組合員名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kuchisu' ? direction : null}
        onClick={() => handleSort('kuchisu')}
        content='口数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shusshi_gaku' ? direction : null}
        onClick={() => handleSort('shusshi_gaku')}
        content='出資額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haito_gaku' ? direction : null}
        onClick={() => handleSort('haito_gaku')}
        content='配当額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zei_gaku' ? direction : null}
        onClick={() => handleSort('zei_gaku')}
        content='税額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zeibikigo_haito_gaku' ? direction : null}
        onClick={() => handleSort('zeibikigo_haito_gaku')}
        content='税引後配当額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'chosei_haito_gaku' ? direction : null}
        onClick={() => handleSort('chosei_haito_gaku')}
        content='配当額（調整額）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'chosei_zei_gaku' ? direction : null}
        onClick={() => handleSort('chosei_zei_gaku')}
        content='税額（調整額）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'chosei_zeinuki_haito_gaku' ? direction : null}
        onClick={() => handleSort('chosei_zeinuki_haito_gaku')}
        content='税引後配当額（調整額）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'choseigo_haito_gaku' ? direction : null}
        onClick={() => handleSort('choseigo_haito_gaku')}
        content='配当額（調整後）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'choseigo_zei_gaku' ? direction : null}
        onClick={() => handleSort('choseigo_zei_gaku')}
        content='税額（調整後）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'choseigo_zeinuki_haito_gaku' ? direction : null}
        onClick={() => handleSort('choseigo_zeinuki_haito_gaku')}
        content='税引後配当額（調整後）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'chosei_riyu' ? direction : null}
        onClick={() => handleSort('chosei_riyu')}
        content='調整理由'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    haitokin_shosai_id: id,
    // haitokin_id,
    // torihikisaki_id,
    torihikisaki_cd,
    torihikisaki_name,
    kuchisu,
    shusshi_gaku,
    haito_gaku,
    zei_gaku,
    zeibikigo_haito_gaku,
    chosei_haito_gaku,
    chosei_zei_gaku,
    chosei_zeinuki_haito_gaku,
    choseigo_haito_gaku,
    choseigo_zei_gaku,
    choseigo_zeinuki_haito_gaku,
    chosei_riyu,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={torihikisaki_cd} />
      <Table.Cell style={{ width: '10.0rem' }} content={torihikisaki_name} />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={kuchisu} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={shusshi_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={haito_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zeibikigo_haito_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={chosei_haito_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={chosei_zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={chosei_zeinuki_haito_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={choseigo_haito_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={choseigo_zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={choseigo_zeinuki_haito_gaku} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={chosei_riyu} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  return (
    <>
      <Grid.Column
        style={{
          display: !nav || condition.torihikisaki_cd ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.torihikisaki_cd}
          label='組合員コード'
          name='torihikisaki_cd'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.torihikisaki_name ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={condition.torihikisaki_name}
          label='組合員名'
          name='torihikisaki_name'
        />
      </Grid.Column>
    </>
  )
}
