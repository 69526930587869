import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Label, Icon, Divider } from 'semantic-ui-react'

const Timeline = props => {
  const {
    direction,
    icon,
    title,
    time,
    description,
    tags,
    labelColor,
    lineColor = 'grey',
    color = 'grey',
  } = props
  const textAlign = direction === 'left' ? 'right' : 'left'

  const card = (
    <Card fluid raised color={color}>
      <Card.Content>
        <Label
          pointing={textAlign}
          color={labelColor}
          attached='top'
          style={{ marginLeft: '0' }}
        >
          {time}
        </Label>
        <Card.Header>{title}</Card.Header>
        <Card.Description>{description}</Card.Description>
        <Divider />
        <Label.Group color={color}>
          {tags.map((tag, i) => (
            <Label key={i.toString()}>{tag}</Label>
          ))}
        </Label.Group>
      </Card.Content>
    </Card>
  )

  const right = direction === 'right' ? card : ''
  const isMobile = window.innerWidth <= 768
  const iconSize = isMobile ? 'small' : 'large'
  const height = 'auto'
  const timelineLineStyle = {
    zIndex: '-1',
    position: 'absolute',
    width: '3px',
    left: '0',
    right: '0',
    margin: 'auto',
    top: '-2em',
    background: lineColor,
    height,
  }

  return (
    <div>
      <div className='Timeline-line' style={timelineLineStyle} />
      <Grid>
        <Grid.Row>
          <Grid.Column width={2}>
            <Icon
              name={icon}
              size={iconSize}
              color={color}
              inverted
              circular
              style={{ margin: 'auto' }}
            />
          </Grid.Column>
          <Grid.Column width={14}>{right}</Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

Timeline.propTypes = {
  direction: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  time: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
}

Timeline.defaultProps = {
  direction: 'right',
  icon: 'truck',
  title: '',
  time: '',
  description: '',
  tags: [],
  color: 'teal',
}

export default Timeline
