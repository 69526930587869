/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToNewMessage = /* GraphQL */ `
  subscription SubscribeToNewMessage($conversationId: ID!) {
    subscribeToNewMessage(conversationId: $conversationId) {
      author {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      content
      conversation {
        createdAt
        id
        name
        systemNoticeCode
      }
      conversationId
      createdAt
      data {
        url
        web
      }
      id
      isSent
      recipient {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      sender
    }
  }
`;
export const subscribeToNewUCs = /* GraphQL */ `
  subscription SubscribeToNewUCs($userId: ID!) {
    subscribeToNewUCs(userId: $userId) {
      associated {
        conversationId
        lastReadTime
        unreadCount
        userId
      }
      conversation {
        createdAt
        id
        name
        systemNoticeCode
      }
      conversationId
      lastReadTime
      unreadCount
      user {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      userId
    }
  }
`;
export const subscribeToNewUsers = /* GraphQL */ `
  subscription SubscribeToNewUsers {
    subscribeToNewUsers {
      conversations {
        nextToken
      }
      deviceToken {
        provider
        token
      }
      displayName
      id
      isSystem
      messages {
        nextToken
      }
      realm
      registered
      username
    }
  }
`;
