import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'

export const Ryokin = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/ryokin'
      keyName='id'
      valueName='id'
      textName='ryokin_name'
      editOptions={options =>
        _(options)
          .map(option => ({
            key: option.id,
            value: option.id,
            text: `${option.ryokin_cd}：${option.ryokin_name}`,
            ryokin_cd: option.ryokin_cd,
          }))
          .value()
      }
      sort={['ryokin_cd']}
      {...props}
    />
  )
}

Ryokin.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

Ryokin.defaultProps = {
  name: 'ryokin',
  label: '料金体系',
  value: undefined,
}
