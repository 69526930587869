import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Button,
  List,
} from 'component/base'
import { Custom } from 'component/pieces'
import { Popup } from 'semantic-ui-react'
import { useUserInfo } from 'component/hook'

export const Kumiaiin = withRouter(props => {
  const {
    history,
    mode,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    tableHeight,
    defaultCondition: _defaultCondition,
    excludeIds,
  } = props

  const { active_haiso_center_id } = useUserInfo()

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(
    () =>
      _.isEmpty(_defaultCondition)
        ? {
            haiso_center_id_list: _.concat([], active_haiso_center_id),
            is_dattai: false,
          }
        : _defaultCondition,
    [_defaultCondition, active_haiso_center_id]
  )

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/kumiaiin/list',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
        mode={mode}
      />
    ),
    [history, handleChangeSelect, mode]
  )

  const editedData = useMemo(
    () =>
      _.isEmpty(excludeIds)
        ? data
        : _(data)
            .filter(
              row =>
                !_.includes(
                  excludeIds,
                  mode === 'kumiaiin' ? row.id : row.torihikisaki_id
                )
            )
            .value(),
    [data, excludeIds]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact={false}
        >
          <DataTable
            name='kumiaiin'
            sortable
            filterable
            tableHeight={tableHeight}
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={editedData}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Kumiaiin.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
  mode: PropTypes.oneOf(['kumiaiin', 'iraisha', 'unsoten', 'hanbaisaki']),
  tableHeight: PropTypes.string,
  defaultCondition: PropTypes.object,
  excludeIds: PropTypes.array,
}

Kumiaiin.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
  mode: 'kumiaiin',
  tableHeight: '50vh',
  defaultCondition: {},
  excludeIds: [],
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'haiso_center_name' ? direction : null}
        onClick={() => handleSort('haiso_center_name')}
        content='配送センター'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shozoku_area_name' ? direction : null}
        onClick={() => handleSort('shozoku_area_name')}
        content='所属エリア'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'kumiaiin_cd' ? direction : null}
        onClick={() => handleSort('kumiaiin_cd')}
        content='運送店コード'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
        content='運送店名'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
        content='運送店名カナ'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'yokujitsu_haisha_kibo_kbn' ? direction : null}
        onClick={() => handleSort('yokujitsu_haisha_kibo_kbn')}
        content='翌日配車希望区分'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={
          column === 'yokujitsu_haisha_kibo_koshin_datetime' ? direction : null
        }
        onClick={() => handleSort('yokujitsu_haisha_kibo_koshin_datetime')}
        content='翌日配車希望区分更新日時'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'kumiaiin_name' ? direction : null}
        onClick={() => handleSort('kumiaiin_name')}
        content='組合員氏名'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'kumiaiin_kana' ? direction : null}
        onClick={() => handleSort('kumiaiin_kana')}
        content='組合員氏名カナ'
      />
      <Table.HeaderCell
        style={{ width: '5.0rem' }}
        sorted={column === 'kumiaiin_age' ? direction : null}
        onClick={() => handleSort('kumiaiin_age')}
        content='年齢'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'address1' ? direction : null}
        onClick={() => handleSort('address1')}
        content='組合員住所'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'tel' ? direction : null}
        onClick={() => handleSort('tel')}
        content='電話番号'
      />
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'shashu' ? direction : null}
        onClick={() => handleSort('shashu')}
        content='車種'
      />
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'hikkoshi' ? direction : null}
        onClick={() => handleSort('hikkoshi')}
        content='引越'
      />
      <Table.HeaderCell
        style={{ width: '5.0rem' }}
        sorted={column === 'team_name_list' ? direction : null}
        onClick={() => handleSort('team_name_list')}
        content='チーム'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'select_bin_komoku_name_list' ? direction : null}
        onClick={() => handleSort('select_bin_komoku_name_list')}
        content='セレクト便項目'
      />
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'tokui_tokusei_name_list' ? direction : null}
        onClick={() => handleSort('tokui_tokusei_name_list')}
        content='得意分野'
      />
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'nigate_tokusei_name_list' ? direction : null}
        onClick={() => handleSort('nigate_tokusei_name_list')}
        content='苦手分野'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'biko' ? direction : null}
        onClick={() => handleSort('biko')}
        content='職員備考'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'haisha_chui_jiko' ? direction : null}
        onClick={() => handleSort('haisha_chui_jiko')}
        content='注意事項'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'ng_unsoten_id_list' ? direction : null}
        onClick={() => handleSort('ng_unsoten_name_list')}
        content='配車禁止得意先'
      />
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'dattai_kbn_name' ? direction : null}
        onClick={() => handleSort('dattai_kbn_name')}
        content='脱退区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'dattai_date' ? direction : null}
        onClick={() => handleSort('dattai_date')}
        content='脱退日'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect, mode } = props || {}

  const {
    id,
    torihikisaki_id,
    haiso_center_name,
    shozoku_area_name,
    kumiaiin_cd,
    unsoten_name,
    unsoten_kana,
    kumiaiin_name,
    kumiaiin_kana,
    kumiaiin_age,
    address1,
    tel,
    shashu_name,
    hikkoshi,
    team_name_list,
    select_bin_komoku_name_list,
    tokui_tokusei_name_list,
    nigate_tokusei_name_list,
    biko,
    haisha_chui_jiko,
    ng_unsoten_name_list,
    dattai_kbn_name,
    dattai_date,
    upload_files_id,
    yokujitsu_haisha_kibo_kbn,
    yokujitsu_haisha_kibo_koshin_datetime,
  } = data || {}

  const targetId =
    mode === 'kumiaiin'
      ? id
      : mode === 'iraisha' || mode === 'unsoten' || mode === 'hanbaisaki'
      ? torihikisaki_id
      : id

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(targetId) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(targetId, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox
            checked={isSelected}
            onChange={checkboxChange}
            value={targetId}
          />
        }
      />
      <Table.Cell style={{ width: '15.0rem' }} content={haiso_center_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={shozoku_area_name} />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={
          <>
            <Custom.Image.KumiaiinAvator
              id={upload_files_id}
              kumiaiin_cd={kumiaiin_cd}
              avatar
            />
            {kumiaiin_cd}
          </>
        }
      />
      <Table.Cell style={{ width: '25.0rem' }} content={unsoten_name} />
      <Table.Cell style={{ width: '25.0rem' }} content={unsoten_kana} />
      <Table.Cell
        style={{ width: '25.0rem' }}
        content={yokujitsu_haisha_kibo_kbn}
      />
      <Table.Cell
        style={{ width: '25.0rem' }}
        content={yokujitsu_haisha_kibo_koshin_datetime}
      />
      <Table.Cell style={{ width: '15.0rem' }} content={kumiaiin_name} />
      <Table.Cell style={{ width: '25.0rem' }} content={kumiaiin_kana} />
      <Table.Cell style={{ width: '5.0rem' }} content={kumiaiin_age} />
      <Table.Cell style={{ width: '25.0rem' }} content={address1} />
      <Table.Cell style={{ width: '12.0rem' }} content={tel} />
      <Table.Cell style={{ width: '7.0rem' }} content={shashu_name} />
      <Table.Cell style={{ width: '7.0rem' }} content={hikkoshi} />
      <Table.Cell
        textAlign={'center'}
        style={{ width: '5.0rem' }}
        content={
          <Popup
            {...props}
            content={<List bulleted items={team_name_list} />}
            on='click'
            trigger={
              <Button
                icon='list'
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            }
            onClick={e => {
              e.stopPropagation()
            }}
            position='right center'
            basic
          />
        }
      />
      <Table.Cell
        textAlign={'center'}
        style={{ width: '10.0rem' }}
        content={
          <Popup
            {...props}
            content={<List bulleted items={select_bin_komoku_name_list} />}
            on='click'
            trigger={
              <Button
                icon='list'
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            }
            onClick={e => {
              e.stopPropagation()
            }}
            position='right center'
            basic
          />
        }
      />
      <Table.Cell
        textAlign={'center'}
        style={{ width: '7.0rem' }}
        content={
          <Popup
            {...props}
            content={<List bulleted items={tokui_tokusei_name_list} />}
            on='click'
            trigger={
              <Button
                icon='list'
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            }
            onClick={e => {
              e.stopPropagation()
            }}
            position='right center'
            basic
          />
        }
      />
      <Table.Cell
        textAlign={'center'}
        style={{ width: '7.0rem' }}
        content={
          <Popup
            {...props}
            content={<List bulleted items={nigate_tokusei_name_list} />}
            on='click'
            trigger={
              <Button
                icon='list'
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            }
            onClick={e => {
              e.stopPropagation()
            }}
            position='right center'
            basic
          />
        }
      />
      <Table.Cell style={{ width: '25.0rem' }} content={biko} />
      <Table.Cell style={{ width: '25.0rem' }} content={haisha_chui_jiko} />
      <Table.Cell style={{ width: '25.0rem' }} content={ng_unsoten_name_list} />
      <Table.Cell style={{ width: '7.0rem' }} content={dattai_kbn_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={dattai_date} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  const {
    unsoten_name,
    unsoten_kana,
    kumiaiin_name,
    kumiaiin_kana,
    haiso_center_id_list,
    shozoku_area_id_list,
    shozoku_busho_id_list,
    team_id_list,
    shashu_id_list,
    select_bin_komoku_id_list,
    tokui_tokusei_id_list,
    is_honjitsu_haiso_yobo_ari,
    is_yokujitsu_haiso_yobo_ari,
    is_hikkoshi_member,
    is_dattai,
  } = condition

  return (
    <>
      <Grid.Column
        style={{
          display: !nav || unsoten_name ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={unsoten_name}
          label='運送店名'
          name='unsoten_name'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || unsoten_kana ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={unsoten_kana}
          label='運送店名カナ'
          name='unsoten_kana'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || kumiaiin_name ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={kumiaiin_name}
          label='組合員氏名'
          name='kumiaiin_name'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || kumiaiin_kana ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={kumiaiin_kana}
          label='組合員氏名カナ'
          name='kumiaiin_kana'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || haiso_center_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.HaisoCenter
          value={haiso_center_id_list}
          label='配送センター'
          multiple
          name='haiso_center_id_list'
          selection
          options={[]}
          enableAuthValue={false}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || shozoku_area_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.ShozokuArea
          value={shozoku_area_id_list}
          label='所属エリア'
          multiple
          name='shozoku_area_id_list'
          selection
          options={[]}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || shozoku_busho_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Busho
          value={shozoku_busho_id_list}
          label='部署'
          multiple
          name='shozoku_busho_id_list'
          selection
          options={[]}
          level_list={[0, 1, 2]}
          enableAuthValue={false}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || team_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Team
          value={team_id_list}
          label='チーム'
          multiple
          name='team_id_list'
          selection
          options={[]}
          is_haiso_team_only={true}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || shashu_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Shashu
          value={shashu_id_list}
          label='車種'
          name='shashu_id_list'
          multiple
          selection
          options={[]}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || select_bin_komoku_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Selectbin
          value={select_bin_komoku_id_list}
          label='セレクト便項目'
          name='select_bin_komoku_id_list'
          multiple
          selection
          option={[]}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || tokui_tokusei_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.KumiaiinTokusei
          value={tokui_tokusei_id_list}
          label='組合員特性（得意分野）'
          name='tokui_tokusei_id_list'
          multiple
          selection
          option={[]}
        />
      </Grid.Column>

      <Segment basic>
        <Grid.Column
          style={{
            display: !nav || is_honjitsu_haiso_yobo_ari ? 'block' : 'none',
          }}
        >
          <Form.Checkbox.Buttony
            checked={is_honjitsu_haiso_yobo_ari}
            label='本日配送要望'
            name='is_honjitsu_haiso_yobo_ari'
            size='mini'
            enableIndeterminate
          />
        </Grid.Column>
      </Segment>
      <Segment basic>
        <Grid.Column
          style={{
            display: !nav || is_yokujitsu_haiso_yobo_ari ? 'block' : 'none',
          }}
        >
          <Form.Checkbox.Buttony
            checked={is_yokujitsu_haiso_yobo_ari}
            label='翌日配送要望'
            name='is_yokujitsu_haiso_yobo_ari'
            size='mini'
            enableIndeterminate
          />
        </Grid.Column>
      </Segment>
      <Segment basic>
        <Grid.Column
          style={{
            display: !nav || is_hikkoshi_member ? 'block' : 'none',
          }}
        >
          <Form.Checkbox.Buttony
            checked={is_hikkoshi_member}
            label='引越メンバー'
            name='is_hikkoshi_member'
            size='mini'
            enableIndeterminate
          />
        </Grid.Column>
      </Segment>
      <Segment basic>
        <Grid.Column
          style={{
            display: !nav || is_dattai ? 'block' : 'none',
          }}
        >
          <Form.Checkbox.Buttony
            checked={is_dattai}
            label='脱退'
            name='is_dattai'
            size='mini'
            enableIndeterminate
          />
        </Grid.Column>
      </Segment>
    </>
  )
}
