import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { Axios, DataTable, Table, Segment } from 'component/base'

export const ShiireTankaRireki = withRouter(props => {
  const { shohin_id } = props

  const [loading, setLoading] = useState(false)
  const [shiire_tanka_data, set_shiire_tanka_data] = useState([])
  const [displayData, setDisplayData] = useState([])

  useEffect(() => {
    setDisplayData(
      shiire_tanka_data.length !== 0
        ? _.orderBy(shiire_tanka_data, ['shiire_date'], ['desc'])
        : []
    )
  }, [shiire_tanka_data])

  useEffect(() => {
    get_shiire_tanka(shohin_id)
  }, [shohin_id])

  const get_shiire_tanka = async shohin_id => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/buppan/shiire/tanka_history`,
      {
        params: { shohin_id },
      }
    )
    if (error) return
    setLoading(false)
    set_shiire_tanka_data(data)
  }

  const TableRowWithProps = useCallback(props => <TableRow {...props} />, [])

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <DataTable
          name='shiire_tanka_rireki'
          sortable
          filterable
          tableHeight='50vh'
          tableHeader={StaticWidthTableHeader}
          tableRow={TableRowWithProps}
          data={displayData}
          bottomControl={<></>}
        />
      </Segment>
    </>
  )
})

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        onClick={() => handleSort('shiire_date')}
        content='仕入日'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        onClick={() => handleSort('torihikisaki_name')}
        content='仕入先'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        onClick={() => handleSort('busho_name')}
        content='仕入部門'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        onClick={() => handleSort('tanka')}
        content='単価'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data } = props || {}

  const { shiire_date, torihikisaki_name, busho_name, tanka } = data || {}

  return withOrder(
    <Table.Row>
      <Table.Cell style={{ width: '8.0rem' }} content={shiire_date} />
      <Table.Cell style={{ width: '15.0rem' }} content={torihikisaki_name} />
      <Table.Cell style={{ width: '8.0rem' }} content={busho_name} />
      <Table.Cell style={{ width: '8.0rem' }} content={tanka} />
    </Table.Row>
  )
}
