import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import $ from 'jquery'
import { findDOMNode } from 'react-dom'
import {
  Grid,
  Header,
  Image,
  Container,
  Form,
  Axios,
  TextArea,
  Message,
  Button,
  Checkbox,
  Dimmer,
  Loader,
  useKeycloak,
} from 'component/base'

export const TermOfUse = withRouter(props => {
  const { history, readOnly } = props

  const { keycloak } = useKeycloak()

  const ref = useRef()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [readAll, setReadAll] = useState(false)
  const [accepted, setAccepted] = useState(false)

  useEffect(() => {
    callApi()
  }, [])

  const { riyo_kiyaku_id, riyo_kiyaku, riyo_kiyaku_doi_id } = data || {}

  const callApi = async () => {
    // through when not authenticated yet
    if (!keycloak.authenticated) return

    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/user/riyo_kiyaku_doi',
      {}
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  useEffect(() => {
    const $dom = $(findDOMNode(ref.current))
    if (!$dom) return
    $dom.find('#kiyaku_area')[0].addEventListener('scroll', e => {
      const { scrollHeight, scrollTop, clientHeight } = e.target

      if (clientHeight + scrollTop >= scrollHeight - 2) setReadAll(true)
    })

    // clean up on unmount
    return () => {
      $dom.removeEventListener && $dom.removeEventListener('scroll')
    }
  }, [])

  const handleSubmit = async () => {
    setLoading(true)
    const { error } = await Axios.ajax.post(
      `/api/user/riyo_kiyaku/${riyo_kiyaku_id}/riyo_kiyaku_doi`,
      {}
    )
    if (error) {
      setLoading(false)
      return
    }
    window.location.href = '/'
  }

  return (
    <>
      <Container>
        <Grid
          textAlign='center'
          style={{ height: '100vh' }}
          verticalAlign='middle'
        >
          <Grid.Column style={{ maxWidth: '500px' }}>
            <Image size='small' src='/images/nais_logo_outline.png' centered />
            <h4>Next Akabou Innovation System</h4>

            {!riyo_kiyaku_doi_id && !readOnly && (
              <Message
                icon='info circle'
                content={
                  <>
                    <p>引き続きNaisをご利用いただくには</p>
                    <p>利用規約へ同意をしていただく必要があります</p>
                  </>
                }
                info
              />
            )}

            <Header
              as='h1'
              color='teal'
              textAlign='center'
              content='利用規約'
            />

            <Form ref={ref} size='large'>
              <Form.Field>
                <TextArea
                  id='kiyaku_area'
                  value={riyo_kiyaku}
                  style={{
                    minHeight: '300px',
                    maxHeight: '300px',
                    overflowY: 'scroll',
                  }}
                  readOnly
                />
              </Form.Field>

              {!readAll && !readOnly && (
                <Message
                  icon='info circle'
                  content={
                    <>
                      <p>続けるには最後までスクロールして</p>
                      <p>利用規約を理解ください</p>
                    </>
                  }
                  info
                />
              )}

              {readAll && !readOnly && (
                <Form.Field style={{ marginTop: '20px' }}>
                  <Checkbox
                    toggle
                    label='利用規約を理解し、内容に同意します'
                    style={{ fontSize: '1.1em' }}
                    onChange={(e, { checked }) => setAccepted(checked)}
                  />
                </Form.Field>
              )}

              {accepted && riyo_kiyaku_id && !readOnly && (
                <Form.Field style={{ marginTop: '20px' }}>
                  <Button
                    primary
                    fluid
                    icon='check'
                    size='huge'
                    content='同意して続ける'
                    disabled={readAll && accepted ? false : true}
                    onClick={handleSubmit}
                  />
                </Form.Field>
              )}

              {readOnly && (
                <Button
                  primary
                  fluid
                  icon='check'
                  size='huge'
                  content='戻る'
                  onClick={() => history.push('/')}
                />
              )}
            </Form>
          </Grid.Column>
        </Grid>
      </Container>

      <Dimmer active={loading} page blurring='true'>
        <Loader>Loading...</Loader>
      </Dimmer>
    </>
  )
})
