import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import $ from 'jquery'
import _ from 'lodash'
import Standard from './Standard'

const Paging = props => {
  const {
    value,
    initItemCount,
    increaseCount,
    options: _options,
    valueName,
    multiple,
    sort,
    sortOrders,
    ...other
  } = props

  const ref = useRef()
  const [options, setOptions] = useState(_options)
  const [searchWord, setSearchWord] = useState('')
  const [pagingCount, setPagingCount] = useState(0)

  // for inifinite paging
  const currentItemCount = useMemo(() => {
    return initItemCount + increaseCount * pagingCount
  }, [initItemCount, increaseCount, pagingCount])

  useEffect(() => {
    const $dom = $(findDOMNode(ref.current))
    if (!$dom) return
    $dom.find('[role="listbox"]')[0].addEventListener('scroll', e => {
      const { scrollHeight, scrollTop, clientHeight } = e.target

      if (clientHeight + scrollTop >= scrollHeight - 2)
        setPagingCount(count => count + 1)
    })

    // clean up on unmount
    return () => {
      $dom.removeEventListener && $dom.removeEventListener('scroll')
    }
  }, [])

  // get item(s) for parent current selected value(s)
  const pickValueItems = useMemo(() => {
    // return _.filter(_options, option => option[valueName] === value)
    return multiple && _.isArray(value)
      ? _.filter(_options, option =>
          _.find(value, v => option[valueName] === v)
        )
      : _.filter(_options, option => option[valueName] === value)
  }, [value, _options])

  // union current selected item(s) and filtered/paged items
  useEffect(() => {
    setOptions(
      _.unionBy(pickValueItems, filter(_options, searchWord), valueName)
    )
  }, [_options, searchWord, currentItemCount, pickValueItems])

  const filter = (options, searchWord) =>
    _.map(
      limit(
        find(handleSort(options), searchWord),
        currentItemCount + (multiple && _.isArray(value) ? value.length : 0)
      ),
      option => option
    )

  const find = (options, searchWord) =>
    searchWord
      ? _.filter(options, option =>
          _.find(option, value => _.includes(value, searchWord))
        )
      : options

  const limit = (options, maxRows) =>
    //todo: sort
    //todo: pick and union props value row
    _.slice(options, 0, maxRows)

  const handleSort = options =>
    _.isEmpty(sortOrders)
      ? _.sortBy(options, sort)
      : _.orderBy(options, sort, sortOrders)

  const handleSearchChange = useCallback(e =>
    debouncedSearchChange(e.target.value)
  )

  const debouncedSearchChange = _.debounce(value => {
    setSearchWord(value)
  }, 500)

  const handleOnOpen = () => setSearchWord('')

  return (
    <Standard
      {...other}
      ref={ref}
      options={options}
      value={value}
      multiple={multiple}
      selection
      search
      onSearchChange={handleSearchChange}
      onOpen={handleOnOpen}
    />
  )
}

Paging.propTypes = {
  _value: PropTypes.any,
  _options: PropTypes.array,
  initItemCount: PropTypes.number,
  increaseCount: PropTypes.number,
  valueName: PropTypes.string,
  sort: PropTypes.array,
}

Paging.defaultProps = {
  _value: '',
  _options: [],
  initItemCount: 10,
  increaseCount: 10,
  valueName: 'value',
  sort: ['key'],
}

export default Paging
