import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Form } from 'semantic-ui-react'

export class FilterBox extends React.Component {
  state = {
    filter: this.props.filter,
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value })
    this.props.onSubmitFilter(value)
  }

  render() {
    const { filter } = this.state || {}

    const { loading } = this.props || {}

    return (
      <Form style={{ display: 'inline' }} size='mini'>
        <Form.Group style={{ margin: 0, display: 'inline-block' }}>
          <Form.Field>
            <Form.Input
              placeholder='Enter the filter.'
              name='filter'
              value={filter}
              onChange={_.throttle(this.handleChange, 1500)}
              icon='filter'
              iconPosition='left'
              loading={loading}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

FilterBox.propTypes = {
  onSubmitFilter: PropTypes.func,
  filter: PropTypes.string,
  loading: PropTypes.bool,
}

FilterBox.defaultProps = {
  onSubmitFilter: () => {},
  filter: '',
  loading: false,
}
