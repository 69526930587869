import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Axios,
  Modal,
  Button,
  Segment,
  Statistic,
  Table,
  Header,
  Item,
  Grid,
  Icon,
  useKeycloak,
} from 'component/base'
import { Custom } from 'component/pieces'
import { getConfig } from 'config'

export const KumiaiinDetailModal = props => {
  const { open: _open, onClose, kumiaiin_cd } = props

  const { keycloak } = useKeycloak()
  const kumiaiin_link = getConfig().kumiaiin_link

  const [open, setOpen] = useState(_open)
  const [viewMode, setViewMode] = useState('about')
  const [loading, setLoading] = useState(false)
  const [kumiaiinMap, setKumiaiinMap] = useState()

  useEffect(() => {
    setOpen(_open)
  }, [_open])

  const { torihikisaki_id, kumiaiin_name, unsoten_name, upload_files_id } =
    kumiaiinMap || {}

  useEffect(() => {
    if (!kumiaiin_cd) return
    getKumiaiin(kumiaiin_cd)
  }, [kumiaiin_cd])

  const getKumiaiin = async kumiaiin_cd => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/kumiaiin/list`,
      {
        params: {
          kumiaiin_cd,
        },
      }
    )
    setLoading(false)
    if (error) return
    setKumiaiinMap(data[0] || {})
  }

  const handleOnClick = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <Modal
      closeIcon={<Icon name='close' circular size='large' />}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      open={open}
      size='large'
      onClose={() => {
        setOpen(false)
        onClose && onClose()
      }}
      onClick={handleOnClick}
    >
      <Modal.Header>
        <Custom.Image.KumiaiinAvator id={upload_files_id} avatar />
        {`${kumiaiin_name || ''}（${unsoten_name || ''}）`}
        {kumiaiin_link && (
          <Button
            as='span'
            content='PDFリンク'
            positive
            icon='external alternate'
            onClick={() =>
              window.open(
                `${kumiaiin_link}?token=${keycloak.token}&kumiaiin_cd=${kumiaiin_cd}`
              )
            }
          />
        )}
      </Modal.Header>
      <Modal.Description>
        <Button.Group attached='top' size='big'>
          <Button
            as='span'
            active={viewMode === 'about'}
            content='概要'
            onClick={() => setViewMode('about')}
          />
          <Button
            as='span'
            active={viewMode === 'haisha'}
            content='配車情報'
            onClick={() => setViewMode('haisha')}
          />
          <Button
            as='span'
            active={viewMode === 'saiken'}
            content='未収状況'
            onClick={() => setViewMode('saiken')}
          />
        </Button.Group>
      </Modal.Description>

      <Modal.Content scrolling style={{ minHeight: 'calc(80vh - 10em)' }}>
        <Segment attached loading={loading}>
          {viewMode === 'about' ? (
            <About kumiaiin_cd={kumiaiin_cd} />
          ) : viewMode === 'saiken' ? (
            <Saiken torihikisaki_id={torihikisaki_id} />
          ) : viewMode === 'haisha' ? (
            <HaishaInfo kumiaiin_cd={kumiaiin_cd} />
          ) : (
            <></>
          )}
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          as='span'
          content='閉じる'
          icon='close'
          onClick={() => {
            setOpen(false)
            onClose && onClose()
          }}
        />
      </Modal.Actions>
    </Modal>
  )
}

const About = props => {
  const { kumiaiin_cd } = props

  const [loading, setLoading] = useState(false)
  const [map, setMap] = useState()

  useEffect(() => {
    if (!kumiaiin_cd) return
    getApi(kumiaiin_cd)
  }, [kumiaiin_cd])

  const getApi = async kumiaiin_cd => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/kumiaiin/list`,
      {
        params: {
          kumiaiin_cd,
        },
      }
    )
    setLoading(false)
    if (error) return
    setMap(data[0] || {})
  }

  const {
    kumiaiin_name,
    kumiaiin_kana,
    unsoten_name,
    unsoten_kana,
    kumiaiin_age,
    upload_files_id,
    seibetsu,

    busho_name,
    chiku_name,
    meiboyo_chiku_name,
    haiso_center_name,
    shozoku_area_name,

    post_cd,
    address,
    daihyo_tel,
    daihyo_mobile_tel,
    tel,
    kinkyu_tel,
    fax,
    main_mail,

    dattai_kbn_name,
    dattai_date,
    kanyu_date,
    is_sodai,

    tekikaku_invoice_toroku_no,

    // menkyosho_yuko_date,
    // menkyosho_yuko_wareki,
    // menkyosho_no,
  } = map || {}

  return (
    <>
      <Segment basic loading={loading} style={{ padding: 0 }}>
        <Item.Group>
          <Item>
            <div className='ui image'>
              <Custom.Image.KumiaiinAvator
                id={upload_files_id}
                style={{
                  width: '150px',
                  height: '150px',
                }}
              />
            </div>
            <Item.Content>
              <Grid padded relaxed style={{ fontSize: '1.2em' }}>
                <Grid.Row columns={4} style={{ paddingBottom: '30px' }}>
                  <Grid.Column>
                    <Header dividing content='組合員名' />
                    <p>{kumiaiin_name}</p>
                    <p>{kumiaiin_kana}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='運送店名' />
                    <p>{unsoten_name}</p>
                    <p>{unsoten_kana}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='年齢' />
                    <p>{kumiaiin_age}歳</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='性別' />
                    <p>
                      <Custom.Label.Seibetsu value={seibetsu} />
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4} style={{ paddingBottom: '30px' }}>
                  <Grid.Column>
                    <Header dividing content='所属支部' />
                    <p>{busho_name}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='所属地区' />
                    <p>{chiku_name}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='所属名簿用地区' />
                    <p>{meiboyo_chiku_name}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='所属配送センター' />
                    <p>{haiso_center_name}</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4} style={{ paddingBottom: '30px' }}>
                  <Grid.Column>
                    <Header dividing content='所属エリア' />
                    <p>{shozoku_area_name}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='加入年月日' />
                    <p>{kanyu_date}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='脱退日' />
                    <p>{dattai_date}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='脱退区分' />
                    <p>{dattai_kbn_name}</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4} style={{ paddingBottom: '30px' }}>
                  <Grid.Column>
                    <Header dividing content='総代' />
                    <p>{is_sodai && <Icon name='check' color='green' />}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='住所' />
                    <p>{post_cd}</p>
                    <p>{address}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='代表電話番号' />
                    <p>{daihyo_tel}</p>
                    <p>{daihyo_mobile_tel}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='FAX' />
                    <p>{fax}</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4} style={{ paddingBottom: '30px' }}>
                  <Grid.Column>
                    <Header dividing content='緊急連絡先' />
                    <p>{kinkyu_tel}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='その他連絡先' />
                    <p>{tel}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='メールアドレス' />
                    <p>{main_mail}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='適格請求書発行事業者番号' />
                    <p>{tekikaku_invoice_toroku_no}</p>
                  </Grid.Column>
                  <Grid.Column>
                    {/**
                    <Header dividing content='免許証有効期限' />
                    <p>{menkyosho_yuko_date}</p>
                    <p>{menkyosho_yuko_wareki}</p>
                    */}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
    </>
  )
}

const HaishaInfo = props => {
  const { kumiaiin_cd } = props

  const [loading, setLoading] = useState(false)
  const [map, setMap] = useState()

  useEffect(() => {
    if (!kumiaiin_cd) return
    getApi(kumiaiin_cd)
  }, [kumiaiin_cd])

  const getApi = async kumiaiin_cd => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/kumiaiin/list`,
      {
        params: {
          kumiaiin_cd,
        },
      }
    )
    setLoading(false)
    if (error) return
    setMap(data[0] || {})
  }

  const {
    upload_files_id,
    daisu,
    shashu_name,
    hikkoshi,
    is_matching_mail_irai_member,
    team_name_list,
    tokui_tokusei_name_list,
    nigate_tokusei_name_list,
    kumiaiin_haisha_settei_biko,
    unsoten_biko,
    haisha_chui_jiko,
  } = map || {}

  return (
    <>
      <Segment basic loading={loading} style={{ padding: 0 }}>
        <Item.Group>
          <Item>
            <div className='ui image'>
              <Custom.Image.KumiaiinAvator
                id={upload_files_id}
                style={{
                  width: '150px',
                  height: '150px',
                }}
              />
            </div>
            <Item.Content>
              <Grid padded relaxed style={{ fontSize: '1.2em' }}>
                <Grid.Row columns={4} style={{ paddingBottom: '30px' }}>
                  <Grid.Column>
                    <Header dividing content='車輌台数' />
                    <p>{daisu}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='車種' />
                    <p>{shashu_name}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='引越' />
                    <p>{hikkoshi}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='マッチングメール' />
                    <p>
                      {is_matching_mail_irai_member && (
                        <Icon name='check' color='green' />
                      )}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4} style={{ paddingBottom: '30px' }}>
                  <Grid.Column>
                    <Header dividing content='チーム' />
                    <p>{team_name_list}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='得意分野' />
                    <p>{tokui_tokusei_name_list}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='苦手分野' />
                    <p>{nigate_tokusei_name_list}</p>
                  </Grid.Column>
                  <Grid.Column></Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4} style={{ paddingBottom: '30px' }}>
                  <Grid.Column>
                    <Header dividing content='配車設定備考' />
                    <p>{kumiaiin_haisha_settei_biko}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='運送店備考' />
                    <p>{unsoten_biko}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <Header dividing content='配車注意事項' />
                    <p>{haisha_chui_jiko}</p>
                  </Grid.Column>
                  <Grid.Column></Grid.Column>
                </Grid.Row>
              </Grid>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
    </>
  )
}

const Saiken = props => {
  const { torihikisaki_id } = props

  const [loading, setLoading] = useState(false)
  const [saikenList, setSaikenList] = useState([])

  useEffect(() => {
    if (!torihikisaki_id) return
    getSaiken(torihikisaki_id)
  }, [torihikisaki_id])

  const getSaiken = async torihikisaki_id => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/kumiaiin/saiken/list?torihikisaki_id=${torihikisaki_id}`
    )
    setLoading(false)
    if (error) return
    setSaikenList(data)
  }

  const comma_edit = val => {
    if (val && val !== '' && val.toString().match(/^[0-9-,]+$/)) {
      const num = parseInt(val.toString().replace(/,/g, ''))
      return num.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    }
    return val || 0
  }

  return (
    <>
      <Segment basic loading={loading} style={{ padding: 0 }}>
        <Table basic='very' celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content='債権名目' />
              <Table.HeaderCell content='締日' />
              <Table.HeaderCell content='債権残額' />
              <Table.HeaderCell content='摘要' />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_(saikenList)
              .orderBy(
                ['saiken_meimoku_kbn_name', 'shime_date'],
                ['asc', 'asc']
              )
              .map((item, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    <Header content={`${item.saiken_meimoku_kbn_name}`} />
                  </Table.Cell>
                  <Table.Cell>
                    <Header content={`${item.shime_date}`} />
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: 'right' }}>
                    <Statistic
                      size='tiny'
                      value={comma_edit(item.mishu_gokei_gaku)}
                    />
                  </Table.Cell>
                  <Table.Cell content={item.tekiyo} />
                </Table.Row>
              ))
              .value()}
          </Table.Body>
        </Table>
      </Segment>
    </>
  )
}
