import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
} from 'component/base'
import { Custom } from 'component/pieces'

export const Ryokin = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
    loading,
    setLoading,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
          ? [selectedId]
          : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id)),
      data
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    setSelectedIds([])
    callApi(condition)
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get('/api/staff/master/ryokin', {
      params: {
        ...condition,
      },
    })
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        selectedIds={selectedIds}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, selectedIds, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='ryokin'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            defaultOrder={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Ryokin.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Ryokin.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => { },
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'ryokin_cd' ? direction : null}
        onClick={() => handleSort('ryokin_cd')}
        content='料金コード'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'ryokin_name' ? direction : null}
        onClick={() => handleSort('ryokin_name')}
        content='料金名'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'is_kyorisei_gaku_tekiyo' ? direction : null}
        onClick={() => handleSort('is_kyorisei_gaku_tekiyo')}
        content='距離制'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'is_jikansei_gaku_tekiyo' ? direction : null}
        onClick={() => handleSort('is_jikansei_gaku_tekiyo')}
        content='時間制'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'is_kyorijikan_gaku_tekiyo' ? direction : null}
        onClick={() => handleSort('is_kyorijikan_gaku_tekiyo')}
        content='距離時間制'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'is_hikkoshi_ryokin_kbn_tekiyo' ? direction : null}
        onClick={() => handleSort('is_hikkoshi_ryokin_kbn_tekiyo')}
        content='引越'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'is_niatsukai_gaku_tekiyo' ? direction : null}
        onClick={() => handleSort('is_niatsukai_gaku_tekiyo')}
        content='荷扱'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'is_kamotsumachi_gaku_tekiyo' ? direction : null}
        onClick={() => handleSort('is_kamotsumachi_gaku_tekiyo')}
        content='貨物待'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'is_yokomochi_kbn_tekiyo' ? direction : null}
        onClick={() => handleSort('is_yokomochi_kbn_tekiyo')}
        content='横持'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'is_takuhai_gaku_tekiyo' ? direction : null}
        onClick={() => handleSort('is_takuhai_gaku_tekiyo')}
        content='宅配'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'warimashi_hasu_shori_kbn' ? direction : null}
        onClick={() => handleSort('warimashi_hasu_shori_kbn')}
        content='割増端数処理区分'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    ryokin_cd,
    ryokin_name,
    is_hikkoshi_ryokin_kbn_tekiyo,
    is_jikansei_gaku_tekiyo,
    is_kyorisei_gaku_tekiyo,
    is_kyorijikan_gaku_tekiyo,
    is_niatsukai_gaku_tekiyo,
    is_kamotsumachi_gaku_tekiyo,
    is_yokomochi_kbn_tekiyo,
    is_takuhai_gaku_tekiyo,
    warimashi_hasu_shori_kbn,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }
  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={ryokin_cd} />
      <Table.Cell style={{ width: '20.0rem' }} content={ryokin_name} />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={is_kyorisei_gaku_tekiyo && '適用'}
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={is_jikansei_gaku_tekiyo && '適用'}
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={is_kyorijikan_gaku_tekiyo && '適用'}
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={is_hikkoshi_ryokin_kbn_tekiyo && '適用'}
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={is_niatsukai_gaku_tekiyo && '適用'}
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={is_kamotsumachi_gaku_tekiyo && '適用'}
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={is_yokomochi_kbn_tekiyo && '適用'}
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={is_takuhai_gaku_tekiyo && '適用'}
      />
      <Table.Cell
        style={{ width: '16.0rem' }}
        content={
          <Custom.Label.WarimashiHasuShoriKbn
            value={warimashi_hasu_shori_kbn}
          />
        }
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  return (
    <>
      <Grid.Column
        style={{
          display: !nav || condition.ryokin_name ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          name='ryokin_name'
          label='料金名'
          value={condition.ryokin_name}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display:
            !nav || condition.is_hikkoshi_ryokin_kbn_tekiyo ? 'block' : 'none',
        }}
      >
        <Form.Field>
          <label>　</label>
          <Form.Checkbox.Buttony
            checked={condition.is_hikkoshi_ryokin_kbn_tekiyo}
            label='引越料金区分'
            name='is_hikkoshi_ryokin_kbn_tekiyo'
            enableIndeterminate
          />
        </Form.Field>
      </Grid.Column>
    </>
  )
}
