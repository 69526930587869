import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form, Message } from 'component/base'

export const HaisoCenterKoza = props => {
  const { haiso_center_id, value, ...other } = props

  const path = useMemo(
    () => `/api/staff/master/haiso_center/${haiso_center_id}/haiso_center_koza`,
    [haiso_center_id]
  )

  return (
    <>
      {haiso_center_id && haiso_center_id !== 0 ? (
        <Form.Dropdown.WithAPI
          url={path}
          editOptions={options =>
            _(options)
              .map(option => ({
                key: option.id,
                value: option.id,
                text: `${option.kinyukikan_name}：${option.koza_meigi}`,
              }))
              .value()
          }
          value={value}
          {...other}
        />
      ) : (
          <Form.Field>
            <label>入金口座</label>
            <Message
              info
              content='所属配送センターが設定されていません'
              style={{ margin: 0 }}
            />
          </Form.Field>
        )}
    </>
  )
}

HaisoCenterKoza.propTypes = {
  haiso_center_id: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

HaisoCenterKoza.defaultProps = {
  haiso_center_id: 0,
  name: 'haiso_center_koza',
  label: '配送センター口座',
  value: undefined,
}
