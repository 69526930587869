import React from 'react'
import _ from 'lodash'
import { useKeycloak } from 'component/base'
import { AccessDenied } from 'component/page/Error/AccessDenied'
import fullMenu from 'config/menu.json'

export const RoleFilter = props => {
  const { children } = props

  const { keycloak } = useKeycloak()

  const isAllowedAccess = () => {
    const menu = _(fullMenu)
      .flatMap()
      .filter(
        row =>
          (!row.submenu || row.submenu === '') && (row.url || row.url !== '')
      )
      .find(row => _.startsWith(window.location.pathname, row.url))

    // avoid menu has submenu
    if (_.isEmpty(menu)) return true

    const apply_roles = _(menu)
      .omitBy(value => value !== true)
      .map((key, value) => value)
      .value()

    return (
      _(apply_roles)
        .filter(role => keycloak.hasRealmRole(role))
        .value().length >= 1
    )
  }

  return (
    <>
      {keycloak.authenticated && !isAllowedAccess() ? (
        <AccessDenied />
      ) : (
        children
      )}
    </>
  )
}
