import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'
import { useUserInfo } from 'component/hook'

export const BushoCurrent = props => {
  const {
    value, // will ignore
    ...other
  } = props

  const { active_busho_id } = useUserInfo()

  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/busho'
      keyName='id'
      valueName='id'
      textName='busho_name'
      value={active_busho_id}
      {...other}
      disabled
    />
  )
}

BushoCurrent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
}

BushoCurrent.defaultProps = {
  name: 'busho_id',
  label: '部署',
}
