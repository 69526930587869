import React, { useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Layout } from 'component/base'

export const Confirm = props => {
  const { onConfirm, ...rest } = props || {}
  return (
    <Layout.Confirm
      onConfirm={_.debounce(
        () => {
          onConfirm()
        },
        1000,
        { leading: false, trailing: true }
      )}
      {...rest}
    />
  )
}

Confirm.propTypes = {
  cancelButton: PropTypes.node,
  confirmButton: PropTypes.node,
  content: PropTypes.node,
  header: PropTypes.node,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  size: PropTypes.oneOf(['mini', 'tiny', ' small', 'large', 'fullscreen']),
}

Confirm.defaultProps = {
  size: 'tiny',
}
