import { useState, useEffect } from 'react'
import { Axios } from 'component/base'

export const useKinyukikanShiten = _key => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const ajax = async key => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/kinyukikan_shiten/${key}`,
      {}
    )
    if (error) {
      setLoading(false)
      return
    }
    setData(data || {})
    setLoading(false)
  }

  useEffect(() => {
    _key && ajax(_key)
  }, [_key])

  return {
    data,
    loading,
  }
}
