import React, {
  createElement,
  cloneElement,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { withFormsy } from '@akihirotakamura/formsy-react'
import { Form, Dropdown, Select } from 'semantic-ui-react'

const FormsyDropdown = props => {
  const {
    inputAs,
    required,
    isValid,
    value,
    onChange,
    onBlur,
    label,
    id,
    multiple,
    // Formsy props
    isPristine,
    errorLabel,
    getErrorMessage,
    setValue,
    getValue,
    isFormSubmitted,
    // Form.Field props
    as,
    width,
    className,
    disabled,
    inline,
    passRequiredToField,
    style,
    suppressLabel,
  } = props

  const [allowError, setAllowError] = useState(false)

  // props valueが変わったら
  useEffect(() => {
    setValue(value)
  }, [value])

  const handleChange = (e, data) => {
    const { value } = data

    if (multiple && getValue() && getValue().length > value.length)
      setAllowError(true)

    setValue(value)
    onChange(e, data, isValid())
  }

  useEffect(() => {
    setAllowError(isFormSubmitted() ? true : false)
  }, [isFormSubmitted()])

  const handleBlur = (e, data) => onBlur(e, data)

  const shortHandMode = useMemo(
    () => inputAs === Form.Dropdown || inputAs === Form.Select,
    [inputAs]
  )

  return (
    <Form.Field
      as={as}
      className={className}
      required={required && passRequiredToField}
      error={!disabled && !isPristine() && !isValid() && allowError}
      width={width}
      inline={inline}
      disabled={disabled}
      style={_.assign({}, { width: '100%', position: 'relative' }, style)}
    >
      {shortHandMode && label && <label htmlFor={id}> {label} </label>}

      {shortHandMode ? (
        createElement(
          createElement(inputAs, {
            ...filterSuirElementProps(props),
            onChange: handleChange,
            onBlur: handleBlur,
            onClose: () => setAllowError(true),
            value: getValue(),
            error: !disabled && !isPristine() && !isValid() && allowError,
          }).props.control,
          {
            ...filterSuirElementProps(props),
            onChange: handleChange,
            onBlur: handleBlur,
            onClose: () => setAllowError(true),
            value: getValue(),
            error: !disabled && !isPristine() && !isValid() && allowError,
          }
        )
      ) : (
        <div
          className={
            label && !_.isEmpty(label) ? 'ui labeled input' : 'ui input'
          }
          style={{ width: '100%' }}
        >
          {label && !_.isEmpty(label) && (
            <div
              className='ui label'
              style={{
                backgroundColor: required ? '#fbdfab' : null,
              }}
            >
              {label}
            </div>
          )}
          {createElement(inputAs, {
            ...filterSuirElementProps(props),
            onChange: handleChange,
            onBlur: handleBlur,
            onClose: () => setAllowError(true),
            value: getValue(),
            error: !disabled && !isPristine() && !isValid() && allowError,
            style: _.assign({}, { width: '100%' }, style),
          })}
        </div>
      )}

      {!isPristine() &&
        !isValid() &&
        allowError &&
        errorLabel &&
        !suppressLabel &&
        cloneElement(errorLabel, {}, getErrorMessage())}
    </Form.Field>
  )
}

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

// for rewrite props(value)
const Wrap = props => {
  const { value: _value, multiple, onChange } = props

  const prevValue = usePrevious(_value)

  const [value, setValue] = useState(multiple ? [] : null)

  useEffect(() => {
    setValue(_value)
  }, [multiple ? _.difference(_value, prevValue).length !== 0 : _value])

  const handleChange = (e, data) => {
    const { value } = data
    setValue(value)
    if (onChange) onChange(e, data)
  }

  return <FormsyDropdown {...props} value={value} onChange={handleChange} />
}

export default withFormsy(Wrap)

const filterSuirElementProps = props => {
  const {
    as, // eslint-disable-line
    instantValidation, // eslint-disable-line
    error, // eslint-disable-line
    defaultChecked, // eslint-disable-line
    defaultSelected, // eslint-disable-line
    rootClassName, // eslint-disable-line
    rootStyle, // eslint-disable-line
    defaultValue, // eslint-disable-line
    rootElement, // eslint-disable-line
    errorLabel, // eslint-disable-line
    formRadioGroup, // eslint-disable-line
    getValue, // eslint-disable-line
    isPristine, // eslint-disable-line
    isValid, // eslint-disable-line
    getErrorMessage, // eslint-disable-line
    setValidations, // eslint-disable-line
    setValue, // eslint-disable-line
    resetValue, // eslint-disable-line
    hasValue, // eslint-disable-line
    getErrorMessages, // eslint-disable-line
    isFormDisabled, // eslint-disable-line
    isFormSubmitted, // eslint-disable-line
    isRequired, // eslint-disable-line
    showRequired, // eslint-disable-line
    showError, // eslint-disable-line
    isValidValue, // eslint-disable-line
    validations, // eslint-disable-line
    validationError, // eslint-disable-line
    validationErrors, // eslint-disable-line
    width, // eslint-disable-line
    passRequiredToField, // eslint-disable-line
    inputAs, // eslint-disable-line
    innerRef, //eslint-disable-line
    inline, // eslint-disable-line
    suppressLabel, // eslint-disable-line
    ...suirProps
  } = props

  return suirProps
}

FormsyDropdown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.number,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  passRequiredToField: PropTypes.bool,
  inputAs: PropTypes.oneOf([Dropdown, Select, Form.Dropdown, Form.Select]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  required: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errorLabel: PropTypes.element,
  isValid: PropTypes.func.isRequired,
  isPristine: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  getValue: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  isFormSubmitted: PropTypes.func.isRequired,
  getErrorMessage: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  validationError: PropTypes.string,
  validationErrors: PropTypes.object,
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  suppressLabel: PropTypes.bool,
}

FormsyDropdown.defaultProps = {
  inputAs: Form.Dropdown,
  onChange: () => {},
  onBlur: () => {},
  passRequiredToField: true,
  suppressLabel: false,
}
