import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form as SUIForm,
  Checkbox as SUICheckbox,
  Radio as SUIRadio,
  Label as SUILabel,
} from 'semantic-ui-react'
import './style.css'
import FormsyCheckbox from './FormsyCheckbox'

class Standard extends Component {
  static propTypes = {
    /*
      formsy-semantic-ui-react props
      https://github.com/zabute/formsy-semantic-ui-react/blob/master/src/FormsyInput.js
    */
    /*
      Form.Field props
    */
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    width: PropTypes.number,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    passRequiredToField: PropTypes.bool,
    /*
      Checkbox props
    */
    inputAs: PropTypes.oneOf([
      SUIForm.Checkbox,
      SUIForm.Radio,
      SUICheckbox,
      SUIRadio,
    ]),
    id: PropTypes.string,
    required: PropTypes.bool,
    errorLabel: PropTypes.element,
    /*
      semantic-ui-react props
      https://react.semantic-ui.com/collections/form/
    */
    /** Whether or not checkbox is checked. */
    checked: PropTypes.bool,
    /** Whether or not checkbox is indeterminate. */
    defaultIndeterminate: PropTypes.bool,
    /** Removes padding for a label. Auto applied when there is no label. */
    fitted: PropTypes.bool,
    /** Whether or not checkbox is indeterminate. */
    indeterminate: PropTypes.bool,
    /** The text of the associated label element. */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** The HTML input name. */
    name: PropTypes.string,
    /**
     * Called when the user attempts to change the checked state.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props and proposed checked/indeterminate state.
     */
    onChange: PropTypes.func,
    /**
     * Called when the checkbox or label is clicked.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props and current checked/indeterminate state.
     */
    onClick: PropTypes.func,
    /**
     * Called when the user presses down on the mouse.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props and current checked/indeterminate state.
     */
    onMouseDown: PropTypes.func,
    /** A checkbox can be read-only and unable to change states. */
    readOnly: PropTypes.bool,
    /** Format as a radio element. This means it is an exclusive option. */
    radio: PropTypes.bool,
    /** Format to emphasize the current selection state. */
    slider: PropTypes.bool,
    /** A checkbox can receive focus. */
    tabIndex: PropTypes.number,
    /** Format to show an on or off choice. */
    toggle: PropTypes.bool,
    /** HTML input type, either checkbox or radio. */
    type: PropTypes.oneOf(['checkbox', 'radio']),
    /** The HTML input value. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /*
      formsy-react props
      https://github.com/formsy/formsy-react/blob/master/API.md
    */
    /** validate on input change */
    instantValidation: PropTypes.bool,
    /** The message that will show when the form input component is invalid. It will be used as a default error. */
    validationError: PropTypes.string,
    /** The message that will show when the form input component is invalid. */
    /** You can combine this with validationError. Keys not found in validationErrors defaults to the general error message. */
    validationErrors: PropTypes.object,
    /** A comma separated list with validation rules */
    /** Default validation is here -> https://github.com/formsy/formsy-react/blob/master/API.md#validators */
    /** useage -> https://github.com/formsy/formsy-react/blob/master/API.md#validations */
    validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }

  static defaultProps = {
    inputAs: SUICheckbox,
    passRequiredToField: true,
    errorLabel: <SUILabel color='red' pointing floating />,
    validationError: 'this is default invalid message',
    validations: {},
    instantValidation: true,
    readOnly: false,
  }

  render = () => {
    const {
      required,
      readOnly,
      className,
      label,
      checked,
      disabled,
      ...other
    } = this.props

    const validationErrors = {
      isDefaultRequiredValue: required ? 'Required' : '',
    }

    const layered = checked =>
      checked === null || checked === undefined
        ? checked
        : checked && checked === 'false'
        ? false
        : checked
        ? true
        : false

    return (
      <FormsyCheckbox
        {...other}
        checked={layered(checked)}
        required={required}
        validationErrors={validationErrors}
        readOnly={readOnly}
        disabled={disabled || readOnly}
        label={label ? label : undefined}
        className={readOnly ? `readOnly ${className || ''}` : className}
        defaultIndeterminate={checked === null || checked === undefined}
      />
    )
  }
}

export default Standard
