import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Axios, Button, Popup } from 'component/base'
import { Custom } from 'component/pieces'

export const MailModalButton = props => {
  const { unsoten_id } = props || {}
  const [unsoten_mail, set_unsoten_mail] = useState('')
  const [showModal, setShowModal] = useState(false)
  const getApiUnsoten = async () => {
    if (!unsoten_id) return
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/unsoten/${unsoten_id}`
    )
    if (error) return
    if (_.isEmpty(data)) return
    set_unsoten_mail(data.mail || '')
  }

  useEffect(() => {
    getApiUnsoten()
  }, [unsoten_id])

  const handleShowModal = () => {
    setShowModal(true)
  }

  return (
    <>
      {unsoten_id && unsoten_mail && (
        <Popup
          content='運送店へのメール作成'
          trigger={
            <Button
              primary
              icon='mail'
              circular
              disabled={!unsoten_id || !unsoten_mail}
              onClick={handleShowModal}
            />
          }
        />
      )}

      {(!unsoten_id || !unsoten_mail) && (
        <Popup
          content='運送店のメールアドレスが設定されていないため使用できません'
          trigger={<Button icon='mail' circular />}
        />
      )}

      {showModal && (
        <Custom.Modal.MailModal
          unsoten_id={unsoten_id}
          isOpenModal={showModal}
          onClickCancel={() => setShowModal(false)}
          setShowModal={setShowModal}
        />
      )}
    </>
  )
}
