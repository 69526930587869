import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'

export const KumiaiinFurikomiKoza = props => {
  const { kumiaiin_id, kumiaiin_tekiyo_kikan_id, value, ...other } = props

  const path = useMemo(
    () =>
      `/api/staff/kumiaiin/kumiaiin/${kumiaiin_id}/kumiaiin_tekiyo_kikan/${kumiaiin_tekiyo_kikan_id}/kumiaiin_furikomi_koza`,
    [kumiaiin_id, kumiaiin_tekiyo_kikan_id]
  )

  return (
    <Form.Dropdown.WithAPI
      url={path}
      editOptions={options =>
        _(options)
          .map(option => ({
            key: option.id,
            value: option.id,
            text: `${option.kinyukikan_name}：${option.koza_meigi}`,
          }))
          .value()
      }
      value={value}
      {...other}
    />
  )
}

KumiaiinFurikomiKoza.propTypes = {
  haiso_center_id: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

KumiaiinFurikomiKoza.defaultProps = {
  haiso_center_id: 0,
  name: 'haiso_center_koza',
  label: '配送センター口座',
  value: undefined,
}
