import React, { Component } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import { Radio, Button } from 'semantic-ui-react'
import './style.css'

class Buttony extends Component {
  state = {
    positive: this.props.checked || this.props.defaultChecked,
  }

  handleClick = e => {
    const $target = $(e.target).siblings('.ui').first().find('input')
    $target.click()
    this.setState({ positive: !this.state.positive })
  }

  render = () => {
    const {
      checked, // from FormsyRadioGroup.js
      onChange, // from FormsyRadioGroup.js
      defaultChecked,
      label,
      buttonProps,
      positiveProps,
      negativeProps,
      readOnly,
      disabled,
      ...other
    } = this.props || {}

    const on = checked ? true : false

    return (
      <>
        {(() =>
          on ? (
            <Button
              {...buttonProps}
              {...positiveProps}
              as='span'
              onClick={this.handleClick}
              content={label}
              disabled={disabled || readOnly}
            />
          ) : (
            <Button
              {...buttonProps}
              {...negativeProps}
              as='span'
              onClick={this.handleClick}
              content={label}
              disabled={disabled || readOnly}
            />
          ))()}
        <Radio
          style={{ display: 'none' }}
          checked={checked}
          label={label}
          {...other}
          onChange={onChange}
          readOnly={readOnly}
        />
      </>
    )
  }
}

export default Buttony

Buttony.propTypes = {
  buttonProps: PropTypes.object,
  positiveProps: PropTypes.object,
  negativeProps: PropTypes.object,
}

Buttony.defaultProps = {
  buttonProps: {},
  positiveProps: {
    icon: 'check',
    positive: true,
  },
  negativeProps: {
    icon: 'minus',
  },
}
