import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import Standard from './Standard'

const DateTime = props => {
  const {
    validations,
    onChange,
    onBlur,
    value: _value,
    required,
    second,
    ...other
  } = props

  const [value, setValue] = useState(_value)

  useEffect(() => {
    setValue(_value)
  }, [_value])

  const handleOnChange = (e, { value }, valid) => {
    const editedValue = edit(value)
    setValue(editedValue)
    onChange(e, { value: editedValue }, isValid(value))
  }

  const handleOnBlur = (e, { value }, valid) => {
    const editedValue = edit(value)
    setValue(editedValue)
    onBlur(e, { value: editedValue }, isValid(value))
  }

  const isValid = val => {
    if (required && (!val || val === '')) return false
    if (!val || val === '') return true

    const plain = val.toString().replace(/[\u002f\u003a\u0020]/g, '')
    return second
      ? plain.match(/[0-9]{14}/) && moment(plain, 'YYYYMMDDHHmmss').isValid()
        ? true
        : false
      : plain.match(/[0-9]{12}/) && moment(plain, 'YYYYMMDDHHmm').isValid()
      ? true
      : false
  }

  const edit = val => {
    if (!isValid(val)) return val || ''
    if (!val || val === '') return val
    const plain = val.toString().replace(/[\u002f\u003a\u0020]/g, '')
    return second
      ? moment(plain, 'YYYYMMDDHHmmss').format('YYYY/MM/DD HH:mm:ss')
      : moment(plain, 'YYYYMMDDHHmm').format('YYYY/MM/DD HH:mm')
  }

  return (
    <Standard
      {...other}
      type='tel'
      placeholder={second ? '例：2000/01/01 12:00:00' : '例：2000/01/01 12:00'}
      validations={_.merge(
        {},
        validations,
        second
          ? {
              isDateTimeSecond: true,
              maxLength: 19,
            }
          : {
              isDateTime: true,
              maxLength: 16,
            }
      )}
      required={required}
      value={value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  )
}

export default DateTime

DateTime.propTypes = {
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  second: PropTypes.bool,
}

DateTime.defaultProps = {
  validations: {},
  onChange: () => {},
  onBlur: () => {},
  value: '',
  required: false,
  second: false,
}
