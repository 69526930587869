import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'
import queryString from 'query-string'

const Base = props => {
  const { url, haiso_center_id, ...other } = props

  return haiso_center_id ? (
    <Form.Dropdown.WithAPI
      url={url}
      keyName='id'
      valueName='id'
      textName='area_name'
      {...other}
    />
  ) : (
      <Form.Dropdown.Standard {...other} />
    )
}

const ListBase = props => {
  const { url, ...other } = props
  return (
    <Form.Dropdown.WithAPI
      url={url}
      keyName='id'
      valueName='id'
      textName='area_name'
      {...other}
    />
  )
}

export const Area = props => {
  const { haiso_center_id, haiso_center_id_list, ...other } = props

  return (
    <>
      {haiso_center_id_list ? (
        <ListBase
          url={`/api/staff/master/area?${queryString.stringify({
            haiso_center_id_list,
          })}`}
          {...other}
        />
      ) : (
          <Base
            url={`/api/staff/master/area?haiso_center_id=${haiso_center_id}`}
            haiso_center_id={haiso_center_id}
            {...other}
          />
        )}
    </>
  )
}

Area.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
}

Area.defaultProps = {
  name: 'area',
  label: 'エリア',
  value: undefined,
}

export const AreaAll = props => {
  const { haiso_center_id, ...other } = props

  return (
    <Base
      url={`/api/staff/master/area_all?haiso_center_id=${haiso_center_id}`}
      haiso_center_id={haiso_center_id}
      {...other}
    />
  )
}

AreaAll.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
}

AreaAll.defaultProps = {
  name: 'area',
  label: 'エリア（全）',
  value: undefined,
}
