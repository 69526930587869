import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Segment, Button, Form } from 'component/base'

const loadImageURL = (imageURL, crossOrigin) => {
  const isDataURL = str => {
    if (str === null) {
      return false
    }
    const regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+;=\-._~:@/?%\s]*\s*$/i
    return !!str.match(regex)
  }

  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => resolve(image)
    image.onerror = reject
    if (isDataURL(imageURL) === false && crossOrigin) {
      image.crossOrigin = crossOrigin
    }
    image.src = imageURL
  })
}

const loadImageFile = imageFile => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      try {
        const image = loadImageURL(e.target.result)
        resolve(image)
      } catch (e) {
        reject(e)
      }
    }
    reader.readAsDataURL(imageFile)
  })
}

const makeCancelable = promise => {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    },
  }
}

const isTouchDevice = !!(
  typeof window !== 'undefined' &&
  typeof navigator !== 'undefined' &&
  ('ontouchstart' in window || navigator.msMaxTouchPoints > 0)
)

const isFileAPISupported = typeof File !== 'undefined'

let pixelRatio =
  typeof window !== 'undefined' && window.devicePixelRatio
    ? window.devicePixelRatio
    : 1

// Draws a rounded rectangle on a 2D context.
const drawRoundedRect = (context, x, y, width, height, borderRadius) => {
  if (borderRadius === 0) {
    context.rect(x, y, width, height)
    return
  }

  const widthMinusRad = width - borderRadius
  const heightMinusRad = height - borderRadius
  context.translate(x, y)
  context.arc(borderRadius, borderRadius, borderRadius, Math.PI, Math.PI * 1.5)
  context.lineTo(widthMinusRad, 0)
  context.arc(
    widthMinusRad,
    borderRadius,
    borderRadius,
    Math.PI * 1.5,
    Math.PI * 2
  )
  context.lineTo(width, heightMinusRad)
  context.arc(
    widthMinusRad,
    heightMinusRad,
    borderRadius,
    Math.PI * 2,
    Math.PI * 0.5
  )
  context.lineTo(borderRadius, height)
  context.arc(
    borderRadius,
    heightMinusRad,
    borderRadius,
    Math.PI * 0.5,
    Math.PI
  )
  context.translate(-x, -y)
}

const defaultEmptyImage = {
  x: 0.5,
  y: 0.5,
}

const AvatarEditor = props => {
  const {
    target,
    scale: _scale,
    rotate: _rotate,
    border,
    borderRadius,
    width,
    height,
    color,
    position: _position,
    style,
    crossOrigin,
    onLoadFailure,
    onLoadSuccess,
    onImageReady,
    onImageChange,
    onMouseUp,
    onMouseMove,
    onSave,
    onPositionChange,
    disableBoundaryChecks,
    disableHiDPIScaling,
    ...other
  } = props

  const ref = useRef()
  const [refresh, setRefresh] = useState(0)
  const [position, setPosition] = useState({})
  const [scale, setScale] = useState(_scale)
  const [rotate, setRotate] = useState(_rotate)
  const [drag, setDrag] = useState(false)

  const [origin, setOrigin] = useState({ x: 0, y: 0 })
  const [translation, setTranslation] = useState({ x: 0, y: 0 })

  const [image, setImage] = useState(defaultEmptyImage)
  const [preview, setPreview] = useState(null)

  useEffect(() => {
    if (disableHiDPIScaling) pixelRatio = 1
    const context = ref.current.getContext('2d')
    paint(context)
  }, [])

  useEffect(() => {
    if (target) {
      loadImage(target)
    } else {
      clearImage()
    }
  }, [target])

  useEffect(() => {
    setPosition(_position)
  }, [_position.x, _position.y])

  useEffect(() => {
    setRotate(_rotate)
  }, [_rotate])

  useEffect(() => {
    setScale(_scale)
  }, [_scale])

  useEffect(() => {
    if (refresh === 0) return
    const context = ref.current.getContext('2d')
    context.clearRect(0, 0, ref.current.width, ref.current.height)
    paint(context)
    paintImage(context, image, border)
    onImageChange()
  }, [refresh, width, height, scale, position.x, position.y])

  const handleScale = e => setScale(parseFloat(e.target.value))

  const handleX = e => {
    const value = e.target.value
    setPosition(position => {
      return {
        ...position,
        x: parseFloat(value),
      }
    })
  }

  const handleY = e => {
    const value = e.target.value
    setPosition(position => {
      return {
        ...position,
        y: parseFloat(value),
      }
    })
  }

  const getImageScaledToCanvas = () => {
    const { width, height } = getDimensions()

    const canvas = document.createElement('canvas')

    if (isVertical()) {
      canvas.width = height
      canvas.height = width
    } else {
      canvas.width = width
      canvas.height = height
    }
    paintImage(canvas.getContext('2d'), image, 0, 1)
    return canvas
  }

  const handleMouseMove = useCallback(
    e => {
      e.preventDefault()
      setTranslation({
        x: e.clientX - origin.x,
        y: e.clientY - origin.y,
      })
      onMouseMove(e)
    },
    [origin.x, origin.y]
  )

  useEffect(() => {
    const width = image.width * scale
    const height = image.height * scale
    let { x: lastX, y: lastY } = getCroppingRect()
    lastX *= width
    lastY *= height

    const toRadians = degree => degree * (Math.PI / 180)
    const cos = Math.cos(toRadians(rotate))
    const sin = Math.sin(toRadians(rotate))

    const x = lastX + translation.x * cos + translation.y * sin
    const y = lastY + -translation.x * sin + translation.y * cos

    const relativeWidth = (1 / scale) * getXScale()
    const relativeHeight = (1 / scale) * getYScale()

    const position = {
      x: x / width + relativeWidth / 2,
      y: y / height + relativeHeight / 2,
    }

    onPositionChange(position)

    setImage(image => ({
      ...image,
      ...position,
    }))

    setRefresh(refresh => ++refresh)
  }, [translation.x, translation.y])

  const handleMouseUp = useCallback(() => {
    setDrag(false)
    onMouseUp()
  })

  const handleMouseDown = useCallback(e => {
    setDrag(true)
    setOrigin({ x: e.clientX, y: e.clientY })
    e.preventDefault()
  }, [])

  useEffect(() => {
    if (drag) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [drag, handleMouseMove, handleMouseUp])

  const paintImage = (context, image, border, scaleFactor = pixelRatio) => {
    if (!image.resource) return

    const position = calculatePosition(image, border)
    context.save()

    context.translate(context.canvas.width / 2, context.canvas.height / 2)
    context.rotate((rotate * Math.PI) / 180)
    context.translate(-(context.canvas.width / 2), -(context.canvas.height / 2))

    if (isVertical()) {
      context.translate(
        (context.canvas.width - context.canvas.height) / 2,
        (context.canvas.height - context.canvas.width) / 2
      )
    }

    context.scale(scaleFactor, scaleFactor)
    context.globalCompositeOperation = 'destination-over'
    context.drawImage(
      image.resource,
      position.x,
      position.y,
      position.width,
      position.height
    )
    context.restore()
  }

  const clearImage = () => {
    const context = ref.current.getContext('2d')
    context.clearRect(0, 0, ref.current.width, ref.current.height)
    setImage(defaultEmptyImage)
    setRefresh(refresh => ++refresh)
  }

  const getCroppingRect = () => {
    const _position = position || {
      x: image.x,
      y: image.y,
    }
    const width = (1 / scale) * getXScale()
    const height = (1 / scale) * getYScale()

    const croppingRect = {
      x: _position.x - width / 2,
      y: _position.y - height / 2,
      width,
      height,
    }

    let xMin = 0
    let xMax = 1 - croppingRect.width
    let yMin = 0
    let yMax = 1 - croppingRect.height

    // If the cropping rect is larger than the image, then we need to change
    // our maxima & minima for x & y to allow the image to appear anywhere up
    // to the very edge of the cropping rect.
    const isLargerThanImage = disableBoundaryChecks || width > 1 || height > 1

    if (isLargerThanImage) {
      xMin = -croppingRect.width
      xMax = 1
      yMin = -croppingRect.height
      yMax = 1
    }

    return {
      ...croppingRect,
      x: Math.max(xMin, Math.min(croppingRect.x, xMax)),
      y: Math.max(yMin, Math.min(croppingRect.y, yMax)),
    }
  }

  const getXScale = () => {
    const canvasAspect = width / height
    const imageAspect = image.width / image.height
    return Math.min(1, canvasAspect / imageAspect)
  }

  const getYScale = () => {
    const canvasAspect = height / width
    const imageAspect = image.height / image.width
    return Math.min(1, canvasAspect / imageAspect)
  }

  const loadImage = target => {
    if (isFileAPISupported && target instanceof File) {
      makeCancelable(loadImageFile(target)).promise.then(handleImageReady)
    } else if (typeof target === 'string') {
      makeCancelable(loadImageURL(target, crossOrigin))
        .promise.then(handleImageReady)
        .catch(onLoadFailure)
    }
  }

  const handleImageReady = image => {
    const imageState = getInitialSize(image.width, image.height)
    imageState.resource = image
    imageState.x = 0.5
    imageState.y = 0.5
    setDrag(false)
    setImage(imageState)
    setRefresh(refresh => ++refresh)
    onImageReady()
    onLoadSuccess(imageState)
  }

  const getInitialSize = (width, height) => {
    let newHeight
    let newWidth

    const dimensions = getDimensions()
    const canvasRatio = dimensions.height / dimensions.width
    const imageRatio = height / width

    if (canvasRatio > imageRatio) {
      newHeight = getDimensions().height
      newWidth = width * (newHeight / height)
    } else {
      newWidth = getDimensions().width
      newHeight = height * (newWidth / width)
    }

    return {
      height: newHeight,
      width: newWidth,
    }
  }

  const getDimensions = () => {
    const canvas = {}

    const [borderX, borderY] = getBorders(border)

    const canvasWidth = width
    const canvasHeight = height

    if (isVertical()) {
      canvas.width = canvasHeight
      canvas.height = canvasWidth
    } else {
      canvas.width = canvasWidth
      canvas.height = canvasHeight
    }

    canvas.width += borderX * 2
    canvas.height += borderY * 2

    return {
      canvas,
      rotate,
      width,
      height,
      border,
    }
  }

  const getBorders = border => {
    return Array.isArray(border) ? border : [border, border]
  }

  const isVertical = () => rotate % 180 !== 0

  const paint = context => {
    context.save()
    context.scale(pixelRatio, pixelRatio)
    context.translate(0, 0)
    context.fillStyle = 'rgba(' + color.slice(0, 4).join(',') + ')'

    let _borderRadius = borderRadius
    const dimensions = getDimensions()
    const [borderSizeX, borderSizeY] = getBorders(dimensions.border)
    const height = dimensions.canvas.height
    const width = dimensions.canvas.width

    // clamp border radius between zero (perfect rectangle) and half the size without borders (perfect circle or "pill")
    _borderRadius = Math.max(_borderRadius, 0)
    _borderRadius = Math.min(
      _borderRadius,
      width / 2 - borderSizeX,
      height / 2 - borderSizeY
    )

    context.beginPath()
    // inner rect, possibly rounded
    drawRoundedRect(
      context,
      borderSizeX,
      borderSizeY,
      width - borderSizeX * 2,
      height - borderSizeY * 2,
      _borderRadius
    )
    context.rect(width, 0, -width, height) // outer rect, drawn "counterclockwise"
    context.fill('evenodd')

    context.restore()
  }

  const calculatePosition = (_image, border) => {
    const im = _image || image
    const [borderX, borderY] = getBorders(border)
    const croppingRect = getCroppingRect()

    const width = im.width * scale
    const height = im.height * scale

    let x = -croppingRect.x * width
    let y = -croppingRect.y * height

    if (isVertical()) {
      x += borderY
      y += borderX
    } else {
      x += borderX
      y += borderY
    }

    return {
      x,
      y,
      height,
      width,
    }
  }

  const attributes = useMemo(() => {
    const dimensions = getDimensions()
    const defaultStyle = {
      width: dimensions.canvas.width,
      height: dimensions.canvas.height,
      cursor: drag ? 'grabbing' : 'grab',
      touchAction: 'none',
    }

    return {
      width: dimensions.canvas.width * pixelRatio,
      height: dimensions.canvas.height * pixelRatio,
      style: {
        ...defaultStyle,
        ...style,
      },
      onMouseDown: handleMouseDown,
      onTouchStart: isTouchDevice ? handleMouseDown : null,
    }
  }, [drag, pixelRatio, style, handleMouseDown])

  return (
    <Segment.Group horizontal>
      <Segment>
        <canvas ref={ref} {...attributes} {...other} />
      </Segment>
      <Segment>
        <Form.Group>
          <Form.Field>
            <label>Zoom</label>
            <input
              name='scale'
              type='range'
              onChange={handleScale}
              min='0.1'
              max='3'
              step='0.01'
              defaultValue='1'
            />
          </Form.Field>

          <Form.Field>
            <label>左右</label>
            <input
              name='x'
              type='range'
              onChange={handleX}
              min='0'
              max='1'
              step='0.01'
              defaultValue='0.5'
            />
          </Form.Field>

          <Form.Field>
            <label>上下</label>
            <input
              name='y'
              type='range'
              onChange={handleY}
              min='0'
              max='1'
              step='0.01'
              defaultValue='0.5'
            />
          </Form.Field>
        </Form.Group>

        <Button
          as='span'
          content='プレビュー'
          fluid
          onClick={() => {
            setPreview(getImageScaledToCanvas().toDataURL())
          }}
        />

        {preview && <img src={preview && preview} alt='preview' />}

        <Button
          as='span'
          content='保存する'
          primary
          fluid
          onClick={() => {
            onSave(getImageScaledToCanvas().toDataURL())
          }}
        />
      </Segment>
    </Segment.Group>
  )
}

AvatarEditor.propTypes = {
  scale: PropTypes.number,
  rotate: PropTypes.number,
  image: PropTypes.oneOfType([
    PropTypes.string,
    ...(isFileAPISupported ? [PropTypes.instanceOf(File)] : []),
  ]),
  border: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  borderRadius: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  color: PropTypes.arrayOf(PropTypes.number),
  crossOrigin: PropTypes.oneOf(['', 'anonymous', 'use-credentials']),
  onLoadFailure: PropTypes.func,
  onLoadSuccess: PropTypes.func,
  onImageReady: PropTypes.func,
  onImageChange: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseMove: PropTypes.func,
  onPositionChange: PropTypes.func,
  onSave: PropTypes.func,
  disableBoundaryChecks: PropTypes.bool,
  disableHiDPIScaling: PropTypes.bool,
}

AvatarEditor.defaultProps = {
  scale: 1,
  rotate: 0,
  border: 25,
  borderRadius: 0,
  width: 200,
  height: 200,
  color: [0, 0, 0, 0.5],
  position: { x: 0.5, y: 0.5 },
  onLoadFailure: () => {},
  onLoadSuccess: () => {},
  onImageReady: () => {},
  onImageChange: () => {},
  onMouseUp: () => {},
  onMouseMove: () => {},
  onPositionChange: () => {},
  onSave: () => {},
  disableBoundaryChecks: false,
  disableHiDPIScaling: false,
}

export default AvatarEditor
