import { useState, useEffect } from 'react'
import { Axios } from 'component/base'

export const useCalcUnchin = props => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const { valid, ...param } = props

  const {
    member_mode,
    ryokin_id,
    warimashi_rate,
    kyorisei_su,
    jikansei_su,
    jikansei_hm,
    hikkoshi_hm,
    hikkoshi_su,
    niatsukai_hm,
    kamotsumachi_hm,
    yokomochi_hm,
    takuhai_su,
    takuhai_tanka,
    kyorijikan_su,
    kyorijikan_hm,
  } = param || {}

  const ajax = async param => {
    setLoading(true)

    const url = member_mode
      ? '/api/member/haisha/calc_unchin'
      : '/api/staff/haisha/haisha/calc_unchin'

    const { data, error } = await Axios.ajax.get(url, {
      params: param,
    })
    if (error) {
      setLoading(false)
      return
    }
    setData(data || {})
    setLoading(false)
  }

  useEffect(() => {
    if (!ryokin_id || valid === false) return
    ajax({
      ...param,
      jikansei_hm: jikansei_hm || '00:00',
      hikkoshi_hm: hikkoshi_hm || '00:00',
      niatsukai_hm: niatsukai_hm || '00:00',
      kamotsumachi_hm: kamotsumachi_hm || '00:00',
      yokomochi_hm: yokomochi_hm || '00:00',
      kyorijikan_hm: kyorijikan_hm || '00:00',
    })
  }, [
    ryokin_id,
    warimashi_rate,
    kyorisei_su,
    jikansei_su,
    jikansei_hm,
    hikkoshi_hm,
    hikkoshi_su,
    niatsukai_hm,
    kamotsumachi_hm,
    yokomochi_hm,
    takuhai_su,
    takuhai_tanka,
    kyorijikan_su,
    kyorijikan_hm,
    valid,
  ])

  return {
    data,
    loading,
  }
}
