import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Search from './FormsySearch'
import {
  Label as SemanticUILabel,
  Form as SemanticUIForm,
  Input as SemanticUIInput,
  TextArea as SemanticUITextArea,
} from 'semantic-ui-react'
import './style.css'

const Standard = props => {
  const {
    required,
    validations,
    readOnly,
    className,
    alignRight,
    label,
    disabled,
    value: _value,
    defaultValue,
    inputAs,
    name,
    outsideLabel,
    onBlur,
    onResultSelect,
    ...other
  } = props

  const [value, setValue] = useState()

  useEffect(() => {
    setValue(_value)
  }, [_value])

  const validationErrors = useMemo(
    () => ({
      isDefaultRequiredValue: required ? 'Required' : '',
    }),
    [validations, required]
  )

  const handleResultSelect = (e, data) => {
    const { result } = data
    onResultSelect && onResultSelect(e, data)
    setValue(result.title)
  }

  const handleOnBlur = (e, data) => {
    onBlur(e, data, true)
  }

  return (
    <Search
      onResultSelect={handleResultSelect}
      {...other}
      name={name}
      inputAs={inputAs}
      required={required}
      outsideLabel={outsideLabel}
      validations={validations}
      validationErrors={validationErrors}
      disabled={disabled || readOnly}
      label={outsideLabel ? (!label || label === '' ? '　' : label) : label}
      className={`
        ${readOnly ? 'readOnly' : ''}
        ${alignRight ? 'alignRight' : ''}
        ${className || ''}
        `}
      value={value && value !== '' ? value.toString() : undefined}
      defaultValue={
        defaultValue && defaultValue !== ''
          ? defaultValue.toString()
          : undefined
      }
      onBlur={handleOnBlur}
    />
  )
}

Standard.propTypes = {
  /*
    semantic-ui-react/formsy-semantic-ui-react props
    https://react.semantic-ui.com/collections/form/
    https://github.com/zabute/formsy-semantic-ui-react/blob/master/src/FormsyInput.js
  */
  /*
    Form.Field props
  */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  /*
    Input/TextArea props
  */
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  /** className for input */
  inputClassName: PropTypes.string,
  /** Input or TextArea selectable */
  inputAs: PropTypes.oneOf([
    Search,
    SemanticUIForm.Input,
    SemanticUIForm.TextArea,
    SemanticUIInput,
    SemanticUITextArea,
  ]),
  /** error label */
  errorLabel: PropTypes.element,
  /** label name or element */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  /** required */
  required: PropTypes.bool,
  /** onBlur callback */
  onBlur: PropTypes.func,
  /** onSearchChange callback */
  onSearchChange: PropTypes.func,
  /** default value */
  defaultValue: PropTypes.string,

  /*
    formsy-react props
    https://github.com/formsy/formsy-react/blob/master/API.md
  */
  /** validate on input change */
  instantValidation: PropTypes.bool,
  /** The message that will show when the form input component is invalid. It will be used as a default error. */
  validationError: PropTypes.string,
  /** The message that will show when the form input component is invalid. */
  /** You can combine this with validationError. Keys not found in validationErrors defaults to the general error message. */
  validationErrors: PropTypes.object,
  /** A comma separated list with validation rules */
  /** Default validation is here -> https://github.com/formsy/formsy-react/blob/master/API.md#validators */
  /** useage -> https://github.com/formsy/formsy-react/blob/master/API.md#validations */
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /*
    custom props
  */
  /** Label position */
  outsideLabel: PropTypes.bool,

  /** switch readOnly mode */
  readOnly: PropTypes.bool,

  /** switch text-align right **/
  alignRight: PropTypes.bool,
}

Standard.defaultProps = {
  errorLabel: <SemanticUILabel color='red' pointing floating />,
  required: false,
  validationError: 'this is default invalid message',
  validations: {},
  instantValidation: true,
  outsideLabel: true,
  readOnly: false,
  alignRight: false,
  onBlur: () => { },
}

export default Standard
