import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'

export const ShozokuArea = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/area'
      keyName='id'
      valueName='id'
      textName='area_name'
      {...props}
    />
  )
}

ShozokuArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

ShozokuArea.defaultProps = {
  name: 'shozokuarea',
  label: '所属エリア',
  value: undefined,
}
