import { useState, useEffect } from 'react'
import _ from 'lodash'
import { Axios } from 'component/base'

export const useSystemSetteiKumiaihito = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const ajax = async key => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/system_settei_kumiaihito`,
      {}
    )
    if (error) {
      setLoading(false)
      return
    }
    setData(data && _.isArray(data) ? data : [])
    setLoading(false)
  }

  useEffect(() => {
    ajax()
  }, [])

  return {
    data,
    loading,
  }
}
