import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Axios, Text } from 'component/base'
import { useUnmounted } from 'component/hook'

export const ApiLabel = props => {
  const { url, defaultValue, textName } = props

  const unmounted = useUnmounted()
  const [refresh, setRefresh] = useState(0)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const [source] = useState(axios.CancelToken.source())

  useEffect(() => {
    callApi(url)
  }, [url])

  useEffect(() => {
    if (unmounted.current) source.cancel('your request was canceled')
  }, [unmounted])

  const callApi = async url => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(url, {
      cancelToken: source.token,
    })
    if (unmounted.current) return

    setLoading(false)
    if (error) {
      setValue('')
      return
    }

    setRefresh(refresh => ++refresh)
    setValue(
      data && data[0]
        ? data[0][textName]
        : data && data[textName]
        ? data[textName]
        : ''
    )
  }

  return <Text.Basic key={refresh} loading={loading} content={value} />
}

ApiLabel.propTypes = {
  defaultValue: PropTypes.string,
  url: PropTypes.string,
  textName: PropTypes.string,
}

ApiLabel.defaultProps = {
  defaultValue: '',
  url: 'https://demo8265123.mockable.io/api/example',
  textName: 'text',
}
