import React from 'react'
import * as Dropdown from 'component/base/Form/Dropdown'

export const HaisoKanrenChushutsuTaishobiKbn = props => {
  return (
    <Dropdown.Static
      kubunKey='haiso_kanren_chushutsu_taishobi_kbn'
      {...props}
    />
  )
}
