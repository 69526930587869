import React, { useState, useRef } from 'react'
import _ from 'lodash'
import {
  Axios,
  notice,
  Modal,
  BaseForm as Form,
  Grid,
  Button,
  Message,
} from 'component/base'

export const GpsTojitsuMemoModal = props => {
  const {
    isOpenModal,
    onClickCancel,
    kumiaiin_id,
    setShowMemoModal,
    onEdited,
  } = props

  const [tojitsuMemoData, setTojitsuMemoData] = useState({})

  const mapModel = inputs => inputs
  const formRef = useRef()

  const handleOnOpen = () => {
    callGetApi({ kumiaiin_id })
  }

  const callGetApi = async () => {
    const { data, error } = await Axios.ajax.get(
      '/api/staff/haisha/gps_tojitsu_memo',
      {
        params: {
          kumiaiin_id,
        },
      }
    )
    if (error) return
    setTojitsuMemoData(data)
  }

  const handleDecision = model => {
    const { naiyo } = model || {}
    if (_.isEmpty(tojitsuMemoData)) postTojitsuMemo(naiyo)
    else patchTojitsuMemo(naiyo)
  }

  const postTojitsuMemo = async naiyo => {
    const { error } = await Axios.ajax.post(
      `/api/staff/haisha/gps_tojitsu_memo`,
      {
        kumiaiin_id,
        naiyo,
      }
    )
    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      return
    }
    setShowMemoModal(false)
    onEdited()
  }

  const patchTojitsuMemo = async naiyo => {
    const { error } = await Axios.ajax.patch(
      `/api/staff/haisha/gps_tojitsu_memo/${tojitsuMemoData.id}`,
      {
        naiyo,
        r_gps_tojitsu_memo_id: tojitsuMemoData.r_gps_tojitsu_memo_id,
      }
    )
    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      return
    }
    setShowMemoModal(false)
    onEdited()
  }

  return (
    <Modal
      closeOnEscape={true}
      closeOnDimmerClick={true}
      dimmer='inverted'
      onMount={handleOnOpen}
      onClose={onClickCancel}
      open={isOpenModal}
      size='small'
    >
      <Modal.Header>当日メモ編集</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Message
                info
                icon='info circle'
                content={
                  <>
                    <p>当日メモの編集ができます</p>
                  </>
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form
                id='formmodal'
                mapping={mapModel}
                onValidSubmit={handleDecision}
                ref={formRef}
              >
                <Form.Textbox.Standard
                  name='naiyo'
                  label='当日メモ'
                  value={tojitsuMemoData.naiyo}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content='完了'
          icon='checkmark'
          form='formmodal'
          labelPosition='right'
          primary
        />
        <Button onClick={onClickCancel} content='キャンセル' />
      </Modal.Actions>
    </Modal>
  )
}

GpsTojitsuMemoModal.defaultProps = {
  onEdited: () => { },
}
