import { useRef, useEffect } from 'react'

export const useUnmounted = () => {
  const unmounted = useRef(false)

  useEffect(
    () => () => {
      unmounted.current = true
    },
    []
  )

  return unmounted
}
