import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Label,
  Input,
  Dropdown,
  Text,
} from 'component/base'
import { Custom } from 'component/pieces'

export const ZaikoIdo = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/buppan/zaiko_ido/list',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='zaiko_ido'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

ZaikoIdo.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

ZaikoIdo.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'ido_date' ? direction : null}
        onClick={() => handleSort('ido_date')}
        content='移動日'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'idomoto_busho_name' ? direction : null}
        onClick={() => handleSort('idomoto_busho_name')}
        content='移動元部署'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'idosaki_busho_name' ? direction : null}
        onClick={() => handleSort('idosaki_busho_name')}
        content='移動先部署'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shohin_cd' ? direction : null}
        onClick={() => handleSort('shohin_cd')}
        content='商品番号'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'shohin_name' ? direction : null}
        onClick={() => handleSort('shohin_name')}
        content='商品名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'su' ? direction : null}
        onClick={() => handleSort('su')}
        content='移動数'
      />
      <Table.HeaderCell
        style={{ width: '30.0rem' }}
        sorted={column === 'biko' ? direction : null}
        onClick={() => handleSort('biko')}
        content='備考'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    ido_date,
    idomoto_busho_name,
    idosaki_busho_name,
    shohin_cd,
    shohin_name,
    su,
    biko,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={ido_date} />
      <Table.Cell style={{ width: '15.0rem' }} content={idomoto_busho_name} />
      <Table.Cell style={{ width: '15.0rem' }} content={idosaki_busho_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={shohin_cd} />
      <Table.Cell style={{ width: '20.0rem' }} content={shohin_name} />
      <Table.Cell
        style={{ width: '10.0rem', textAlign: 'right' }}
        content={<Text.Comma content={su} />}
      />
      <Table.Cell style={{ width: '30.0rem' }} content={biko} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const {
    ido_date_from,
    ido_date_to,
    idomoto_busho_id,
    idosaki_busho_id,
    shohin_id,
  } = condition

  return (
    <>
      <Grid.Column>
        <Form.Textbox.DatePicker
          inputAs={Input}
          value={ido_date_from}
          label={<Label>移動日FROM</Label>}
          name='ido_date_from'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          inputAs={Input}
          value={ido_date_to}
          label='移動日TO'
          name='ido_date_to'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Busho
          inputAs={Dropdown}
          value={idomoto_busho_id}
          label='移動元部署'
          name='idomoto_busho_id'
          clearable
          enableAuthValue={false}
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Busho
          inputAs={Dropdown}
          value={idosaki_busho_id}
          label='移動先部署'
          name='idosaki_busho_id'
          clearable
          enableAuthValue={false}
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Shohin
          inputAs={Dropdown}
          value={shohin_id}
          label='商品'
          name='shohin_id'
          clearable
          search
        />
      </Grid.Column>
    </>
  )
}
