import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table } from 'semantic-ui-react'
import './sticky-table.css'

export const StickyTable = props => {
  const { xy, height, children, className, size, ...other } = props || {}

  const stickyClassName = useMemo(
    () => `${className} ${xy ? 'sticky_table xy' : 'sticky_table'} ${size}`,
    [className, xy, size]
  )

  const customStyle = useMemo(
    () => ({
      overflowY: 'scroll',
      overflowX: xy ? 'scroll' : '',
      height,
    }),
    [xy, height]
  )

  return (
    <div style={customStyle}>
      <Table className={stickyClassName} style={{ border: 'none' }} {...other}>
        {children}
      </Table>
    </div>
  )
}

StickyTable.propTypes = {
  xy: PropTypes.bool,
  height: PropTypes.string,
}

StickyTable.defaultProps = {
  xy: false,
  height: '70vh',
}

StickyTable.Header = props => <StickyTableHeader {...props} />

export const StickyTableHeader = props => {
  const { style, fullWidth, children, ...other } = props || {}

  const customStyle = useMemo(
    () =>
      _.merge({}, style, {
        width: fullWidth ? undefined : 'max-content',
      }),
    [style, fullWidth]
  )

  return (
    <Table.Header {...other} fullWidth={fullWidth} style={customStyle}>
      {children}
    </Table.Header>
  )
}
