import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import $ from 'jquery'
import {
  Menu,
  Icon,
  Header,
  Visibility,
  Media,
  MediaContextProvider,
} from 'component/base'
import { Breadcrumb } from 'component/pieces'
import './style.css'

const ResponsiveToolbar = withRouter(props => <ToolbarWrapper {...props} />)

export default ResponsiveToolbar

ResponsiveToolbar.propTypes = {
  title: PropTypes.string,
  rightMenu: PropTypes.node,
  compact: PropTypes.bool,
  toolbar: PropTypes.bool,
  breadcrumb: PropTypes.bool,
  size: PropTypes.string,
}
ResponsiveToolbar.defaultProps = {
  title: 'Default Title',
  menuItems: <></>,
  rightMenu: <></>,
  compact: false,
  toolbar: true,
  breadcrumb: true,
  size: 'small',
}

const ToolbarWrapper = props => {
  const { title } = props

  const [scrolled, setScrolled] = useState(false)

  const onBottomPassedReverse = () => setScrolled(false)
  const onBottomPassed = () => setScrolled(true)

  useEffect(() => {
    document.title = title
  }, [title])

  const onGoBack = e => {
    const breadcrumb = $(e.target)
      .closest('.ui.menu')
      .parent()
      .parent()
      .find('.breadcrumb-group')

    const first = breadcrumb.find('.step:eq(0)').find('a').get(0)

    const second = breadcrumb.find('.step:eq(1)').find('a').get(0)

    return second ? second.click() : first ? first.click() : null
  }

  return (
    <MediaContextProvider>
      <DesktopToolbar
        {...props}
        scrolled={scrolled}
        onBottomPassed={onBottomPassed}
        onBottomPassedReverse={onBottomPassedReverse}
        onGoBack={onGoBack}
      />
      <TabletToolbar
        {...props}
        scrolled={scrolled}
        onBottomPassed={onBottomPassed}
        onBottomPassedReverse={onBottomPassedReverse}
        onGoBack={onGoBack}
      />
      <MobileToolbar
        {...props}
        scrolled={scrolled}
        onBottomPassed={onBottomPassed}
        onBottomPassedReverse={onBottomPassedReverse}
        onGoBack={onGoBack}
      />
    </MediaContextProvider>
  )
}

const ToolbarInside = props => {
  const { scrolled, title, compact, menuItems, rightMenu, onGoBack, size } =
    props || {}

  return (
    <Menu
      id='toolbar-menu'
      borderless
      style={{ borderRadius: 0, marginBottom: '0px', zIndex: 9999 }}
      fixed={scrolled ? 'top' : null}
      stackable={compact ? true : false}
      size={size}
    >
      <Menu.Item onClick={onGoBack}>
        <Icon name='arrow alternate circle left outline' size='big' />
      </Menu.Item>
      <Menu.Item header>
        <Header size='medium'>{title}</Header>
      </Menu.Item>

      {menuItems}

      <Menu.Menu position='right'>{rightMenu}</Menu.Menu>
    </Menu>
  )
}

const DesktopToolbar = props => {
  const {
    scrolled,
    onBottomPassed,
    onBottomPassedReverse,
    breadcrumb,
    ...other
  } = props || {}

  return (
    <Media greaterThanOrEqual='computer'>
      <Visibility
        once={false}
        onBottomPassed={onBottomPassed}
        onBottomPassedReverse={onBottomPassedReverse}
      >
        <ToolbarInside {...other} scrolled={scrolled} />
      </Visibility>

      <Breadcrumb disabled={!breadcrumb} />
    </Media>
  )
}

const TabletToolbar = props => {
  const {
    scrolled,
    onBottomPassed,
    onBottomPassedReverse,
    breadcrumb,
    ...other
  } = props || {}

  return (
    <Media at='tablet'>
      <ToolbarInside {...other} scrolled={scrolled} />
      <Breadcrumb disabled={!breadcrumb} />
    </Media>
  )
}

const MobileToolbar = props => {
  const {
    scrolled,
    onBottomPassed,
    onBottomPassedReverse,
    breadcrumb,
    ...other
  } = props || {}

  return (
    <Media at='mobile'>
      <ToolbarInside {...other} scrolled={scrolled} />
      <Breadcrumb mobile disabled={!breadcrumb} />
    </Media>
  )
}
