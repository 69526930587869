import React, { useMemo, useEffect, useState }  from 'react'
import { Loader } from 'semantic-ui-react'
import { ApolloProvider, useMutation } from '@apollo/client'
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import gql from 'graphql-tag'
import { getConfigChat } from 'config'
import {
  useKeycloak,
  Menu,
} from 'component/base'
import { createUser } from 'graphql/mutations'

require('isomorphic-fetch')

const generateClient = (token) => {
  const config = getConfigChat(token)
  
  const link = ApolloLink.from([
    createAuthLink(config),
    createSubscriptionHandshakeLink(config)
  ])
 
  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
      }
    }
  })
}

const RegistUser = props => {
  const [ user, setUser ] = useState({})
  const [ mutateCreateUser, { data, loading } ] = useMutation(gql(createUser))

  useEffect(() => {
    mutateCreateUser()
  }, [])
  useEffect(()=>{
    if (data) setUser(data.createUser)
  }, [data])

  if (loading || !user) return (<Menu.Item content={<Loader active inline='centered' />} />)

  return (
    <>{props.children}</>
  )  
}

export const GraphQLProvider = props => {
  const { keycloak } = useKeycloak()
  const { token } = keycloak || {}
  const client = useMemo(
    () => (token ? generateClient(token) : null),
    [token]
  )

  return (
    <>
      {client ? ( 
        <ApolloProvider client={client}>
          <RegistUser>
            {props.children}
          </RegistUser>
        </ApolloProvider>
      ) : (
        <></>
      )}
    </>
  )
}


