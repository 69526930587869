import _ from 'lodash'

const zenKana = [
  'ア',
  'イ',
  'ウ',
  'エ',
  'オ',
  'カ',
  'キ',
  'ク',
  'ケ',
  'コ',
  'サ',
  'シ',
  'ス',
  'セ',
  'ソ',
  'タ',
  'チ',
  'ツ',
  'テ',
  'ト',
  'ナ',
  'ニ',
  'ヌ',
  'ネ',
  'ノ',
  'ハ',
  'ヒ',
  'フ',
  'ヘ',
  'ホ',
  'マ',
  'ミ',
  'ム',
  'メ',
  'モ',
  'ヤ',
  'ヰ',
  'ユ',
  'ヱ',
  'ヨ',
  'ラ',
  'リ',
  'ル',
  'レ',
  'ロ',
  'ワ',
  'ヲ',
  'ン',
  'ガ',
  'ギ',
  'グ',
  'ゲ',
  'ゴ',
  'ザ',
  'ジ',
  'ズ',
  'ゼ',
  'ゾ',
  'ダ',
  'ヂ',
  'ヅ',
  'デ',
  'ド',
  'バ',
  'ビ',
  'ブ',
  'ベ',
  'ボ',
  'パ',
  'ピ',
  'プ',
  'ペ',
  'ポ',
  'ァ',
  'ィ',
  'ゥ',
  'ェ',
  'ォ',
  'ャ',
  'ュ',
  'ョ',
  'ッ',
  '゛',
  '°',
  '、',
  '。',
  '「',
  '」',
  'ー',
  '・',
]

const hanKana = [
  'ｱ',
  'ｲ',
  'ｳ',
  'ｴ',
  'ｵ',
  'ｶ',
  'ｷ',
  'ｸ',
  'ｹ',
  'ｺ',
  'ｻ',
  'ｼ',
  'ｽ',
  'ｾ',
  'ｿ',
  'ﾀ',
  'ﾁ',
  'ﾂ',
  'ﾃ',
  'ﾄ',
  'ﾅ',
  'ﾆ',
  'ﾇ',
  'ﾈ',
  'ﾉ',
  'ﾊ',
  'ﾋ',
  'ﾌ',
  'ﾍ',
  'ﾎ',
  'ﾏ',
  'ﾐ',
  'ﾑ',
  'ﾒ',
  'ﾓ',
  'ﾔ',
  'ｲ',
  'ﾕ',
  'ｴ',
  'ﾖ',
  'ﾗ',
  'ﾘ',
  'ﾙ',
  'ﾚ',
  'ﾛ',
  'ﾜ',
  'ｦ',
  'ﾝ',
  'ｶﾞ',
  'ｷﾞ',
  'ｸﾞ',
  'ｹﾞ',
  'ｺﾞ',
  'ｻﾞ',
  'ｼﾞ',
  'ｽﾞ',
  'ｾﾞ',
  'ｿﾞ',
  'ﾀﾞ',
  'ﾁﾞ',
  'ﾂﾞ',
  'ﾃﾞ',
  'ﾄﾞ',
  'ﾊﾞ',
  'ﾋﾞ',
  'ﾌﾞ',
  'ﾍﾞ',
  'ﾎﾞ',
  'ﾊﾟ',
  'ﾋﾟ',
  'ﾌﾟ',
  'ﾍﾟ',
  'ﾎﾟ',
  'ｧ',
  'ｨ',
  'ｩ',
  'ｪ',
  'ｫ',
  'ｬ',
  'ｭ',
  'ｮ',
  'ｯ',
  'ﾞ',
  'ﾟ',
  '､',
  '｡',
  '｢',
  '｣',
  'ｰ',
  '･',
]

const boinList = ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ']
const shiinList = [
  'あいうえお',
  'かきくけこ',
  'さしすせそ',
  'たちつてと',
  'なにぬねの',
  'はひふへほ',
  'まみむめも',
  'やゆよ',
  'らりるれろ',
  'わをん',
]

export const zenKanaToHanKana = str =>
  _(str)
    .map(char => hanKana[_.findIndex(zenKana, str => str === char)])
    .value()
    .join('')

export const hanKanaToZenKana = str =>
  _(str)
    .map(char => zenKana[_.findIndex(hanKana, str => str === char)])
    .value()
    .join('')

export const zenKanaToZenHira = str =>
  str.replace(/[\u30a1-\u30f6]/g, match =>
    String.fromCharCode(match.charCodeAt(0) - 0x60)
  )

export const zenHiraToHanKana = str => zenKanaToHanKana(zenHiraToZenKana(str))

export const zenHiraToZenKana = str =>
  str.replace(/[\u3041-\u3096]/g, match =>
    String.fromCharCode(match.charCodeAt(0) + 0x60)
  )

export const includesFromBoin = (char, boin) => {
  const boinIndex = _.findIndex(boinList, b => b === boin)
  const shiin = shiinList[boinIndex]

  const applyList = _.concat(
    [],
    _.map(shiin), // 該当子音の全角ひらがな
    _.map(zenHiraToZenKana(shiin)), // 該当子音の全角カタカナ
    _.map(zenHiraToHanKana(shiin)) // 該当子音の半角カタカナ
  )

  return _.includes(applyList, char)
}
