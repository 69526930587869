import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'
import { Custom } from 'component/pieces'

export const InputRow = props => {
  const { index, rowData, onChange } = props || {}

  const createCell = (cell, ind) => {
    const form =
      cell.type === 'TextboxArea' ? (
        <Form.Textbox.Standard name={cell.name} value={cell.val} textAreaMode />
      ) : cell.type === 'TextboxStandard' ? (
        <Form.Textbox.Standard name={cell.name} value={cell.val} />
      ) : cell.type === 'Dropdown' ? (
        <Form.Dropdown.WithAPI
          name={cell.name}
          value={cell.val}
          url={cell.url}
        />
      ) : cell.type === 'Money' ? (
        <Form.Textbox.Money name={cell.name} defaultValue={cell.val} />
      ) : cell.type === 'DatePicker' ? (
        <Form.Textbox.DatePicker name={cell.name} defaultValue={cell.val} />
      ) : cell.type === 'Int' ? (
        <Form.Textbox.Int name={cell.name} defaultValue={cell.val} />
      ) : cell.type === 'Float' ? (
        <Form.Textbox.Float name={cell.name} defaultValue={cell.val} />
      ) : cell.type === 'CustomDropdownStore' ? (
        <Custom.Dropdown.Store
          name={cell.name}
          defaultValue={cell.val}
          multiple
          selection
        />
      ) : cell.type === 'CustomDropdownHoken' ? (
        <Custom.Dropdown.Hoken name={cell.name} value={cell.val} />
      ) : (
        <div style={{ width: cell.width }} name={cell.label}>
          {cell.val}
        </div>
      )

    return form
  }

  const body = rowData.map((val, ind) => {
    return (
      <DetailColumn index={index} key={ind} onChange={onChange}>
        {createCell(val, ind)}
      </DetailColumn>
    )
  })

  return (
    <tr key={index} data-rownum={index} className='tablerow'>
      {body}
    </tr>
  )
}

InputRow.propTypes = {
  index: PropTypes.number,
  rowData: PropTypes.array,
  onChange: PropTypes.func,
}
InputRow.defaultProps = {
  index: 0,
  rowData: [],
  onChange: () => {},
}

const DetailColumn = props => {
  const { index, children, onChange } = props

  const mapModel = inputs => ({ ...inputs })
  const formRef = useRef()

  return (
    <td key={index}>
      <Form
        onChange={model => onChange(model, index)}
        mapping={mapModel}
        ref={formRef}
      >
        {children}
      </Form>
    </td>
  )
}
