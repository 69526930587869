import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Grid } from 'semantic-ui-react'
import './sticky-grid.css'
import { BaseForm as Form } from 'component/base'

export const StickyGrid = props => {
  const { xy, height, style, gridWidth, children, className, ...other } =
    props || {}

  return (
    <div
      style={{
        overflowY: 'scroll',
        overflowX: xy ? 'scroll' : '',
        height,
      }}
    >
      <Grid
        celled
        className={`${xy ? 'sticky_grid_xy' : 'sticky_grid'} ${className}`}
        style={_.merge({}, style, gridWidth ? { width: gridWidth } : {})}
        {...other}
      >
        {children}
      </Grid>
    </div>
  )
}

StickyGrid.propTypes = {
  xy: PropTypes.bool,
  height: PropTypes.string,
}

StickyGrid.defaultProps = {
  xy: true,
  height: '70vh',
}

StickyGrid.HeaderRow = props => <HeaderRow {...props} />
StickyGrid.HeaderCell = props => <Cell {...props} />
StickyGrid.Row = props => <Row {...props} />
StickyGrid.FormedRow = props => <FormedRow {...props} />
StickyGrid.Cell = props => <Cell {...props} />

const HeaderRow = props => {
  const { className, ...other } = props || {}

  return (
    <Grid.Row className={`thead ${className ? className : ''}`} {...other} />
  )
}

const Cell = props => {
  const { fontSize, style, width, ...other } = props

  return (
    <Grid.Column
      style={_.merge({}, style, { width }, { fontSize })}
      {...other}
    />
  )
}

const Row = props => {
  const { className, ...other } = props

  return (
    <Grid.Row className={`tbody ${className ? className : ''}`} {...other} />
  )
}

const FormedRow = props => {
  const {
    data,
    handleChange,
    handleValid,
    handleInvalid,
    children,
    warningColor,
    ...other
  } = props

  const { id } = data || {}

  const ref = useRef()
  const [isValid, setIsValid] = useState(true)

  const mapModel = input => input

  const onValidSubmit = model =>
    handleChange({
      id,
      ...model,
    })

  const onValid = () => {
    setIsValid(true)
    handleValid(id)
  }

  const onInvalid = () => {
    setIsValid(false)
    handleInvalid(id)
  }

  const onBlur = () => ref.current.submit()

  return (
    <Form
      onValidSubmit={onValidSubmit}
      onValid={onValid}
      onInvalid={onInvalid}
      mapping={mapModel}
      ref={ref}
      gridRowMode
      onBlur={onBlur}
      style={{ backgroundColor: isValid ? '' : warningColor }}
      {...other}
    >
      {children}
    </Form>
  )
}

FormedRow.propTypes = {
  data: PropTypes.object,
  handleChange: PropTypes.func,
  handleValid: PropTypes.func,
  handleInvalid: PropTypes.func,
  warningColor: PropTypes.string,
}

FormedRow.defaultProps = {
  data: {},
  handleChange: () => {},
  handleValid: () => {},
  handleInvalid: () => {},
  warningColor: '#DB2828B3',
}
