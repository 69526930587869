import React, { Component } from 'react'
import Standard from './Standard'

class Secondary extends Component {
  render = () => {
    return <Standard {...this.props} secondary />
  }
}

export default Secondary
