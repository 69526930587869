import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'
import queryString from 'query-string'

export const HojoKamoku = props => {
  const { kamoku_id, ...other } = props

  return (
    <>
      {kamoku_id ? (
        <ListBase
          url={`/api/staff/master/hojo_kamoku?${queryString.stringify({
            kamoku_id,
          })}`}
          {...other}
        />
      ) : (
          <Form.Dropdown.WithAPI
            url='/api/staff/master/hojo_kamoku'
            keyName='id'
            valueName='id'
            textName='hojo_kamoku_name'
            {...other}
          />
        )}
    </>
  )
}

HojoKamoku.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
  is_member: PropTypes.bool,
}

HojoKamoku.defaultProps = {
  name: 'hojo_kamoku_id',
  label: '補助科目',
  value: undefined,
}

const ListBase = props => {
  const { url, ...other } = props

  return (
    <Form.Dropdown.WithAPI
      url={url}
      keyName='id'
      valueName='id'
      textName='hojo_kamoku_name'
      {...other}
    />
  )
}
