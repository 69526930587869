import React, { useState, useRef, useEffect }  from 'react'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import {
  Button,
  BaseForm as Form,
  Grid,
} from 'component/base'
import { createMessage } from 'graphql/mutations'

const gplCreateMessage = gql(createMessage)

export const MessageForm = props => {
  const { conversationId } = props
  const formRef = useRef()

  const [message, setMessage] = useState('')

  useEffect(()=>{
    setMessage('')
  }, [conversationId])

  const [mutate] = useMutation(gplCreateMessage)

  const sendMessage = () => {
    mutate({
      variables:{
        content: message,
        conversationId: conversationId
      }
    })
  }

  return (
    <>
    <Form
      id='1234'
      mapping={inputs => ({ ...inputs })}
      ref={formRef}
      onValidSubmit={()=>{}}
      onValid={() => {}}
      onInvalid={() => {}}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <Form.Textbox.AutoHeightTextArea
              name='message'
              value={message}
              rows={1}
              textAreaMode
              onChange={(_e, { value }) => setMessage(value)}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <Button.Group>
              <Button
                icon="paper plane"
                content='送信'
                primary
                onClick={sendMessage}
              />
            </Button.Group>
          </Grid.Column>
        </Grid.Row> 
      </Grid>
    </Form>
    </>
  )
}
