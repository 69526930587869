import React, { useState, useMemo, useEffect } from 'react'
import _ from 'lodash'
import Standard from './Standard'

const AutoHeightTextArea = props => {
  const { rows, value, style, onBlur, onChange, onFocus, ...other } = props

  const [currentValue, setCurrentValue] = useState(value)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const line_count = useMemo(() => {
    const line = currentValue ? currentValue.match(/\r\n|\n/g) : null
    return line ? line.length + 1 : rows
  }, [currentValue])

  const visible_line_count = useMemo(() => (focused ? line_count : rows), [
    line_count,
    focused,
  ])

  const handleOnFocus = e => {
    onFocus && onFocus(e)
    setFocused(true)
  }

  const handleOnBlur = (e, data, valid) => {
    onBlur && onBlur(e, data, valid)
    setFocused(false)
  }

  const handleOnChange = (e, data, valid) => {
    onChange && onChange(e, data, valid)
    const { value } = data || {}
    setCurrentValue(value)
  }

  return (
    <Standard
      {...other}
      textAreaMode
      rows={visible_line_count}
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      value={value}
      style={_.merge(
        {},
        {
          backgroundColor: line_count > 1 ? 'aliceblue' : '',
          resize: 'none',
        },
        style
      )}
    />
  )
}

AutoHeightTextArea.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
}

export default AutoHeightTextArea
