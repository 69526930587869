import React from 'react'
import PropTypes from 'prop-types'
import { Step } from 'component/base'
import Breadcrumbs from 'react-router-dynamic-breadcrumbs'
import routes from 'config/routes.json'
import './style.css'

export const Breadcrumb = props => {
  const { mobile, disabled } = props || {}

  return (
    <div
      className={mobile ? 'mobile-breadcrumb' : ''}
      style={{
        display: disabled ? 'none' : null,
      }}
    >
      <Breadcrumbs
        mappedRoutes={routes}
        WrapperComponent={props => (
          <Step.Group
            size='mini'
            attached='top'
            unstackable
            className='breadcrumb-group'
          >
            {props.children}
          </Step.Group>
        )}
        ActiveLinkComponent={props => (
          <Step link active>
            {props.children}
          </Step>
        )}
        LinkComponent={props => <Step link>{props.children}</Step>}
      />
    </div>
  )
}

Breadcrumb.propTypes = {
  mobile: PropTypes.bool,
}
Breadcrumb.defaultProps = {
  mobile: false,
}
