import { useState, useEffect, useMemo } from 'react'
import { Axios } from 'component/base'

export const useKumiaiinHaishaSettei = (
  kumiaiin_id,
  kumiaiin_tekiyo_kikan_id
) => {
  const BASE_PATH = useMemo(
    () =>
      `/api/staff/kumiaiin/kumiaiin/${kumiaiin_id}/kumiaiin_tekiyo_kikan/${kumiaiin_tekiyo_kikan_id}/kumiaiin_haisha_settei`,
    [kumiaiin_id, kumiaiin_tekiyo_kikan_id]
  )

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const ajax = async path => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(path, {})
    if (error) {
      setLoading(false)
      return
    }
    setData(data || {})
    setLoading(false)
  }

  useEffect(() => {
    ajax(BASE_PATH)
  }, [BASE_PATH])

  return {
    data,
    loading,
  }
}
