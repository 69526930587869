import React, { useState, cloneElement, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Sidebar, Grid } from 'component/base'
import { SearchSidebar } from './SearchSidebar'
import { SearchNav } from './SearchNav'
import './style.css'

export const ConditionWrapper = props => {
  const {
    onConditionChange,
    FormElement,
    defaultCondition: _defaultCondition,
    condition: _condition,
    children,
    enableSidebar,
    loadOnMount,
    compact,
    maxHeight,
    extraContent,
    size,
  } = props

  const [isOnLoad, setIsOnLoad] = useState(true)
  const [showSidebar, setShowSidebar] = useState(false)
  const [condition, setCondition] = useState({})
  const [defaultCondition, setDefaultCondition] = useState({})
  const [reset, setReset] = useState(0)

  useEffect(() => {
    setDefaultCondition(_defaultCondition)
  }, [_defaultCondition])

  useEffect(() => {
    setCondition(_condition)
  }, [_condition])

  const merged_condition = useMemo(
    () =>
      isOnLoad
        ? _.assign({}, defaultCondition, condition)
        : _.assign({}, condition),
    [isOnLoad, condition, defaultCondition]
  )

  const handleConditionSubmit = model => {
    setIsOnLoad(false)
    onConditionChange(
      _.mapValues(model, value =>
        _.isArray(value)
          ? value.length !== 0
            ? value.length === 1 && value[0] === undefined
              ? null
              : value
            : null
          : _.isBoolean(value)
          ? value
          : value && value !== ''
          ? value
          : null
      )
    )
    setReset(reset => ++reset)
    setShowSidebar(false)
  }

  const handleClearClick = () => {
    onConditionChange(defaultCondition || {}, true)
    setReset(reset => ++reset)
  }

  return (
    <>
      <Sidebar.Pushable>
        {enableSidebar && showSidebar && (
          <SearchSidebar
            key={reset}
            visible={showSidebar}
            onSubmit={handleConditionSubmit}
            onClear={handleClearClick}
            onCancel={() => setShowSidebar(false)}
            condition={merged_condition}
            FormElement={FormElement}
            compact={compact}
          />
        )}

        <Sidebar.Pusher dimmed={showSidebar}>
          <Grid>
            <Grid.Row
              style={{
                paddingBottom: '0px',
                minHeight: enableSidebar ? '100px' : 'unset',
              }}
            >
              <Grid.Column>
                {!showSidebar && (
                  <SearchNav
                    key={reset}
                    onSubmit={handleConditionSubmit}
                    onClear={handleClearClick}
                    condition={merged_condition}
                    FormElement={FormElement}
                    enableSidebar={enableSidebar}
                    showSidebar={showSidebar}
                    setShowSidebar={setShowSidebar}
                    compact={compact}
                    maxHeight={maxHeight}
                    extraContent={extraContent}
                    size={size}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 0 }}>
              <Grid.Column>
                {/* expect DataTable Element */}
                {cloneElement(children, {
                  ...children.props,
                  suppressRendering: !loadOnMount,
                })}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  )
}

ConditionWrapper.propTypes = {
  onConditionChange: PropTypes.func,
  FormElement: PropTypes.func,
  condition: PropTypes.object,
  children: PropTypes.node,

  // enable/disable sidebar contents
  // whhen false, all of search items will shown on nav
  enableSidebar: PropTypes.bool,

  // suppress data rows rendering.
  // this for canceling onMount loading date
  loadOnMount: PropTypes.bool,

  compact: PropTypes.bool,
  maxHeight: PropTypes.string,
  extraContent: PropTypes.object,
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'huge', 'massive']),
}

ConditionWrapper.defaultProps = {
  onConditionChange: () => {},
  FormElement: () => {},
  condition: {},
  children: <></>,
  enableSidebar: true,
  loadOnMount: true,
  compact: true,
  maxHeight: undefined,
  extraContent: undefined,
  size: 'small',
}
