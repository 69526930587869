import _ from 'lodash'

// 111,111 を 111111 に変換
export const moneyToInt = val => {
  if (!val || val === '' || val === '0') return 0
  if (val.toString().match(/^[-0-9,]+$/)) {
    return parseInt(val.toString().replace(/,/g, ''))
  }
  return 0
}

export const intToMoney = val => {
  const i = _.toInteger(val)
  return i.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
}

export const ymdToStr = val => {
  if (!val) return ''
  return val.toString().replace(/\//g, '')
}

export const base64ToBlob = (base64, type = 'image/png') => {
  const bin = atob(base64.split(',')[1])
  const buffer = new Uint8Array(bin.length)
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i)
  }
  return new Blob([buffer.buffer], { type })
}
