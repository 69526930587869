/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allMessage = /* GraphQL */ `
  query AllMessage($after: String, $conversationId: ID!, $first: Int) {
    allMessage(after: $after, conversationId: $conversationId, first: $first) {
      author {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      content
      conversation {
        createdAt
        id
        name
        systemNoticeCode
      }
      conversationId
      createdAt
      data {
        url
        web
      }
      id
      isSent
      recipient {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      sender
    }
  }
`;
export const allMessageConnection = /* GraphQL */ `
  query AllMessageConnection(
    $after: String
    $conversationId: ID!
    $first: Int
  ) {
    allMessageConnection(
      after: $after
      conversationId: $conversationId
      first: $first
    ) {
      messages {
        author {
          displayName
          id
          isSystem
          realm
          registered
          username
        }
        content
        conversation {
          createdAt
          id
          name
          systemNoticeCode
        }
        conversationId
        createdAt
        data {
          url
          web
        }
        id
        isSent
        recipient {
          displayName
          id
          isSystem
          realm
          registered
          username
        }
        sender
      }
      nextToken
    }
  }
`
export const allMessageFrom = /* GraphQL */ `
  query AllMessageFrom(
    $after: String
    $conversationId: ID!
    $first: Int
    $sender: String!
  ) {
    allMessageFrom(
      after: $after
      conversationId: $conversationId
      first: $first
      sender: $sender
    ) {
      author {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      content
      conversation {
        createdAt
        id
        name
        systemNoticeCode
      }
      conversationId
      createdAt
      data {
        url
        web
      }
      id
      isSent
      recipient {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      sender
    }
  }
`;
export const allUser = /* GraphQL */ `
  query AllUser($after: String, $first: Int) {
    allUser(after: $after, first: $first) {
      conversations {
        nextToken
      }
      deviceToken {
        provider
        token
      }
      displayName
      id
      isSystem
      messages {
        nextToken
      }
      realm
      registered
      username
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($conversationId: String!) {
    getConversation(conversationId: $conversationId) {
      createdAt
      id
      messages {
        nextToken
      }
      name
      systemNoticeCode
      userConversations {
        conversationId
        lastReadTime
        unreadCount
        userId
      }
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($conversationId: String!, $createdAt: Int) {
    getMessage(conversationId: $conversationId, createdAt: $createdAt) {
      author {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      content
      conversation {
        createdAt
        id
        name
        systemNoticeCode
      }
      conversationId
      createdAt
      data {
        url
        web
      }
      id
      isSent
      recipient {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      sender
    }
  }
`;
export const getSystemConversations = /* GraphQL */ `
  query GetSystemConversations($from: String!, $to: String!) {
    getSystemConversations(from: $from, to: $to) {
      conversation {
        createdAt
        id
        name
        systemNoticeCode
      }
      conversationId
      from
      to
    }
  }
`;
export const getUserByUsername = /* GraphQL */ `
  query GetUserByUsername($realm: String!, $username: String!) {
    getUserByUsername(realm: $realm, username: $username) {
      conversations {
        nextToken
      }
      deviceToken {
        provider
        token
      }
      displayName
      id
      isSystem
      messages {
        nextToken
      }
      realm
      registered
      username
    }
  }
`;
export const me = /* GraphQL */ `
  query Me {
    me {
      conversations {
        nextToken
      }
      deviceToken {
        provider
        token
      }
      displayName
      id
      isSystem
      messages {
        nextToken
      }
      realm
      registered
      username
    }
  }
`;
