import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ResizableTable } from './ResizableTable'
import { useLocalStorage } from 'component/hook'

export const SortableTable = props => {
  const {
    data,
    name,
    tableHeader: TableHeader,
    defaultSortColumnName,
    defaultSortDirection,
    customSort,
    ...other
  } = props

  const { localState: state, setLocalState: setState } = useLocalStorage(
    `datatable-${name}-sort`,
    {
      sortColumn: defaultSortColumnName || 'id',
      sortDirection: defaultSortDirection || 'ascending',
    }
  )

  const { sortColumn, sortDirection } = state || {}

  const handleSort = clickedColumnName => {
    setState({
      sortColumn: clickedColumnName,
      sortDirection:
        sortColumn !== clickedColumnName
          ? 'ascending'
          : sortDirection === 'ascending'
          ? 'descending'
          : 'ascending',
    })
  }

  const sortedData = useMemo(
    () =>
      customSort
        ? customSort(data, sortColumn, sortDirection)
        : _.orderBy(
            data || [],
            [sortColumn],
            [sortDirection.replace('ending', '')]
          ),
    [data, sortColumn, sortDirection]
  )

  const HeaderWithProps = useCallback(
    props => (
      <TableHeader
        {...props}
        column={sortColumn}
        direction={sortDirection}
        handleSort={handleSort}
      />
    ),
    [sortColumn, sortDirection, handleSort]
  )

  return (
    <>
      <ResizableTable
        {...other}
        name={name}
        tableHeader={HeaderWithProps}
        data={sortedData}
      />
    </>
  )
}

SortableTable.propTypes = {
  data: PropTypes.array,
  tableHeader: PropTypes.func.isRequired,
  customSort: PropTypes.func,
}

SortableTable.defaultProps = {
  data: [],
  customSort: undefined,
}
