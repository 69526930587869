import React, { cloneElement, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withFormsy } from '@akihirotakamura/formsy-react'
import { Form, Search } from 'semantic-ui-react'

const FormsySearch = props => {
  const {
    setValue,
    onSearchChange,
    instantValidation,
    onBlur,

    id,
    inputAs: InputAs,
    inputClassName,
    required,
    label,
    defaultValue,
    getValue,
    isValid,
    isPristine,
    getErrorMessage,
    errorLabel,
    isFormSubmitted,
    value,
    outsideLabel,
    // Form.Field props
    as,
    width,
    className,
    disabled,
    inline,
    passRequiredToField,
    style,
    labelPosition,
    size,
    suppressLabel,
  } = props

  const [allowError, setAllowError] = useState(false)

  useEffect(() => {
    if (defaultValue) setValue(defaultValue)
  }, [])

  useEffect(() => {
    setValue(value)
  }, [value])

  useEffect(() => {
    setAllowError(isFormSubmitted() ? true : false)
  }, [isFormSubmitted()])

  const error = useMemo(() => !isPristine() && !isValid() && allowError, [
    isPristine(),
    isValid(),
    allowError,
  ])

  const handleChange = (e, data) => {
    const { value } = data
    setValue(value)
    if (onSearchChange) onSearchChange(e, data)
    if (instantValidation) setAllowError(true)
  }

  const handleBlur = (e, data) => {
    setAllowError(true)
    if (onBlur) onBlur(e, data)
  }

  return (
    <>
      {
        <Form.Field
          as={as}
          className={className}
          required={required && passRequiredToField}
          error={!disabled && error}
          width={width}
          inline={inline}
          disabled={disabled}
          style={_.assign(
            {},
            {
              width: '100%',
              position: 'relative',
            },
            style
          )}
        >
          {outsideLabel ? (
            <>
              {label && <label htmlFor={id}>{label}</label>}
              <InputAs
                {...filterSuirElementProps(props)}
                id={id}
                value={getValue() || (isPristine() && defaultValue) || ''}
                onSearchChange={handleChange}
                onBlur={handleBlur}
                className={inputClassName}
                style={{ width: '100%' }}
              />
              {!disabled &&
                error &&
                errorLabel &&
                !suppressLabel &&
                cloneElement(errorLabel, {}, getErrorMessage())}
            </>
          ) : (
            <div
              className={`ui labeled input ${size && size} ${
                labelPosition && labelPosition
              }`}
            >
              {label && labelPosition !== 'right' ? (
                _.isString(label) ? (
                  <div className='ui label'>{label}</div>
                ) : (
                  cloneElement(label, {})
                )
              ) : (
                <></>
              )}
              <InputAs
                {...filterSuirElementProps(props)}
                id={id}
                value={getValue() || (isPristine() && defaultValue) || ''}
                onSearchChange={handleChange}
                onBlur={handleBlur}
                className={inputClassName}
                style={{ width: '100%' }}
              />
              {label && labelPosition === 'right' ? (
                _.isString(label) ? (
                  <div className='ui label'>{label}</div>
                ) : (
                  cloneElement(label, {})
                )
              ) : (
                <></>
              )}

              {!disabled &&
                error &&
                errorLabel &&
                !suppressLabel &&
                cloneElement(errorLabel, {}, getErrorMessage())}
            </div>
          )}
        </Form.Field>
      }
    </>
  )
}

export default withFormsy(FormsySearch)

const filterSuirElementProps = props => {
  const {
    as, // eslint-disable-line
    instantValidation, // eslint-disable-line
    error, // eslint-disable-line
    defaultChecked, // eslint-disable-line
    defaultSelected, // eslint-disable-line
    rootClassName, // eslint-disable-line
    rootStyle, // eslint-disable-line
    defaultValue, // eslint-disable-line
    rootElement, // eslint-disable-line
    errorLabel, // eslint-disable-line
    formRadioGroup, // eslint-disable-line
    getValue, // eslint-disable-line
    isPristine, // eslint-disable-line
    isValid, // eslint-disable-line
    getErrorMessage, // eslint-disable-line
    setValidations, // eslint-disable-line
    setValue, // eslint-disable-line
    resetValue, // eslint-disable-line
    hasValue, // eslint-disable-line
    getErrorMessages, // eslint-disable-line
    isFormDisabled, // eslint-disable-line
    isFormSubmitted, // eslint-disable-line
    isRequired, // eslint-disable-line
    showRequired, // eslint-disable-line
    showError, // eslint-disable-line
    isValidValue, // eslint-disable-line
    validations, // eslint-disable-line
    validationError, // eslint-disable-line
    validationErrors, // eslint-disable-line
    width, // eslint-disable-line
    passRequiredToField, // eslint-disable-line
    inputAs, // eslint-disable-line
    innerRef, //eslint-disable-line
    inline, // eslint-disable-line
    label,
    outsideLabel,
    labelPosition,
    size,
    suppressLabel,
    ...suirProps
  } = props

  return suirProps
}

FormsySearch.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.number,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  passRequiredToField: PropTypes.bool,
  inputAs: PropTypes.oneOf([Search, Form.Input]),
  errorLabel: PropTypes.element,
  required: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  instantValidation: PropTypes.bool,
  defaultValue: PropTypes.string,
  onBlur: PropTypes.func,
  isValid: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func,
  isPristine: PropTypes.func.isRequired,
  getErrorMessage: PropTypes.func.isRequired,
  validationError: PropTypes.string,
  validationErrors: PropTypes.object,
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  suppressLabel: PropTypes.bool,
}

FormsySearch.defaultProps = {
  inputAs: Search,
  passRequiredToField: true,
  suppressLabel: false,
}
