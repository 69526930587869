import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Dimmer, Loader, Axios, useKeycloak, notice } from 'component/base'
import { useLocalStorage } from 'component/hook'

export const TokuisakiFilter = props => {
  const { children } = props

  const { keycloak } = useKeycloak()

  const { busy, localState, setLocalState } = useLocalStorage(
    'user-active-info'
  )

  const { preferred_username } = localState || {}

  const [loading, setLoading] = useState(true)

  const callApi = async preferred_username => {
    const { data, error } = await Axios.ajax.get(
      '/api/tokuisaki/master/tokuisaki',
      {}
    )
    if (error || _.isEmpty(data)) {
      const { status, statusText } = error || {}

      notice({
        title: `Error ${status ? status : ''}`,
        description: `得意先マスタが読み込めませんでした\n 得意先コード:${preferred_username}\n ${
          statusText ? statusText : ''
        }`,
        type: 'error',
        time: 0,
      })
      setLoading(false)
      return
    }

    _.isEmpty(data)
      ? setLocalState(state => _.assign({}, state, { tokuisaki: {} }))
      : setLocalState(state => _.assign({}, state, { tokuisaki: data }))
    setLoading(false)
  }

  useEffect(() => {
    if (!preferred_username || !keycloak.hasRealmRole('tokuisaki')) {
      setLoading(false)
      return
    }
    callApi(preferred_username)
  }, [])

  return (
    <>
      {busy || loading ? (
        <Dimmer active page blurring='true'>
          <Loader>Initializing Tokuisaki Information...</Loader>
        </Dimmer>
      ) : (
        children
      )}
    </>
  )
}
