import React from 'react'
import { Header, Dimmer, Icon } from 'semantic-ui-react'

export const PageDimmer = props => {
  const { active, content, icon } = props

  return (
    <Dimmer page active={active}>
      <Header as='h2' icon inverted>
        <Icon loading name={icon} />
        {content}
      </Header>
    </Dimmer>
  )
}

PageDimmer.defaultProps = {
  active: false,
  content: 'Loading...',
  icon: 'spinner',
}
