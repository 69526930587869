import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Label,
  Popup,
  Text,
} from 'component/base'
import { Custom } from 'component/pieces'

export const Getsujishime = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/buppan/uriage_seikyu/list',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='kumiaiinMail'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Getsujishime.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Getsujishime.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'is_shime' ? direction : null}
        onClick={() => handleSort('is_shime')}
        content='状態'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shime_date' ? direction : null}
        onClick={() => handleSort('shime_date')}
        content='締年月日'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'busho_name' ? direction : null}
        onClick={() => handleSort('busho_name')}
        content='部署名'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
        content='販売先'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zeinuki_gaku' ? direction : null}
        onClick={() => handleSort('zeinuki_gaku')}
        content='税抜額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zei_gaku' ? direction : null}
        onClick={() => handleSort('zei_gaku')}
        content='消費税額'
      />
      <Table.HeaderCell style={{ width: '10.0rem' }} content='請求額' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'choseigo_zeinuki_gaku' ? direction : null}
        onClick={() => handleSort('choseigo_zeinuki_gaku')}
        content='調整後税抜額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'choseigo_zei_gaku' ? direction : null}
        onClick={() => handleSort('choseigo_zei_gaku')}
        content='調整後消費税額'
      />
      <Table.HeaderCell style={{ width: '10.0rem' }} content='調整後請求額' />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    is_shime,
    shime_date,
    busho_name,
    torihikisaki_name,
    zei_gaku,
    zeinuki_gaku,
    seikyu_gaku,
    choseigo_zeinuki_gaku,
    choseigo_zei_gaku,
    choseigo_seikyu_gaku,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          is_shime ? (
            <Popup
              content='請求締めが行われているので変更するには締めを解除してください'
              trigger={<Label color='orange' content='締め済' />}
            />
          ) : (
            <Popup
              content='調整入力・請求の削除が可能です'
              trigger={<Label content='操作可能' />}
            />
          )
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={shime_date} />
      <Table.Cell style={{ width: '15.0rem' }} content={busho_name} />
      <Table.Cell style={{ width: '15.0rem' }} content={torihikisaki_name} />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zeinuki_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={seikyu_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={choseigo_zeinuki_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={choseigo_zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={choseigo_seikyu_gaku} />}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const { shime_date_from, shime_date_to, busho_id } = condition

  return (
    <>
      <Grid.Column>
        <Form.Textbox.DatePicker
          value={shime_date_from}
          label='締年月日FROM'
          name='shime_date_from'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          value={shime_date_to}
          label='締年月日TO'
          name='shime_date_to'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Busho
          value={busho_id}
          label='部署'
          name='busho_id'
          enableAuthValue={false}
          clearable
        />
      </Grid.Column>
    </>
  )
}
