import React from 'react'
import { Grid, Header, Button, useKeycloak } from 'component/base'
import { getConfig } from 'config'

export const AccessDenied = () => {
  const { keycloak } = useKeycloak()

  return (
    <>
      <Grid
        textAlign='center'
        style={{ height: '100vh' }}
        verticalAlign='middle'
      >
        <Grid.Column style={{ maxWidth: '50vw' }}>
          <Header
            as='h1'
            textAlign='center'
            image='/images/akabou-sorry.png'
            content='Access Denied'
            subheader='このページを表示する権限がありません'
            style={{ fontSize: '5em' }}
          />
          <Button
            basic
            primary
            icon='sign-out'
            content='サインアウト'
            onClick={() => {
              keycloak.logout({
                redirectUri: getConfig().top_url,
              })
            }}
          />
        </Grid.Column>
      </Grid>
    </>
  )
}
