import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'semantic-ui-react'
import { useLocalStorage } from 'component/hook'
import { DataTableSkeleton } from './DataTableSkeleton'
import { PageLimitSelector } from './PageLimitSelector'
import { PaginationDisplay } from './PaginationDisplay'

export const PaginativeTable = props => {
  const { name, data, defaultPageLimit, ...other } = props

  const { localState: state, setLocalState: setState } = useLocalStorage(
    `datatable-${name}-paging`,
    {
      pageLimit: defaultPageLimit,
    }
  )

  const { pageLimit } = state || {}

  const [activePage, setActivePage] = useState(1)

  const totalPages = useMemo(() => Math.ceil(data.length / pageLimit), [
    data.length,
    pageLimit,
  ])

  const substanceActivePage = useMemo(
    () => (activePage > totalPages ? totalPages : activePage),
    [activePage, totalPages]
  )

  const start = useMemo(
    () =>
      (substanceActivePage - 1) * pageLimit < 0
        ? 0
        : (substanceActivePage - 1) * pageLimit,
    [substanceActivePage, pageLimit]
  )

  const end = useMemo(() => start + pageLimit, [start, pageLimit])

  const onPageChange = (e, param) => {
    const { activePage } = param
    setActivePage(activePage)
  }

  const onLimitChange = (e, data) =>
    setState({
      pageLimit: data.value,
    })

  return (
    <DataTableSkeleton
      {...other}
      pagination={
        <Pagination
          activePage={substanceActivePage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          boundaryRange={0}
          floated='right'
          pointing
          secondary
          size='mini'
        />
      }
      paginationDisplay={
        <PaginationDisplay total={data.length} start={start + 1} end={end} />
      }
      pageLimitSelector={
        <PageLimitSelector limit={pageLimit} onLimitChange={onLimitChange} />
      }
      name={name}
      data={data.slice(start, end)}
    />
  )
}

PaginativeTable.propTypes = {
  data: PropTypes.array,
  defaultPageLimit: PropTypes.number,
}

PaginativeTable.defaultProps = {
  data: [],
  defaultPageLimit: 10,
}
