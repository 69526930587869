import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import withProps from 'recompose/withProps'
import _ from 'lodash'
import {
  Axios,
  Segment,
  DataTable,
  Table,
  Text,
  Checkbox,
  Button,
} from 'component/base'
import { Custom } from 'component/pieces'

export const Find = withRouter(props => {
  const { mode, condition, onSelectChange, multiple, refresh } = props

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])

  const BASE_URL = useMemo(
    () =>
      mode === 'tokuisaki'
        ? `/api/staff/haisha/haishaseikyu/tokuisaki`
        : mode === 'kumiaiin'
        ? `/api/staff/haisha/haishaseikyu/kumiaiin`
        : mode === 'tatanso'
        ? `/api/staff/haisha/haishaseikyu/tatanso`
        : `/api/staff/haisha/haishaseikyu/tokuisaki`,
    [mode]
  )

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(BASE_URL, {
      params: {
        ...condition,
      },
    })
    setLoading(false)
    if (error) return
    setData(data)
  }

  // aggregate haisha data to seikyu data(tokuisaki aggr)
  const aggr_data = useMemo(
    () =>
      _(data)
        .groupBy('torihikisaki_id')
        .map((rows, key) => {
          const row = rows[0] || {}
          const zei_rate = row && row.zei_rate ? row.zei_rate : 0
          return {
            ...row,
            torihikisaki_id: key,
            kanryo_date_from: _.orderBy(rows, ['kanryo_date'], ['asc'])[0]
              .kanryo_date,
            kanryo_date_to: _.orderBy(rows, ['kanryo_date'], ['desc'])[0]
              .kanryo_date,
            haisha_count: rows.length,
            seikyu_gaku: _.sumBy(rows, 'seikyu_gaku'),
            seikyu_zei_gaku:
              row.zei_keisan_kbn === 'seikyu_soto'
                ? Math.floor(
                    (_.sumBy(rows, 'unchin_seikyu_gaku') *
                      _.toNumber(zei_rate)) /
                      100
                  )
                : _.sumBy(rows, 'seikyu_zei_gaku'),
            seikyu_zeikomi_gaku:
              row.zei_keisan_kbn === 'seikyu_soto'
                ? _.sumBy(rows, 'seikyu_gaku') +
                  Math.floor(
                    (_.sumBy(rows, 'unchin_seikyu_gaku') *
                      _.toNumber(zei_rate)) /
                      100
                  )
                : _.sumBy(rows, 'seikyu_zeikomi_gaku'),
            haisha_id_list: _(rows)
              .filter(row => row.haisha_id)
              .map(row => row.haisha_id)
              .value(),
            seikyu_shiharai_id_list: _(rows)
              .filter(row => row.seikyu_shiharai_id)
              .map(row => row.seikyu_shiharai_id)
              .value(),
          }
        })
        .value(),
    [data]
  )

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(aggr_data, row =>
        _.find(selectedIds, id => row.torihikisaki_id === id)
      )
    )
  }, [selectedIds])

  const handleAllSelect = () =>
    setSelectedIds(
      _(aggr_data)
        .map(row => row.torihikisaki_id)
        .value()
    )

  const handleAllDeSelect = () => setSelectedIds([])

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = withProps(props => ({
    ...props,
    handleChangeSelect,
  }))(TableRow)

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <DataTable
          name='haisha-seikyu_shime-find'
          sortable
          filterable
          tableHeight='50vh'
          tableHeader={TableHeader}
          tableRow={TableRowWithProps}
          data={aggr_data}
          topControl={
            <Button.Group>
              <Button content='全選択' onClick={handleAllSelect} />
              <Button content='全解除' onClick={handleAllDeSelect} />
            </Button.Group>
          }
          bottomControl={<></>}
          selectedIds={selectedIds}
        />
      </Segment>
    </>
  )
})

const TableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'haiso_center_name' ? direction : null}
        onClick={() => handleSort('haiso_center_name')}
        content='配送センター'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'iraisha_cd' ? direction : null}
        onClick={() => handleSort('iraisha_cd')}
        content='依頼者コード'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'iraisha_name' ? direction : null}
        onClick={() => handleSort('iraisha_name')}
        content='依頼者名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kanryo_date_from' ? direction : null}
        onClick={() => handleSort('kanryo_date_from')}
        content='完了日（最小）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kanryo_date_to' ? direction : null}
        onClick={() => handleSort('kanryo_date_to')}
        content='完了日（最大）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haisha_count' ? direction : null}
        onClick={() => handleSort('haisha_count')}
        content='配車票件数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zei_keisan_kbn' ? direction : null}
        onClick={() => handleSort('zei_keisan_kbn')}
        content='税計算区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zei_rate' ? direction : null}
        onClick={() => handleSort('zei_rate')}
        content='消費税率'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'seikyu_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_gaku')}
        content='税抜額計'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'seikyu_zei_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_zei_gaku')}
        content='消費税額計'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'seikyu_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_zeikomi_gaku')}
        content='請求額計'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    haiso_center_name,
    torihikisaki_id: id,
    iraisha_cd,
    iraisha_name,
    kanryo_date_from,
    kanryo_date_to,
    haisha_count,
    zei_keisan_kbn,
    zei_rate,
    seikyu_gaku,
    seikyu_zei_gaku,
    seikyu_zeikomi_gaku,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '15.0rem' }} content={haiso_center_name} />
      <Table.Cell style={{ width: '15.0rem' }} content={iraisha_cd} />
      <Table.Cell style={{ width: '25.0rem' }} content={iraisha_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={kanryo_date_from} />
      <Table.Cell style={{ width: '10.0rem' }} content={kanryo_date_to} />
      <Table.Cell style={{ width: '10.0rem' }} content={haisha_count} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.Zeikeisan value={zei_keisan_kbn} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={zei_rate} />
      <Table.Cell
        style={{
          width: '15.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={seikyu_gaku} />}
      />
      <Table.Cell
        style={{
          width: '15.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={seikyu_zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '15.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={seikyu_zeikomi_gaku} />}
      />
    </Table.Row>
  )
}
