import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import L from 'leaflet'
import { Marker } from 'react-leaflet'
import {
  Segment,
  BasicMap,
  OsrmPolyLine,
  OsrmMarker,
  Util,
} from 'component/base'

export const UnsotenMap = props => {
  const {
    baseLat,
    baseLng,
    goalLat,
    goalLng,
    loading,
    targets,
    zoom,
    distanceFirst,
    enableToll,
  } = props

  const Markers = useMemo(() => {
    const arr = []

    // base Marker
    if (baseLat && baseLng) {
      arr.push(
        <Marker
          key={Util.getRandomID()}
          position={[baseLat, baseLng]}
          icon={
            new L.Icon({
              iconUrl: '/images/hatchi.png', //指定アイコンのパス
              iconSize: [60, 60], // アイコンのサイズ
              iconAnchor: [30, 43], //指定座標とアイコンの表示相対位置
              popupAnchor: [0, -50], //地点からのポップアップの表示相対位置
              tooltipAnchor: [30, -15], //地点からのツールチップの表示相対位置
            })
          }
        />
      )
    }

    // goalMarker
    if (goalLat && goalLng) {
      arr.push(
        <Marker
          key={Util.getRandomID()}
          position={[goalLat, goalLng]}
          icon={
            new L.Icon({
              iconUrl: '/images/chakuchi.png', //指定アイコンのパス
              iconSize: [60, 60], // アイコンのサイズ
              iconAnchor: [30, 43], //指定座標とアイコンの表示相対位置
              popupAnchor: [0, -50], //地点からのポップアップの表示相対位置
              tooltipAnchor: [30, -15], //地点からのツールチップの表示相対位置
            })
          }
        />
      )
    }

    // target markers
    targets.forEach((target, i) => {
      const { latLng, iconUrl, tooltip, popup } = target || {}

      const [targetLat, targetLng] = latLng || []

      arr.push(
        <OsrmMarker
          key={i}
          latLng={[targetLat, targetLng]}
          iconUrl={iconUrl}
          tooltip={tooltip}
          popup={popup}
        />
      )
    })

    return arr
  }, [baseLat, baseLng, goalLat, goalLng, targets])

  const lines = useMemo(
    () =>
      _(targets)
        .map((target, i) => (
          <OsrmPolyLine
            key={i}
            baseLatLng={[baseLat, baseLng]}
            targetLatLng={target.latLng}
            color='blue'
            weight={6}
            opacity={0.5}
            distanceFirst={distanceFirst}
            enableToll={enableToll}
          />
        ))
        .concat(
          <OsrmPolyLine
            key={Util.getRandomID()}
            baseLatLng={[baseLat, baseLng]}
            targetLatLng={[goalLat, goalLng]}
            color='green'
            weight={8}
            opacity={1}
            distanceFirst={distanceFirst}
            enableToll={enableToll}
          />
        )
        .value(),
    [baseLat, baseLng, targets, goalLat, goalLng, distanceFirst, enableToll]
  )

  return (
    <Segment loading={loading} basic style={{ padding: 0 }}>
      {!loading && (
        <BasicMap
          latitude={baseLat}
          longitude={baseLng}
          zoom={zoom}
          markers={Markers}
          lines={lines}
          loading={loading}
          nowOnMarker={false}
        />
      )}
    </Segment>
  )
}

UnsotenMap.propTypes = {
  base: PropTypes.object,
  targets: PropTypes.array,
  zoom: PropTypes.number,
}

UnsotenMap.defaultProps = {
  base: {
    latLng: [0, 0],
  },
  targets: [
    {
      iconUrl: '/images/akabokun.png',
      tooltip: <p>no name</p>,
      popup: <p>no name</p>,
      latLng: [0, 0],
    },
  ],
  zoom: 15,
}
