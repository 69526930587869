import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import {
  Axios,
  BaseForm as Form,
  DataTable,
  Grid,
  Table,
  ConditionWrapper,
  Segment,
  Icon,
  Checkbox,
  Dropdown,
  Input,
  Label,
  Button,
} from 'component/base'
import { UnsotenGpsMap } from 'component/pieces'
import { Custom } from 'component/pieces'
import { useUserInfo } from 'component/hook'
import { getHikkoshiUrl } from 'config'

export const Haisha = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    allowAllCenter,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )
  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const { active_haiso_center_id } = useUserInfo()

  const defaultCondition = useMemo(() => {
    const from_date = moment().format('YYYY/MM/DD')
    const to_date = moment().format('YYYY/MM/DD')

    return {
      haiso_center_id: active_haiso_center_id,
      hikitori_date_from: from_date,
      hikitori_date_to: to_date,
      deleted: false,
    }
  }, [allowAllCenter])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  const [mapHaishaId, setMapHaishaId] = useState(null)

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
          ? [selectedId]
          : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition)
          .assign(query)
          .assign({ haiso_center_id: active_haiso_center_id })
          .value()
        : _(condition)
          .assign({ haiso_center_id: active_haiso_center_id })
          .value()
    )
  }, [enableQueryCondition, query, allowAllCenter])

  useEffect(() => {
    callApi(
      allowAllCenter
        ? _(condition).omit(['haiso_center_id']).value()
        : condition
    )
    setSelectedIds([])
  }, [condition, allowAllCenter, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/haisha/haisha/list',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return

    setData(data.map(record => ({
      ...record,
      formattedTorokuNo:
        typeof record.tekikaku_invoice_toroku_no === 'string'
          ? record.tekikaku_invoice_toroku_no.replace(/-/g, '')
          : ''
    })))
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
        allowAllCenter={allowAllCenter}
        setMapHaishaId={setMapHaishaId}
      />
    ),
    [history, handleChangeSelect, allowAllCenter, setMapHaishaId]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='haisha'
            size='mini'
            sortable
            filterable
            tableHeight='80vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            defaultSortColumnName='hikitori_date'
            defaultSortDirection='descending'
            customSort={(data, sortColumn, sortDirection) =>
              _(data)
                .orderBy(
                  [sortColumn, 'id'],
                  [sortDirection.replace('ending', ''), 'desc']
                )
                .value()
            }
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
        {mapHaishaId && (
          <UnsotenGpsMap
            haisha_id={mapHaishaId}
            onMapClose={() => setMapHaishaId(null)}
            data={{}}
          />
        )}
      </Segment>
    </>
  )
})

Haisha.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Haisha.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => { },
}

const TableHeader = props => {
  const { column, direction, withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'id' ? direction : null}
        onClick={() => handleSort('id')}
      >
        配車番号
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haiso_center_id' ? direction : null}
        onClick={() => handleSort('haiso_center_id')}
      >
        配送センター
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'haisha_jotai_kbn' ? direction : null}
        onClick={() => handleSort('haisha_jotai_kbn')}
      >
        状態
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'haisha_hassei_kbn' ? direction : null}
        onClick={() => handleSort('haisha_hassei_kbn')}
      >
        配車発生区分
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'iraisha_kbn' ? direction : null}
        onClick={() => handleSort('iraisha_kbn')}
      >
        依頼者区分
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'iraisha_name' ? direction : null}
        onClick={() => handleSort('iraisha_name')}
      >
        依頼者
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hikitori_date' ? direction : null}
        onClick={() => handleSort('hikitori_date')}
      >
        引取日
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'hikitori_time' ? direction : null}
        onClick={() => handleSort('hikitori_time')}
      >
        引取時間
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'hatchi_address1' ? direction : null}
        onClick={() => handleSort('hatchi_address1')}
      >
        発地
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'chakuchi_address1' ? direction : null}
        onClick={() => handleSort('chakuchi_address1')}
      >
        着地
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '11.0rem' }}
        sorted={column === 'web_kyusha_keijiban_id' ? direction : null}
        onClick={() => handleSort('web_kyusha_keijiban_id')}
      >
        掲示板
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '11.0rem' }}
        sorted={column === 'web_kyusha_mail_id' ? direction : null}
        onClick={() => handleSort('web_kyusha_mail_id')}
      >
        求車メール
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '11.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
      >
        運送店
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'formattedTorokuNo' ? direction : null}
        onClick={() => handleSort('formattedTorokuNo')}
      >
        適格請求書発行事業者登録番号
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kanryo_date' ? direction : null}
        onClick={() => handleSort('kanryo_date')}
      >
        完了日
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'kanryo_time' ? direction : null}
        onClick={() => handleSort('kanryo_time')}
      >
        完了時間
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'entry_user_name' ? direction : null}
        onClick={() => handleSort('entry_user_name')}
      >
        入力担当者
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'juchu_shubetsu_kbn' ? direction : null}
        onClick={() => handleSort('juchu_shubetsu_kbn')}
      >
        受注種別
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'seikyu_kbn' ? direction : null}
        onClick={() => handleSort('seikyu_kbn')}
      >
        請求区分
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'seikyusaki_kbn' ? direction : null}
        onClick={() => handleSort('seikyusaki_kbn')}
      >
        請求先区分
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'shukin_kbn' ? direction : null}
        onClick={() => handleSort('shukin_kbn')}
      >
        集金方法
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'seikyu_biko' ? direction : null}
        onClick={() => handleSort('seikyu_biko')}
      >
        請求備考
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={
          column === 'shiharai_unchin_gokei_zeikomi_gaku' ? direction : null
        }
        onClick={() => handleSort('shiharai_unchin_gokei_zeikomi_gaku')}
      >
        支払運賃（税込）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'shiharai_tatekaekin_gaku' ? direction : null}
        onClick={() => handleSort('shiharai_tatekaekin_gaku')}
      >
        支払立替金
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'shiharai_gokei_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('shiharai_gokei_zeikomi_gaku')}
      >
        支払合計（税込）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'haisha_tesuryo_rate' ? direction : null}
        onClick={() => handleSort('haisha_tesuryo_rate')}
      >
        配車手数料率
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'haisha_tesuryo_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('haisha_tesuryo_zeikomi_gaku')}
      >
        配車手数料（税込）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'shiharai_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('shiharai_zeikomi_gaku')}
      >
        支払金額（税込）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={
          column === 'seikyu_unchin_gokei_zeikomi_gaku' ? direction : null
        }
        onClick={() => handleSort('seikyu_unchin_gokei_zeikomi_gaku')}
      >
        請求運賃（税込）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'seikyu_tatekaekin_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_tatekaekin_gaku')}
      >
        請求立替金
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'seikyu_gokei_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_gokei_zeikomi_gaku')}
      >
        請求合計（税込）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'irai_tesuryo_rate' ? direction : null}
        onClick={() => handleSort('irai_tesuryo_rate')}
      >
        依頼手数料率
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'irai_tesuryo_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('irai_tesuryo_zeikomi_gaku')}
      >
        依頼手数料（税込）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'seikyu_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_zeikomi_gaku')}
      >
        請求金額（税込）
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'haisha_tesuryo_shuju' ? direction : null}
        onClick={() => handleSort('haisha_tesuryo_shuju')}
      >
        手数料収受
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'is_unprinted' ? direction : null}
        onClick={() => handleSort('is_unprinted')}
      >
        印刷状態
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'level_kbn_name' ? direction : null}
        onClick={() => handleSort('level_kbn_name')}
      >
        引越しランク
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'mitumori_irai_id' ? direction : null}
        onClick={() => handleSort('mitumori_irai_id')}
      >
        見積票
      </Table.HeaderCell>
    </Table.Row>
  )
}

const TableRow = props => {
  const {
    withOrder,
    history,
    data,
    selectedIds,
    handleChangeSelect,
    setMapHaishaId,
  } = props || {}

  const {
    id: haisha_id,
    haiso_center_name,
    haisha_jotai_kbn,
    iraisha_kbn,
    iraisha_name,
    hikitori_date,
    hikitori_time: _hikitori_time,
    hikitori_jikan_shitei_kbn,
    hatchi_address1,
    chakuchi_address1,
    unsoten_name,
    kanryo_date,
    kanryo_time,
    kanryo_jikan_shitei_kbn,
    juchu_shubetsu_kbn,
    seikyu_kbn,
    seikyusaki_kbn,
    shukin_kbn,
    seikyu_biko,
    shiharai_unchin_gokei_zeikomi_gaku,
    shiharai_tatekaekin_gaku,
    shiharai_gokei_zeikomi_gaku,
    haisha_tesuryo_rate,
    haisha_tesuryo_zeikomi_gaku,
    shiharai_zeikomi_gaku,
    seikyu_unchin_gokei_zeikomi_gaku,
    seikyu_tatekaekin_gaku,
    seikyu_gokei_zeikomi_gaku,
    irai_tesuryo_zeikomi_gaku,
    irai_tesuryo_rate,
    seikyu_zeikomi_gaku,
    haisha_tesuryo_shuju,
    entry_user_name,
    web_kyusha_keijiban_id,
    web_kyusha_mail_id,
    is_unprinted,
    haisha_sakujo_id,
    haisha_horyu_id,
    haisha_hassei_kbn,
    web_kyusha_id,
    upload_files_id,
    unsoten_cd,
    level_kbn_name,
    mitsumori_irai_id,
    formattedTorokuNo,
  } = data || {}


  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(haisha_id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  const hikkoshiUrl = getHikkoshiUrl()

  const hikitori_time = useMemo(
    () => (_hikitori_time ? _hikitori_time.substr(0, 5) : ''),
    [_hikitori_time]
  )

  return withOrder(
    <Table.Row
      positive={isSelected}
      onDoubleClick={() => {
        haisha_id
          ? history.push(`/staff/haisha/entry/edit/${haisha_id}`)
          : history.push(`/staff/haisha/web-require/${web_kyusha_id}/edit`)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox
            checked={isSelected}
            onChange={checkboxChange}
            value={haisha_id}
            fitted
            disabled={!haisha_id}
          />
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={haisha_id}
        title={haisha_id}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={haiso_center_name}
        title={haisha_id}
      />
      <Table.Cell
        style={{
          width: '8.0rem',
          backgroundColor: haisha_sakujo_id
            ? ''
            : haisha_horyu_id
              ? 'orange'
              : haisha_jotai_kbn === 20
                ? 'aquamarine'
                : haisha_jotai_kbn === 30
                  ? 'lightcyan'
                  : haisha_jotai_kbn === 40
                    ? 'gold'
                    : haisha_jotai_kbn === 100
                      ? 'lightpink'
                      : '',
        }}
        content={
          haisha_sakujo_id ? (
            '削除'
          ) : haisha_horyu_id ? (
            '保留'
          ) : (
            <>
              <Custom.Label.Jotai value={haisha_jotai_kbn} />
              {haisha_jotai_kbn === 80 && (
                <Button
                  primary
                  circular
                  size='mini'
                  icon='map marker alternate'
                  onClick={e => {
                    e.stopPropagation()
                    setMapHaishaId(haisha_id)
                  }}
                />
              )}
            </>
          )
        }
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={<Custom.Label.HaishaHasseiKbn value={haisha_hassei_kbn} />}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.TorihikisakiKbn value={iraisha_kbn} />}
      />
      <Table.Cell
        style={{ width: '16.0rem' }}
        content={iraisha_name}
        title={iraisha_name}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          backgroundColor:
            !hikitori_date || !kanryo_date
              ? ''
              : hikitori_date < kanryo_date
                ? 'mistyrose'
                : '',
        }}
        content={hikitori_date}
        title={hikitori_date}
      />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={
          <>
            {hikitori_time + ' '}
            {hikitori_jikan_shitei_kbn && (
              <Custom.Label.JikanShiteiKbn value={hikitori_jikan_shitei_kbn} />
            )}
          </>
        }
        title={hikitori_time}
      />
      <Table.Cell
        style={{ width: '16.0rem' }}
        content={hatchi_address1}
        title={hatchi_address1}
      />
      <Table.Cell
        style={{ width: '16.0rem' }}
        content={chakuchi_address1}
        title={chakuchi_address1}
      />
      <Table.Cell
        style={{ width: '11.0rem' }}
        content={
          web_kyusha_keijiban_id ? <Label icon='check' content='該当' /> : <></>
        }
      />
      <Table.Cell
        style={{ width: '11.0rem' }}
        content={
          web_kyusha_mail_id ? <Label icon='check' content='該当' /> : <></>
        }
      />
      <Table.Cell
        style={{ width: '11.0rem' }}
        content={
          <>
            <Custom.Image.KumiaiinAvator
              id={upload_files_id}
              kumiaiin_cd={unsoten_cd}
              avatar
            />
            {unsoten_name}
          </>
        }
        title={unsoten_name}
      />
      <Table.Cell
        style={{ width: '11.0rem' }}
        content={formattedTorokuNo}
        title={formattedTorokuNo}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={kanryo_date}
        title={kanryo_date}
      />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={
          <>
            {kanryo_time + ' '}
            {kanryo_time && kanryo_jikan_shitei_kbn && (
              <Custom.Label.JikanShiteiKbn value={kanryo_jikan_shitei_kbn} />
            )}
          </>
        }
        title={hikitori_time}
      />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={entry_user_name}
        title={entry_user_name}
      />
      <Table.Cell
        style={{ width: '6.0rem' }}
        content={<Custom.Label.JuchuShubetsu value={juchu_shubetsu_kbn} />}
      />
      <Table.Cell
        style={{ width: '6.0rem' }}
        content={<Custom.Label.Seikyu value={seikyu_kbn} />}
      />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={<Custom.Label.Seikyusaki value={seikyusaki_kbn} />}
      />
      <Table.Cell
        style={{ width: '6.0rem' }}
        content={<Custom.Label.Shukin value={shukin_kbn} />}
      />
      <Table.Cell
        style={{ width: '6.0rem' }}
        content={seikyu_biko}
        title={seikyu_biko}
      />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={shiharai_unchin_gokei_zeikomi_gaku}
      />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={shiharai_tatekaekin_gaku}
      />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={shiharai_gokei_zeikomi_gaku}
      />
      <Table.Cell style={{ width: '8.0rem' }} content={haisha_tesuryo_rate} />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={haisha_tesuryo_zeikomi_gaku}
      />
      <Table.Cell style={{ width: '7.0rem' }} content={shiharai_zeikomi_gaku} />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={seikyu_unchin_gokei_zeikomi_gaku}
      />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={seikyu_tatekaekin_gaku}
      />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={seikyu_gokei_zeikomi_gaku}
      />
      <Table.Cell style={{ width: '8.0rem' }} content={irai_tesuryo_rate} />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={irai_tesuryo_zeikomi_gaku}
      />
      <Table.Cell style={{ width: '7.0rem' }} content={seikyu_zeikomi_gaku} />
      <Table.Cell style={{ width: '7.0rem' }} content={haisha_tesuryo_shuju} />
      <Table.Cell
        style={{ width: '7.0rem' }}
        content={is_unprinted ? '未印刷' : '印刷済'}
      />
      <Table.Cell style={{ width: '7.0rem' }} content={level_kbn_name} />
      <Table.Cell style={{ width: '7.0rem' }}>
        {mitsumori_irai_id ? (
          <Button
            as='a'
            target='_blank'
            icon='address card'
            size='mini'
            content='見積票'
            primary
            href={`${hikkoshiUrl}/manage/${mitsumori_irai_id}`}
          ></Button>
        ) : (
          <></>
        )}
      </Table.Cell>
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const {
    pending: _pending,
    deleted: _deleted,
    unprinted: _unprinted,
    is_contain_draft: _is_contain_draft,
    is_hikkoshi_mitsumori: _is_hikkoshi_mitsumori,
  } = condition || {}

  const [date_mode, set_date_mode] = useState('hikitori_date')
  const [date_from, set_date_from] = useState(condition.hikitori_date_from)
  const [date_to, set_date_to] = useState(condition.hikitori_date_to)
  const [search_count, set_search_count] = useState(0)

  const [pending, set_pending] = useState()
  const [deleted, set_deleted] = useState()
  const [unprinted, set_unprinted] = useState()
  const [is_contain_draft, set_is_contain_draft] = useState()
  const [is_hikkoshi_mitsumori, set_is_hikkoshi_mitsumori] = useState()

  useEffect(() => {
    set_pending(_pending)
    set_deleted(_deleted)
    set_unprinted(_unprinted)
    set_is_contain_draft(_is_contain_draft)
    set_is_hikkoshi_mitsumori(_is_hikkoshi_mitsumori)
  }, [
    _pending,
    _deleted,
    _unprinted,
    _is_contain_draft,
    _is_hikkoshi_mitsumori,
  ])

  useEffect(() => {
    !!condition.kanryo_date_from || !!condition.kanryo_date_to
      ? set_date_mode('kanryo_date')
      : !!condition.uketsuke_date_from || !!condition.uketsuke_date_to
        ? set_date_mode('uketsuke_date')
        : set_date_mode('hikitori_date')

    set_search_count(count => ++count)
  }, [condition])

  useEffect(() => {
    if (date_mode === 'hikitori_date') {
      set_date_from(condition.hikitori_date_from)
      set_date_to(condition.hikitori_date_to)
    } else if (date_mode === 'uketsuke_date') {
      set_date_from(condition.uketsuke_date_from)
      set_date_to(condition.uketsuke_date_to)
    } else if (date_mode === 'kanryo_date') {
      set_date_from(condition.kanryo_date_from)
      set_date_to(condition.kanryo_date_to)
    }
  }, [search_count])

  return (
    <>
      <Grid.Column style={{ display: 'none' }}>
        <Custom.Dropdown.HaisoCenterCurrent
          value={condition.haiso_center_id}
          inputAs={Dropdown}
          label='配送センター'
          name='haiso_center_id'
          clearable
          fluid
        />
      </Grid.Column>

      {date_mode === 'hikitori_date' && (
        <>
          <Grid.Column>
            <Form.Textbox.DatePicker
              inputAs={Input}
              value={date_from}
              label={
                <Label>
                  <Icon
                    name='retweet'
                    onClick={() => {
                      set_date_mode('kanryo_date')
                    }}
                  />
                  引取日FROM
                </Label>
              }
              name='hikitori_date_from'
              onChange={(e, { value }) => set_date_from(value)}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Textbox.DatePicker
              inputAs={Input}
              value={date_to}
              label='引取日TO'
              name='hikitori_date_to'
              onChange={(e, { value }) => set_date_to(value)}
            />
          </Grid.Column>
        </>
      )}

      {date_mode === 'kanryo_date' && (
        <>
          <Grid.Column>
            <Form.Textbox.DatePicker
              inputAs={Input}
              value={date_from}
              label={
                <Label>
                  <Icon
                    name='retweet'
                    onClick={() => {
                      set_date_mode('uketsuke_date')
                    }}
                  />
                  完了日FROM
                </Label>
              }
              name='kanryo_date_from'
              onChange={(e, { value }) => set_date_from(value)}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Textbox.DatePicker
              inputAs={Input}
              value={date_to}
              label='完了日TO'
              name='kanryo_date_to'
              onChange={(e, { value }) => set_date_to(value)}
            />
          </Grid.Column>
        </>
      )}

      {date_mode === 'uketsuke_date' && (
        <>
          <Grid.Column>
            <Form.Textbox.DatePicker
              inputAs={Input}
              value={date_from}
              label={
                <Label>
                  <Icon
                    name='retweet'
                    onClick={() => {
                      set_date_mode('hikitori_date')
                    }}
                  />
                  受付日FROM
                </Label>
              }
              name='uketsuke_date_from'
              onChange={(e, { value }) => set_date_from(value)}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Textbox.DatePicker
              inputAs={Input}
              value={date_to}
              label='受付日TO'
              name='uketsuke_date_to'
              onChange={(e, { value }) => set_date_to(value)}
            />
          </Grid.Column>
        </>
      )}

      <Grid.Column>
        <Custom.Dropdown.Iraisha
          inputAs={Dropdown}
          value={condition.iraisha_id}
          label='依頼者'
          name='iraisha_id'
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={condition.iraisha_name}
          label='依頼者名'
          name='iraisha_name'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Phone
          inputAs={Input}
          value={condition.iraisha_tel}
          label='依頼者電話番号'
          name='iraisha_tel'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={condition.irai_tantosha_name}
          label='依頼担当者名'
          name='irai_tantosha_name'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={condition.id}
          label='配車番号'
          name='id'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Unsoten
          inputAs={Dropdown}
          value={condition.unsoten_id}
          label='運送店'
          name='unsoten_id'
          search
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={condition.hatchi_address1}
          label='発地'
          name='hatchi_address1'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={condition.chakuchi_address1}
          label='着地'
          name='chakuchi_address1'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.JuchuShubetsu
          inputAs={Dropdown}
          value={condition.juchu_shubetsu_kbn}
          label='受注種別'
          name='juchu_shubetsu_kbn'
          multiple
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.HaishaJotai
          inputAs={Dropdown}
          value={condition.haisha_jotai_kbn}
          label='配車状態'
          name='haisha_jotai_kbn'
          multiple
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.SeikyuKbn
          inputAs={Dropdown}
          value={condition.seikyu_kbn}
          label='請求区分'
          name='seikyu_kbn'
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Textbox.UserName
          inputAs={Input}
          value={condition.name}
          label='入力担当者'
          name='name'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.IraishaKbn
          inputAs={Dropdown}
          value={condition.iraisha_kbn}
          label='依頼者区分'
          name='iraisha_kbn'
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.UnsotenKbn
          inputAs={Dropdown}
          value={condition.unsoten_kbn}
          label='運送店区分'
          name='unsoten_kbn'
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          name='deleted'
          label={
            deleted === null || deleted === undefined
              ? '削除状態の指定なし'
              : deleted
                ? '削除状態のみを表示'
                : '削除状態以外を表示'
          }
          onChange={(e, { checked }) => set_deleted(checked)}
          size='mini'
          checked={condition.deleted}
          enableIndeterminate
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          name='pending'
          label={
            pending === null || pending === undefined
              ? '保留状態の指定なし'
              : pending
                ? '保留状態のみを表示'
                : '保留状態以外を表示'
          }
          onChange={(e, { checked }) => set_pending(checked)}
          size='mini'
          checked={condition.pending}
          enableIndeterminate
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          name='unprinted'
          label={
            unprinted === null || unprinted === undefined
              ? '未印刷の指定なし'
              : unprinted
                ? '未印刷状態のみを表示'
                : '印刷済を表示'
          }
          onChange={(e, { checked }) => set_unprinted(checked)}
          size='mini'
          checked={condition.unprinted}
          enableIndeterminate
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          name='is_contain_draft'
          label={
            is_contain_draft === null || is_contain_draft === undefined
              ? '配車ドラフトの指定なし'
              : is_contain_draft
                ? '配車ドラフトのみを表示'
                : '配車ドラフト以外を表示'
          }
          onChange={(e, { checked }) => set_is_contain_draft(checked)}
          size='mini'
          checked={condition.is_contain_draft}
          enableIndeterminate
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.LevelKbn
          inputAs={Dropdown}
          value={condition.level_kbn}
          label='引越しランク'
          name='level_kbn'
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          name='is_hikkoshi_mitsumori'
          label={
            is_hikkoshi_mitsumori === null ||
              is_hikkoshi_mitsumori === undefined
              ? '引越見積から連携の指定なし'
              : is_hikkoshi_mitsumori
                ? '引越見積から連携のみを表示'
                : '引越見積から連携以外を表示'
          }
          onChange={(e, { checked }) => set_is_hikkoshi_mitsumori(checked)}
          size='mini'
          checked={condition.is_hikkoshi_mitsumori}
          enableIndeterminate
        />
      </Grid.Column>
    </>
  )
}
