import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'

const Base = props => {
  const { url, area_id, ...other } = props

  return area_id ? (
    <Form.Dropdown.WithAPI
      url={url}
      keyName='id'
      valueName='id'
      textName='shikuchoson_name'
      {...other}
    />
  ) : (
      <Form.Dropdown.Standard {...other} />
    )
}

export const Shikuchoson = props => {
  const { area_id, ...other } = props

  return (
    <Base
      url={`/api/staff/master/shikuchoson?area_id=${area_id}`}
      area_id={area_id}
      {...other}
    />
  )
}

Shikuchoson.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
}

Shikuchoson.defaultProps = {
  name: 'shikuchoson_id',
  label: '市区町村',
  value: undefined,
}

export const ShikuchosonAll = props => {
  const { area_id, ...other } = props

  return (
    <Base
      url={`/api/staff/master/shikuchoson_all?area_id=${area_id}`}
      area_id={area_id}
      {...other}
    />
  )
}

ShikuchosonAll.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
}

ShikuchosonAll.defaultProps = {
  name: 'shikuchoson_id',
  label: '市区町村（全）',
  value: undefined,
}
