import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import {
  Axios,
  BaseForm as Form,
  Checkbox,
  DataTable,
  Grid,
  Table,
  ConditionWrapper,
  Segment,
  Label,
  Button,
} from 'component/base'
import { Custom } from 'component/pieces'
import { useUserInfo } from 'component/hook'

export const WebRequire = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const { active_haiso_center_id } = useUserInfo()

  const defaultCondition = useMemo(() => {
    return {
      haiso_center_id: active_haiso_center_id,
      koshin_datetime_from: moment().format('YYYY/MM/DD'),
      koshin_datetime_to: moment().format('YYYY/MM/DD'),
    }
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/haisha/webrequire/list',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
        >
          <DataTable
            name='webrequire'
            size='mini'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

WebRequire.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

WebRequire.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { column, direction, withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }}>#</Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'id' ? direction : null}
        onClick={() => handleSort('id')}
      >
        求車ID
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'iraisha_name' ? direction : null}
        onClick={() => handleSort('iraisha_name')}
      >
        依頼者
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hikiuke_daisu' ? direction : null}
        onClick={() => handleSort('hikiuke_daisu')}
      >
        引受台数
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
      >
        担当店
      </Table.HeaderCell>
      <Table.HeaderCell style={{ width: '10.0rem' }}></Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'level_kbn_name' ? direction : null}
        onClick={() => handleSort('level_kbn_name')}
      >
        引越しランク
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'web_kyusha_keijiban_id' ? direction : null}
        onClick={() => handleSort('web_kyusha_keijiban_id')}
      >
        掲示板
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'web_kyusha_mail_id' ? direction : null}
        onClick={() => handleSort('web_kyusha_mail_id')}
      >
        求車メール
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'keiji_kokai_datetime' ? direction : null}
        onClick={() => handleSort('keiji_kokai_datetime')}
      >
        公開日時
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'koshin_datetime' ? direction : null}
        onClick={() => handleSort('koshin_datetime')}
      >
        登録日時
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'name' ? direction : null}
        onClick={() => handleSort('name')}
      >
        登録者
      </Table.HeaderCell>
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect, history } =
    props || {}

  const {
    id: web_kyusha_id,
    iraisha_name,
    web_kyusha_keijiban_id,
    web_kyusha_mail_id,
    keiji_kokai_datetime,
    koshin_datetime,
    name,
    kyusha_daisu,
    hikiuke_daisu,
    unsoten_name,
    haisha_id,
    level_kbn_name,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(web_kyusha_id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(web_kyusha_id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox
            checked={isSelected}
            onChange={checkboxChange}
            value={web_kyusha_id}
          />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={web_kyusha_id} />
      <Table.Cell style={{ width: '15.0rem' }} content={iraisha_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={`${hikiuke_daisu}台 / ${kyusha_daisu}台`}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={unsoten_name} />
      <Table.Cell style={{ width: '10.0rem' }}>
        {haisha_id ? (
          <Button
            icon='address card'
            size='mini'
            content='配車票'
            primary
            onClick={() => {
              history.push(`/staff/haisha/entry/edit/${haisha_id}`)
            }}
          />
        ) : (
          <></>
        )}
      </Table.Cell>

      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          level_kbn_name ? (
            <Label icon='check' content={level_kbn_name} />
          ) : (
            <></>
          )
        }
      />

      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          web_kyusha_keijiban_id ? <Label icon='check' content='該当' /> : <></>
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          web_kyusha_mail_id ? <Label icon='check' content='該当' /> : <></>
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={keiji_kokai_datetime} />
      <Table.Cell style={{ width: '10.0rem' }} content={koshin_datetime} />
      <Table.Cell style={{ width: '15.0rem' }} content={name} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  return (
    <>
      <Grid.Column>
        <Custom.Dropdown.HaisoCenter
          value={condition.haiso_center_id}
          label='配送センター'
          name='haiso_center_id'
          clearable
          fluid
        />
      </Grid.Column>

      <Grid.Column>
        <Form.Textbox.DatePicker
          value={condition.koshin_datetime_from}
          label='登録日FROM'
          name='koshin_datetime_from'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          value={condition.koshin_datetime_to}
          label='登録日TO'
          name='koshin_datetime_to'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          value={condition.keiji_kokai_datetime_from}
          label='掲示板公開日FROM'
          name='keiji_kokai_datetime_from'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          value={condition.keiji_kokai_datetime_to}
          label='掲示板公開日TO'
          name='keiji_kokai_datetime_to'
        />
      </Grid.Column>
      <Grid.Column width={6}>
        <Custom.Dropdown.Iraisha
          value={condition.iraisha_id}
          label='依頼者'
          name='iraisha_id'
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Team
          value={condition.team_id_list}
          label='チーム'
          name='team_id_list'
          clearable
          multiple
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Field>
          <label>　</label>
          <Form.Checkbox.Buttony
            name='is_own'
            label='自分の担当分のみ'
            checked={condition.is_own}
            enableIndeterminate
          />
        </Form.Field>
      </Grid.Column>
    </>
  )
}
