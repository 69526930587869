import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import moment from 'moment'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Text,
  Popup,
  Label,
  Button,
} from 'component/base'
import { Custom } from 'component/pieces'
import { useUserInfo } from 'component/hook'

export const Shime = withRouter(props => {
  const {
    history,
    mode,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const { active_haiso_center_id } = useUserInfo()

  const defaultCondition = useMemo(() => {
    return {
      haiso_center_id:
        mode === 'tokuisaki' ? active_haiso_center_id : undefined,
      shime_date_from: moment().startOf('month').format('YYYY/MM/DD'),
      shime_date_to: moment().endOf('month').format('YYYY/MM/DD'),
    }
  }, [mode, active_haiso_center_id])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row =>
        _.find(selectedIds, id => row.haisha_seikyu_id === id)
      )
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/haisha/haishaseikyu/shime',
      {
        params: {
          ...condition,
          torihikisaki_kbn: mode,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const handleAllSelect = () =>
    setSelectedIds(
      _(data)
        .map(row => row.haisha_seikyu_id)
        .value()
    )

  const handleAllDeSelect = () => setSelectedIds([])

  const TableHeaderWithProps = useCallback(
    props => <TableHeader {...props} mode={mode} />,
    [mode]
  )

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  const SearchElementWithProps = useCallback(
    props => <SearchElement {...props} mode={mode} />,
    [mode]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          condition={condition}
          defaultCondition={defaultCondition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElementWithProps}
          enableSidebar={false}
          compact
        >
          <DataTable
            name={`haisha-${mode}-seikyu-shime`}
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeaderWithProps}
            tableRow={TableRowWithProps}
            data={data}
            topControl={
              <Button.Group>
                <Button content='全選択' onClick={handleAllSelect} />
                <Button content='全解除' onClick={handleAllDeSelect} />
              </Button.Group>
            }
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Shime.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Shime.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { withOrder, handleSort, column, direction, mode } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'jotai_kbn' ? direction : null}
        onClick={() => handleSort('jotai_kbn')}
        content='状態'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'shime_date' ? direction : null}
        onClick={() => handleSort('shime_date')}
        content='請求締日'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'kanryo_start_date' ? direction : null}
        onClick={() => handleSort('kanryo_start_date')}
        content='完了日FROM'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'kanryo_end_date' ? direction : null}
        onClick={() => handleSort('kanryo_end_date')}
        content='完了日TO'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'haiso_center_name' ? direction : null}
        onClick={() => handleSort('haiso_center_name')}
        content='配送センター'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'torihikisaki_cd' ? direction : null}
        onClick={() => handleSort('torihikisaki_cd')}
        content={
          mode === 'tokuisaki'
            ? '得意先コード'
            : mode === 'kumiaiin'
            ? '組合員コード'
            : mode === 'tatanso'
            ? '単組請求支払先コード'
            : 'モード不正'
        }
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
        content={
          mode === 'tokuisaki'
            ? '得意先名'
            : mode === 'kumiaiin'
            ? '運送店名'
            : mode === 'tatanso'
            ? '単組請求支払先名'
            : 'モード不正'
        }
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'shime_day' ? direction : null}
        onClick={() => handleSort('shime_day')}
        content='得意先締日'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'seikyushime_kensakuyo_group_cd' ? direction : null}
        onClick={() => handleSort('seikyushime_kensakuyo_group_cd')}
        content='検索用グループ'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haisha_count' ? direction : null}
        onClick={() => handleSort('haisha_count')}
        content='配車票件数'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zei_keisan_kbn' ? direction : null}
        onClick={() => handleSort('zei_keisan_kbn')}
        content='税計算区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zei_rate' ? direction : null}
        onClick={() => handleSort('zei_rate')}
        content='消費税率'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'seikyu_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_gaku')}
        content='請求額（税抜）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'seikyu_zei_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_zei_gaku')}
        content='請求額（消費税）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'seikyu_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_zeikomi_gaku')}
        content='請求額（税込）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'chosei_gaku' ? direction : null}
        onClick={() => handleSort('chosei_gaku')}
        content='調整額（税抜）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'chosei_zei_gaku' ? direction : null}
        onClick={() => handleSort('chosei_zei_gaku')}
        content='調整額（消費税）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'chosei_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('chosei_zeikomi_gaku')}
        content='調整額（税込）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'choseigo_seikyu_gaku' ? direction : null}
        onClick={() => handleSort('choseigo_seikyu_gaku')}
        content='調整後請求額（税抜）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'choseigo_seikyu_zei_gaku' ? direction : null}
        onClick={() => handleSort('choseigo_seikyu_zei_gaku')}
        content='調整後請求額（消費税）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'choseigo_seikyu_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('choseigo_seikyu_zeikomi_gaku')}
        content='調整後請求額（税込）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'chosei_riyu' ? direction : null}
        onClick={() => handleSort('chosei_riyu')}
        content='調整理由'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'nyukin_yotei_date' ? direction : null}
        onClick={() => handleSort('nyukin_yotei_date')}
        content='入金予定日'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    haisha_seikyu_id: id,
    jotai_kbn,
    shime_date,
    kanryo_start_date,
    kanryo_end_date,
    haiso_center_name,
    torihikisaki_cd,
    torihikisaki_name,
    shime_day,
    seikyushime_kensakuyo_group_cd,
    haisha_count,
    zei_keisan_kbn,
    zei_rate,
    seikyu_gaku,
    seikyu_zei_gaku,
    seikyu_zeikomi_gaku,
    chosei_gaku,
    chosei_zei_gaku,
    chosei_zeikomi_gaku,
    choseigo_seikyu_gaku,
    choseigo_seikyu_zei_gaku,
    choseigo_seikyu_zeikomi_gaku,
    chosei_riyu,
    nyukin_yotei_date,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          jotai_kbn === 'operated' ? (
            <Popup
              content='調整入力・準備の解除が可能です'
              trigger={<Label content='操作可能' />}
            />
          ) : jotai_kbn === 'closed' ? (
            <Popup
              content='請求締めが行われているので変更するには締めを解除してください'
              trigger={<Label color='orange' content='締め済' />}
            />
          ) : jotai_kbn === 'cleared' ? (
            <Popup
              content='すでに消込が行われているため、操作できません。'
              trigger={<Label color='red' content='消込済み' />}
            />
          ) : (
            <></>
          )
        }
      />
      <Table.Cell style={{ width: '12.0rem' }} content={shime_date} />
      <Table.Cell style={{ width: '12.0rem' }} content={kanryo_start_date} />
      <Table.Cell style={{ width: '12.0rem' }} content={kanryo_end_date} />
      <Table.Cell style={{ width: '12.0rem' }} content={haiso_center_name} />
      <Table.Cell style={{ width: '12.0rem' }} content={torihikisaki_cd} />
      <Table.Cell style={{ width: '12.0rem' }} content={torihikisaki_name} />
      <Table.Cell style={{ width: '12.0rem' }} content={shime_day} />
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={seikyushime_kensakuyo_group_cd}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={haisha_count} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.Zeikeisan value={zei_keisan_kbn} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={zei_rate} />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={seikyu_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={seikyu_zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={seikyu_zeikomi_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={chosei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={chosei_zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={chosei_zeikomi_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={choseigo_seikyu_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={choseigo_seikyu_zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={choseigo_seikyu_zeikomi_gaku} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={chosei_riyu} />
      <Table.Cell style={{ width: '10.0rem' }} content={nyukin_yotei_date} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition, mode } = props

  return (
    <>
      {mode === 'tokuisaki' && (
        <Grid.Column
          style={{
            display: !nav || condition.haiso_center_id ? 'block' : 'none',
          }}
        >
          <Custom.Dropdown.HaisoCenter
            value={condition.haiso_center_id}
            label='配送センター'
            name='haiso_center_id'
            required
            enableAuthValue={false}
          />
        </Grid.Column>
      )}
      <Grid.Column
        style={{
          display: !nav || condition.shime_date_from ? 'block' : 'none',
        }}
      >
        <Form.Textbox.DatePicker
          value={condition.shime_date_from}
          label='請求締日FROM'
          name='shime_date_from'
          required
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shime_date_to ? 'block' : 'none',
        }}
      >
        <Form.Textbox.DatePicker
          value={condition.shime_date_to}
          label='請求締日TO'
          name='shime_date_to'
          required
        />
      </Grid.Column>

      {mode === 'tokuisaki' && (
        <Grid.Column
          style={{
            display: !nav || condition.torihikisaki_id_list ? 'block' : 'none',
          }}
        >
          <Custom.Dropdown.Tokuisaki
            value={condition.torihikisaki_id_list}
            label='得意先'
            name='torihikisaki_id_list'
            returnTorihikisakiId={true}
            multiple
          />
        </Grid.Column>
      )}

      {mode === 'kumiaiin' && (
        <Grid.Column
          style={{
            display: !nav || condition.torihikisaki_id_list ? 'block' : 'none',
          }}
        >
          <Custom.Dropdown.Kumiaiin
            value={condition.torihikisaki_id_list}
            label='組合員'
            name='torihikisaki_id_list'
            multiple
          />
        </Grid.Column>
      )}

      {mode === 'tatanso' && (
        <Grid.Column
          style={{
            display: !nav || condition.torihikisaki_id_list ? 'block' : 'none',
          }}
        >
          <Custom.Dropdown.TansoSeikyuShiharaisaki
            value={condition.torihikisaki_id_list}
            label='単組請求支払先'
            name='torihikisaki_id_list'
            multiple
          />
        </Grid.Column>
      )}

      <Grid.Column
        style={{
          display: !nav || condition.kanryo_date_from ? 'block' : 'none',
        }}
      >
        <Form.Textbox.DatePicker
          value={condition.kanryo_date_from}
          label='完了日FROM'
          name='kanryo_date_from'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.kanryo_date_to ? 'block' : 'none',
        }}
      >
        <Form.Textbox.DatePicker
          value={condition.kanryo_date_to}
          label='完了日TO'
          name='kanryo_date_to'
        />
      </Grid.Column>

      {mode === 'tokuisaki' && (
        <>
          <Grid.Column
            style={{
              display: !nav || condition.shime_day ? 'block' : 'none',
            }}
          >
            <Form.Textbox.Standard
              value={condition.shime_day}
              name='shime_day'
              label='締日'
            />
          </Grid.Column>
          <Grid.Column
            style={{
              display:
                !nav || condition.seikyushime_kensakuyo_group_cd
                  ? 'block'
                  : 'none',
            }}
          >
            <Form.Textbox.Standard
              value={condition.seikyushime_kensakuyo_group_cd}
              name='seikyushime_kensakuyo_group_cd'
              label='請求締め検索用グループコード'
            />
          </Grid.Column>
        </>
      )}
    </>
  )
}
