import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import moment from 'moment'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  ConditionWrapper,
  BaseForm as Form,
  Button,
  notice,
  Text,
} from 'component/base'
import { Custom } from 'component/pieces'

export const KeiriKamokuHenko = withRouter(props => {
  const { history, enableQueryCondition } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {
      shime_date_from: moment().startOf('month').format('YYYY/MM/DD'),
      shime_date_to: moment().endOf('month').format('YYYY/MM/DD'),
      is_keiri_kamoku_kakuninyo: true,
    }
  }, [])

  const [seikyuData, setSeikyuData] = useState([])
  const [meisaiData, setMeisaiData] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )
  const [seikyuLoading, setSeikyuLoading] = useState(false)
  const [meisaiLoading, setMeisaiLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)

  const data = useMemo(() => {
    if (seikyuData.length === 0 || meisaiData.length === 0) return []

    return _.map(seikyuData, seikyu => ({
      ...seikyu,
      haisha_seikyu_shime_keiri_meisai: _.filter(meisaiData, data =>
        _.includes(seikyu.haisha_seikyu_shime_keiri_meisai_id_list, data.id)
      ),
    }))
  }, [meisaiData, seikyuData])

  useEffect(() => {
    setLoading(seikyuLoading || meisaiLoading)
  }, [seikyuLoading, meisaiLoading])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callSeikyuApi(condition)
    callMeisaiApi()
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callSeikyuApi = async condition => {
    setSeikyuLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/haisha/haishaseikyu/shime',
      {
        params: {
          ...condition,
          torihikisaki_kbn: 'tokuisaki',
          is_shime: true,
        },
      }
    )
    setSeikyuLoading(false)
    if (error) return
    setSeikyuData(data)
  }

  const callMeisaiApi = async () => {
    setMeisaiLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/haisha/haishaseikyu/shime/keiri_meisai'
    )
    setMeisaiLoading(false)
    if (error) return
    setMeisaiData(data)
  }

  const TableRowWithProps = useCallback(
    props => <TableRow {...props} history={history} setRefresh={setRefresh} />,
    [history, setRefresh]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='keiri_kamoku_henko'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

KeiriKamokuHenko.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

KeiriKamokuHenko.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihikisaki_cd' ? direction : null}
        onClick={() => handleSort('torihikisaki_cd')}
        content='取引先コード'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
        content='取引先名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haiso_center_name' ? direction : null}
        onClick={() => handleSort('haiso_center_name')}
        content='配送センター名'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'haisha_seikyu_id' ? direction : null}
        onClick={() => handleSort('haisha_seikyu_id')}
        content='請求番号'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shime_date' ? direction : null}
        onClick={() => handleSort('shime_date')}
        content='締日'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'seikyu_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_gaku')}
        content='請求額（税抜）'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'seikyu_zei_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_zei_gaku')}
        content='請求額（消費税）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'seikyu_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('seikyu_zeikomi_gaku')}
        content='請求額（税込）'
      />
      <Table.HeaderCell style={{ width: '8.0rem' }} content='科目確定済' />
      <Table.HeaderCell style={{ width: '10.0rem' }} content='科目確定日' />
      <Table.HeaderCell style={{ width: '11.0rem' }} content='経理明細変更' />
      <Table.HeaderCell
        style={{ width: '100.0rem', padding: '0rem' }}
        content={
          <Grid
            celled
            verticalAlign='middle'
            style={{ width: '100.0rem', margin: '0rem' }}
          >
            <Grid.Row>
              <Grid.Column width={2}>詳細</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column style={{ width: '10.0rem' }}>計上日</Grid.Column>
              <Grid.Column style={{ width: '10.0rem' }}>請求金額</Grid.Column>
              <Grid.Column style={{ width: '30.0rem' }}>取引種類</Grid.Column>
              <Grid.Column style={{ width: '50.0rem' }}>摘要</Grid.Column>
            </Grid.Row>
          </Grid>
        }
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, setRefresh } = props || {}

  const {
    haisha_seikyu_shime_id,
    haisha_seikyu_id,
    torihikisaki_cd,
    torihikisaki_name,
    haiso_center_name,
    shime_date,
    seikyu_gaku,
    seikyu_zei_gaku,
    seikyu_zeikomi_gaku,
    haisha_seikyu_shime_keiri_meisai,
    keiri_kakutei_id: _keiri_kakutei_id,
    keiri_kakutei_date,
  } = data || {}

  const [is_keiri_kakutei, set_is_keiri_kakutei] = useState(
    _keiri_kakutei_id ? true : false
  )
  const [keiri_kakutei_id, set_keiri_kakutei_id] = useState(_keiri_kakutei_id)

  useEffect(() => {
    set_is_keiri_kakutei(_keiri_kakutei_id ? true : false)
    set_keiri_kakutei_id(_keiri_kakutei_id)
  }, [_keiri_kakutei_id])

  const changeKeiriKakutei = () => {
    is_keiri_kakutei ? deleteKeiriKakutei() : postKeiriKakutei()
    set_is_keiri_kakutei(state => !state)
  }

  const postKeiriKakutei = async condition => {
    const { data, error } = await Axios.ajax.post(
      `/api/staff/haisha/haishaseikyu/shime/${haisha_seikyu_shime_id}/keiri_kakutei`
    )
    if (error) return
    notice({
      title: 'Success',
      description: '登録しました。',
      type: 'success',
    })
    set_keiri_kakutei_id(data.id)
    setRefresh(refresh => ++refresh)
  }

  const deleteKeiriKakutei = async condition => {
    const { error } = await Axios.ajax.delete(
      `/api/staff/haisha/haishaseikyu/shime/keiri_kakutei/${keiri_kakutei_id}`
    )
    if (error) return
    notice({
      title: 'Success',
      description: '解除しました。',
      type: 'success',
    })
    setRefresh(refresh => ++refresh)
  }

  return withOrder(
    <Table.Row>
      <Table.Cell style={{ width: '10.0rem' }} content={torihikisaki_cd} />
      <Table.Cell style={{ width: '15.0rem' }} content={torihikisaki_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={haiso_center_name} />
      <Table.Cell style={{ width: '8.0rem' }} content={haisha_seikyu_id} />
      <Table.Cell style={{ width: '10.0rem' }} content={shime_date} />
      <Table.Cell
        style={{
          width: '8.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={seikyu_gaku} />}
      />
      <Table.Cell
        style={{
          width: '8.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={seikyu_zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '8.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={seikyu_zeikomi_gaku} />}
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={
          <Form mapping={input => input} onValidSubmit={() => {}}>
            <Form.Checkbox.Toggle
              name='is_keiri_kakutei'
              checked={is_keiri_kakutei}
              onClick={changeKeiriKakutei}
            />
          </Form>
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={keiri_kakutei_date} />
      <Table.Cell
        style={{ width: '11.0rem' }}
        content={
          <Button
            content='経理明細変更'
            disabled={is_keiri_kakutei}
            as={Link}
            primary
            to={`/staff/haisha/keiri_kamoku_henko/meisai/${haisha_seikyu_shime_id}`}
          />
        }
      />
      <Table.Cell
        style={{ width: '100.0rem', padding: '0rem' }}
        content={
          <Grid
            celled
            verticalAlign='middle'
            style={{ width: '100.0rem', margin: '0rem' }}
          >
            {_.map(haisha_seikyu_shime_keiri_meisai, data => (
              <DetailsRow data={data} key={data.id || 0} />
            ))}
          </Grid>
        }
      />
    </Table.Row>
  )
}

const DetailsRow = props => {
  const { data } = props

  const { keijo_date, seikyu_zeikomi_gaku, torihiki_name, tekiyo } = data || {}

  return (
    <>
      <Grid.Row>
        <Grid.Column
          style={{
            width: '10.0rem',
            maxWidth: '10.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {keijo_date}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '10.0rem',
            maxWidth: '10.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          textAlign='right'
        >
          <Text.Comma content={seikyu_zeikomi_gaku} />
        </Grid.Column>
        <Grid.Column
          style={{
            width: '30.0rem',
            maxWidth: '30.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {torihiki_name}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '50.0rem',
            maxWidth: '50.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {tekiyo}
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

const SearchElement = props => {
  const { condition } = props

  return (
    <>
      <Grid.Column>
        <Custom.Dropdown.Tokuisaki
          name='torihikisaki_id_list'
          label='得意先'
          value={condition.torihikisaki_id_list}
          clearable
          multiple
          returnTorihikisakiId
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.HaisoCenter
          name='haiso_center_id'
          label='配送センター'
          value={condition.haiso_center_id}
          enableAuthValue={false}
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          value={condition.shime_date_from}
          label='請求締日FROM'
          name='shime_date_from'
          required
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          value={condition.shime_date_to}
          label='請求締日TO'
          name='shime_date_to'
          required
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          name='is_keiri_kamoku_kakuninyo'
          label='要経理科目確認'
          size='mini'
          checked={condition.is_keiri_kamoku_kakuninyo}
          insertEmptyLabel
        />
      </Grid.Column>
    </>
  )
}
