import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
} from 'component/base'
import { Custom } from 'component/pieces'

export const HaisoCenterKoza = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
    haiso_center_id,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(haiso_center_id, condition)
    setSelectedIds([])
  }, [condition, refresh, haiso_center_id])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async (haiso_center_id, condition) => {
    if (!haiso_center_id) return
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/haiso_center/${haiso_center_id}/haiso_center_koza`,
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='haiso_center'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

HaisoCenterKoza.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

HaisoCenterKoza.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'kinyukikan_name' ? direction : null}
        onClick={() => handleSort('kinyukikan_name')}
        content='金融機関'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'shiten_name' ? direction : null}
        onClick={() => handleSort('shiten_name')}
        content='金融機関支店'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'yokin_kbn' ? direction : null}
        onClick={() => handleSort('yokin_kbn')}
        content='預金区分'
      />
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'is_furikomi' ? direction : null}
        onClick={() => handleSort('is_furikomi')}
        content='振込'
      />
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'is_hikisari' ? direction : null}
        onClick={() => handleSort('is_hikisari')}
        content='引去'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'koza_meigi' ? direction : null}
        onClick={() => handleSort('koza_meigi')}
        content='口座名義'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'koza_meigi_kanji1' ? direction : null}
        onClick={() => handleSort('koza_meigi_kanji1')}
        content='口座名義漢字1'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'koza_meigi_kanji2' ? direction : null}
        onClick={() => handleSort('koza_meigi_kanji2')}
        content='口座名義漢字2'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'koza_no' ? direction : null}
        onClick={() => handleSort('koza_no')}
        content='口座番号'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'furikomi_tesuryo_futan_kbn' ? direction : null}
        onClick={() => handleSort('furikomi_tesuryo_futan_kbn')}
        content='振込手数料負担区分'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'header_shubetsu_cd' ? direction : null}
        onClick={() => handleSort('header_shubetsu_cd')}
        content='ヘッダ種別コード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'jigyonushi_no' ? direction : null}
        onClick={() => handleSort('jigyonushi_no')}
        content='事業主番号'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'jigyonushi_name' ? direction : null}
        onClick={() => handleSort('jigyonushi_name')}
        content='事業主名称'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'toriatsukaikyoku_no' ? direction : null}
        onClick={() => handleSort('toriatsukaikyoku_no')}
        content='取扱局番号'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shokai_hyoji' ? direction : null}
        onClick={() => handleSort('shokai_hyoji')}
        content='照会表示'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'biko' ? direction : null}
        onClick={() => handleSort('biko')}
        content='備考'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    kinyukikan_name,
    shiten_name,
    yokin_kbn,
    is_furikomi,
    is_hikisari,
    koza_meigi,
    koza_meigi_kanji1,
    koza_meigi_kanji2,
    koza_no,
    furikomi_tesuryo_futan_kbn,
    header_shubetsu_cd,
    jigyonushi_no,
    jigyonushi_name,
    toriatsukaikyoku_no,
    shokai_hyoji,
    biko,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '15.0rem' }} content={kinyukikan_name} />
      <Table.Cell style={{ width: '15.0rem' }} content={shiten_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.YokinKbn value={yokin_kbn} />}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={is_furikomi ? '対象' : '-'}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={is_hikisari ? '対象' : '-'}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={koza_meigi} />
      <Table.Cell style={{ width: '10.0rem' }} content={koza_meigi_kanji1} />
      <Table.Cell style={{ width: '10.0rem' }} content={koza_meigi_kanji2} />
      <Table.Cell style={{ width: '10.0rem' }} content={koza_no} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          <Custom.Label.FurikomiTesuryoFutanKbn
            value={furikomi_tesuryo_futan_kbn}
          />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={header_shubetsu_cd} />
      <Table.Cell style={{ width: '10.0rem' }} content={jigyonushi_no} />
      <Table.Cell style={{ width: '10.0rem' }} content={jigyonushi_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={toriatsukaikyoku_no} />
      <Table.Cell style={{ width: '10.0rem' }} content={shokai_hyoji} />
      <Table.Cell style={{ width: '20.0rem' }} content={biko} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const { kinyukikan_id: _kinyukikan_id, kinyukikan_shiten_id } = condition

  const [kinyukikan_id, set_kinyukikan_id] = useState(undefined)

  useEffect(() => {
    set_kinyukikan_id(_kinyukikan_id)
  }, [_kinyukikan_id])

  return (
    <>
      <Grid.Column>
        <Custom.Dropdown.Kinyukikan
          name='kinyukikan_id'
          value={kinyukikan_id}
          label='金融機関'
          onChange={(e, { value }) => set_kinyukikan_id(value)}
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.KinyukikanShiten
          name='kinyukikan_shiten_id'
          value={kinyukikan_shiten_id}
          label='金融機関支店'
          kinyukikan_id={kinyukikan_id}
          disabled={!kinyukikan_id}
        />
      </Grid.Column>
    </>
  )
}
