import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Checkbox,
  DataTable,
  Grid,
  Table,
  ConditionWrapper,
  Segment,
} from 'component/base'
import { Custom } from 'component/pieces'
import { useUserInfo } from 'component/hook'

export const Team = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
    team_mode,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const { active_haiso_center_id, active_busho_id } = useUserInfo()

  const defaultCondition = useMemo(
    () =>
      team_mode === 'haiso_team'
        ? { haiso_center_id: active_haiso_center_id }
        : team_mode === 'busho_team'
        ? { busho_id: active_busho_id }
        : {},
    [team_mode]
  )

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id)),
      data
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    if (refresh === 0) return
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get('/api/staff/master/team', {
      params: {
        ...condition,
        is_haiso_team_only:
          team_mode === 'haiso_team' || condition.haiso_center_id
            ? true
            : false,
        is_busho_team_only:
          team_mode === 'busho_team' || condition.busho_id ? true : false,
      },
    })
    setLoading(false)
    if (error) return
    setData(data)
    setSelectedIds([])
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  const SearchElementWithProps = useCallback(
    props => <SearchElement {...props} team_mode={team_mode} />,
    [team_mode]
  )

  const editedData = useMemo(
    () =>
      _(data)
        .map(row => ({
          ...row,
          team_kind_name: row.haiso_center_id
            ? '配送チーム'
            : row.busho_id
            ? '部署チーム'
            : '組合チーム',
        }))
        .value(),
    [data]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElementWithProps}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='team'
            scrollX
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={editedData}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Team.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Team.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { column, direction, withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'name' ? direction : null}
        onClick={() => handleSort('name')}
        content='チーム名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'team_kind_name' ? direction : null}
        onClick={() => handleSort('team_kind_name')}
        content='チーム種類'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haiso_center_name' ? direction : null}
        onClick={() => handleSort('haiso_center_name')}
        content='配送センター'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'busho_name' ? direction : null}
        onClick={() => handleSort('busho_name')}
        content='部署'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    name,
    // haiso_center_id,
    haiso_center_name,
    // busho_id,
    busho_name,
    team_kind_name,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={name} />
      <Table.Cell style={{ width: '10.0rem' }} content={team_kind_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={haiso_center_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={busho_name} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition, team_mode } = props

  const { haiso_center_id, busho_id } = condition || {}

  return (
    <>
      {(team_mode === 'haiso_team' || team_mode === 'all') && (
        <Grid.Column
          style={{ display: !nav || haiso_center_id ? 'block' : 'none' }}
        >
          <Custom.Dropdown.HaisoCenter
            name='haiso_center_id'
            value={haiso_center_id}
            label='配送センター'
            fluid
            clearable
            enableAuthValue={false}
          />
        </Grid.Column>
      )}
      {(team_mode === 'busho_team' || team_mode === 'all') && (
        <Grid.Column style={{ display: !nav || busho_id ? 'block' : 'none' }}>
          <Custom.Dropdown.Busho
            name='busho_id'
            value={busho_id}
            label='部署'
            fluid
            clearable
            enableAuthValue={false}
            level_list={[0, 1, 2]}
          />
        </Grid.Column>
      )}
    </>
  )
}
