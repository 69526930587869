import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { Segment, Header, Icon, Util, AvatarEditor } from 'component/base'

const Avatar = props => {
  const { icon, onSave } = props

  const [image, setImage] = useState(null)

  const onDrop = useCallback(files => {
    setImage(files[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleSave = image => {
    onSave(Util.Convert.base64ToBlob(image))
  }

  return (
    <>
      {!image ? (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Segment
            placeholder
            inverted={isDragActive}
            color={isDragActive ? 'orange' : null}
          >
            <Header icon>
              <Icon name={icon} />
              {isDragActive ? (
                <p>ファイルをドロップ</p>
              ) : (
                <>
                  <h2>ファイルをドラッグ＆ドロップ</h2>
                  <h4>またはクリックしてファイルを選択</h4>
                </>
              )}
            </Header>
          </Segment>
        </div>
      ) : (
        <AvatarEditor
          target={image}
          width={250}
          height={250}
          border={50}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={1.2}
          rotate={0}
          onSave={handleSave}
        />
      )}
    </>
  )
}

export default Avatar

Avatar.propTypes = {
  icon: PropTypes.string,
  onSave: PropTypes.func,
}

Avatar.defaultProps = {
  icon: 'file outline',
  onSave: () => {},
}
