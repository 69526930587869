import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'

export const Selectbin = props => {
  const edit = rows =>
    _(rows)
      .map(row => ({
        key: row.id,
        value: row.id,
        text: `(${row.category})${row.name}`,
      }))
      .value()

  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/select_bin_komoku'
      keyName='id'
      valueName='id'
      textName='name'
      editOptions={edit}
      sort={['sort']}
      {...props}
    />
  )
}

Selectbin.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

Selectbin.defaultProps = {
  name: 'selectbin',
  label: 'セレクト便項目',
  value: undefined,
}
