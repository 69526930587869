import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { withFormsy } from '@akihirotakamura/formsy-react'
import { Segment, Axios, Form, Checkbox, Header } from 'component/base'

export const Tokusei = withFormsy(props => {
  const {
    // Formsy props
    // isPristine,
    // errorLabel,
    // getErrorMessage,
    setValue,
    // getValue,
    // isValid,

    // Other
    value,
    stackable,
    label,
  } = props

  const [selectedIds, setSelectedIds] = useState(value || [])
  const [loading, setLoading] = useState(false)
  const [tokuseiList, setTokuseiList] = useState([])

  useEffect(() => {
    setSelectedIds(value || [])
  }, [value])

  const ajax = async condition => {
    setLoading(true)

    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/kumiaiin_tokusei',
      {}
    )

    setLoading(false)

    if (error) return

    setTokuseiList(data || [])
  }

  const handleChange = (e, data) => {
    const list = data.checked
      ? [...selectedIds, data.value]
      : _.without(selectedIds, data.value)
    setSelectedIds(list)
    setValue(list)
  }

  useEffect(() => {
    ajax()
  }, [])

  return (
    <>
      <Header attached='top' content={label} inverted />
      <Segment attached='bottom' loading={loading}>
        <Form.Group grouped>
          {_(tokuseiList)
            .map(row => (
              <Checkbox
                key={row.id}
                value={row.id}
                label={row.name}
                onChange={handleChange}
                checked={_.includes(selectedIds, row.id)}
                style={{
                  width: stackable ? '100%' : '',
                  marginBottom: stackable ? '10px' : '',
                }}
              />
            ))
            .value()}
        </Form.Group>
      </Segment>
    </>
  )
})
