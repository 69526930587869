import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'

export const TansoSeikyuShiharaisaki = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/tanso_seikyu_shiharaisaki/list'
      keyName='id'
      valueName='torihikisaki_id'
      textName='seikyu_shiharaisaki_name'
      editOptions={options =>
        _(options)
          .map(option => ({
            key: option.id,
            value: option.torihikisaki_id,
            text: `${option.seikyu_shiharaisaki_cd || '-'}：${option.seikyu_shiharaisaki_name || '-'
              }`,
            seikyu_shiharaisaki_cd: option.seikyu_shiharaisaki_cd,
          }))
          .value()
      }
      sort={['seikyu_shiharaisaki_cd']}
      {...props}
    />
  )
}

TansoSeikyuShiharaisaki.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

TansoSeikyuShiharaisaki.defaultProps = {
  name: 'torihikisaki_id',
  label: '組合員',
  value: undefined,
}
