import { useState, useEffect } from 'react'
import _ from 'lodash'
import { Axios, notice } from 'component/base'

export const useHaisha = (id, refresh) => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({})
  const [postState, setPostState] = useState({})

  const {
    haisha,
    haisha_kumiai_seikyu,
    haisha_unsoten_seikyu,
    haisha_sagyo_meisai,
    haisha_draft_haisha,
    haisha_end_hokoku,
    haisha_unsoten,
    haisha_jotai,
    haisha_haisoryo,
    haisha_haisoryo_seikyu,
    haisha_haisoryo_shiharai,
    web_kyusha_haisha,
    gps_taiki_id,
  } = state || {}

  const { is_haisoryo_dogaku, zei_rate } = haisha_haisoryo || {}

  const { ryokin_id: seikyu_ryokin_id } = haisha_haisoryo_seikyu || {}

  const { ryokin_id: shiharai_ryokin_id } = haisha_haisoryo_shiharai || {}

  useEffect(() => {
    setPostState({
      haisha_haisoryo: {
        is_haisoryo_dogaku,
        zei_rate,
      },
      haisha_haisoryo_seikyu: {
        ryokin_id: seikyu_ryokin_id,
      },
      haisha_haisoryo_shiharai: {
        ryokin_id: shiharai_ryokin_id,
      },
    })
  }, [state])

  const BASE_URL = '/api/staff/haisha/haisha'

  const ajax_get = async () => {
    if (!id) return EMPTY_ROW

    setLoading(true)
    const { data, error } = await Axios.ajax.get(`${BASE_URL}/${id}`)
    setLoading(false)

    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      return { error }
    }
    setState(data)
  }

  const ajax_post = async (_data, callback) => {
    setLoading(true)

    // idを削除 mock用。apiではid入ってきても無視する
    const body = _.merge({}, postState, _data)
    delete body.id

    const { data, error } = await Axios.ajax.post(BASE_URL, body)
    setLoading(false)
    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      if (callback) callback({ error })
      return
    }

    notice({
      title: '登録しました',
      type: 'info',
    })
    setState(data)
    if (callback) callback({ data })
  }

  const ajax_patch = async (_data, callback) => {
    const {
      haisha: _haisha,
      haisha_kumiai_seikyu: _haisha_kumiai_seikyu,
      haisha_unsoten_seikyu: _haisha_unsoten_seikyu,
      haisha_sagyo_meisai: _haisha_sagyo_meisai,
      haisha_draft_haisha: _haisha_draft_haisha,
      haisha_end_hokoku: _haisha_end_hokoku,
      haisha_unsoten: _haisha_unsoten,
      haisha_jotai: _haisha_jotai,
      haisha_haisoryo: _haisha_haisoryo,
      haisha_haisoryo_seikyu: _haisha_haisoryo_seikyu,
      haisha_haisoryo_shiharai: _haisha_haisoryo_shiharai,
      web_kyusha_haisha: _web_kyusha_haisha,
      gps_taiki_id: _gps_taiki_id,
    } = _data || {}

    if (!id) return
    setLoading(true)
    const { data, error } = await Axios.ajax.patch(`${BASE_URL}`, {
      haisha:
        _.values(_haisha).length === 0 ? haisha : _.assign({}, haisha, _haisha),
      haisha_kumiai_seikyu:
        _.values(_haisha_kumiai_seikyu).length === 0
          ? haisha_kumiai_seikyu
          : _.assign({}, haisha_kumiai_seikyu, _haisha_kumiai_seikyu),
      haisha_unsoten_seikyu:
        _.values(_haisha_unsoten_seikyu).length === 0
          ? haisha_unsoten_seikyu
          : _.assign({}, haisha_unsoten_seikyu, _haisha_unsoten_seikyu),
      haisha_sagyo_meisai:
        _.values(_haisha_sagyo_meisai).length === 0
          ? haisha_sagyo_meisai
          : _.assign({}, haisha_sagyo_meisai, _haisha_sagyo_meisai),
      haisha_draft_haisha:
        _.values(_haisha_draft_haisha).length === 0
          ? haisha_draft_haisha
          : _.assign({}, haisha_draft_haisha, _haisha_draft_haisha),
      haisha_end_hokoku:
        _.values(_haisha_end_hokoku).length === 0
          ? haisha_end_hokoku
          : _.assign({}, haisha_end_hokoku, _haisha_end_hokoku),
      haisha_unsoten:
        _.values(_haisha_unsoten).length === 0
          ? haisha_unsoten
          : _.assign({}, haisha_unsoten, _haisha_unsoten),
      haisha_jotai:
        _.values(_haisha_jotai).length === 0
          ? haisha_jotai
          : _.assign({}, haisha_jotai, _haisha_jotai),
      haisha_haisoryo:
        _.values(_haisha_haisoryo).length === 0
          ? haisha_haisoryo
          : _.assign({}, haisha_haisoryo, _haisha_haisoryo),
      haisha_haisoryo_seikyu:
        _.values(_haisha_haisoryo_seikyu).length === 0
          ? haisha_haisoryo_seikyu
          : _.assign({}, haisha_haisoryo_seikyu, _haisha_haisoryo_seikyu),
      haisha_haisoryo_shiharai:
        _.values(_haisha_haisoryo_shiharai).length === 0
          ? haisha_haisoryo_shiharai
          : _.assign({}, haisha_haisoryo_shiharai, _haisha_haisoryo_shiharai),
      web_kyusha_haisha:
        _.values(_web_kyusha_haisha).length === 0
          ? web_kyusha_haisha
          : _.assign({}, web_kyusha_haisha, _web_kyusha_haisha),
      gps_taiki_id: _gps_taiki_id ? _gps_taiki_id : gps_taiki_id,
    })
    setLoading(false)
    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      if (callback) callback({ error })
      return
    }
    notice({
      title: '更新しました',
      type: 'info',
    })
    setState(data)
    if (callback) callback({ data })
  }

  const ajax_delete = async callback => {
    if (!id) return
    setLoading(true)
    const { error } = await Axios.ajax.delete(`${BASE_URL}/${id}`)
    setLoading(false)
    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      if (callback) callback({ error })
      return
    }
    notice({
      title: '削除しました',
      type: 'info',
    })
    if (callback) callback({})
  }

  useEffect(() => {
    ajax_get()
  }, [id, refresh])

  return {
    loading,
    ajax_post,
    ajax_patch,
    ajax_delete,
    haisha_data: state,
    haisha,
    haisha_kumiai_seikyu,
    haisha_unsoten_seikyu,
    haisha_sagyo_meisai,
    haisha_end_hokoku,
    haisha_unsoten,
    haisha_jotai,
    haisha_haisoryo,
    haisha_haisoryo_seikyu,
    haisha_haisoryo_shiharai,
    web_kyusha_haisha,
    gps_taiki_id,
  }
}

const EMPTY_ROW = {
  haisha: {
    haisha_id: null,
    tanso_cd: '',
    iraisha_id: null,
    uketsuke_date: null,
    haiso_center_id: null,
    is_ichigen: false,
    iraisha_name: '',
    is_driver_kakutei_mail_send: false,
    is_end_mail_send: false,
    iraisha_address1: '',
    iraisha_address2: '',
    irai_tantosha_name: '',
    iraisha_mail: '',
    iraisha_tel: '',
    iraisha_mobile_tel: '',
    unsohin_name: '',
    haisha_biko: '',
    juchu_shubetsu_kbn: '',
    kosoku_riyo_kbn: '',
    mitsumori_date: '',
    mitsumori_time: '',
    mitsumori_gaku: 0,
    shitei_gaku: 0,
    shitei_kyori: 0,
    haisha_hoho_kbn: '',
    hikitori_date: '',
    hikitori_time: '',
    hatchi_kokyaku_name: '',
    hatchi_address1: '',
    hatchi_address2: '',
    hatchi_tel1: '',
    hatchi_tel2: '',
    hatchi_biko: '',
    kanryo_date: '',
    kanryo_time: '',
    chakuchi_kokyaku_name: '',
    chakuchi_address1: '',
    chakuchi_address2: '',
    chakuchi_tel1: '',
    chakuchi_tel2: '',
    chakuchi_biko: '',
    uketorinin_name: '',
    end_hokoku_biko: '',
    shashu_id_list: [],
    seikyu_biko: '',
    shonai_seikyu_biko: '',
    is_keiri_kamoku_kakuninyo: false,
    seikyu_kbn: '',
    seikyusaki_kbn: '',
    shukin_kbn: '',
    level_kbn: '',
    mitsumori_irai_id: null,
  },
  haisha_kumiai_seikyu: {
    seikyusaki_id: null,
  },
  haisha_unsoten_seikyu: {
    seikyusaki_name: '',
    seikyusaki_post_cd: '',
    seikyusaki_tel1: '',
    seikyusaki_tel2: '',
    seikyusaki_address1: '',
    seikyusaki_address2: '',
  },
  haisha_sagyo_meisai: {
    sagyo_meisai_kakunin_date: null,
  },
  haisha_draft_haisha: {
    haisha_draft_id: null,
  },
  haisha_end_hokoku: {
    shuryo_hokoku_kakunin_date: null,
  },
  haisha_unsoten: {
    unsoten_id: null,
    shashu_id: null,
    shatai_no: '',
    unsoten_tel1: '',
    unsoten_tel2: '',
  },
  haisha_jotai: {
    haisha_jotai_kbn: '',
  },
  haisha_haisoryo: {
    id: null,
    haisha_id: null,
    uketorimachi_start_time: null,
    uketorimachi_end_time: null,
    nohinmachi_start_time: null,
    nohinmachi_end_time: null,
    is_haisoryo_dogaku: true,
    zei_rate: 0,
  },
  haisha_haisoryo_seikyu: {
    id: null,
    haisha_haisoryo_id: null,
    ryokin_id: null,
    chiku_warimashi_gaku: 0,
    warimashi_rate: 0,
    unchin_kyorisei_su: 0,
    unchin_kyorisei_gaku: 0,
    unchin_jikansei_jikansei_hm: null,
    unchin_jikansei_kyorisei_su: 0,
    unchin_hikkoshi_kyorisei_su: 0,
    unchin_hikkoshi_jikansei_hm: null,
    unchin_hikkoshi_gaku: 0,
    unchin_niatsukai_hm: null,
    unchin_niatsukai_gaku: 0,
    unchin_kamotsumachi_hm: null,
    unchin_kamotsumachi_gaku: 0,
    unchin_yokomochi_hm: null,
    unchin_yokomochi_gaku: 0,
    unchin_takuhai_kosu: 0,
    unchin_takuhai_tanka: 0,
    unchin_takuhai_gaku: 0,
    unchin_sonota_gaku: 0,
    tatekaekin_kosokudoro_gaku: 0,
    tatekaekin_sonota_gaku: 0,
    irai_tesuryo_rate: 0,
    irai_tesuryo_gaku: 0,
    unchin_gokei_zeikomi_gaku: 0,
    unchin_gokei_zei_gaku: 0,
    seikyu_gaku: 0,
  },
  haisha_haisoryo_shiharai: {
    id: null,
    haisha_haisoryo_id: null,
    ryokin_id: null,
    chiku_warimashi_gaku: 0,
    warimashi_rate: 0,
    unchin_kyorisei_su: 0,
    unchin_kyorisei_gaku: 0,
    unchin_jikansei_jikansei_hm: null,
    unchin_jikansei_kyorisei_su: 0,
    unchin_hikkoshi_kyorisei_su: 0,
    unchin_hikkoshi_jikansei_hm: null,
    unchin_hikkoshi_gaku: 0,
    unchin_niatsukai_hm: null,
    unchin_niatsukai_gaku: 0,
    unchin_kamotsumachi_hm: null,
    unchin_kamotsumachi_gaku: 0,
    unchin_yokomochi_hm: null,
    unchin_yokomochi_gaku: 0,
    unchin_takuhai_kosu: 0,
    unchin_takuhai_tanka: 0,
    unchin_takuhai_gaku: 0,
    unchin_sonota_gaku: 0,
    tatekaekin_kosokudoro_gaku: 0,
    tatekaekin_sonota_gaku: 0,
    haisha_tesuryo_rate: 0,
    haisha_tesuryo_gaku: 0,
    unchin_gokei_zeikomi_gaku: 0,
    unchin_gokei_zei_gaku: 0,
    shiharai_gaku: 0,
  },
  web_kyusha_haisha: {
    web_kyusha_id: null,
  },
  gps_taiki_id: null,
}
