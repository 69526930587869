import React from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import Standard from './Standard'

class Alpha extends React.Component {
  static propTypes = {
    validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }

  static defaultProps = {
    validations: {},
  }

  render = () => {
    const { validations, ...other } = this.props

    return (
      <Standard
        validations={merge({}, validations, { isAlpha: true })}
        {...other}
      />
    )
  }
}

export default Alpha
