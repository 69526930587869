import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { withFormsy } from '@akihirotakamura/formsy-react'
import { Segment, Axios, Form, Checkbox, Header, Grid } from 'component/base'

export const Selectbin = withFormsy(props => {
  const {
    // Formsy props
    // isPristine,
    // errorLabel,
    // getErrorMessage,
    setValue,
    // getValue,
    // isValid,

    // Other
    value,
    stackable,
  } = props

  const [selectedIds, setSelectedIds] = useState(value || [])
  const [loading, setLoading] = useState(false)
  const [selectbinList, setSelectbinList] = useState([])

  useEffect(() => {
    setSelectedIds(value || [])
  }, [value])

  const ajax = async condition => {
    setLoading(true)

    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/select_bin_komoku',
      {}
    )

    setLoading(false)

    if (error) return

    setSelectbinList(data || [])
  }

  const handleChange = (e, data) => {
    const list = data.checked
      ? [...selectedIds, data.value]
      : _.without(selectedIds, data.value)
    setSelectedIds(list)
    setValue(list)
  }

  useEffect(() => {
    ajax()
  }, [])

  return (
    <>
      <Form.Group grouped>
        <Grid>
          {_(selectbinList)
            .orderBy(['sort'], ['asc'])
            .groupBy('category')
            .map((list, category) => (
              <Grid.Column key={category} computer={8} tablet={8} mobile={16}>
                <Header
                  key={category}
                  attached='top'
                  content={category}
                  inverted
                />
                <Segment attached='bottom' loading={loading}>
                  {_(list)
                    .map(row => (
                      <Checkbox
                        key={row.id}
                        value={row.id}
                        label={row.name}
                        onChange={handleChange}
                        checked={_.includes(selectedIds, row.id)}
                        style={{
                          width: stackable ? '100%' : '',
                          marginBottom: stackable ? '10px' : '',
                        }}
                      />
                    ))
                    .value()}
                </Segment>
              </Grid.Column>
            ))
            .value()}
        </Grid>
      </Form.Group>
    </>
  )
})
