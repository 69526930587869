import React from 'react'
import _ from 'lodash'
import { Container, Segment } from 'component/base'

const PageContainer = props => {
  const { loading, children, style, ...other } = props || {}

  const defaultStyle = {
    paddingTop: '10px',
    paddingBottom: '10px',
  }

  return (
    <Container {...other} style={_.merge({}, defaultStyle, style)}>
      <Segment
        basic
        loading={loading}
        style={{ padding: '0em' }}
        className='computer-segment'
      >
        {children}
      </Segment>
    </Container>
  )
}

export default PageContainer
