import axios from 'axios'
import _ from 'lodash'
import { setupCache } from 'axios-cache-adapter'
import queryString from 'query-string'
import { getConfig, getConfigGlobal } from 'config'

const cache = setupCache({
  // 15 minutes
  maxAge: 15 * 60 * 1000,
  exclude: {
    query: false,
    filter: req => !_.includes(req.url || '', '/master/'),
  },
  invalidate: async (config, request) => {
    if (request.headers.clearCacheEntry) {
      await config.store.clear()
    }
  },
})

class Axios {
  static ajax = (() => {
    const { api_endpoint } = getConfig()

    axios.defaults.baseURL = api_endpoint

    axios.defaults.adapter = cache.adapter

    axios.defaults.paramsSerializer = params =>
      queryString.stringify(params, {
        arrayFormat: 'none',
        skipNull: true,
      })

    return axios
  })()

  static noauth_ajax = (() => {
    const { realm, api_endpoint } = getConfig()

    const instance = axios.create({
      baseURL: api_endpoint,
      headers: {
        Realm: realm,
      },
    })

    instance.interceptors.request.use(config => {
      return new Promise(resolve => resolve(config))
    })
    return instance
  })()

  static osrm = (() => {
    return axios.create({
      baseURL:
        getConfigGlobal().osrmEndpoint || 'https://router.project-osrm.org',
    })
  })()
}

export default Axios
