import { useState, useEffect } from 'react'
import { Axios } from 'component/base'

export const useKumiaiinTekiyoKikan = (
  _kumiaiin_tekiyo_kikan_id,
  refresh = 0
) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const ajax = async kumiaiin_tekiyo_kikan_id => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/kumiaiin/kumiaiin/list/${kumiaiin_tekiyo_kikan_id}`,
      {}
    )
    if (error) {
      setLoading(false)
      return
    }
    setData(data || {})
    setLoading(false)
  }

  useEffect(() => {
    _kumiaiin_tekiyo_kikan_id && ajax(_kumiaiin_tekiyo_kikan_id)
  }, [_kumiaiin_tekiyo_kikan_id, refresh])

  return {
    data,
    loading,
  }
}
