import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  ConditionWrapper,
  BaseForm as Form,
  Text,
  Checkbox,
  Input,
  Dropdown,
} from 'component/base'
import { Custom } from 'component/pieces'

export const UriageHenpin = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    refresh,
    multiple,
    selectedId,
    onSelectChange,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])
  const [data, setData] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/buppan/uriage/uriage_henpin/list',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='ryokin'
            scrollX
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            defaultOrder={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

UriageHenpin.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

UriageHenpin.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'uriage_date' ? direction : null}
        onClick={() => handleSort('uriage_date')}
        content='売上日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'keijo_date' ? direction : null}
        onClick={() => handleSort('keijo_date')}
        content='返品計上日'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
        content='販売先'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'seikyusaki_torihikisaki_name' ? direction : null}
        onClick={() => handleSort('seikyusaki_torihikisaki_name')}
        content='請求先名'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'sonota_torihikisaki_name' ? direction : null}
        onClick={() => handleSort('sonota_torihikisaki_name')}
        content='その他販売先'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'busho_name' ? direction : null}
        onClick={() => handleSort('busho_name')}
        content='部署'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihiki_kbn' ? direction : null}
        onClick={() => handleSort('torihiki_kbn')}
        content='取引区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hanbaisaki_kbn' ? direction : null}
        onClick={() => handleSort('hanbaisaki_kbn')}
        content='販売先区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zei_gaku' ? direction : null}
        onClick={() => handleSort('zei_gaku')}
        content='消費税額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'keigen_zei_gaku' ? direction : null}
        onClick={() => handleSort('keigen_zei_gaku')}
        content='軽減税額'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'biko' ? direction : null}
        onClick={() => handleSort('biko')}
        content='備考'
      />
      <Table.HeaderCell
        style={{ width: '40.0rem', padding: '0rem' }}
        content={
          <Grid
            celled
            verticalAlign='middle'
            style={{ width: '40.0rem', margin: '0rem' }}
          >
            <Grid.Row>
              <Grid.Column>返品詳細</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column style={{ width: '24.0rem' }}>商品</Grid.Column>
              <Grid.Column style={{ width: '8.0rem' }}>数量</Grid.Column>
              <Grid.Column style={{ width: '8.0rem' }}>税抜額</Grid.Column>
            </Grid.Row>
          </Grid>
        }
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    uriage_date,
    keijo_date,
    torihikisaki_name,
    seikyusaki_torihikisaki_name,
    sonota_torihikisaki_name,
    busho_name,
    torihiki_kbn,
    hanbaisaki_kbn,
    zei_gaku,
    keigen_zei_gaku,
    biko,
    uriage_henpin_shosai,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={uriage_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={keijo_date} />
      <Table.Cell style={{ width: '15.0rem' }} content={torihikisaki_name} />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={seikyusaki_torihikisaki_name}
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={sonota_torihikisaki_name}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={busho_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.TorihikiKbn value={torihiki_kbn} />}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.HanbaisakiKbn value={hanbaisaki_kbn} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zei_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={keigen_zei_gaku} />}
      />
      <Table.Cell style={{ width: '20.0rem' }} content={biko} />
      <Table.Cell
        style={{ width: '40.0rem', padding: '0rem' }}
        content={
          <Grid
            celled
            verticalAlign='middle'
            style={{ width: '40.0rem', margin: '0rem' }}
          >
            {_.map(uriage_henpin_shosai, data => (
              <DetailsRow key={data.shiire_henpin_shosai_id} data={data} />
            ))}
          </Grid>
        }
      />
    </Table.Row>
  )
}

const DetailsRow = props => {
  const { data } = props

  const { shohin_name, su, zeinuki_gaku } = data || {}

  return (
    <>
      <Grid.Row>
        <Grid.Column
          style={{
            width: '24.0rem',
            maxWidth: '24.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {shohin_name}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '8.0rem',
            maxWidth: '8.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          textAlign='right'
        >
          {su}
        </Grid.Column>
        <Grid.Column
          style={{
            width: '8.0rem',
            maxWidth: '8.0rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          textAlign='right'
        >
          <Text.Comma content={zeinuki_gaku} />
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

const SearchElement = props => {
  const { condition } = props

  const {
    uriage_date_from,
    uriage_date_to,
    keijo_date_from,
    keijo_date_to,
    torihikisaki_id,
    busho_id,
  } = condition || {}

  return (
    <>
      <Grid.Column>
        <Form.Textbox.DatePicker
          inputAs={Input}
          value={uriage_date_from}
          label='売上日FROM'
          name='uriage_date_from'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          inputAs={Input}
          value={uriage_date_to}
          label='売上日TO'
          name='uriage_date_to'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          inputAs={Input}
          value={keijo_date_from}
          label='返品計上日FROM'
          name='keijo_date_from'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          inputAs={Input}
          value={keijo_date_to}
          label='返品計上日TO'
          name='keijo_date_to'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Hanbaisaki
          inputAs={Dropdown}
          value={torihikisaki_id}
          label='販売先'
          name='torihikisaki_id'
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Busho
          inputAs={Dropdown}
          value={busho_id}
          label='部署'
          name='busho_id'
          enableAuthValue={false}
          clearable
        />
      </Grid.Column>
    </>
  )
}
