import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  DataTable,
  Table,
  Checkbox,
  Grid,
  BaseForm as Form,
  Segment,
  ConditionWrapper,
  Label,
} from 'component/base'
import { Custom } from 'component/pieces'
import { useUserInfo } from 'component/hook'

export const Tokuisaki = withRouter(props => {
  const {
    history,
    mode,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
    loading,
    setLoading,
  } = props

  const { active_haiso_center_id } = useUserInfo()

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {
      haiso_center_id: active_haiso_center_id,
    }
  }, [active_haiso_center_id])

  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/tokuisaki',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
        mode={mode}
      />
    ),
    [history, handleChangeSelect, mode]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact={false}
        >
          <DataTable
            name='tokuisaki'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Tokuisaki.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
  mode: PropTypes.oneOf(['tokuisaki', 'iraisha']),
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
}

Tokuisaki.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
  mode: 'tokuisaki',
  loading: false,
  setLoading: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'tokuisaki_cd' ? direction : null}
        onClick={() => handleSort('tokuisaki_cd')}
        content='得意先コード'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'oya_tokuisaki_name' ? direction : null}
        onClick={() => handleSort('oya_tokuisaki_name')}
        content='親得意先'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'torihikisaki_id' ? direction : null}
        onClick={() => handleSort('torihikisaki_id')}
        content='取引先id'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'tokuisaki_name' ? direction : null}
        onClick={() => handleSort('tokuisaki_name')}
        content='得意先名称'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'tokuisaki_kana' ? direction : null}
        onClick={() => handleSort('tokuisaki_kana')}
        content='得意先カナ'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'seishiki_name1' ? direction : null}
        onClick={() => handleSort('seishiki_name1')}
        content='正式名称１'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'seishiki_name2' ? direction : null}
        onClick={() => handleSort('seishiki_name2')}
        content='正式名称２'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'ryaku_name' ? direction : null}
        onClick={() => handleSort('ryaku_name')}
        content='略称'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'gyoshu_kbn_name' ? direction : null}
        onClick={() => handleSort('gyoshu_kbn_name')}
        content='業種別区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'rank' ? direction : null}
        onClick={() => handleSort('rank')}
        content='ランク'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haiso_center_name' ? direction : null}
        onClick={() => handleSort('haiso_center_name')}
        content='配送センター'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'post_cd' ? direction : null}
        onClick={() => handleSort('post_cd')}
        content='郵便番号'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'address1' ? direction : null}
        onClick={() => handleSort('address1')}
        content='住所1'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'address2' ? direction : null}
        onClick={() => handleSort('address2')}
        content='住所2'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'fax' ? direction : null}
        onClick={() => handleSort('fax')}
        content='FAX番号'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'ryokin_name' ? direction : null}
        onClick={() => handleSort('ryokin_name')}
        content='料金'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haisha_tesuryo_rate' ? direction : null}
        onClick={() => handleSort('haisha_tesuryo_rate')}
        content='配車手数料率'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'irai_tesuryo_rate' ? direction : null}
        onClick={() => handleSort('irai_tesuryo_rate')}
        content='依頼手数料率'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tel' ? direction : null}
        onClick={() => handleSort('tel')}
        content='電話番号'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'mobile_tel' ? direction : null}
        onClick={() => handleSort('mobile_tel')}
        content='携帯電話番号'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'mail' ? direction : null}
        onClick={() => handleSort('mail')}
        content='メールアドレス'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'haisha_chui_jiko' ? direction : null}
        onClick={() => handleSort('haisha_chui_jiko')}
        content='配車注意事項'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'seikyu_kbn_name' ? direction : null}
        onClick={() => handleSort('seikyu_kbn_name')}
        content='請求区分'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'seikyusaki_tokuisaki_name' ? direction : null}
        onClick={() => handleSort('seikyusaki_tokuisaki_name')}
        content='請求先得意先'
      />
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'shime_day' ? direction : null}
        onClick={() => handleSort('shime_day')}
        content='請求締日'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'seikyushime_kensakuyo_group_cd' ? direction : null}
        onClick={() => handleSort('seikyushime_kensakuyo_group_cd')}
        content='請求締め検索用グループコード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kaishu_tsuki_kbn' ? direction : null}
        onClick={() => handleSort('kaishu_tsuki_kbn')}
        content='回収月区分'
      />
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'kaishu_day' ? direction : null}
        onClick={() => handleSort('kaishu_day')}
        content='回収日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zei_keisan_kbn' ? direction : null}
        onClick={() => handleSort('zei_keisan_kbn')}
        content='税計算区分'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'seikyu_tani_zei_hasu_shori_kbn' ? direction : null}
        onClick={() => handleSort('seikyu_tani_zei_hasu_shori_kbn')}
        content='請求単位税端数処理区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kurikoshi_umu_kbn' ? direction : null}
        onClick={() => handleSort('kurikoshi_umu_kbn')}
        content='繰越有無区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'nyukin_kinshu_kbn' ? direction : null}
        onClick={() => handleSort('nyukin_kinshu_kbn')}
        content='入金金種区分'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'haiso_center_koza_name' ? direction : null}
        onClick={() => handleSort('haiso_center_koza_name')}
        content='配送センター口座'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'seikyu_biko' ? direction : null}
        onClick={() => handleSort('seikyu_biko')}
        content='請求備考'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'keiri_biko' ? direction : null}
        onClick={() => handleSort('keiri_biko')}
        content='経理備考'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'is_driver_kakutei_mail_send' ? direction : null}
        onClick={() => handleSort('is_driver_kakutei_mail_send')}
        content='ドライバー確定メール送信'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'is_end_mail_send' ? direction : null}
        onClick={() => handleSort('is_end_mail_send')}
        content='終了メール送信'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'ng_unsoten_name_list' ? direction : null}
        onClick={() => handleSort('ng_unsoten_name_list')}
        content='NG運送店'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect, mode } = props || {}

  const {
    id,
    torihikisaki_id,
    tokuisaki_cd,
    tokuisaki_name,
    tokuisaki_kana,
    seishiki_name1,
    seishiki_name2,
    ryaku_name,
    gyoshu_kbn,
    rank,
    oya_tokuisaki_name,
    haisha_chui_jiko,
    shime_day,
    haiso_center_name,
    post_cd,
    address1,
    address2,
    fax,
    ryokin_name,
    haisha_tesuryo_rate,
    irai_tesuryo_rate,
    tel,
    mobile_tel,
    mail,
    seikyu_kbn,
    seikyusaki_tokuisaki_name,
    seikyushime_kensakuyo_group_cd,
    kaishu_tsuki_kbn,
    kaishu_day,
    zei_keisan_kbn,
    seikyu_tani_zei_hasu_shori_kbn,
    kurikoshi_umu_kbn,
    nyukin_kinshu_kbn,
    haiso_center_koza_name,
    seikyu_biko,
    keiri_biko,
    is_driver_kakutei_mail_send,
    is_end_mail_send,
    ng_unsoten_name_list,
  } = data || {}

  const targetId =
    mode === 'tokuisaki' ? id : mode === 'iraisha' ? torihikisaki_id : id

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(targetId) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(targetId, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox
            checked={isSelected}
            onChange={checkboxChange}
            value={targetId}
          />
        }
      />
      <Table.Cell style={{ width: '9.0rem' }} content={tokuisaki_cd} />
      <Table.Cell style={{ width: '20.0rem' }} content={oya_tokuisaki_name} />
      <Table.Cell style={{ width: '20.0rem' }} content={torihikisaki_id} />
      <Table.Cell style={{ width: '25.0rem' }} content={tokuisaki_name} />
      <Table.Cell style={{ width: '25.0rem' }} content={tokuisaki_kana} />
      <Table.Cell style={{ width: '25.0rem' }} content={seishiki_name1} />
      <Table.Cell style={{ width: '25.0rem' }} content={seishiki_name2} />
      <Table.Cell style={{ width: '15.0rem' }} content={ryaku_name} />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={<Custom.Label.GyoshuKbn value={gyoshu_kbn} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={rank} />
      <Table.Cell style={{ width: '10.0rem' }} content={haiso_center_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={post_cd} />
      <Table.Cell style={{ width: '20.0rem' }} content={address1} />
      <Table.Cell style={{ width: '20.0rem' }} content={address2} />
      <Table.Cell style={{ width: '10.0rem' }} content={fax} />
      <Table.Cell style={{ width: '20.0rem' }} content={ryokin_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={haisha_tesuryo_rate} />
      <Table.Cell style={{ width: '10.0rem' }} content={irai_tesuryo_rate} />
      <Table.Cell style={{ width: '10.0rem' }} content={tel} />
      <Table.Cell style={{ width: '10.0rem' }} content={mobile_tel} />
      <Table.Cell style={{ width: '20.0rem' }} content={mail} />
      <Table.Cell style={{ width: '15.0rem' }} content={haisha_chui_jiko} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.Seikyu value={seikyu_kbn} />}
      />
      <Table.Cell
        style={{ width: '25.0rem' }}
        content={seikyusaki_tokuisaki_name}
      />
      <Table.Cell style={{ width: '7.0rem' }} content={shime_day} />
      <Table.Cell
        style={{ width: '20.0rem' }}
        content={seikyushime_kensakuyo_group_cd}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.Kaishutsuki value={kaishu_tsuki_kbn} />}
      />
      <Table.Cell style={{ width: '7.0rem' }} content={kaishu_day} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.Zeikeisan value={zei_keisan_kbn} />}
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={
          <Custom.Label.HasuShori value={seikyu_tani_zei_hasu_shori_kbn} />
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.KurikoshiUmu value={kurikoshi_umu_kbn} />}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={<Custom.Label.NyukinKinshu value={nyukin_kinshu_kbn} />}
      />
      <Table.Cell
        style={{ width: '20.0rem' }}
        content={haiso_center_koza_name}
      />
      <Table.Cell style={{ width: '20.0rem' }} content={seikyu_biko} />
      <Table.Cell style={{ width: '20.0rem' }} content={keiri_biko} />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={
          is_driver_kakutei_mail_send ? (
            <Label icon='check' content='該当' />
          ) : (
            <Label icon='minus' content='非該当' />
          )
        }
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={
          is_end_mail_send ? (
            <Label icon='check' content='該当' />
          ) : (
            <Label icon='minus' content='非該当' />
          )
        }
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={
          _.isArray(ng_unsoten_name_list) ? ng_unsoten_name_list.join(', ') : ''
        }
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  const {
    haiso_center_id,
    tokuisaki_name,
    oya_tokuisaki_name,
    gyoshu_kbn,
    haisha_chui_jiko,
  } = condition

  return (
    <>
      <Grid.Column
        style={{
          display: !nav || haiso_center_id ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.HaisoCenter
          value={haiso_center_id}
          label='配送センター'
          multiple
          name='haiso_center_id'
          selection
          options={[]}
          enableAuthValue={false}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || tokuisaki_name ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={tokuisaki_name}
          label='得意先名'
          name='tokuisaki_name'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || oya_tokuisaki_name ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={oya_tokuisaki_name}
          label='親得意先名'
          name='oya_tokuisaki_name'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || gyoshu_kbn ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.GyoshuKbn
          value={gyoshu_kbn}
          label='業種別区分'
          name='gyoshu_kbn'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || haisha_chui_jiko ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={haisha_chui_jiko}
          label='配車注意事項'
          name='haisha_chui_jiko'
          options={[]}
        />
      </Grid.Column>
    </>
  )
}
