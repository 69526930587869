import React, { useEffect, useState } from 'react'
import * as Dropdown from 'component/base/Form/Dropdown'
import { Axios } from 'component/base'

export const SharyoForMember = props => {
  const { value, ...other } = props

  const [options, setOptions] = useState([])
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    setRefresh(refresh => ++refresh)
  }, [value])

  const getOptions = async () => {
    const { data, error } = await Axios.ajax.get(
      `/api/member/kumiaiin/zokusei_sharyo`
    )
    if (error) return
    const customOptions = data.map(d => {
      return {
        key: d.id,
        value: d.id,
        text: `${d.shashu_name} ${d.rikuun_shikyoku_ryaku_name}${d.shashu_no}${d.shashu_no_kana}${d.toroku_no}`,
      }
    })
    setOptions(customOptions)
  }

  useEffect(() => {
    getOptions()
  }, [])

  return (
    <Dropdown.Standard
      {...other}
      key={refresh}
      options={options}
      value={value}
    />
  )
}
