import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'semantic-ui-react'

const OPEN_TIME = 500
const CLOSE_TIME = 1000

const withTransition = Component => {
  class SemanticTransition extends React.Component {
    state = {
      visible: false,
      time: OPEN_TIME,
      animation: this.props.openAnimation,
    }

    componentDidMount() {
      if (this.props.time)
        this.timerId = setTimeout(this.onClose, this.props.time)
      this.setState({ visible: true })
    }

    onClose = () => {
      this.setState(
        prevState => ({
          visible: !prevState.visible,
          animation: this.props.closeAnimation,
          time: CLOSE_TIME,
        }),
        () => {
          setTimeout(() => {
            if (this.timerId) clearTimeout(this.timerId)

            this.props.onClose(this.props.notificationId)
          }, CLOSE_TIME)
        }
      )
    }

    render() {
      const { visible, time, animation } = this.state
      const styles = { marginBottom: '1em' }

      return (
        <Transition animation={animation} duration={time} visible={visible}>
          <div style={styles} role='presentation'>
            <Component {...this.props} onClose={this.onClose} />
          </div>
        </Transition>
      )
    }
  }
  SemanticTransition.propTypes = {
    notificationId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    openAnimation: PropTypes.string.isRequired,
    closeAnimation: PropTypes.string.isRequired,
    time: PropTypes.number,
  }
  SemanticTransition.defaultProps = {
    time: 2000,
  }

  return SemanticTransition
}

export default withTransition
