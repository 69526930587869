import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'

export const Nyukinkoza = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/nyukinkoza'
      keyName='id'
      valueName='id'
      textName='text'
      {...props}
    />
  )
}

Nyukinkoza.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

Nyukinkoza.defaultProps = {
  name: 'nyukinn_koza',
  label: '入金口座',
  value: undefined,
}
