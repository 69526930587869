import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'

export const Kinyukikan = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/kinyukikan'
      editOptions={options =>
        _(options)
          .map(option => ({
            key: option.id,
            value: option.id,
            text: `${option.kinyukikan_cd}：${option.kinyukikan_name}`,
            kinyukikan_cd: option.kinyukikan_cd,
          }))
          .value()
      }
      sort={['kinyukikan_cd']}
      {...props}
    />
  )
}

Kinyukikan.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

Kinyukikan.defaultProps = {
  name: 'kinyukikan_id',
  label: '金融機関',
  value: undefined,
}
