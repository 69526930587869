import React, { useState, useEffect } from 'react'
import { Axios, Icon, Card, Grid, useKeycloak } from 'component/base'
import { saveAs } from 'file-saver'
import { VideoCard } from '.'

export const UploadFile = props => {
  const { ids, mode, onDelete } = props

  const { keycloak } = useKeycloak()

  const onCancel = id => {
    onDelete(id)
  }

  return (
    <Card.Group style={{ fontSize: '1.0rem' }}>
      {ids.map((id, index) => (
        <FileCard
          key={index}
          onCancel={onCancel}
          fileId={id}
          mode={mode}
          url={
            keycloak.hasRealmRole('shokuin')
              ? '/api/staff/upload_files'
              : '/api/member/upload_files'
          }
          shouldShowVideo={true}
        />
      ))}
    </Card.Group>
  )
}

const FileCard = props => {
  const { fileId, onCancel, mode, url } = props

  const [data, setData] = useState([])

  useEffect(() => {
    const callApi = async () => {
      const { data, error } = await Axios.ajax.get(`${url}/info/${fileId}`)

      if (error) return
      setData(data)
    }

    callApi()
  }, [fileId])

  const { id, original_name, size, is_video } = data || {}

  const download = async () => {
    const { data, error } = await Axios.ajax.get(`${url}/${id}`, {
      responseType: 'blob',
    })

    if (error || !data) return

    const blob = new Blob([data], {
      type: data.type,
    })

    const fileName = getFileName(original_name)

    saveAs(blob, fileName)
  }

  const getFileName = contentDisposition => {
    return decodeURI(
      contentDisposition
        .substring(
          contentDisposition.indexOf("''") + 1,
          contentDisposition.length
        )
        .replace(/\+/g, ' ')
    )
  }

  return (
    <Card key={id} onClick={!is_video ? download : () => {}}>
      <Card.Content>
        {mode === 'new' ||
        mode === 'edit' ||
        mode === 'kumiaiin_maintenance' ? (
          <Icon
            className='right floated'
            size='big'
            name='remove circle'
            color='red'
            content='取消'
            onClick={e => {
              e.stopPropagation()
              onCancel(fileId)
            }}
          />
        ) : (
          <></>
        )}
        {is_video ? (
          <VideoCard fileId={fileId} fileName={original_name} />
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Column width={2}>
                <Icon size='big' name='file outline' color='teal' />
              </Grid.Column>
              <Grid.Column width={14}>
                <Card.Header>{original_name}</Card.Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Card.Content>

      <Card.Content extra>
        {size < 1000 ? 1 : Math.round(size / 1000)} Kbytes
      </Card.Content>
    </Card>
  )
}
