import React, { useRef } from 'react'
import _ from 'lodash'
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { BaseForm as Form, Button, Modal, Grid } from 'component/base'
import { createConversation, createUserConversations } from 'graphql/mutations'
import { useUserInfo } from 'component/hook'

const getUser = /* GraphQL */ `
  query users($after: String, $first: Int) {
    allUser(after: $after, first: $first) {
      id
      username
      displayName
      conversations {
        nextToken
        userConversations {
          conversationId
          userId
          conversation {
            name
            id
            createdAt
          }
        }
      }
    }
  }
`
 
const getMyConversations = /* GraphQL */ `
  query myConversations {
    me {
      conversations {
        userConversations {
          conversation {
            id
            name
          }
          associated {
            userId
          }
        }
      }
    }
  }
`

export const CreateConversation = props => {

  const {openMessage, open, onClose, mode} = props
  const {userInfo} = useUserInfo()
  const formRef = useRef()

  const { data, loading } = useQuery(gql(getUser), {
    variables: {},
    fetchPolicy: 'network-only'
  })
 
  const { data: myConversations } = useQuery(gql(getMyConversations),
    {
      variables: {},
      fetchPolicy: 'network-only'
    }
  )
 
  const [addC] = useMutation(gql(createConversation))
 
  const [addUC] = useMutation(gql(createUserConversations))

  const findExistConversationId = (userIds) => {
    
    if (mode === 'group') return null

    const result = _(myConversations?.me?.conversations?.userConversations)
      .map(uc => ({
        users: _(uc?.associated)
          .map(as => as?.userId)
          .filter(targetUserId => targetUserId !== userInfo.sub) // exclude myself
          .value(),
        conversationId: uc?.conversation?.id,
        conversationName: uc?.conversation?.name
      }))
      .filter(w => (_.isEmpty(w.conversationName))) // ignore group
      .filter(w => _.difference(w.users, [userIds]).length <= 0)
      .value()
 
    // return result[0]?.conversationId || null
    return result[0].conversationId
  }

  const handleStart = async model => {
    const { conversationName, userIds, myUserId } = model
    const conversationId = findExistConversationId(userIds)

    if (conversationId) {
      openMessage(conversationId)
      return
    }

    try {
      const conversation = await addC({
        variables: {
          name: mode === 'group' ? conversationName : undefined
        }
      })
 
      const conversationId = conversation.data.createConversation.id
      if (conversation.errors || !conversationId) {
        console.error('create conversation error', conversation.errors)
        return
      }
 
      // create user-conversation
      await Promise.all(
        _(Array.isArray(userIds) ? userIds : [userIds])
          .union([myUserId]) // add myself
          .map(async u => {
            const result = await addUC({
              variables: {
                conversationId,
                userId: u
              }
            })
 
            if (result.errors) {
              console.error('create user-conversation error', result.errors)
              return
            }
          })
      )

      openMessage(conversationId)

    } catch (e) {
      console.log(e)
    }
    
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      size='small'
    >
      <Modal.Header>{mode === 'group' ? 'グループチャットをはじめる' : 'チャットをはじめる'}</Modal.Header>
      <Modal.Content>
        <Form
          id='form'
          mapping={inputs => ({ ...inputs, myUserId: userInfo.sub })}
          ref={formRef}
          onValidSubmit={handleStart}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Textbox.Standard
                  label='グループの名前'
                  name='conversationName'
                  required
                />
              </Grid.Column>
            </Grid.Row>
            {!loading ? (
            <Grid.Row>
              <Grid.Column width={16}>
              <Form.Dropdown.Standard
                name='userIds'
                label='メンバー'
                style={{ marginRight: '15px' }}
                options={
                  data ? data.allUser.filter(u=>u.id !== userInfo.sub).map(u=>(
                    {
                      key: u.id,
                      text: u.displayName,
                      value: u.id
                    }
                  )) : []
                }
                multiple={mode === 'group'}
                width={16}
                required
              />
              </Grid.Column>
            </Grid.Row>
            ):(<></>)
          }
            <Grid.Row>
              <Grid.Column>
                <Button
                  icon='plus'
                  content='チャットを開始'
                  floated='right'
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

/*

          <Grid>
          {mode === 'group' ? (
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Input
                  label='グループの名前' 
                  name='conversationName'
                  value={conversationName}
                  onChange={(_e, { value }) => setConversationName(value)}
                  required
                />
              </Grid.Column>
            </Grid.Row>
            ):(<></>)
          }
          {!loading ? (
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Dropdown
                  width={16} 
                  placeholder='' 
                  label='メンバー' 
                  multiple={mode === 'group'}
                  value={userSelection}
                  selection
                  required 
                  options={data ? data.allUser.filter(u=>u.id !== userInfo.sub).map(u=>({
                    key: u.id,
                    text: u.displayName,
                    value: u.id}
                  )) : []}
                  onChange={(_e, d) => {
                    setUserSelection(d.value)
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            ):(<></>)
          }
          </Grid>
*/