import React, { Component } from 'react'
import { Label as SUILabel } from 'semantic-ui-react'
import { RadioGroup as FSUIRadioGroup } from '@akihirotakamura/formsy-semantic-ui-react'

class Group extends Component {
  static propTypes = {}

  static defaultProps = {
    passRequiredToField: true,
    errorLabel: <SUILabel color='red' pointing='left' />,
    validationError: 'this is default invalid message',
    validations: {},
    instantValidation: true,
  }

  render = () => {
    const { required, ...other } = this.props

    const validationErrors = {
      isDefaultRequiredValue: required ? 'Please select one of these' : '',
    }

    return (
      <FSUIRadioGroup
        {...other}
        required={required}
        validationErrors={validationErrors}
      />
    )
  }
}

export default Group
