import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Checkbox,
  DataTable,
  Grid,
  Table,
  Button,
  ConditionWrapper,
  BaseForm as Form,
  Segment,
} from 'component/base'

export const Kinyukikan = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(() => queryString.parse(window.location.search), [
    window.location.search,
  ])

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [displayData, setdisplayData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
          ? [selectedId]
          : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id)),
      data
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/kinyukikan',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
    setSelectedIds([])
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  useEffect(
    () => setdisplayData(_(data).orderBy(['kinyukikan_id'], ['asc']).value()),
    [data]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
        >
          <DataTable
            name='kinyukikan'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={displayData}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

Kinyukikan.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Kinyukikan.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => { },
}

const TableHeader = props => {
  const { column, direction, withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kinyukikan_cd' ? direction : null}
        onClick={() => handleSort('kinyukikan_cd')}
        content='金融機関コード'
      />
      <Table.HeaderCell
        style={{ width: '17.0rem' }}
        sorted={column === 'kinyukikan_name' ? direction : null}
        onClick={() => handleSort('kinyukikan_name')}
        content='金融機関名称'
      />
      <Table.HeaderCell
        style={{ width: '17.0rem' }}
        sorted={column === 'kinyukikan_kana' ? direction : null}
        onClick={() => handleSort('kinyukikan_kana')}
        content='金融機関カナ'
      />
      <Table.HeaderCell
        style={{ width: '7.0rem' }}
        sorted={column === 'shiten' ? direction : null}
        content=''
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const { id, kinyukikan_cd, kinyukikan_name, kinyukikan_kana } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={kinyukikan_cd} />
      <Table.Cell style={{ width: '17.0rem' }} content={kinyukikan_name} />
      <Table.Cell style={{ width: '17.0rem' }} content={kinyukikan_kana} />
      <Table.Cell style={{ width: '7.0rem' }}>
        <Button
          primary
          content='支店'
          as={Link}
          to={`/staff/master/kinyukikan/${id}/shiten`}
        />
      </Table.Cell>
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  const { kinyukikan_name, kinyukikan_kana } = condition

  return (
    <>
      <Grid.Column
        style={{ display: !nav || kinyukikan_name ? 'block' : 'none' }}
      >
        <Form.Textbox.Standard
          value={kinyukikan_name}
          label='金融機関名称'
          name='kinyukikan_name'
        />
      </Grid.Column>
      <Grid.Column
        style={{ display: !nav || kinyukikan_kana ? 'block' : 'none' }}
      >
        <Form.Textbox.Standard
          value={kinyukikan_kana}
          label='金融機関カナ'
          name='kinyukikan_kana'
        />
      </Grid.Column>
    </>
  )
}
