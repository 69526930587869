import { useMemo } from 'react'
import _ from 'lodash'
import { useLocalStorage } from 'component/hook'

export const useUserInfo = () => {
  const { localState, setLocalState } = useLocalStorage('user-active-info')

  const {
    // from keycloak token
    email,
    email_verified,
    family_name,
    given_name,
    locale,
    name,
    preferred_username,
    // custom attributes
    active_haiso_center_id: _active_haiso_center_id,
    active_busho_id: _active_busho_id,
    kumiaiin,
    shokuin,
  } = localState || {}

  const { riyo_kano_busho_id_list, riyo_kano_haiso_center_id_list } =
    shokuin || {}

  const active_busho_id = useMemo(() => {
    return _active_busho_id &&
      _.includes(riyo_kano_busho_id_list, _.toInteger(_active_busho_id))
      ? _.toInteger(_active_busho_id)
      : _.isArray(riyo_kano_busho_id_list)
      ? _.toInteger(riyo_kano_busho_id_list[0])
      : null
  }, [riyo_kano_busho_id_list, _active_busho_id])

  const active_haiso_center_id = useMemo(
    () =>
      _active_haiso_center_id &&
      _.includes(
        riyo_kano_haiso_center_id_list,
        _.toInteger(_active_haiso_center_id)
      )
        ? _.toInteger(_active_haiso_center_id)
        : _.isArray(riyo_kano_haiso_center_id_list)
        ? _.toInteger(riyo_kano_haiso_center_id_list[0])
        : null,
    [riyo_kano_haiso_center_id_list, _active_haiso_center_id]
  )

  const is_multi_busho = useMemo(
    () =>
      _.isArray(riyo_kano_busho_id_list) && riyo_kano_busho_id_list.length > 1,
    [riyo_kano_busho_id_list]
  )

  const is_multi_haiso_center = useMemo(
    () =>
      _.isArray(riyo_kano_haiso_center_id_list) &&
      riyo_kano_haiso_center_id_list.length > 1,
    [riyo_kano_haiso_center_id_list]
  )

  return {
    userInfo: localState,
    setUserInfo: setLocalState,
    email,
    email_verified,
    family_name, // 氏
    given_name, // 名
    locale,
    name, // 氏名
    preferred_username, // ログインID
    haiso_center_id_list: riyo_kano_haiso_center_id_list, // 所属のリスト
    busho_id_list: riyo_kano_busho_id_list, // 所属のリスト
    active_busho_id, // 現在カレントにしている部署
    active_haiso_center_id, // 現在カレントにしている配送センター
    is_multi_busho, // 複数所属かどうか
    is_multi_haiso_center, // 複数所属かどうか
    kumiaiin,
    shokuin,
  }
}
