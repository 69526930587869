import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Input,
  Dropdown,
  Text,
} from 'component/base'
import { Custom } from 'component/pieces'

export const KeshikomizumiSaimu = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(_.assign({}, defaultCondition))

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
    callApi(condition)
    setSelectedIds([])
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/kumiaiin/saimu/keshikomi',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name=''
            sortable
            filterable
            scrollX
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

KeshikomizumiSaimu.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

KeshikomizumiSaimu.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihikisaki_cd' ? direction : null}
        onClick={() => handleSort('torihikisaki_cd')}
        content='取引先コード'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
        content='取引先名'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'keijo_date' ? direction : null}
        onClick={() => handleSort('keijo_date')}
        content='計上日'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'kingaku' ? direction : null}
        onClick={() => handleSort('kingaku')}
        content='消込金額'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'saimu_meimoku_kbn' ? direction : null}
        onClick={() => handleSort('saimu_meimoku_kbn')}
        content='名目'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    torihikisaki_cd,
    torihikisaki_name,
    keijo_date,
    kingaku,
    saimu_meimoku_kbn_name_list,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '15.0rem' }} content={torihikisaki_cd} />
      <Table.Cell style={{ width: '15.0rem' }} content={torihikisaki_name} />
      <Table.Cell style={{ width: '15.0rem' }} content={keijo_date} />
      <Table.Cell
        style={{
          width: '15.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={kingaku} />}
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={saimu_meimoku_kbn_name_list.join(', ')}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const {
    keijo_date_from,
    keijo_date_to,
    torihikisaki_id,
    saimu_meimoku_kbn,
  } = condition

  return (
    <>
      <Grid.Column>
        <Form.Textbox.DatePicker
          name='keijo_date_from'
          inputAs={Input}
          value={keijo_date_from}
          label='計上日FROM'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          name='keijo_date_to'
          inputAs={Input}
          value={keijo_date_to}
          label='計上日TO'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Torihikisaki
          name='torihikisaki_id'
          inputAs={Dropdown}
          value={torihikisaki_id}
          label='取引先'
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.SaimuMeimokuKbn
          name='saimu_meimoku_kbn'
          inputAs={Dropdown}
          value={saimu_meimoku_kbn}
          label='名目'
        />
      </Grid.Column>
    </>
  )
}
