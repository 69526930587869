import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table, Image, Button, Icon } from 'semantic-ui-react'
import { FilteredTable } from './FilteredTable'
import { SortableTable } from './SortableTable'
import { ResizableTable } from './ResizableTable'

export const DataTable = props => {
  const { data, filterable, sortable, suppressRendering, ...other } =
    props || {}

  const TableComponent = useMemo(
    () =>
      filterable ? FilteredTable : sortable ? SortableTable : ResizableTable,
    [filterable, sortable]
  )

  return (
    <TableComponent
      {...other}
      data={suppressRendering ? [] : data}
      originalData={data}
    />
  )
}

DataTable.propTypes = {
  // row data(array includes flat object)
  data: PropTypes.array,

  // table header element(HOC)
  tableHeader: PropTypes.func,

  // table row element(HOC)
  tableRow: PropTypes.func,

  // enable filter
  filterable: PropTypes.bool,

  // default filter text
  defaltFilterText: PropTypes.string,

  // enable sort
  sortable: PropTypes.bool,

  // enable resize column width
  resizable: PropTypes.bool,

  // default row count per 1 pagination
  // 10, 25, 50, 100, 1000, Number.MAX_SAFE_INTEGER is available
  defaultPageLimit: PropTypes.number,

  // custom top control
  topControl: PropTypes.element,

  // custom bottom control
  bottomControl: PropTypes.element,

  // csv download file name
  downloadFileName: PropTypes.string,

  // enable datatabe loading effect
  loading: PropTypes.bool,

  // enable x scroll
  // it have to set static width style
  // to header-cell and body-cell when enabled x scroll
  scrollX: PropTypes.bool,

  // datatable height
  tableHeight: PropTypes.string,

  // enable columns order change on drug and drop
  // if undifined => disabled
  // fill index of column count(zero origin)
  // set like [0,1,2,3,4] for 5columns table
  // Important: Use with withOrder HOC
  defaultOrder: PropTypes.array,

  // suppress data rows rendering while true.
  // this for canceling onMount loading date
  suppressRendering: PropTypes.bool,

  // datatable size
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'huge', 'massive']),

  // default sort
  defaultSortColumnName: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf(['ascending', 'descending']),
}

DataTable.defaultProps = {
  data: [],
  tableHeader: props => {
    const { column, direction, handleSort } = props || {}

    return (
      <Table.Row>
        <Table.HeaderCell
          width={1}
          sorted={column === 'id' ? direction : null}
          onClick={() => handleSort('id')}
        >
          #
        </Table.HeaderCell>
        <Table.HeaderCell
          width={3}
          sorted={column === 'name' ? direction : null}
          onClick={() => handleSort('name')}
        >
          Name
        </Table.HeaderCell>
        <Table.HeaderCell
          width={3}
          sorted={column === 'country' ? direction : null}
          onClick={() => handleSort('country')}
        >
          Country
        </Table.HeaderCell>
        <Table.HeaderCell
          width={1}
          sorted={column === 'image' ? direction : null}
          onClick={() => handleSort('image')}
        >
          Image
        </Table.HeaderCell>
        <Table.HeaderCell
          width={1}
          sorted={column === 'favorite' ? direction : null}
          onClick={() => handleSort('favorite')}
        >
          Favorite
        </Table.HeaderCell>
      </Table.Row>
    )
  },
  tableRow: props => {
    const { data } = props || {}
    return (
      <Table.Row>
        <Table.Cell>{data.id}</Table.Cell>
        <Table.Cell>{data.name}</Table.Cell>
        <Table.Cell>{data.country}</Table.Cell>
        <Table.Cell>
          <Image src={data.image} rounded size='mini' />
        </Table.Cell>
        <Table.Cell textAlign='center'>
          <Button />
        </Table.Cell>
      </Table.Row>
    )
  },
  filterable: false,
  defaltFilterText: '',
  sortable: false,
  resizable: true,
  topControl: <></>,
  bottomControl: (
    <>
      <Button floated='right' icon labelPosition='left' primary size='small'>
        <Icon name='user' /> Add User
      </Button>
      <Button size='small'>Approve</Button>
      <Button disabled size='small'>
        Approve All
      </Button>
    </>
  ),
  defaultPageLimit: 10,
  downloadFileName: 'data.csv',
  loading: false,
  scrollX: false,
  tableHeight: '70vh',
  size: 'small',
}
