import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import kubun from 'config/kubun.json'

export const StaticLabel = props => {
  const { kubun_name, value } = props

  const exchangeName = useMemo(() => {
    const pick =
      kubun && kubun[kubun_name]
        ? _.find(kubun[kubun_name], obj => obj.value === value)
        : {}

    return pick ? pick.text || '-' : '-'
  }, [kubun_name, value])

  return <>{exchangeName}</>
}

StaticLabel.propTypes = {
  kubun_name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

StaticLabel.defaultProps = {
  value: '',
}
