import { addValidationRule } from '@akihirotakamura/formsy-react'
import moment from 'moment'

addValidationRule('isYMD', (values, value) => {
  if (!value || value === '') return true
  return (
    value.replace(/\u002f/g, '').match(/[0-9]{8}/) &&
    moment(value.replace(/\u002f/g, ''), 'YYYYMMDD').isValid()
  )
})
