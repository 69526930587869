import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'
import { useUserInfo } from 'component/hook'

export const HaisoCenterCurrent = props => {
  const {
    value, // will ignore
    ...other
  } = props

  const { active_haiso_center_id } = useUserInfo()

  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/haiso_center'
      keyName='id'
      valueName='id'
      textName='haiso_center_name'
      value={active_haiso_center_id}
      {...other}
      disabled
    />
  )
}

HaisoCenterCurrent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
}

HaisoCenterCurrent.defaultProps = {
  name: 'haiso_center_id',
  label: '配送センター',
}
