import React from 'react'
import { Dimmer, Header, Icon, Divider, Button } from 'component/base'
import { useKeycloak } from 'component/base'

const Login = props => {
  const { location } = props
  const { keycloak } = useKeycloak()

  const getPath = location => {
    if (location && location.state && location.state.from)
      return location.state.from.pathname
    if (location && location.from) return location.from.pathname
    return ''
  }
  const path = getPath(location)
  const redirectUri = `${window.location.protocol}//${window.location.host}${path}`

  return (
    <Dimmer active page>
      <Header as='h1' inverted>
        <Icon name='key' />
        <Header.Content>
          <span>ログインが必要です</span>
          <Header.Subheader>
            <span>Loginボタンからログインしてください</span>
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Divider />
      <Button
        color='teal'
        fluid
        size='massive'
        onClick={() => keycloak.login({ redirectUri })}
      >
        <Icon name='sign-in' />
        <span>ここからログイン</span>
      </Button>
    </Dimmer>
  )
}

export default Login
