import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  Button,
  BaseForm as Form,
} from 'component/base'
import { Custom } from 'component/pieces'

export const KumiaiinMail = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return { is_oshirase_mail_target: true }
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
          ? [selectedId]
          : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { is_oshirase_mail_target, ...other } = condition
    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/kumiaiin/list',
      {
        params: is_oshirase_mail_target
          ? {
            is_oshirase_mail_target: true,
            is_dattai: false,
            ...other,
          }
          : {
            is_all_mail_target: true,
            is_dattai: false,
            ...other,
          },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const oshirase_mail_data = useMemo(
    () => _.filter(data, kumiaiin => kumiaiin.oshirase_mail.length),
    [data]
  )

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  const handleAllSelect = () =>
    setSelectedIds(
      _(oshirase_mail_data)
        .map(row => row.id)
        .value()
    )

  const handleAllDeSelect = () => setSelectedIds([])

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='kumiaiinMail'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={oshirase_mail_data}
            topControl={
              <Button.Group>
                <Button content='全選択' onClick={handleAllSelect} />
                <Button content='全解除' onClick={handleAllDeSelect} />
              </Button.Group>
            }
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

KumiaiinMail.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
  mode: PropTypes.oneOf(['kumiaiin', 'iraisha', 'unsoten']),
}

KumiaiinMail.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => { },
  mode: 'kumiaiin',
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kumiaiin_cd' ? direction : null}
        onClick={() => handleSort('kumiaiin_cd')}
        content='組合員コード'
      />
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'kumiaiin_name' ? direction : null}
        onClick={() => handleSort('kumiaiin_name')}
        content='組合員名'
      />
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
        content='運送店名'
      />
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'oshirase_mail' ? direction : null}
        onClick={() => handleSort('oshirase_mail')}
        content='お知らせメールアドレス'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const { id, kumiaiin_cd, kumiaiin_name, unsoten_name, oshirase_mail } =
    data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={kumiaiin_cd} />
      <Table.Cell style={{ width: '16.0rem' }} content={kumiaiin_name} />
      <Table.Cell style={{ width: '16.0rem' }} content={unsoten_name} />
      <Table.Cell
        style={{ width: '16.0rem' }}
        content={oshirase_mail.join(', ')}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  const [haiso_center_id_list, set_haiso_center_id_list] = useState([])
  const [busho_id_list, set_busho_id_list] = useState([])
  const [chiku_id_list, set_chiku_id_list] = useState([])
  const [area_id_list, set_area_id_list] = useState([])
  const [team_id_list, set_team_id_list] = useState([])
  const [chiku_reset, set_chiku_reset] = useState(0)
  const [changed_haiso_center, set_changed_haiso_center] = useState(0)
  const [is_sodai, set_is_sodai] = useState()

  useEffect(() => {
    if (condition.haiso_center_id_list) {
      !_.isEmpty(condition.haiso_center_id_list)
        ? set_haiso_center_id_list(condition.haiso_center_id_list)
        : set_haiso_center_id_list([condition.haiso_center_id_list])
    }
    if (condition.shozoku_busho_id_list) {
      !_.isEmpty(condition.shozoku_busho_id_list)
        ? set_busho_id_list(condition.shozoku_busho_id_list)
        : set_busho_id_list([condition.shozoku_busho_id_list])
    }
  }, [condition])

  useEffect(() => {
    set_chiku_id_list(condition.shozoku_chiku_id_list)
  }, [condition.shozoku_chiku_id_list])

  useEffect(() => {
    set_area_id_list(condition.shozoku_area_id_list)
  }, [condition.shozoku_area_id_list])

  useEffect(() => {
    set_team_id_list(condition.team_id_list)
  }, [condition.team_id_list])

  useEffect(() => {
    set_is_sodai(condition.is_sodai)
  }, [condition.is_sodai])

  useEffect(() => {
    set_chiku_reset(reset => ++reset)
  }, [busho_id_list])

  useEffect(() => {
    set_changed_haiso_center(reset => ++reset)
  }, [haiso_center_id_list])

  return (
    <>
      <Grid.Column
        style={{
          display:
            !nav || condition.kumiai_yakushoku_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.KumiaiYakushoku
          value={condition.kumiai_yakushoku_id_list}
          label='組合役職'
          name='kumiai_yakushoku_id_list'
          multiple
          clearable
        />
      </Grid.Column>

      <Grid.Column
        style={{
          display:
            !nav || condition.is_sodai ? 'block' : 'none',
        }}
      >
        <Form.Field>
          <label>　</label>
          <Form.Checkbox.Buttony
            checked={is_sodai}
            label={
              is_sodai === true
                ? '総代のみ'
                : is_sodai === false
                  ? '総代以外'
                  : '総代指定なし'
            }
            onChange={(_e, { checked }) => {
              set_is_sodai(checked)
            }}
            name='is_sodai'
            enableIndeterminate
          />
        </Form.Field>
      </Grid.Column>

      <Grid.Column
        style={{
          display: !nav || condition.shozoku_busho_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Busho
          value={condition.shozoku_busho_id_list}
          label='所属部署'
          name='shozoku_busho_id_list'
          multiple
          clearable
          enableAuthValue={false}
          level_list={[0, 1, 2]}
          onChange={(e, { value }) => {
            set_busho_id_list(value)
            set_chiku_id_list([])
          }}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shozoku_chiku_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Chiku
          key={chiku_reset}
          value={chiku_id_list}
          label='所属地区'
          name='shozoku_chiku_id_list'
          multiple
          clearable
          disabled={busho_id_list.length === 0}
          busho_id_list={busho_id_list}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.haiso_center_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.HaisoCenter
          value={condition.haiso_center_id_list}
          label='所属配送センター'
          name='haiso_center_id_list'
          multiple
          clearable
          enableAuthValue={false}
          onChange={(e, { value }) => {
            set_haiso_center_id_list(value)
            set_area_id_list([])
            set_team_id_list([])
          }}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shozoku_area_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Area
          key={changed_haiso_center}
          value={area_id_list}
          label='所属エリア'
          name='shozoku_area_id_list'
          multiple
          clearable
          disabled={haiso_center_id_list.length === 0}
          haiso_center_id_list={haiso_center_id_list}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.team_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Team
          key={changed_haiso_center}
          value={team_id_list}
          label='チーム'
          name='team_id_list'
          multiple
          clearable
          haiso_center_id={
            _.isArray(haiso_center_id_list) && !_.isEmpty(haiso_center_id_list)
              ? haiso_center_id_list.length === 1
                ? haiso_center_id_list[0]
                : undefined
              : undefined
          }
          busho_id={
            _.isArray(busho_id_list) && !_.isEmpty(busho_id_list)
              ? busho_id_list.length === 1
                ? busho_id_list[0]
                : undefined
              : undefined
          }
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shashu_id_list ? 'block' : 'none',
        }}
      >
        <Custom.Dropdown.Shashu
          value={condition.shashu_id_list}
          label='車種'
          name='shashu_id_list'
          multiple
          clearable
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.is_hikkoshi_member ? 'block' : 'none',
        }}
      >
        <Form.Field>
          <label>　</label>
          <Form.Checkbox.Buttony
            checked={condition.is_hikkoshi_member}
            label='引越し'
            name='is_hikkoshi_member'
            enableIndeterminate
          />
        </Form.Field>
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.is_oshirase_mail_target ? 'block' : 'none',
        }}
      >
        <Form.Field>
          <label>　</label>
          <Form.Checkbox.Buttony
            checked={condition.is_oshirase_mail_target}
            label='お知らせメール対象者のみ'
            name='is_oshirase_mail_target'
            enableIndeterminate
          />
        </Form.Field>
      </Grid.Column>
    </>
  )
}
