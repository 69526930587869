import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form, useKeycloak } from 'component/base'
import { useUserInfo } from 'component/hook'

const Base = props => {
  const { url, value, enableAuthValue, ...other } = props

  const { active_haiso_center_id } = useUserInfo()

  return (
    <Form.Dropdown.WithAPI
      url={url}
      keyName='id'
      valueName='id'
      textName='haiso_center_name'
      value={enableAuthValue ? (value ? value : active_haiso_center_id) : value}
      sort={['haiso_center_cd']}
      {...other}
    />
  )
}

export const HaisoCenter = props => {
  const { keycloak } = useKeycloak()

  return (
    <Base
      url={
        keycloak.hasRealmRole('shokuin')
          ? '/api/staff/master/haiso_center'
          : '/api/member/master/haiso_center'
      }
      {...props}
    />
  )
}

HaisoCenter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  enableAuthValue: PropTypes.bool,
}

HaisoCenter.defaultProps = {
  name: 'haiso_center_id',
  label: '配送センター',
  value: undefined,
  enableAuthValue: true,
}

export const HaisoCenterAll = props => {
  const { tanso_cd, enableAuthValue, ...other } = props

  const { keycloak } = useKeycloak()

  return tanso_cd ? (
    <Base
      url={
        keycloak.hasRealmRole('shokuin')
          ? `/api/staff/master/haiso_center_all?tanso_cd=${tanso_cd}`
          : `/api/member/master/haiso_center_all?tanso_cd=${tanso_cd}`
      }
      enableAuthValue={enableAuthValue}
      {...other}
    />
  ) : (
    <Form.Dropdown.Standard options={[]} {...other} />
  )
}

HaisoCenterAll.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  enableAuthValue: PropTypes.bool,
  tanso_cd: PropTypes.any,
}

HaisoCenterAll.defaultProps = {
  name: 'haiso_center_id',
  label: '配送センター（全）',
  value: undefined,
  enableAuthValue: true,
  tanso_cd: null,
}
