import React, { useMemo } from 'react'
import { LayersControl, TileLayer } from 'react-leaflet'
import { BaseMap } from './BaseMap'
import { getConfigGlobal } from 'config'

export const BasicMap = props => {
  const { zoom } = props

  const mapLayers = useMemo(
    () => (
      <LayersControl.BaseLayer name='カラーMAP' checked>
        <TileLayer
          url={`${getConfigGlobal().tileEndpoint}/tile/{z}/{x}/{y}.png`}
        />
      </LayersControl.BaseLayer>
    ),
    []
  )

  return (
    <BaseMap
      {...props}
      mapLayers={mapLayers}
      minZoom={1}
      maxZoom={19}
      zoom={zoom ? zoom : Math.floor((1 + 20) / 2)}
    />
  )
}
