import React from 'react'
import ReactDOM from 'react-dom'

// polyfills for IE9/IE10/IE11
import 'react-app-polyfill/ie9'
import 'animate.css'
import './semantic/dist/semantic.min.css'
import './index.css'
import App from './App'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
