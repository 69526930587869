import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { SortableTable } from './SortableTable'
import { FilterBox } from './FilterBox'

export const FilteredTable = props => {
  const { data, loading, ...other } = props

  const [filterText, setFilterText] = useState()

  const onSubmitFilter = filterText => setFilterText(filterText)

  const filter = (arr, filterText) =>
    _.isEmpty(filterText)
      ? arr
      : _.filter(arr, row =>
          _.find(row, value => _.includes(_.toString(value), filterText))
        )

  const filteredData = useMemo(() => filter(data, filterText), [
    data,
    filterText,
  ])

  return (
    <>
      <SortableTable
        {...other}
        data={filteredData}
        loading={loading}
        attached={true}
        filterBox={
          <FilterBox
            onSubmitFilter={onSubmitFilter}
            filter={filterText}
            existsData={filteredData.length !== 0}
            loading={loading}
          />
        }
      />
    </>
  )
}

FilteredTable.propTypes = {
  data: PropTypes.array,
  tableHeader: PropTypes.func.isRequired,
  tableRow: PropTypes.func.isRequired,
  defaltFilterText: PropTypes.string,
  loading: PropTypes.bool,
}

FilteredTable.defaultProps = {
  data: [],
  defaltFilterText: '',
  loading: false,
}
