import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'

export const HatchiAreaDetailCity = props => {
  const edit = rows => {
    const aggregate = _.uniqBy(rows, v =>
      [v.hatchi_area, v.hatchi_area_detail, v.city].join()
    )

    return _.map(aggregate, (v, i) => ({
      key: i,
      value: v.city,
      text: `${v.hatchi_area_name} - ${v.hatchi_area_detail_name} - ${v.city_name}`,
    }))
  }

  return (
    <Form.Dropdown.WithAPI
      url='/api/member/setting/hatchiarea'
      editOptions={edit}
      {...props}
    />
  )
}

HatchiAreaDetailCity.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

HatchiAreaDetailCity.defaultProps = {
  name: 'hatchiarea_detail_city',
  label: '発地エリア',
  value: undefined,
}
