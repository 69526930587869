import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'

export const HatchiArea = props => {
  const edit = rows => {
    const aggregate = _.uniqBy(rows, v => [v.hatchi_area].join())

    return _.map(aggregate, (v, i) => ({
      key: i,
      value: v.hatchi_area,
      text: v.hatchi_area_name,
    }))
  }

  return (
    <Form.Dropdown.WithAPI
      url='/api/member/setting/hatchiarea'
      editOptions={edit}
      {...props}
    />
  )
}

HatchiArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

HatchiArea.defaultProps = {
  name: 'hatchiarea',
  label: '発地エリア',
  value: undefined,
}
