/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addDeviceToken = /* GraphQL */ `
  mutation AddDeviceToken($deviceTokens: [AddDeviceTokenInput]) {
    addDeviceToken(deviceTokens: $deviceTokens) {
      conversations {
        nextToken
      }
      deviceToken {
        provider
        token
      }
      displayName
      id
      isSystem
      messages {
        nextToken
      }
      realm
      registered
      username
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation($name: String) {
    createConversation(name: $name) {
      createdAt
      id
      messages {
        nextToken
      }
      name
      systemNoticeCode
      userConversations {
        conversationId
        lastReadTime
        unreadCount
        userId
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $content: String
    $conversationId: ID!
    $data: DataInput
  ) {
    createMessage(
      content: $content
      conversationId: $conversationId
      data: $data
    ) {
      author {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      content
      conversation {
        createdAt
        id
        name
        systemNoticeCode
      }
      conversationId
      createdAt
      data {
        url
        web
      }
      id
      isSent
      recipient {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      sender
    }
  }
`;
export const createSystemConversations = /* GraphQL */ `
  mutation CreateSystemConversations($name: String, $realm: String!) {
    createSystemConversations(name: $name, realm: $realm) {
      createdAt
      id
      messages {
        nextToken
      }
      name
      systemNoticeCode
      userConversations {
        conversationId
        lastReadTime
        unreadCount
        userId
      }
    }
  }
`;
export const createSystemMessage = /* GraphQL */ `
  mutation CreateSystemMessage(
    $content: String
    $conversationId: ID!
    $data: DataInput
    $sender: String
  ) {
    createSystemMessage(
      content: $content
      conversationId: $conversationId
      data: $data
      sender: $sender
    ) {
      author {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      content
      conversation {
        createdAt
        id
        name
        systemNoticeCode
      }
      conversationId
      createdAt
      data {
        url
        web
      }
      id
      isSent
      recipient {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      sender
    }
  }
`;
export const createSystemUser = /* GraphQL */ `
  mutation CreateSystemUser($realm: String!) {
    createSystemUser(realm: $realm) {
      conversations {
        nextToken
      }
      deviceToken {
        provider
        token
      }
      displayName
      id
      isSystem
      messages {
        nextToken
      }
      realm
      registered
      username
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser {
    createUser {
      conversations {
        nextToken
      }
      deviceToken {
        provider
        token
      }
      displayName
      id
      isSystem
      messages {
        nextToken
      }
      realm
      registered
      username
    }
  }
`;
export const createUserConversations = /* GraphQL */ `
  mutation CreateUserConversations($conversationId: ID!, $userId: ID!) {
    createUserConversations(conversationId: $conversationId, userId: $userId) {
      associated {
        conversationId
        lastReadTime
        unreadCount
        userId
      }
      conversation {
        createdAt
        id
        name
        systemNoticeCode
      }
      conversationId
      lastReadTime
      unreadCount
      user {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      userId
    }
  }
`;
export const updateLastReadTime = /* GraphQL */ `
  mutation UpdateLastReadTime($conversationId: ID!) {
    updateLastReadTime(conversationId: $conversationId) {
      associated {
        conversationId
        lastReadTime
        unreadCount
        userId
      }
      conversation {
        createdAt
        id
        name
        systemNoticeCode
      }
      conversationId
      lastReadTime
      unreadCount
      user {
        displayName
        id
        isSystem
        realm
        registered
        username
      }
      userId
    }
  }
`;
