import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'
import { useUserInfo } from 'component/hook'

export const HatchiAreaDetail = props => {
  const [refresh, setRefresh] = useState(0)

  const { active_haiso_center_id } = useUserInfo()

  const { myHaisoCenterOnly, ...other } = props

  useEffect(() => {
    setRefresh(refresh => ++refresh)
  }, [active_haiso_center_id, myHaisoCenterOnly])

  const edit = useCallback(
    rows =>
      _(rows)
        .filter(row =>
          active_haiso_center_id && myHaisoCenterOnly
            ? row.haiso_center_id === active_haiso_center_id
            : true
        )
        .map(row => ({
          key: row.id,
          value: row.id,
          text: `(${row.haiso_center_ryaku_name})${row.area_name}`,
        }))
        .value(),
    [active_haiso_center_id, myHaisoCenterOnly]
  )

  return (
    <Form.Dropdown.WithAPI
      key={refresh}
      url='/api/staff/master/area'
      editOptions={edit}
      {...other}
    />
  )
}

HatchiAreaDetail.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

HatchiAreaDetail.defaultProps = {
  name: 'area_id',
  label: 'エリア',
  value: undefined,
}
