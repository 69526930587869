import { AUTH_TYPE } from 'aws-appsync-auth-link'
import env from './config.json'

export const hostname = window.location.hostname

// export const getEnv = () => 'development'
// export const getConfig = () => env['development']['shutoken']
// export const getConfig = () => env['development']['localhost']
// export const getConfig = () => env['development']['local-prd']

// export const getEnv = () => 'stage'
// export const getConfig = () => env['stage']['shutoken']

// export const getEnv = () => 'production'
// export const getConfig = () => env['production']['shutoken']

export const getEnv = () => {
  return hostname.indexOf('dev.akabou-nais.com') >= 0 ||
    hostname.indexOf('localhost') >= 0
    ? 'development'
    : hostname.indexOf('stage.akabou-nais.com') >= 0
      ? 'stage'
      : 'production'
}

export const getConfig = () => {
  const subdomain = hostname.split('.').slice(0, 1)
  return env && env[getEnv()] && env[getEnv()][subdomain]
    ? env[getEnv()][subdomain]
    : {}
}

export const getConfigGlobal = () => env.global

export const getMediaUrl = () => {
  const env = getEnv()
  return `https://${env === 'development' ? 'dev.' : env === 'stage' ? 'stage.' : ''
    }${getConfigGlobal().mediaEndpoint}`
}

export const getConfigChat = jwtToken => ({
  url: env.notification[getEnv()] ? env.notification[getEnv()].url : '',
  region: env.notification[getEnv()] ? env.notification[getEnv()].region : '',
  auth: {
    type: AUTH_TYPE.OPENID_CONNECT,
    jwtToken,
  },
})

export const getHikkoshiUrl = () => {
  const env = getEnv()
  return `https://${env === 'development' ? 'dev.' : env === 'stage' ? 'stage.' : ''
    }${getConfigGlobal().hikkoshiEndpoint}`
}
