import { useState, useEffect } from 'react'
import _ from 'lodash'
import { Axios, notice } from 'component/base'

export const useSeikyuShiharai = (id, refresh) => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({})

  const {
    seikyu_shiharai,
    seikyu_shiharai_kumiai_seikyu,
    seikyu_shiharai_unsoten_seikyu,
    seikyu_shiharai_sakujo,
    meisai,
  } = state || {}

  const BASE_URL = '/api/staff/haisha/seikyu'

  const ajax_get = async () => {
    if (!id) return EMPTY_ROW

    setLoading(true)
    const { data, error } = await Axios.ajax.get(`${BASE_URL}/${id}`)
    setLoading(false)

    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      return { error }
    }
    setState({
      ...data,
      id,
    })
  }

  const ajax_post = async (_data, callback) => {
    setLoading(true)

    const body = _.cloneDeep(_data)
    delete body.id

    const { data, error } = await Axios.ajax.post(BASE_URL, body)
    setLoading(false)
    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      if (callback) callback({ error })
      return
    }

    notice({
      title: '登録しました',
      type: 'info',
    })
    setState(data)
    if (callback) callback({ data })
  }

  const ajax_patch = async (_data, callback) => {
    const {
      seikyu_shiharai: _seikyu_shiharai,
      seikyu_shiharai_kumiai_seikyu: _seikyu_shiharai_kumiai_seikyu,
      seikyu_shiharai_unsoten_seikyu: _seikyu_shiharai_unsoten_seikyu,
      seikyu_shiharai_sakujo: _seikyu_shiharai_sakujo,
      meisai: _meisai,
    } = _data || {}

    if (!id) return
    setLoading(true)

    const { data, error } = await Axios.ajax.patch(`${BASE_URL}/${id}`, {
      seikyu_shiharai:
        _.values(_seikyu_shiharai).length === 0
          ? seikyu_shiharai
          : _.assign({}, seikyu_shiharai, _seikyu_shiharai),
      seikyu_shiharai_kumiai_seikyu:
        _.values(_seikyu_shiharai_kumiai_seikyu).length === 0
          ? seikyu_shiharai_kumiai_seikyu
          : _.assign(
              {},
              seikyu_shiharai_kumiai_seikyu,
              _seikyu_shiharai_kumiai_seikyu
            ),
      seikyu_shiharai_unsoten_seikyu:
        _.values(_seikyu_shiharai_unsoten_seikyu).length === 0
          ? seikyu_shiharai_unsoten_seikyu
          : _.assign(
              {},
              seikyu_shiharai_unsoten_seikyu,
              _seikyu_shiharai_unsoten_seikyu
            ),
      seikyu_shiharai_sakujo:
        _.values(_seikyu_shiharai_sakujo).length === 0
          ? seikyu_shiharai_sakujo
          : _.assign({}, seikyu_shiharai_sakujo, _seikyu_shiharai_sakujo),
      meisai: _meisai,
      // 明細は更新時すべて洗い替えにするため、originalとのassignはしない
      // meisai:
      //   _(_meisai)
      //     .map(row => {
      //       // get from edited model
      //       const {
      //         seikyu_shiharai_meisai: _seikyu_shiharai_meisai,
      //         seikyu_shiharai_haisoryo_seikyu: _seikyu_shiharai_haisoryo_seikyu,
      //         seikyu_shiharai_haisoryo_shiharai: _seikyu_shiharai_haisoryo_shiharai,
      //       } = row || {}
      //
      //       const {
      //         id: _seikyu_shiharai_meisai_id
      //       } = _seikyu_shiharai_meisai || {}
      //
      //       // get from original state
      //       const org = _(meisai).find(row => {
      //         const {
      //           seikyu_shiharai_meisai
      //         } = row || {}
      //
      //         const {
      //           id: seikyu_shiharai_meisai_id
      //         } = seikyu_shiharai_meisai || {}
      //
      //         return (_seikyu_shiharai_meisai_id === seikyu_shiharai_meisai_id)
      //       })
      //
      //       const {
      //         seikyu_shiharai_meisai,
      //         seikyu_shiharai_haisoryo_seikyu,
      //         seikyu_shiharai_haisoryo_shiharai,
      //       } = org || {}
      //
      //       return {
      //         seikyu_shiharai_meisai:
      //           _.assign({}, seikyu_shiharai_meisai, _seikyu_shiharai_meisai)
      //         ,
      //         seikyu_shiharai_haisoryo_seikyu:
      //           _.assign({}, seikyu_shiharai_haisoryo_seikyu, _seikyu_shiharai_haisoryo_seikyu)
      //         ,
      //         seikyu_shiharai_haisoryo_shiharai:
      //           _.assign({}, seikyu_shiharai_haisoryo_shiharai, _seikyu_shiharai_haisoryo_shiharai)
      //         ,
      //       }
      //     })
      //     .value()
    })
    setLoading(false)

    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      if (callback) callback({ error })
      return
    }

    notice({
      title: '更新しました',
      type: 'info',
    })
    setState(data)
    if (callback) callback({ data })
  }

  const ajax_delete = async callback => {
    if (!id) return
    setLoading(true)
    const { error } = await Axios.ajax.delete(`${BASE_URL}/${id}`)
    setLoading(false)
    if (error) {
      notice({
        title: `Error ${error.status}`,
        description: error.statusText,
        type: 'error',
      })
      if (callback) callback({ error })
      return
    }
    notice({
      title: '削除しました',
      type: 'info',
    })
    if (callback) callback({})
  }

  useEffect(() => {
    ajax_get()
  }, [id, refresh])

  return {
    loading,
    ajax_post,
    ajax_patch,
    ajax_delete,
    seikyu_shiharai_data: state,
    seikyu_shiharai,
    seikyu_shiharai_kumiai_seikyu,
    seikyu_shiharai_unsoten_seikyu,
    seikyu_shiharai_sakujo,
    meisai,
  }
}

const EMPTY_ROW = {
  seikyu_shiharai: {},
  seikyu_shiharai_kumiai_seikyu: {},
  seikyu_shiharai_unsoten_seikyu: {},
  seikyu_shiharai_sakujo: {},
  meisai: [],
}
