import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Dropdown } from 'semantic-ui-react'

export class DownloadCsvButton extends React.Component {
  state = {
    loading: false,
    disabled: false,
  }

  onClick = (e, data) => {
    this.setState({ loading: true, disabled: true })

    _.delay(
      () => {
        const targetData =
          data.value === 'all' ? this.props.originalData : this.props.data

        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        const blob = new Blob([bom, this.jsonToCsv(targetData)], {
          type: 'text/csv',
        })
        const url = (window.URL || window.webkitURL).createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = this.props.downloadFileName
        a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':')
        a.click()
        this.setState({ loading: false, disabled: false })
      },
      100,
      'later'
    )
  }

  jsonToCsv = (data = null, delimiter = ',', linefeed = '\r\n') => {
    if (data === null || !data.length) return null

    const keys = Object.keys(data[0])
    const header = keys.join(delimiter) + linefeed

    const body = _.map(data, row =>
      _.map(keys, key => {
        const value = row[key]
        return _.isString(value)
          ? `"${value}"`
          : _.isObject(value) // when array or object
          ? `"${JSON.stringify(value).replace(/"/g, '')}"`
          : value
      })
    )

    return header + _.map(body, row => row.join(delimiter)).join(linefeed)
  }

  render() {
    const { loading, disabled } = this.state || {}

    return (
      <>
        <Dropdown
          text='CSV'
          icon='cloud download'
          labeled
          button
          upward
          className='icon'
          loading={loading}
          disabled={disabled}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              icon='file outline'
              value='limited'
              text='表示されているデータだけを出力'
              onClick={this.onClick}
            />
            <Dropdown.Item
              icon='file'
              value='all'
              text='全データを出力'
              onClick={this.onClick}
            />
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  }
}

DownloadCsvButton.propTypes = {
  data: PropTypes.array,
  originalData: PropTypes.array,
  downloadFileName: PropTypes.string,
}

DownloadCsvButton.defaultProps = {
  data: [],
  originalData: [],
  downloadFileName: 'data.csv',
}
