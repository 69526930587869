import React from 'react'
import PropTypes from 'prop-types'
import { Label, Dropdown } from 'semantic-ui-react'

const limitOptions = [
  { key: '0', value: 10, text: '10' },
  { key: '1', value: 25, text: '25' },
  { key: '2', value: 50, text: '50' },
  { key: '3', value: 100, text: '100' },
  { key: '4', value: 1000, text: '1000' },
  { key: '5', value: Number.MAX_SAFE_INTEGER, text: '全' },
]

export const PageLimitSelector = props => (
  <>
    <Label size='mini'>
      <Dropdown
        options={limitOptions}
        defaultValue={props.limit}
        onChange={props.onLimitChange}
      />
      <span>件表示</span>
    </Label>
  </>
)

PageLimitSelector.propTypes = {
  limit: PropTypes.number,
  onLimitChange: PropTypes.func,
}

PageLimitSelector.defaultProps = {
  limit: 10,
  onLimitChange: () => {},
}
