import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Dropdown from 'component/base/Form/Dropdown'
import { Axios } from 'component/base'

export const UnsotenSharyo = props => {
  const { unsoten_id, value, ...other } = props

  const [options, setOptions] = useState([])
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    setRefresh(refresh => ++refresh)
  }, [value])

  const getOptions = async () => {
    if (!unsoten_id) return
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/unsoten/${unsoten_id}/sharyo`
    )
    if (error) return
    const customOptions = data.map(d => {
      return {
        key: d.id,
        value: d.id,
        text: `${d.shashu_name} ${d.rikuun_shikyoku_ryaku_name}${d.shashu_no}${d.shashu_no_kana}${d.toroku_no}`,
      }
    })
    setOptions(customOptions)
  }

  useEffect(() => {
    getOptions()
  }, [unsoten_id])

  return (
    <Dropdown.Standard
      {...other}
      key={refresh}
      options={options}
      value={value}
    />
  )
}

UnsotenSharyo.propTypes = {
  unsoten_id: PropTypes.any,
}

UnsotenSharyo.defaultProps = {}
