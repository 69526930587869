import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Text,
  Util,
} from 'component/base'
import { Custom } from 'component/pieces'

export const SonotaSaikenSaimu = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    kind,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
        is_keshikomizumi: false,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {
      date_from: moment().startOf('month').format('YYYY/MM/DD'),
      date_to: moment().endOf('month').format('YYYY/MM/DD'),
    }
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/kumiaiin/${kind}/list`,
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableHeaderWithProps = useCallback(
    props => <TableHeader {...props} kind={kind} />,
    [kind]
  )

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
        kind={kind}
      />
    ),
    [history, handleChangeSelect, kind]
  )

  const SearchElementWithProps = useCallback(
    props => <SearchElement {...props} kind={kind} />,
    [kind]
  )

  const edited_data = useMemo(
    () =>
      _(data)
        .map(row => ({
          ...row,
          zangaku: _.sum([
            Util.Convert.moneyToInt(row.kingaku),
            Util.Convert.moneyToInt(row.keshikomizumi_kingaku) * -1,
          ]),
        }))
        .value(),
    [data]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElementWithProps}
          enableSidebar={false}
          compact
        >
          <DataTable
            name={`kumiaiin-${kind}`}
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeaderWithProps}
            tableRow={TableRowWithProps}
            data={edited_data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

SonotaSaikenSaimu.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
  kind: PropTypes.oneOf(['sonota_saiken', 'sonota_saimu']),
}

SonotaSaikenSaimu.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
  kind: 'sonota_saiken',
}

const TableHeader = props => {
  const { withOrder, handleSort, column, direction, kind } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'id' ? direction : null}
        onClick={() => handleSort('id')}
        content={kind === 'sonota_saiken' ? 'その他債権ID' : 'その他債務ID'}
      />
      {kind === 'sonota_saiken' && (
        <Table.HeaderCell
          style={{ width: '10.0rem' }}
          sorted={column === 'saiken_meimoku_kbn' ? direction : null}
          onClick={() => handleSort('saiken_meimoku_kbn')}
          content='債権名目'
        />
      )}
      {kind === 'sonota_saimu' && (
        <Table.HeaderCell
          style={{ width: '10.0rem' }}
          sorted={column === 'saimu_meimoku_kbn' ? direction : null}
          onClick={() => handleSort('saimu_meimoku_kbn')}
          content='債務名目'
        />
      )}
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihiki_name' ? direction : null}
        onClick={() => handleSort('torihiki_name')}
        content='取引種類'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihikisaki_kbn' ? direction : null}
        onClick={() => handleSort('torihikisaki_kbn')}
        content='取引先区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihikisaki_cd' ? direction : null}
        onClick={() => handleSort('torihikisaki_cd')}
        content='取引先コード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
        content='取引先名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'date' ? direction : null}
        onClick={() => handleSort('date')}
        content='対象年月日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tekiyo' ? direction : null}
        onClick={() => handleSort('tekiyo')}
        content='摘要'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kingaku' ? direction : null}
        onClick={() => handleSort('kingaku')}
        content='金額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'keshikomizumi_kingaku' ? direction : null}
        onClick={() => handleSort('keshikomizumi_kingaku')}
        content='消込済金額'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'zangaku' ? direction : null}
        onClick={() => handleSort('zangaku')}
        content='残額'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect, kind } = props || {}

  const {
    id,
    saiken_meimoku_kbn,
    saimu_meimoku_kbn,
    torihiki_name,
    // torihikisaki_kbn,
    torihikisaki_kbn_name,
    torihikisaki_cd,
    torihikisaki_name,
    date,
    tekiyo,
    kingaku,
    keshikomizumi_kingaku,
    zangaku,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={id} />
      {kind === 'sonota_saiken' && (
        <Table.Cell
          style={{ width: '10.0rem' }}
          content={<Custom.Label.SaikenMeimokuKbn value={saiken_meimoku_kbn} />}
        />
      )}
      {kind === 'sonota_saimu' && (
        <Table.Cell
          style={{ width: '10.0rem' }}
          content={<Custom.Label.SaimuMeimokuKbn value={saimu_meimoku_kbn} />}
        />
      )}
      <Table.Cell style={{ width: '10.0rem' }} content={torihiki_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={torihikisaki_kbn_name}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={torihikisaki_cd} />
      <Table.Cell style={{ width: '10.0rem' }} content={torihikisaki_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={date} />
      <Table.Cell style={{ width: '10.0rem' }} content={tekiyo} />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={kingaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={keshikomizumi_kingaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={zangaku} />}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition, kind } = props

  return (
    <>
      <Grid.Column>
        <Form.Textbox.DatePicker
          value={condition.date_from}
          label='対象年月日FROM'
          name='date_from'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.DatePicker
          value={condition.date_to}
          label='対象年月日TO'
          name='date_to'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.TorihikisakiKbn
          value={condition.torihikisaki_kbn_list}
          label='取引先区分'
          name='torihikisaki_kbn_list'
          multiple
        />
      </Grid.Column>

      {kind === 'sonota_saiken' && (
        <Grid.Column>
          <Custom.Dropdown.SaikenMeimokuKbn
            value={condition.saiken_meimoku_kbn}
            label='債権名目'
            name='saiken_meimoku_kbn'
          />
        </Grid.Column>
      )}
      {kind === 'sonota_saimu' && (
        <Grid.Column>
          <Custom.Dropdown.SaimuMeimokuKbn
            value={condition.saimu_meimoku_kbn}
            label='債務名目'
            name='saimu_meimoku_kbn'
          />
        </Grid.Column>
      )}
      <Grid.Column>
        <Form.Field>
          <label>　</label>
          <Form.Checkbox.Buttony
            name='is_keshikomizumi'
            label='残額がないデータも表示する'
            checked={condition.is_keshikomizumi}
            size='small'
            enableIndeterminate
          />
        </Form.Field>
      </Grid.Column>
    </>
  )
}
