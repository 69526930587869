import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form, Button, Grid, Modal } from 'component/base'
import { PageContainer } from 'component/pieces'

export class HanbaiTankaInputModal extends Component {
  mapModel = inputs => ({ ...inputs })

  render() {
    const { data, isOpenModal, onClickCancel, onClickDecision } = this.props

    return (
      <Modal
        closeOnDimmerClick={false}
        dimmer='inverted'
        onClose={onClickCancel}
        open={isOpenModal}
        size='large'
      >
        <Modal.Header>販売単価登録</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <PageContainer>
              <Form
                id='modalform'
                mapping={this.mapModel}
                onValidSubmit={onClickDecision}
                ref={ref => (this.form = ref)}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={3}>
                      <Form.Textbox.DatePicker
                        label='適用開始日'
                        name='tekiyo_start_ymd'
                        placeholder='YYYY/MM/DD'
                        value={data.tekiyo_start_ymd}
                        required
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column computer={3}>
                      <Form.Textbox.Standard
                        label='単組単価'
                        name='tansoin_tanka'
                        value={data.tansoin_tanka}
                        required
                      />
                    </Grid.Column>
                    <Grid.Column computer={3}>
                      <Form.Textbox.Standard
                        label='組合員単価'
                        name='kumiaiin_tanka'
                        value={data.kumiaiin_tanka}
                        required
                      />
                    </Grid.Column>
                    <Grid.Column computer={3}>
                      <Form.Textbox.Standard
                        label='非組合員単価'
                        name='hikumiaiin_tanka'
                        value={data.hikumiaiin_tanka}
                        required
                      />
                    </Grid.Column>
                  </Grid.Row>
                  ※税抜き価格を設定してください
                </Grid>
              </Form>
            </PageContainer>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content='登録'
            icon='checkmark'
            form='modalform'
            labelPosition='right'
            primary
          />
          <Button onClick={onClickCancel} content='閉じる' />
        </Modal.Actions>
      </Modal>
    )
  }
}

HanbaiTankaInputModal.propTypes = {
  data: PropTypes.object,
  isOpenModal: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickDecision: PropTypes.func,
}

HanbaiTankaInputModal.defaultProps = {
  data: {},
  isOpenModal: false,
  onClickCancel: () => {},
  onClickDecision: () => {},
}
