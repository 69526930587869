import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import moment from 'moment'
import JapaneseHolidays from 'japanese-holidays'
import YMD from './YMD'
import { Util } from 'component/base'
import 'css/jquery-ui.css'
import 'css/jquery-ui.theme.css'
import 'css/jquery-ui.structure.css'
import 'css/holiday.css'

const DatePicker = props => {
  const { required, onChange, onBlur, value: _value, ...other } = props

  const [value, setValue] = useState(_value)
  const [isOpen, setIsOpen] = useState(false)
  const [focused, setFocused] = useState(0)
  const [id] = useState(Util.getRandomID())

  useEffect(() => {
    loadDatePicker()
  }, [])

  useEffect(() => {
    setValue(_value)
  }, [_value])

  const handleOnChange = (e, { value }, valid) => {
    setValue(value)
    onChange(e, { value }, valid)
  }

  useEffect(() => {
    if (focused === 0) return

    // onBlur trigger -> open/close datepicker
    if (isOpen) return

    onBlur({ target: { value } }, { value }, isValid(value))
  }, [isOpen])

  const isValid = val => {
    if (required && (!val || val === '')) return false
    if (!val || val === '') return true

    const plain = val.toString().replace(/\//g, '')
    return plain.match(/[0-9]{8}/) && moment(plain, 'YYYYMMDD').isValid()
      ? true
      : false
  }

  const loadDatePicker = (
    date,
    formatDate = 'YYYY/MM/DD',
    numberOfMonths = 1
  ) => {
    const m = moment(date, formatDate)

    const defaultDate = m.isValid()
      ? m.format(formatDate)
      : moment().format(formatDate)

    $(`#${id}`).datepicker({
      formatDate,
      beforeShowDay: holidaySetting,
      beforeShow: (id, inst) => {
        setTimeout(() => {
          $('#ui-datepicker-div').css('z-index', '1001')
        }, 0)
      },
      onSelect: date => {
        handleOnChange({ target: { value: date } }, { value: date }, true)
      },
      onClose: () => setIsOpen(false),
      defaultDate,
      numberOfMonths,
    })
  }

  const holidaySetting = day => {
    const m = moment(day)
    if (m.format('YYYY/MM/DD') === '2021/07/22') return [true, 'datepicker-holiday', '海の日']
    if (m.format('YYYY/MM/DD') === '2021/07/23') return [true, 'datepicker-holiday', 'スポーツの日']
    if (m.format('YYYY/MM/DD') === '2021/08/08') return [true, 'datepicker-holiday', '山の日']
    if (m.format('YYYY/MM/DD') === '2021/08/09') return [true, 'datepicker-holiday', '振替休日']
    if (m.format('YYYY/MM/DD') === '2021/07/19') return [true, '', ''] // 本来の海の日
    if (m.format('YYYY/MM/DD') === '2021/08/11') return [true, '', '']  //本来の山の日
    if (m.format('YYYY/MM/DD') === '2021/10/11') return [true, '', '']  //本来のスポーツの日

    const holiday = JapaneseHolidays.isHoliday(day, true)
    if (holiday) return [true, 'datepicker-holiday', holiday]
    if (day.getDay() === 0) return [true, 'datepicker-sunday', '']
    if (day.getDay() === 6) return [true, 'datepicker-saturday', '']
    return [true, '', '']
  }

  return (
    <YMD
      {...other}
      type='tel'
      id={id}
      value={value}
      required={required}
      onChange={handleOnChange}
      onBlur={() => { }}
      onFocus={() => {
        setFocused(state => ++state)
        setIsOpen(true)
      }}
    />
  )
}

export default DatePicker

DatePicker.propTypes = {
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
}

DatePicker.defaultProps = {
  validations: {},
  onChange: () => { },
  onBlur: () => { },
  required: false,
}
