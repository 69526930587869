import { useState, useEffect } from 'react'
import _ from 'lodash'
import { Axios } from 'component/base'

export const useReverseGeocode = address => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)

  const ajax = async () => {
    if (!address || !_.isString(address)) return

    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/user/gps/search/address`,
      {
        params: {
          name: address
            .replace(/\(|\)|\||&|:|\*|!/g, '')
            .replace(/(^(\s|　)+)|((\s|　)+$)/g, ''),
        },
      }
    )
    setLoading(false)

    if (error) return
    if (data.length === 0) return

    setData(data)

    const { lat, lng } = data[0]

    setLat(lat)
    setLng(lng)
  }

  useEffect(() => {
    if (!address || address.length < 3) return
    ajax()
  }, [address])

  return {
    lat,
    lng,
    data,
    loading,
  }
}
