import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  Text,
  Popup,
  Label,
} from 'component/base'

export const DaigaeEnjokin = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(aggr_data, row =>
        _.find(selectedIds, id => row.daigae_enjokin_id === id)
      )
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/kumiaiin/daigae_enjokin/list',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const aggr_data = useMemo(
    () =>
      _(data)
        .groupBy('daigae_enjokin_id')
        .map((rows, key) => {
          const row = rows[0] || {}
          return {
            ...row,
            daigae_enjokin_gaku: _.sumBy(rows, 'daigae_enjokin_gaku'),
            chosei_daigae_enjokin_gaku: _.sumBy(
              rows,
              'chosei_daigae_enjokin_gaku'
            ),
            choseigo_daigae_enjokin_gaku: _.sumBy(
              rows,
              'choseigo_daigae_enjokin_gaku'
            ),
          }
        })
        .value(),
    [data]
  )

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='kumiaiin-daigae-enjokin'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={aggr_data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

DaigaeEnjokin.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

DaigaeEnjokin.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'jotai_kbn' ? direction : null}
        onClick={() => handleSort('jotai_kbn')}
        content='状態'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shime_date' ? direction : null}
        onClick={() => handleSort('shime_date')}
        content='締め年月日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'daigae_enjokin_gaku' ? direction : null}
        onClick={() => handleSort('daigae_enjokin_gaku')}
        content='代替援助金'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'chosei_daigae_enjokin_gaku' ? direction : null}
        onClick={() => handleSort('chosei_daigae_enjokin_gaku')}
        content='代替援助金（調整額）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'choseigo_daigae_enjokin_gaku' ? direction : null}
        onClick={() => handleSort('choseigo_daigae_enjokin_gaku')}
        content='代替援助金（調整後）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'datetime' ? direction : null}
        onClick={() => handleSort('datetime')}
        content='処理日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'preferred_username' ? direction : null}
        onClick={() => handleSort('preferred_username')}
        content='担当者ID'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'name' ? direction : null}
        onClick={() => handleSort('name')}
        content='担当者'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    daigae_enjokin_id: id,
    jotai_kbn,
    shime_date,
    daigae_enjokin_gaku,
    chosei_daigae_enjokin_gaku,
    choseigo_daigae_enjokin_gaku,
    datetime,
    preferred_username,
    name,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          jotai_kbn === 'operated' ? (
            <Popup
              content='調整入力・削除が可能です'
              trigger={<Label content='操作可能' />}
            />
          ) : jotai_kbn === 'closed' ? (
            <Popup
              content='締めが行われているので変更するには締めを解除してください'
              trigger={<Label color='orange' content='締め済' />}
            />
          ) : jotai_kbn === 'cleared' ? (
            <Popup
              content='すでに消込が行われているため、操作できません。'
              trigger={<Label color='red' content='消込済み' />}
            />
          ) : (
            <></>
          )
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={shime_date} />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={daigae_enjokin_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={chosei_daigae_enjokin_gaku} />}
      />
      <Table.Cell
        style={{
          width: '10.0rem',
          textAlign: 'right',
        }}
        content={<Text.Comma content={choseigo_daigae_enjokin_gaku} />}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={datetime} />
      <Table.Cell style={{ width: '10.0rem' }} content={preferred_username} />
      <Table.Cell style={{ width: '10.0rem' }} content={name} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  return (
    <>
      <Grid.Column
        style={{
          display: !nav || condition.shime_date_from ? 'block' : 'none',
        }}
      >
        <Form.Textbox.YMD
          value={condition.shime_date_from}
          label='締め年月日FROM'
          name='shime_date_from'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || condition.shime_date_to ? 'block' : 'none',
        }}
      >
        <Form.Textbox.YMD
          value={condition.shime_date_to}
          label='締め年月日TO'
          name='shime_date_to'
        />
      </Grid.Column>
    </>
  )
}
