import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm as Form } from 'component/base'

export const KaikeiBumon = props => {
  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/kaikei_bumon'
      keyName='id'
      valueName='id'
      textName='kaikei_bumon_cd'
      {...props}
    />
  )
}

KaikeiBumon.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
}

KaikeiBumon.defaultProps = {
  name: 'kaikei_bumon_id',
  label: '会計部門',
  value: undefined,
}
