import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Axios, Segment, DataTable, Table } from 'component/base'

export const SosaRireki = withRouter(props => {
  const { refresh, url } = props

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    callApi()
  }, [url, refresh])

  const callApi = async () => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(url)
    setLoading(false)
    if (error) return
    setData(data)
  }

  const TableRowWithProps = useCallback(props => <TableRow {...props} />, [
    props,
  ])

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <DataTable
          name='haishasosarireki'
          sortable
          tableHeight='50vh'
          tableHeader={StaticWidthTableHeader}
          tableRow={TableRowWithProps}
          data={data}
          bottomControl={<></>}
        />
      </Segment>
    </>
  )
})

SosaRireki.propTypes = {
  refresh: PropTypes.number,
}

SosaRireki.defaultProps = {
  refresh: 0,
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell
        style={{ width: '30.0rem' }}
        sorted={column === 'datetime' ? direction : null}
        onClick={() => handleSort('datetime')}
        content='操作日時'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'sosa_kbn_name' ? direction : null}
        onClick={() => handleSort('sosa_kbn_name')}
        content='操作区分'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'user_name' ? direction : null}
        onClick={() => handleSort('user_name')}
        content='ユーザー名'
      />
      <Table.HeaderCell
        style={{ width: '30.0rem' }}
        sorted={column === 'naiyo' ? direction : null}
        onClick={() => handleSort('naiyo')}
        content='内容'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data } = props || {}

  const { datetime, sosa_kbn_name, user_name, naiyo } = data || {}

  return withOrder(
    <Table.Row>
      <Table.Cell style={{ width: '30.0rem' }} content={datetime} />
      <Table.Cell style={{ width: '15.0rem' }} content={sosa_kbn_name} />
      <Table.Cell style={{ width: '20.0rem' }} content={user_name} />
      <Table.Cell style={{ width: '30.0rem' }} content={naiyo} />
    </Table.Row>
  )
}
