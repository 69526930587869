import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

moment.locale('ja', {
  weekdays: [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
  ],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
})

export const DayOfWeek = props => {
  const { ymd, onlyDayOfWeek } = props

  return !ymd || !moment(ymd, 'YYYY/MM/DD').isValid() ? (
    <>{onlyDayOfWeek ? '' : ymd}</>
  ) : (
    <>
      {moment(ymd, 'YYYY/MM/DD').format(
        onlyDayOfWeek ? '(ddd)' : 'YYYY/MM/DD(ddd)'
      )}
    </>
  )
}

export default DayOfWeek

DayOfWeek.propTypes = {
  ymd: PropTypes.string.isRequired,
  onlyDayOfWeek: PropTypes.bool,
}
