import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { geolocated } from 'react-geolocated'
import { Icon, Util, Axios, useKeycloak } from 'component/base'
import { useInterval, usePrevious, useLocalStorage } from 'component/hook'

export const GpsTrackingFilter = props => {
  const { children } = props

  const { keycloak } = useKeycloak()

  const [interval, setInterval] = useState(0)

  useInterval(() => {
    setInterval(state => ++state)
  }, 1000)

  const enableTracking = useMemo(() => Util.LowDB.get('gps-tracking').value(), [
    interval,
  ])

  return (
    <>
      {keycloak.hasRealmRole('kumiaiin') && enableTracking && (
        <>
          <GpsTrackingComponent />
          <GpsTaikiPolling />
        </>
      )}
      {children}
    </>
  )
}

const GpsTaikiPolling = props => {
  const { setLocalState: setTrackingEnabled } = useLocalStorage(
    'gps-tracking',
    false
  )

  const [interval, setInterval] = useState(0)

  useInterval(() => {
    setInterval(state => ++state)
  }, 60000)

  const getApi = async () => {
    const { data, error } = await Axios.ajax.get(
      '/api/member/haisha/gps_taiki',
      {}
    )
    if (error) return

    const { gps_taiki_jotai_kbn } = _.isArray(data) ? data[0] || {} : {}

    setTrackingEnabled(
      gps_taiki_jotai_kbn === 'soko' || gps_taiki_jotai_kbn === 'soko_gentei'
        ? true
        : false
    )
  }

  useEffect(() => {
    getApi()
  }, [interval])

  return <></>
}

const GpsTracking = props => {
  const { isGeolocationAvailable, coords } = props

  const { latitude, longitude } = coords || {}

  const prevState = usePrevious({
    latitude,
    longitude,
  })

  useEffect(() => {
    if (!isGeolocationAvailable) return

    const { latitude: prev_latitude, longitude: prev_longitude } =
      prevState || {}

    if (
      !_.isNumber(prev_latitude) ||
      !_.isNumber(prev_longitude) ||
      !_.isNumber(latitude) ||
      !_.isNumber(longitude)
    )
      return

    // excludes a bit of change location
    if (
      _.isEqual(_.round(prev_latitude, 3), _.round(latitude, 3)) &&
      _.isEqual(_.round(prev_longitude, 3), _.round(longitude, 3))
    )
      return

    ajax_post(latitude, longitude)
  }, [latitude, longitude])

  const ajax_post = async (lat, lng) => {
    if (!lat || !lng) return
    const { error } = await Axios.ajax.post('/api/member/haisha/gps_tsuiseki', {
      lat,
      lng,
    })

    if (error) {
      console.log('gps tracking error')
      console.log(error)
      return
    }
  }

  return (
    <>
      <div
        className='ui blue medium active progress'
        data-percent='100'
        style={{ margin: 0 }}
      >
        <div
          className='bar'
          style={{
            width: '100%',
            borderRadius: 0,
            textAlign: 'center',
            color: 'white',
          }}
        >
          <p>
            <span>
              <Icon name='location arrow' />
              GPS Tracking
            </span>
          </p>
        </div>
      </div>
    </>
  )
}

const GpsTrackingComponent = geolocated({
  positionOptions: {
    enableHighAccuracy: false,
    timeout: Infinity,
  },
  userDecisionTimeout: null,
  watchPosition: true,
})(GpsTracking)
