import React, { useState, useEffect } from 'react'
import { Axios, Image } from 'component/base'
import { Custom } from 'component/pieces'
import axios from 'axios'
import { useUnmounted } from 'component/hook'
import { getConfig } from 'config'

export const KumiaiinAvator = props => {
  const NO_IMAGE_URL = '/images/noimage.jpg'

  const unmounted = useUnmounted()
  const [blob, setBlob] = useState(null)
  const [loading, setLoading] = useState(false)
  const [source] = useState(axios.CancelToken.source())
  const [showKumiaiinDetailModal, setShowKumiaiinDetailModal] = useState(false)

  const { id, kumiaiin_cd, onClick, ...other } = props

  const { api_endpoint } = getConfig()

  useEffect(() => {
    getBlob(id)
  }, [id])

  useEffect(() => {
    if (unmounted.current) source.cancel('your request was canceled')
  }, [unmounted])

  const getBlob = async id => {
    if (!id) {
      setBlob(null)
      return
    }

    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `${api_endpoint}/api/staff/upload_files/${id}`,
      {
        responseType: 'arraybuffer',
        cancelToken: source.token,
        params: {},
      }
    )
    if (unmounted.current) return
    setLoading(false)

    if (error || !data) return
    setBlob(new Blob([data], { type: data.type }))
  }

  const handleOnClick = e => {
    e.stopPropagation()
    e.preventDefault()

    // onClick指定されている場合、組合員モーダルは出さない
    if (onClick) {
      onClick(e)
      return
    }

    if (!kumiaiin_cd) return
    setShowKumiaiinDetailModal(true)
  }

  return (
    <>
      {loading ? (
        <Image
          src={NO_IMAGE_URL}
          onError={e => {
            e.target.onerror = null
            e.target.src = NO_IMAGE_URL
          }}
          {...other}
          onClick={handleOnClick}
        />
      ) : blob ? (
        <Image
          src={(window.URL || window.webkitURL).createObjectURL(blob)}
          onError={e => {
            e.target.onerror = null
            e.target.src = NO_IMAGE_URL
          }}
          {...other}
          onClick={handleOnClick}
        />
      ) : (
        <Image
          src={NO_IMAGE_URL}
          onError={e => {
            e.target.onerror = null
            e.target.src = NO_IMAGE_URL
          }}
          {...other}
          onClick={handleOnClick}
        />
      )}

      {showKumiaiinDetailModal && (
        <Custom.Modal.KumiaiinDetailModal
          kumiaiin_cd={kumiaiin_cd}
          open={showKumiaiinDetailModal}
          onClose={() => setShowKumiaiinDetailModal(false)}
        />
      )}
    </>
  )
}
