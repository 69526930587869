import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { BaseForm as Form } from 'component/base'

export const Tokuisaki = props => {
  const { returnTorihikisakiId, ...other } = props

  return (
    <Form.Dropdown.WithAPI
      url='/api/staff/master/tokuisaki/slim'
      keyName='id'
      valueName={returnTorihikisakiId ? 'torihikisaki_id' : 'id'}
      textName='tokuisaki_name'
      editOptions={options =>
        _(options)
          .map(option => ({
            key: option.id,
            value: returnTorihikisakiId ? option.torihikisaki_id : option.id,
            text: `${option.tokuisaki_cd || '-'}：${option.tokuisaki_name || '-'
              }`,
            tokuisaki_cd: option.tokuisaki_cd,
          }))
          .value()
      }
      sort={['tokuisaki_cd']}
      {...other}
    />
  )
}

Tokuisaki.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  returnTorihikisakiId: PropTypes.bool,
}

Tokuisaki.defaultProps = {
  name: 'tokuisaki',
  label: '得意先',
  value: undefined,
  returnTorihikisakiId: false,
}
