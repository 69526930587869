import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
  List,
  Button,
  Popup,
} from 'component/base'

export const TansoSeikyuShiharaisaki = withRouter(props => {
  const {
    history,
    mode,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    loading,
    setLoading,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
          ? [selectedId]
          : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/tanso_seikyu_shiharaisaki/list',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
        mode={mode}
      />
    ),
    [history, handleChangeSelect, mode]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact={false}
        >
          <DataTable
            name='tansoSeikyuShiharaisaki'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

TansoSeikyuShiharaisaki.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
  mode: PropTypes.oneOf(['tanso', 'torihikisaki']),
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
}

TansoSeikyuShiharaisaki.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => { },
  mode: 'tanso',
  loading: false,
  setLoading: () => { },
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'seikyu_shiharaisaki_cd' ? direction : null}
        onClick={() => handleSort('seikyu_shiharaisaki_cd')}
        content='請求支払先コード'
      />
      <Table.HeaderCell
        style={{ width: '35.0rem' }}
        sorted={column === 'seikyu_shiharaisaki_name' ? direction : null}
        onClick={() => handleSort('seikyu_shiharaisaki_name')}
        content='請求支払先名称'
      />
      <Table.HeaderCell
        style={{ width: '35.0rem' }}
        sorted={column === 'seikyu_shiharaisaki_kana' ? direction : null}
        onClick={() => handleSort('seikyu_shiharaisaki_kana')}
        content='請求支払先カナ'
      />
      <Table.HeaderCell
        style={{ width: '35.0rem' }}
        sorted={column === 'seishiki_name1' ? direction : null}
        onClick={() => handleSort('seishiki_name1')}
        content='正式名称1'
      />
      <Table.HeaderCell
        style={{ width: '35.0rem' }}
        sorted={column === 'seishiki_name2' ? direction : null}
        onClick={() => handleSort('seishiki_name2')}
        content='正式名称2'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'ryaku_name' ? direction : null}
        onClick={() => handleSort('ryaku_name')}
        content='略称'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'haisha_tesuryo_rate' ? direction : null}
        onClick={() => handleSort('haisha_tesuryo_rate')}
        content='配車手数料率'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'irai_tesuryo_rate' ? direction : null}
        onClick={() => handleSort('irai_tesuryo_rate')}
        content='依頼手数料率'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'tel' ? direction : null}
        onClick={() => handleSort('tel')}
        content='電話番号'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'mobile_tel' ? direction : null}
        onClick={() => handleSort('mobile_tel')}
        content='携帯電話番号'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'fax' ? direction : null}
        onClick={() => handleSort('fax')}
        content='ファックス'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'mail' ? direction : null}
        onClick={() => handleSort('mail')}
        content='メール'
      />
      <Table.HeaderCell
        style={{ width: '25.0rem' }}
        sorted={column === 'haisha_chui_jiko' ? direction : null}
        onClick={() => handleSort('haisha_chui_jiko')}
        content='配車注意事項'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'seikyu_kbn_name' ? direction : null}
        onClick={() => handleSort('seikyu_kbn_name')}
        content='請求区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'post_cd' ? direction : null}
        onClick={() => handleSort('post_cd')}
        content='郵便番号'
      />
      <Table.HeaderCell
        style={{ width: '30.0rem' }}
        sorted={column === 'address1' ? direction : null}
        onClick={() => handleSort('address1')}
        content='住所１'
      />
      <Table.HeaderCell
        style={{ width: '30.0rem' }}
        sorted={column === 'address2' ? direction : null}
        onClick={() => handleSort('address2')}
        content='住所２'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'seikyusaki_tel' ? direction : null}
        onClick={() => handleSort('seikyusaki_tel')}
        content='請求先電話番号'
      />
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'seikyusaki_fax' ? direction : null}
        onClick={() => handleSort('seikyusaki_fax')}
        content='請求先FAX番号'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'shime_day' ? direction : null}
        onClick={() => handleSort('shime_day')}
        content='請求締日'
      />
      <Table.HeaderCell
        style={{ width: '18.0rem' }}
        sorted={column === 'seikyushime_kensakuyo_group_cd' ? direction : null}
        onClick={() => handleSort('seikyushime_kensakuyo_group_cd')}
        content='請求締め検索用グループコード'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'kaishu_tsuki_kbn_name' ? direction : null}
        onClick={() => handleSort('kaishu_tsuki_kbn_name')}
        content='回収月区分'
      />
      <Table.HeaderCell
        style={{ width: '5.0rem' }}
        sorted={column === 'kaishu_day' ? direction : null}
        onClick={() => handleSort('kaishu_day')}
        content='回収日'
      />
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'zei_keisan_kbn_name' ? direction : null}
        onClick={() => handleSort('zei_keisan_kbn_name')}
        content='税計算区分'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={
          column === 'seikyu_tani_zei_hasu_shori_kbn_name' ? direction : null
        }
        onClick={() => handleSort('seikyu_tani_zei_hasu_shori_kbn_name')}
        content='請求単位税端数処理区分'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'kurikoshi_umu_kbn_name' ? direction : null}
        onClick={() => handleSort('kurikoshi_umu_kbn_name')}
        content='繰越有無区分'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'nyukin_kinshu_kbn_name' ? direction : null}
        onClick={() => handleSort('nyukin_kinshu_kbn_name')}
        content='入金金種区分'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'seikyu_biko' ? direction : null}
        onClick={() => handleSort('seikyu_biko')}
        content='請求備考'
      />
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'keiri_biko' ? direction : null}
        onClick={() => handleSort('keiri_biko')}
        content='経理備考'
      />
      <Table.HeaderCell
        style={{ width: '9.0rem' }}
        sorted={column === 'ng_unsoten_name_list' ? direction : null}
        onClick={() => handleSort('ng_unsoten_name_list')}
        content='NG運送店'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect, mode } = props || {}

  const {
    id,
    torihikisaki_id,
    seikyu_shiharaisaki_cd,
    seikyu_shiharaisaki_name,
    seikyu_shiharaisaki_kana,
    seishiki_name1,
    seishiki_name2,
    ryaku_name,
    haisha_tesuryo_rate,
    irai_tesuryo_rate,
    tel,
    mobile_tel,
    fax,
    mail,
    haisha_chui_jiko,
    seikyu_kbn_name,
    post_cd,
    address1,
    address2,
    seikyusaki_tel,
    seikyusaki_fax,
    shime_day,
    seikyushime_kensakuyo_group_cd,
    kaishu_tsuki_kbn_name,
    kaishu_day,
    zei_keisan_kbn_name,
    seikyu_tani_zei_hasu_shori_kbn_name,
    kurikoshi_umu_kbn_name,
    nyukin_kinshu_kbn_name,
    seikyu_biko,
    keiri_biko,
    ng_unsoten_name_list,
  } = data || {}

  const targetId = mode === 'tanso' ? id : torihikisaki_id

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(targetId) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(targetId, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox
            checked={isSelected}
            onChange={checkboxChange}
            value={targetId}
          />
        }
      />
      <Table.Cell
        style={{ width: '12.0rem' }}
        content={seikyu_shiharaisaki_cd}
      />
      <Table.Cell
        style={{ width: '35.0rem' }}
        content={seikyu_shiharaisaki_name}
      />
      <Table.Cell
        style={{ width: '35.0rem' }}
        content={seikyu_shiharaisaki_kana}
      />
      <Table.Cell style={{ width: '35.0rem' }} content={seishiki_name1} />
      <Table.Cell style={{ width: '35.0rem' }} content={seishiki_name2} />
      <Table.Cell style={{ width: '25.0rem' }} content={ryaku_name} />
      <Table.Cell style={{ width: '9.0rem' }} content={haisha_tesuryo_rate} />
      <Table.Cell style={{ width: '9.0rem' }} content={irai_tesuryo_rate} />
      <Table.Cell style={{ width: '12.0rem' }} content={tel} />
      <Table.Cell style={{ width: '12.0rem' }} content={mobile_tel} />
      <Table.Cell style={{ width: '12.0rem' }} content={fax} />
      <Table.Cell style={{ width: '20.0rem' }} content={mail} />
      <Table.Cell style={{ width: '25.0rem' }} content={haisha_chui_jiko} />
      <Table.Cell style={{ width: '10.0rem' }} content={seikyu_kbn_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={post_cd} />
      <Table.Cell style={{ width: '30.0rem' }} content={address1} />
      <Table.Cell style={{ width: '30.0rem' }} content={address2} />
      <Table.Cell style={{ width: '12.0rem' }} content={seikyusaki_tel} />
      <Table.Cell style={{ width: '12.0rem' }} content={seikyusaki_fax} />
      <Table.Cell style={{ width: '8.0rem' }} content={shime_day} />
      <Table.Cell
        style={{ width: '18.0rem' }}
        content={seikyushime_kensakuyo_group_cd}
      />
      <Table.Cell style={{ width: '8.0rem' }} content={kaishu_tsuki_kbn_name} />
      <Table.Cell style={{ width: '5.0rem' }} content={kaishu_day} />
      <Table.Cell style={{ width: '8.0rem' }} content={zei_keisan_kbn_name} />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={seikyu_tani_zei_hasu_shori_kbn_name}
      />
      <Table.Cell
        style={{ width: '9.0rem' }}
        content={kurikoshi_umu_kbn_name}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={nyukin_kinshu_kbn_name}
      />
      <Table.Cell style={{ width: '20.0rem' }} content={seikyu_biko} />
      <Table.Cell style={{ width: '20.0rem' }} content={keiri_biko} />
      <Table.Cell
        style={{ width: '9.0rem' }}
        content={
          <Popup
            {...props}
            content={<List bulleted items={ng_unsoten_name_list} />}
            on='click'
            trigger={
              <Button
                icon='list'
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            }
            onClick={e => {
              e.stopPropagation()
            }}
            position='right center'
            basic
          />
        }
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { nav, condition } = props

  const {
    seikyu_shiharaisaki_name,
    seikyu_shiharaisaki_kana,
    seishiki_name1,
    seishiki_name2,
    ryaku_name,
    tel,
    shime_day,
    seikyushime_kensakuyo_group_cd,
  } = condition

  return (
    <>
      <Grid.Column
        style={{
          display: !nav || seikyu_shiharaisaki_name ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={seikyu_shiharaisaki_name}
          label='請求支払先名'
          name='seikyu_shiharaisaki_name'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || seikyu_shiharaisaki_kana ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={seikyu_shiharaisaki_kana}
          label='請求支払先カナ'
          name='seikyu_shiharaisaki_kana'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || seishiki_name1 ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={seishiki_name1}
          label='正式名称１'
          name='seishiki_name1'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || seishiki_name2 ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={seishiki_name2}
          label='正式名称２'
          name='seishiki_name2'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || ryaku_name ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={ryaku_name}
          label='略称'
          name='ryaku_name'
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || tel ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard value={tel} label='電話番号' name='tel' />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || shime_day ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Int
          value={shime_day}
          label='請求締日'
          name='shime_day'
          allowDefaultZero={false}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: !nav || seikyushime_kensakuyo_group_cd ? 'block' : 'none',
        }}
      >
        <Form.Textbox.Standard
          value={seikyushime_kensakuyo_group_cd}
          label='請求締め検索用グループコード'
          name='seikyushime_kensakuyo_group_cd'
        />
      </Grid.Column>
    </>
  )
}
