import { addValidationRule } from '@akihirotakamura/formsy-react'
import moment from 'moment'

addValidationRule('isUnfutureYMD', (values, value) => {
  if (!value || value === '') return true
  const plain = value.toString().replace(/\u002f/g, '')
  const m = moment(plain, 'YYYYMMDD')
  if (!m.isValid()) return true

  return !m.isAfter(moment(), 'days')
})
