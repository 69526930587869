import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import { Menu, List, Modal, Segment, Grid, useKeycloak } from 'component/base'
import { useUserInfo, useLocalStorage } from 'component/hook'
import { Custom } from 'component/pieces'

export const ShozokuMenu = props => {
  const { keycloak } = useKeycloak()

  const {
    active_busho_id,
    active_haiso_center_id,
    is_multi_busho,
    is_multi_haiso_center,
    haiso_center_id_list,
    busho_id_list,
  } = useUserInfo()

  const [showBushoModal, setShowBushoModal] = useState(false)
  const [showHaisoCenterModal, setShowHaisoCenterModal] = useState(false)

  const HaisoCenterMenu = useCallback(
    props => (
      <List.Item
        icon='truck'
        content={<Custom.Label.HaisoCenter id={active_haiso_center_id} />}
        onClick={() => {
          if (!is_multi_haiso_center) return
          setShowHaisoCenterModal(state => !state)
        }}
      />
    ),
    [active_haiso_center_id, is_multi_haiso_center]
  )

  const BushoMenu = useCallback(
    props => (
      <List.Item
        icon='home'
        content={<Custom.Label.Busho id={active_busho_id} />}
        onClick={() => {
          if (!is_multi_busho) return
          setShowBushoModal(state => !state)
        }}
      />
    ),
    [active_busho_id, is_multi_busho]
  )

  return (
    <>
      {!keycloak.authenticated ||
      !keycloak.hasRealmRole('shokuin') ||
      (!active_haiso_center_id && !active_busho_id) ? (
        <></>
      ) : (
        <>
          <Menu.Item
            content={
              <List size='mini'>
                <HaisoCenterMenu />
                <BushoMenu />
              </List>
            }
          />
          <BushoSwitchModal
            show={showBushoModal}
            setShow={setShowBushoModal}
            active_busho_id={active_busho_id}
            busho_id_list={busho_id_list}
          />
          <HaisoCenterSwitchModal
            show={showHaisoCenterModal}
            setShow={setShowHaisoCenterModal}
            active_haiso_center_id={active_haiso_center_id}
            haiso_center_id_list={haiso_center_id_list}
          />
        </>
      )}
    </>
  )
}

const BushoSwitchModal = props => {
  const { show, setShow, active_busho_id, busho_id_list } = props

  const { setLocalState } = useLocalStorage('user-active-info')

  const [currentId, setCurrentId] = useState(active_busho_id)

  return (
    <Modal
      open={show}
      header='部署切替'
      size='tiny'
      content={
        <Segment basic>
          <Grid>
            <Grid.Column>
              <List divided relaxed size='large'>
                {_(busho_id_list)
                  .map(id => (
                    <List.Item
                      key={id}
                      onClick={() => {
                        setCurrentId(id)
                      }}
                      style={{
                        backgroundColor: currentId === id ? '#bfe4ff' : '',
                      }}
                    >
                      <List.Icon name={currentId === id ? 'check' : 'minus'} />
                      <List.Content>
                        <Custom.Label.Busho id={id} />
                      </List.Content>
                    </List.Item>
                  ))
                  .value()}
              </List>
            </Grid.Column>
          </Grid>
        </Segment>
      }
      actions={[
        {
          key: 1,
          content: 'キャンセル',
          onClick: () => {
            setShow(false)
          },
        },
        {
          key: 2,
          content: '確定',
          primary: true,
          onClick: () => {
            setLocalState(state =>
              _.assign({}, state, {
                active_busho_id: currentId,
              })
            )
            window.location.reload()
          },
        },
      ]}
    />
  )
}

const HaisoCenterSwitchModal = props => {
  const { show, setShow, active_haiso_center_id, haiso_center_id_list } = props

  const { setLocalState } = useLocalStorage('user-active-info')

  const [currentId, setCurrentId] = useState(active_haiso_center_id)

  return (
    <Modal
      open={show}
      header='配送センター切替'
      size='tiny'
      content={
        <Segment basic>
          <Grid>
            <Grid.Column>
              <List divided relaxed size='large'>
                {_(haiso_center_id_list)
                  .map(id => (
                    <List.Item
                      key={id}
                      onClick={() => {
                        setCurrentId(id)
                      }}
                      style={{
                        backgroundColor: currentId === id ? '#bfe4ff' : '',
                      }}
                    >
                      <List.Icon name={currentId === id ? 'check' : 'minus'} />
                      <List.Content>
                        <Custom.Label.HaisoCenter id={id} />
                      </List.Content>
                    </List.Item>
                  ))
                  .value()}
              </List>
            </Grid.Column>
          </Grid>
        </Segment>
      }
      actions={[
        {
          key: 1,
          content: 'キャンセル',
          onClick: () => {
            setShow(false)
          },
        },
        {
          key: 2,
          content: '確定',
          primary: true,
          onClick: () => {
            setLocalState(state =>
              _.assign({}, state, {
                active_haiso_center_id: currentId,
              })
            )
            window.location.reload()
          },
        },
      ]}
    />
  )
}
