import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import {
  Axios,
  Grid,
  Segment,
  DataTable,
  Table,
  Checkbox,
  ConditionWrapper,
  BaseForm as Form,
} from 'component/base'

export const HaisoCenter = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(() => {
    return {}
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [displayData, setdisplayData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [isRecentOnly, setIsRecentOnly] = useState(true)
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id)),
      data
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  useEffect(
    () =>
      setdisplayData(
        isRecentOnly
          ? _(data)
              .orderBy(
                ['haiso_center_id', 'tekiyo_start_date'],
                ['asc', 'desc']
              )
              .uniqBy('haiso_center_id')
              .value()
          : data
      ),
    [isRecentOnly, data]
  )

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/master/haiso_center_zokusei',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='haiso_center'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={StaticWidthTableHeader}
            tableRow={TableRowWithProps}
            data={displayData}
            topControl={
              <Checkbox
                toggle
                checked={isRecentOnly}
                label='最新世代のみ表示'
                onChange={(e, { checked }) => setIsRecentOnly(checked)}
              />
            }
            bottomControl={<></>}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

HaisoCenter.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

HaisoCenter.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const StaticWidthTableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'haiso_center_name' ? direction : null}
        onClick={() => handleSort('haiso_center_name')}
        content='配送センター名称'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'haiso_center_ryaku_name' ? direction : null}
        onClick={() => handleSort('haiso_center_ryaku_name')}
        content='配送センター略名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haiso_center_cd' ? direction : null}
        onClick={() => handleSort('haiso_center_cd')}
        content='配送センターコード'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tekiyo_start_date' ? direction : null}
        onClick={() => handleSort('tekiyo_start_date')}
        content='適用開始日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tekiyo_end_date' ? direction : null}
        onClick={() => handleSort('tekiyo_end_date')}
        content='適用終了日'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hikiuke_seigen_su' ? direction : null}
        onClick={() => handleSort('hikiuke_seigen_su')}
        content='引き受け制限数'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'address1' ? direction : null}
        onClick={() => handleSort('address1')}
        content='住所1'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'address2' ? direction : null}
        onClick={() => handleSort('address2')}
        content='住所2'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'mail' ? direction : null}
        onClick={() => handleSort('mail')}
        content='メールアドレス'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tel' ? direction : null}
        onClick={() => handleSort('tel')}
        content='電話番号'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'fax' ? direction : null}
        onClick={() => handleSort('fax')}
        content='FAX番号'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kaikei_bumon_name' ? direction : null}
        onClick={() => handleSort('kaikei_bumon_name')}
        content='会計部門'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tekikaku_invoice_toroku_date' ? direction : null}
        onClick={() => handleSort('tekikaku_invoice_toroku_date')}
        content='適格請求書発行事業者登録年月日'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'tekikaku_invoice_toroku_no' ? direction : null}
        onClick={() => handleSort('tekikaku_invoice_toroku_no')}
        content='適格請求書発行事業者登録番号'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    id,
    haiso_center_name,
    haiso_center_ryaku_name,
    haiso_center_cd,
    tekiyo_start_date,
    tekiyo_end_date,
    hikiuke_seigen_su,
    address1,
    address2,
    mail,
    tel,
    fax,
    kaikei_bumon_name,
    tekikaku_invoice_toroku_date,
    tekikaku_invoice_toroku_no,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell style={{ width: '15.0rem' }} content={haiso_center_name} />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={haiso_center_ryaku_name}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={haiso_center_cd} />
      <Table.Cell style={{ width: '10.0rem' }} content={tekiyo_start_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={tekiyo_end_date} />
      <Table.Cell style={{ width: '10.0rem' }} content={hikiuke_seigen_su} />
      <Table.Cell style={{ width: '15.0rem' }} content={address1} />
      <Table.Cell style={{ width: '15.0rem' }} content={address2} />
      <Table.Cell style={{ width: '15.0rem' }} content={mail} />
      <Table.Cell style={{ width: '10.0rem' }} content={tel} />
      <Table.Cell style={{ width: '10.0rem' }} content={fax} />
      <Table.Cell style={{ width: '10.0rem' }} content={kaikei_bumon_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={tekikaku_invoice_toroku_date}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={tekikaku_invoice_toroku_no}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const { haiso_center_name } = condition

  return (
    <>
      <Grid.Column>
        <Form.Textbox.Standard
          value={haiso_center_name}
          label='配送センター名'
          name='haiso_center_name'
        />
      </Grid.Column>
    </>
  )
}
