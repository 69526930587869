import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Standard from './Standard'
import kubun from 'config/kubun.json'

const Static = props => {
  const { value, kubunKey, ...other } = props

  const [refresh, setRefresh] = useState(0)
  useEffect(() => {
    setRefresh(refresh => ++refresh)
  }, [value])

  return (
    <Standard
      {...other}
      key={refresh}
      value={value}
      options={kubun[kubunKey]}
    />
  )
}

Static.propTypes = {
  kubunKey: PropTypes.string,
}

Static.defaultProps = {
  kubunKey: '',
}

export default Static
