import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Axios, Button, Modal } from 'component/base'
import { PageContainer } from 'component/pieces'
import { Custom } from 'component/pieces'

export const HanbaiTankaRirekiModal = props => {
  const { isOpenModal, onClickCancel, shohin_id } = props

  const [shohinData, setShohinData] = useState({})

  useEffect(() => {
    get_shohin_name(shohin_id)
  }, [shohin_id])

  const get_shohin_name = async shohin_id => {
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/shohin/${shohin_id}`
    )
    if (error) return
    setShohinData(data)
  }

  return (
    <Modal
      dimmer='inverted'
      open={isOpenModal}
      closeOnDimmerClick={false}
      size='large'
    >
      <Modal.Header
        content={'販売単価履歴（商品：' + shohinData.shohin_name + '）'}
      />
      <Modal.Content image scrolling>
        <Modal.Description>
          <PageContainer style={{ width: '90vw' }}>
            <Custom.DataTable.HanbaiTankaRireki shohin_id={shohin_id} />
          </PageContainer>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClickCancel(false)} content='閉じる' />
      </Modal.Actions>
    </Modal>
  )
}

HanbaiTankaRirekiModal.propTypes = {
  isOpenModal: PropTypes.bool,
  onClickCancel: PropTypes.func,
}

HanbaiTankaRirekiModal.defaultProps = {
  isOpenModal: false,
  onClickCancel: () => {},
}
