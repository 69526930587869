import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import withProps from 'recompose/withProps'
import _ from 'lodash'
import {
  Axios,
  Segment,
  DataTable,
  Table,
  Text,
  Checkbox,
  Button,
} from 'component/base'

export const Sosai = withRouter(props => {
  const { condition, onSelectChange, multiple, refresh } = props

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/kumiaiin/sosai/list/detail',
      {
        params: {
          ...condition,
        },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row =>
        _.find(selectedIds, id => row.torihikisaki_id === id)
      )
    )
  }, [selectedIds])

  const handleAllSelect = () =>
    setSelectedIds(
      _(data)
        .map(row => row.torihikisaki_id)
        .value()
    )

  const handleAllDeSelect = () => setSelectedIds([])

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = withProps(props => ({
    ...props,
    handleChangeSelect,
  }))(TableRow)

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <DataTable
          name='sosai'
          sortable
          filterable
          tableHeight='50vh'
          tableHeader={TableHeader}
          tableRow={TableRowWithProps}
          data={data}
          topControl={
            <Button.Group>
              <Button content='全選択' onClick={handleAllSelect} />
              <Button content='全解除' onClick={handleAllDeSelect} />
            </Button.Group>
          }
          bottomControl={<></>}
          selectedIds={selectedIds}
        />
      </Segment>
    </>
  )
})

const TableHeader = props => {
  const { withOrder, handleSort, column, direction } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihikisaki_kbn' ? direction : null}
        onClick={() => handleSort('torihikisaki_kbn')}
        content='取引先区分'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihikisaki_cd' ? direction : null}
        onClick={() => handleSort('torihikisaki_cd')}
        content='取引先コード'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'torihikisaki_name' ? direction : null}
        onClick={() => handleSort('torihikisaki_name')}
        content='取引先名'
      />
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
        content='運送店名'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'sosai_mae_seikyu_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('sosai_mae_seikyu_zeikomi_gaku')}
        content='相殺前請求額（税込）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'sosai_mae_shiharai_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('sosai_mae_shiharai_zeikomi_gaku')}
        content='相殺前支払額（税込）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'sosai_go_seikyu_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('sosai_go_seikyu_zeikomi_gaku')}
        content='相殺後請求額（税込）'
      />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'sosai_go_shiharai_zeikomi_gaku' ? direction : null}
        onClick={() => handleSort('sosai_go_shiharai_zeikomi_gaku')}
        content='相殺後支払額（税込）'
      />
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, data, selectedIds, handleChangeSelect } = props || {}

  const {
    torihikisaki_id: id,
    torihikisaki_kbn_name,
    torihikisaki_cd,
    torihikisaki_name,
    unsoten_name,
    sosai_mae_seikyu_zeikomi_gaku,
    sosai_mae_shiharai_zeikomi_gaku,
    sosai_go_seikyu_zeikomi_gaku,
    sosai_go_shiharai_zeikomi_gaku,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(id, !isSelected)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox checked={isSelected} onChange={checkboxChange} value={id} />
        }
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={torihikisaki_kbn_name}
      />
      <Table.Cell style={{ width: '15.0rem' }} content={torihikisaki_cd} />
      <Table.Cell style={{ width: '15.0rem' }} content={torihikisaki_name} />
      <Table.Cell style={{ width: '15.0rem' }} content={unsoten_name} />
      <Table.Cell
        style={{ width: '10.0rem', textAlign: 'right' }}
        content={<Text.Comma content={sosai_mae_seikyu_zeikomi_gaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem', textAlign: 'right' }}
        content={<Text.Comma content={sosai_mae_shiharai_zeikomi_gaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem', textAlign: 'right' }}
        content={<Text.Comma content={sosai_go_seikyu_zeikomi_gaku} />}
      />
      <Table.Cell
        style={{ width: '10.0rem', textAlign: 'right' }}
        content={<Text.Comma content={sosai_go_shiharai_zeikomi_gaku} />}
      />
    </Table.Row>
  )
}
