import React, { useState, useEffect, useMemo } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { Util } from 'component/base/Util'

export const Standard = props => {
  const { defaultValue, enableAll, options: _options, ...other } = props

  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    setRefresh(refresh => ++refresh)
  }, [defaultValue])

  const options = useMemo(
    () =>
      enableAll
        ? [
            {
              key: Util.getRandomID(),
              text: '全て',
              value: 0,
            },
            ..._options,
          ]
        : _options,
    [_options, enableAll]
  )

  return (
    <Dropdown
      {...other}
      key={refresh}
      defaultValue={defaultValue}
      options={options}
    />
  )
}

export default Standard
