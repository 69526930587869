import { useState, useEffect } from 'react'
import axios from 'axios'
import { Axios } from 'component/base'
import { useUnmounted } from 'component/hook/useUnmounted'

export const useRyokin = _id => {
  const unmounted = useUnmounted()
  const [source] = useState(axios.CancelToken.source())
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const ajax = async id => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      `/api/staff/master/ryokin/${id}`,
      {
        cancelToken: source.token,
      }
    )
    if (unmounted.current) return
    setLoading(false)

    if (error) {
      return
    }
    setData(data || {})
  }

  useEffect(() => {
    _id && ajax(_id)
  }, [_id])

  useEffect(() => {
    if (unmounted.current) source.cancel('your request was canceled')
  }, [unmounted])

  return {
    data,
    loading,
  }
}
