import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import moment from 'moment'
import {
  Axios,
  BaseForm as Form,
  Button,
  Dropdown,
  Checkbox,
  ConditionWrapper,
  DataTable,
  Grid,
  Icon,
  Table,
  Segment,
  Card,
  List,
  Label,
  notice,
  StickyGrid,
  Modal,
} from 'component/base'
import { Custom, UnsotenMap, Confirm } from 'component/pieces'
import {
  useUserInfo,
  useReverseGeocode,
  useOsrm,
  useHaisha,
} from 'component/hook'
import { getConfig } from 'config'

export const Gps = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,

    // custom
    onSubmitUnsoten,
    copy_haisha_id,

    //運送店検索用
    isUnsoutenSearch,
    iraisha_id,
    hatchi_address1,
    chakuchi_address1,
    shashu_id_list,
  } = props

  const { loading: haisha_loading, haisha_data } = useHaisha(copy_haisha_id)

  const { haisha: copy_haisha } = haisha_data || {}

  const config = useMemo(() => getConfig(), [])

  const { tanso_cd: config_tanso_cd } = config || {}

  const { active_haiso_center_id } = useUserInfo()

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const defaultCondition = useMemo(
    () =>
      isUnsoutenSearch
        ? {
            iraisha_id,
            hatchi_address1,
            chakuchi_address1,
            shashu_id: shashu_id_list,
            tanso_cd: config_tanso_cd,
            haiso_center_id: active_haiso_center_id,
            gps_taiki_jotai_kbn: [
              'taiki',
              'soko',
              'taiki_gentei',
              'soko_gentei',
            ],
          }
        : copy_haisha
        ? {
            iraisha_id: copy_haisha.iraisha_id,
            hatchi_address1: copy_haisha.hatchi_address1,
            chakuchi_address1: copy_haisha.chakuchi_address1,
            shashu_id: copy_haisha.shashu_id_list,
            tanso_cd: config_tanso_cd,
            haiso_center_id: active_haiso_center_id,
            gps_taiki_jotai_kbn: [
              'taiki',
              'soko',
              'taiki_gentei',
              'soko_gentei',
            ],
          }
        : {
            tanso_cd: config_tanso_cd,
            haiso_center_id: active_haiso_center_id,
            gps_taiki_jotai_kbn: [
              'taiki',
              'soko',
              'taiki_gentei',
              'soko_gentei',
            ],
          },
    [
      copy_haisha,
      isUnsoutenSearch,
      iraisha_id,
      hatchi_address1,
      chakuchi_address1,
      shashu_id_list,
      config_tanso_cd,
      active_haiso_center_id,
    ]
  )

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )
  const [mode, setMode] = useState('list')
  const [refresh, setRefresh] = useState(0)
  const [kumiaiin_id, set_kumiaiin_id] = useState(null)
  const [showMemoModal, setShowMemoModal] = useState(false)
  const [showHonjitsuYoteiModal, setShowHonjitsuYoteiModal] = useState(false)
  const [showYokujitsuYoteiModal, setShowYokujitsuYoteiModal] = useState(false)

  const [osrm_refresh, set_osrm_refresh] = useState(0)
  const [distanceFirst, setDistanceFirst] = useState(true)
  const [enableToll, setEnableToll] = useState(false)
  const [enableAutoRefresh, setEnableAutoRefresh] = useState(false)

  useEffect(() => {
    setLoading(haisha_loading)
  }, [haisha_loading])

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row => _.find(selectedIds, id => row.id === id))
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition).assign(query).value()
        : _(condition).value()
    )
  }, [enableQueryCondition, query])

  useEffect(() => {
    if (_.isEmpty(condition.hatchi_address1)) return
    callApi(condition)
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (isOnClear) {
      setData([])
    }
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const {
    lat: baseLat,
    lng: baseLng,
    loading: baseLoading,
  } = useReverseGeocode(condition.hatchi_address1)

  const fromLatLng = useMemo(() => [baseLat, baseLng], [baseLat, baseLng])

  const {
    lat: goalLat,
    lng: goalLng,
    loading: goalLoading,
  } = useReverseGeocode(condition.chakuchi_address1)

  const toLatLng = useMemo(() => [goalLat, goalLng], [goalLat, goalLng])

  useEffect(() => {
    set_osrm_refresh(state => ++state)
  }, [condition.hatchi_address1, condition.chakuchi_address1])

  const osrm_options = useMemo(
    () => ({
      exclude: enableToll ? undefined : 'toll',
    }),
    [enableToll]
  )

  const { shortestDistanceRoute, shortestDurationRoute } = useOsrm(
    fromLatLng,
    toLatLng,
    osrm_options,
    osrm_refresh
  )

  const distance = useMemo(
    () =>
      distanceFirst
        ? (shortestDistanceRoute || {}).distance
        : (shortestDurationRoute || {}).distance,
    [distanceFirst, shortestDistanceRoute]
  )

  const duration = useMemo(
    () =>
      distanceFirst
        ? (shortestDistanceRoute || {}).duration
        : (shortestDurationRoute || {}).duration,
    [distanceFirst, shortestDurationRoute]
  )

  useEffect(() => {
    const timer = setInterval(() => {
      if (!enableAutoRefresh) return
      setRefresh(refresh => ++refresh)
    }, 120000) // 2 minites per

    return () => clearTimeout(timer)
  }, [enableAutoRefresh])

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get('/api/staff/haisha/gps', {
      params: { ...condition },
    })
    setLoading(false)
    if (error) return
    setData(data)

    setSelectedIds(selectedIds =>
      _.intersection(selectedIds, _(data).map('id').value())
    )
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        set_kumiaiin_id={set_kumiaiin_id}
        setShowMemoModal={setShowMemoModal}
        setShowHonjitsuYoteiModal={setShowHonjitsuYoteiModal}
        setShowYokujitsuYoteiModal={setShowYokujitsuYoteiModal}
        history={history}
        handleChangeSelect={handleChangeSelect}
        setRefresh={setRefresh}
      />
    ),
    [history, handleChangeSelect]
  )

  const handleChangeMode = () =>
    mode === 'list'
      ? setMode('map')
      : mode === 'map'
      ? setMode('list')
      : setMode('')

  const [showConfirmGPSDelete, setshowConfirmGPSDelete] = useState(false)
  const handleConfirmGPSDelete = async () => {
    const {
      error,
    } = await Axios.ajax.delete(
      `/api/staff/haisha/gps_taiki/${selectedIds[0]}`,
      { headers: { clearCacheEntry: true } }
    )

    if (error) return
    notice({
      title: 'Success',
      description: '解除しました',
      type: 'success',
    })
    setshowConfirmGPSDelete(false)

    setRefresh(refresh => ++refresh)
  }

  return (
    <>
      <Segment basic style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
          extraContent={
            <>
              <Button.Group>
                <Button
                  content='この運送店にする'
                  primary
                  onClick={() =>
                    onSubmitUnsoten(
                      _.assign(
                        {},
                        condition,
                        _(data).filter({ id: selectedIds[0] }).value()[0]
                      )
                    )
                  }
                  disabled={selectedIds.length === 1 ? false : true}
                />
                <Button
                  content={
                    mode === 'list'
                      ? 'ルートマップ表示'
                      : mode === 'map'
                      ? '運送店一覧表示'
                      : ''
                  }
                  onClick={() => handleChangeMode()}
                  disabled={selectedIds.length === 0 ? true : false}
                />
              </Button.Group>

              <Button.Group>
                <Button
                  content={distanceFirst ? '距離優先' : '時間優先'}
                  positive={distanceFirst}
                  icon={distanceFirst ? 'tachometer alternate' : 'clock'}
                  onClick={() => setDistanceFirst(state => !state)}
                />
                <Button
                  content={enableToll ? '有料道路を使う' : '有料道路を使わない'}
                  positive={enableToll}
                  icon={enableToll ? 'check' : 'minus'}
                  onClick={() => setEnableToll(state => !state)}
                />
              </Button.Group>

              <Button
                content={enableAutoRefresh ? '自動更新ON' : '自動更新OFF'}
                positive={enableAutoRefresh}
                icon='refresh'
                loading={enableAutoRefresh ? loading : false}
                onClick={() => setEnableAutoRefresh(state => !state)}
                style={{ marginLeft: '5px' }}
              />

              <Button
                content='GPS待機状態を解除する'
                primary
                onClick={() => setshowConfirmGPSDelete(true)}
                disabled={selectedIds.length === 1 ? false : true}
              />

              {distance && (
                <Label
                  content={`発地〜着地ルート距離: ${
                    Math.round((_.toNumber(distance) / 1000) * 10) / 10
                  }Km`}
                />
              )}
              {duration && (
                <Label
                  content={`発地〜着地所要時間: ${
                    Math.round((_.toNumber(duration) / 60) * 10) / 10
                  }分`}
                />
              )}
            </>
          }
        >
          <DataTable
            name='haisha-gps'
            size='mini'
            sortable
            filterable
            tableHeight='50vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            defaultSortColumnName='tsuiseki_hatchi_kyori'
            defaultSortDirection='ascending'
            style={{
              display: mode === 'map' ? 'none' : null,
            }}
            selectedIds={selectedIds}
          />
        </ConditionWrapper>

        {mode === 'map' && (
          <UnsotenMap
            baseLat={baseLat}
            baseLng={baseLng}
            goalLat={goalLat}
            goalLng={goalLng}
            loading={baseLoading || goalLoading}
            distanceFirst={distanceFirst}
            enableToll={enableToll}
            targets={_(data)
              .filter(row => _.find(selectedIds, id => row.id === id))
              .orderBy(['datetime'], ['desc']) // 待機が古いものが上にくるように描画
              .map(row => ({
                latLng: row.tsuiseki_point,
                iconUrl: '/images/akabokun.png',
                tooltip: <p>{row.unsoten_name}</p>,
                popup: (
                  <UnsotenCard
                    condition={condition}
                    data={row}
                    latLng={row.tsuiseki_point}
                    hatchiLatLng={fromLatLng}
                    onSubmitUnsoten={onSubmitUnsoten}
                  />
                ),
              }))
              .value()}
          />
        )}
        <Confirm
          cancelButton='キャンセル'
          confirmButton='解除'
          content='解除します。よろしいですか？'
          onCancel={() => setshowConfirmGPSDelete(false)}
          onConfirm={handleConfirmGPSDelete}
          open={showConfirmGPSDelete}
        />
      </Segment>
      <Custom.Modal.GpsTojitsuMemoModal
        isOpenModal={showMemoModal}
        setShowMemoModal={setShowMemoModal}
        onClickCancel={() => setShowMemoModal(false)}
        kumiaiin_id={kumiaiin_id}
        onEdited={() => setRefresh(state => ++state)}
      />
      <HonjitsuYoteiModal
        isOpenModal={showHonjitsuYoteiModal}
        onClickConfirm={() => setShowHonjitsuYoteiModal(false)}
        kumiaiin_id={kumiaiin_id}
      />
      <YokujitsuYoteiModal
        isOpenModal={showYokujitsuYoteiModal}
        onClickConfirm={() => setShowYokujitsuYoteiModal(false)}
        kumiaiin_id={kumiaiin_id}
      />
    </>
  )
})

Gps.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

Gps.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { column, direction, withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }} content='#' />
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tsuiseki_hatchi_kyori' ? direction : null}
        onClick={() => handleSort('tsuiseki_hatchi_kyori')}
      >
        発地からの距離
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'tsuiseki_datetime' ? direction : null}
        onClick={() => handleSort('tsuiseki_datetime')}
      >
        GPS取得日時
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'tsuiseki_address' ? direction : null}
        onClick={() => handleSort('tsuiseki_address')}
      >
        GPS更新住所
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'honjitsu_haisha_kibo_kbn_list' ? direction : null}
        onClick={() => handleSort('honjitsu_haisha_kibo_kbn_list')}
      >
        本日配車希望
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={
          column === 'honjitsu_haisha_kibo_kbn_insert_datetime'
            ? direction
            : null
        }
        onClick={() => handleSort('honjitsu_haisha_kibo_kbn_insert_datetime')}
      >
        本日希望更新時刻
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'yokujitsu_haisha_kibo_kbn_list' ? direction : null}
        onClick={() => handleSort('yokujitsu_haisha_kibo_kbn_list')}
      >
        翌日配車希望
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={
          column === 'yokujitsu_haisha_kibo_kbn_insert_datetime'
            ? direction
            : null
        }
        onClick={() => handleSort('yokujitsu_haisha_kibo_kbn_insert_datetime')}
      >
        翌日希望更新時刻
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'gps_taiki_jotai_kbn_name' ? direction : null}
        onClick={() => handleSort('gps_taiki_jotai_kbn_name')}
      >
        待機：状態
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '15.0rem' }}
        sorted={column === 'datetime' ? direction : null}
        onClick={() => handleSort('datetime')}
      >
        待機：開始日時
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'address' ? direction : null}
        onClick={() => handleSort('address')}
      >
        待機：住所
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'hatchi_kyori' ? direction : null}
        onClick={() => handleSort('hatchi_kyori')}
      >
        待機：発地からの距離
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'biko' ? direction : null}
        onClick={() => handleSort('biko')}
      >
        待機：備考
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tanso_cd' ? direction : null}
        onClick={() => handleSort('tanso_cd')}
      >
        所属単組
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'busho_id' ? direction : null}
        onClick={() => handleSort('busho_id')}
      >
        所属支部
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haiso_center_id' ? direction : null}
        onClick={() => handleSort('haiso_center_id')}
      >
        所属配送センター
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shozoku_area_id' ? direction : null}
        onClick={() => handleSort('shozoku_area_id')}
      >
        所属エリア
      </Table.HeaderCell>

      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'unsoten_name' ? direction : null}
        onClick={() => handleSort('unsoten_name')}
      >
        運送店名
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kumiaiin_name' ? direction : null}
        onClick={() => handleSort('kumiaiin_name')}
      >
        組合員名
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tel' ? direction : null}
        onClick={() => handleSort('tel')}
      >
        電話番号１
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'tel2' ? direction : null}
        onClick={() => handleSort('tel2')}
      >
        電話番号２
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shashu_name_list' ? direction : null}
        onClick={() => handleSort('shashu_name_list')}
      >
        車種
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'is_hikkoshi_member' ? direction : null}
        onClick={() => handleSort('is_hikkoshi_member')}
      >
        引越
      </Table.HeaderCell>

      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'team_name_list' ? direction : null}
        onClick={() => handleSort('team_name_list')}
      >
        チーム
      </Table.HeaderCell>

      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'is_gps_haisha_kyohi' ? direction : null}
        onClick={() => handleSort('is_gps_haisha_kyohi')}
      >
        GPS配車拒否
      </Table.HeaderCell>

      <Table.HeaderCell style={{ width: '10.0rem' }}>
        本日配車予定
      </Table.HeaderCell>
      <Table.HeaderCell style={{ width: '10.0rem' }}>
        翌日配車予定
      </Table.HeaderCell>
      <Table.HeaderCell style={{ width: '20.0rem' }}>当日メモ</Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'shokuin_biko' ? direction : null}
        onClick={() => handleSort('shokuin_biko')}
      >
        職員備考
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'haisha_chui_jiko' ? direction : null}
        onClick={() => handleSort('haisha_chui_jiko')}
      >
        配車注意事項
      </Table.HeaderCell>
    </Table.Row>
  )
}

const TableRow = props => {
  const {
    withOrder,
    data,
    selectedIds,
    handleChangeSelect,
    set_kumiaiin_id,
    setShowMemoModal,
    setShowHonjitsuYoteiModal,
    setShowYokujitsuYoteiModal,
    setRefresh,
  } = props || {}

  const {
    id: gps_entry_id,
    hatchi_kyori,
    datetime,
    address,
    biko,
    tsuiseki_hatchi_kyori,
    tsuiseki_datetime,
    tsuiseki_address,
    honjitsu_haisha_kibo_kbn_list,
    yokujitsu_haisha_kibo_kbn_list,
    honjitsu_haisha_kibo_kbn_insert_datetime,
    yokujitsu_haisha_kibo_kbn_insert_datetime,
    // tanso_cd,
    tanso_name,
    // busho_id,
    busho_name,
    // haiso_center_id,
    haiso_center_name,
    // shozoku_area_id,
    area_name,
    unsoten_name,
    kumiaiin_cd,
    kumiaiin_id,
    kumiaiin_name,
    tel1,
    tel2,
    // shashu_id_list,
    shashu_name_list,
    is_hikkoshi_member,
    // team_id_list,
    team_name_list,
    is_gps_haisha_kyohi,
    gps_tojitsu_memo_naiyo,
    shokuin_biko,
    haisha_chui_jiko,
    gps_taiki_jotai_kbn_name,
    gps_haisha_kyohi_id,
    upload_files_id,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(gps_entry_id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  const handleOnClickMemo = e => {
    e.stopPropagation()
    set_kumiaiin_id(kumiaiin_id)
    setShowMemoModal(true)
  }

  const handleOnClickHonjitsuYotei = e => {
    e.stopPropagation()
    set_kumiaiin_id(kumiaiin_id)
    setShowHonjitsuYoteiModal(true)
  }

  const handleOnClickYokujitsuYotei = e => {
    e.stopPropagation()
    set_kumiaiin_id(kumiaiin_id)
    setShowYokujitsuYoteiModal(true)
  }

  const postHaishaKyohi = async kumiaiin_id => {
    const { error } = await Axios.ajax.post(
      `/api/staff/kumiaiin/kumiaiin/${kumiaiin_id}/gps_haisha_kyohi`
    )
    if (error) return
    notice({
      title: 'Success',
      description: '配車拒否しました',
      type: 'success',
    })
    setRefresh(refresh => ++refresh)
  }

  const deleteHaishaKyohi = async (kumiaiin_id, gps_haisha_kyohi_id) => {
    const { error } = await Axios.ajax.delete(
      `/api/staff/kumiaiin/kumiaiin/${kumiaiin_id}/gps_haisha_kyohi/${gps_haisha_kyohi_id}`
    )
    if (error) return
    notice({
      title: 'Success',
      description: '配車拒否状態を解除しました',
      type: 'success',
    })
    setRefresh(refresh => ++refresh)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onClick={() => {
        setIsSelected(state => !state)
        handleChangeSelect(gps_entry_id, !isSelected)
      }}
      style={{
        backgroundColor: is_gps_haisha_kyohi ? 'orange' : null,
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox
            checked={isSelected}
            onChange={checkboxChange}
            value={gps_entry_id}
          />
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={`${tsuiseki_hatchi_kyori}km`}
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={
          moment(tsuiseki_datetime, 'YYYY/MM/DD HH:mm:ss:SSS').isValid()
            ? moment(tsuiseki_datetime, 'YYYY/MM/DD HH:mm:ss:SSS').format(
                'HH:mm:ss:SSS'
              )
            : tsuiseki_datetime
        }
      />
      <Table.Cell style={{ width: '20.0rem' }} content={tsuiseki_address} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          _.isArray(honjitsu_haisha_kibo_kbn_list)
            ? honjitsu_haisha_kibo_kbn_list.join(',')
            : '-'
        }
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={honjitsu_haisha_kibo_kbn_insert_datetime}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          _.isArray(yokujitsu_haisha_kibo_kbn_list)
            ? yokujitsu_haisha_kibo_kbn_list.join(',')
            : '-'
        }
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={yokujitsu_haisha_kibo_kbn_insert_datetime}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={gps_taiki_jotai_kbn_name}
      />
      <Table.Cell
        style={{ width: '15.0rem' }}
        content={
          moment(datetime, 'YYYY/MM/DD HH:mm:ss:SSS').isValid()
            ? moment(datetime, 'YYYY/MM/DD HH:mm:ss:SSS').format('HH:mm:ss:SSS')
            : datetime
        }
      />
      <Table.Cell style={{ width: '20.0rem' }} content={address} />
      <Table.Cell style={{ width: '10.0rem' }} content={`${hatchi_kyori}km`} />
      <Table.Cell style={{ width: '20.0rem' }} content={biko} />
      <Table.Cell style={{ width: '10.0rem' }} content={tanso_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={busho_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={haiso_center_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={area_name} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          <>
            <Custom.Image.KumiaiinAvator
              id={upload_files_id}
              kumiaiin_cd={kumiaiin_cd}
              avatar
            />
            {unsoten_name}
          </>
        }
      />
      <Table.Cell style={{ width: '10.0rem' }} content={kumiaiin_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={tel1} />
      <Table.Cell style={{ width: '10.0rem' }} content={tel2} />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={_.isArray(shashu_name_list) ? shashu_name_list.join(',') : '-'}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={is_hikkoshi_member ? '対応可' : '不可'}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={_.isArray(team_name_list) ? team_name_list.join(',') : ''}
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          is_gps_haisha_kyohi ? (
            <Button
              size='mini'
              content='配車拒否中'
              negative
              onClick={e => {
                e.stopPropagation()
                deleteHaishaKyohi(kumiaiin_id, gps_haisha_kyohi_id)
              }}
            />
          ) : (
            <Button
              size='mini'
              content='配車拒否にする'
              onClick={e => {
                e.stopPropagation()
                postHaishaKyohi(kumiaiin_id)
              }}
            />
          )
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          <Button primary icon size='mini' onClick={handleOnClickHonjitsuYotei}>
            <Icon name='list' />
          </Button>
        }
      />
      <Table.Cell
        style={{ width: '10.0rem' }}
        content={
          <Button
            primary
            icon
            size='mini'
            onClick={handleOnClickYokujitsuYotei}
          >
            <Icon name='list' />
          </Button>
        }
      />
      <Table.Cell style={{ width: '20.0rem' }}>
        <Button onClick={handleOnClickMemo} icon='pencil' size='mini' primary />
        {gps_tojitsu_memo_naiyo}
      </Table.Cell>
      <Table.Cell style={{ width: '10.0rem' }} content={shokuin_biko} />
      <Table.Cell style={{ width: '10.0rem' }} content={haisha_chui_jiko} />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  const {
    tanso_cd: _tanso_cd,
    haiso_center_id: _haiso_center_id,
    shozoku_area_id: _shozoku_area_id,
    team_id_list,
  } = condition || {}

  const [refreshHatchKyori, setRefreshHatchKyori] = useState(0)
  const [refreshJikan, setRefreshJikan] = useState(0)

  useEffect(() => {
    setRefreshHatchKyori(refresh => ++refresh)
  }, [condition.tsuiseki_hatchi_kyori])

  useEffect(() => {
    setRefreshJikan(refresh => ++refresh)
  }, [condition.taiki_machi_time])

  const [tanso_cd, set_tanso_cd] = useState(_tanso_cd || 0)
  const [haiso_center_id, set_haiso_center_id] = useState(_haiso_center_id || 0)
  const [shozoku_area_id, set_shozoku_area_id] = useState(_shozoku_area_id || 0)
  const [haiso_center_refresh, set_haiso_center_refresh] = useState(0)
  const [area_refresh, set_area_refresh] = useState(0)

  useEffect(() => {
    set_tanso_cd(_tanso_cd || 0)
    set_haiso_center_id(_haiso_center_id || 0)
    set_shozoku_area_id(_shozoku_area_id || 0)
  }, [_tanso_cd, _haiso_center_id, _shozoku_area_id])

  useEffect(() => {
    set_haiso_center_refresh(refresh => ++refresh)
  }, [tanso_cd])

  useEffect(() => {
    set_area_refresh(refresh => ++refresh)
  }, [haiso_center_id])

  const droplist_kyori = [
    { key: 1, value: '1', text: '1km' },
    { key: 2, value: '3', text: '3km' },
    { key: 3, value: '5', text: '5km' },
    { key: 4, value: '10', text: '10km' },
    { key: 5, value: '20', text: '20km' },
  ]

  const droplist_zikan = [
    { key: 1, value: '10', text: '10分' },
    { key: 2, value: '30', text: '30分' },
    { key: 3, value: '60', text: '60分' },
    { key: 4, value: '90', text: '90分' },
    { key: 5, value: '120', text: '120分' },
  ]

  return (
    <>
      <Grid.Column>
        <Custom.Dropdown.Iraisha
          inputAs={Dropdown}
          value={condition.iraisha_id}
          label='依頼者'
          name='iraisha_id'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Textbox.Address
          value={condition.hatchi_address1}
          label={<Label content='発地住所１' color='orange' />}
          name='hatchi_address1'
          outsideLabel={false}
          required
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Textbox.Address
          value={condition.chakuchi_address1}
          label='着地住所１'
          name='chakuchi_address1'
          outsideLabel={false}
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Shashu
          inputAs={Dropdown}
          value={condition.shashu_id}
          label='車種'
          name='shashu_id'
          multiple
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Dropdown.Standard
          key={refreshHatchKyori}
          inputAs={Dropdown}
          value={condition.tsuiseki_hatchi_kyori}
          name='tsuiseki_hatchi_kyori'
          label='発地からの距離'
          options={droplist_kyori}
          fluid
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Dropdown.Standard
          key={refreshJikan}
          inputAs={Dropdown}
          value={condition.taiki_machi_time}
          name='taiki_machi_time'
          label='GPS取得経過時間'
          options={droplist_zikan}
          fluid
          clearable
        />
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.Tanso
          inputAs={Dropdown}
          value={tanso_cd}
          name='tanso_cd'
          label='単組'
          fluid
          onChange={(e, { value }) => {
            set_tanso_cd(value)
            set_haiso_center_id(0)
            set_shozoku_area_id(0)
          }}
          enableAll
        />
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.HaisoCenterAll
          key={haiso_center_refresh}
          inputAs={Dropdown}
          value={haiso_center_id}
          name='haiso_center_id'
          label='配送センター'
          fluid
          onChange={(e, { value }) => {
            set_haiso_center_id(value)
            set_shozoku_area_id(0)
          }}
          tanso_cd={tanso_cd}
          enableAuthValue={false}
          enableAll
        />
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.AreaAll
          key={area_refresh}
          inputAs={Dropdown}
          value={shozoku_area_id}
          name='shozoku_area_id'
          label='エリア'
          fluid
          onChange={(e, { value }) => set_shozoku_area_id(value)}
          haiso_center_id={haiso_center_id}
          enableAll
        />
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.GpsTaikiJotaiKbn
          inputAs={Dropdown}
          value={condition.gps_taiki_jotai_kbn}
          name='gps_taiki_jotai_kbn'
          label='待機状態'
          fluid
          multiple
        />
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.Team
          inputAs={Dropdown}
          value={team_id_list}
          name='team_id_list'
          label='配送チーム'
          clearable
          multiple
          haiso_center_id={haiso_center_id}
          is_haiso_team_only={true}
        />
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.HaishaKiboKbn
          inputAs={Dropdown}
          value={condition.haisha_kibo_kbn_list}
          name='haisha_kibo_kbn_list'
          label='翌日配車希望'
          fluid
          multiple
        />
      </Grid.Column>
    </>
  )
}

const UnsotenCard = props => {
  const { condition, data, latLng, hatchiLatLng, onSubmitUnsoten } = props

  const {
    unsoten_name,
    tsuiseki_datetime,
    tanso_name,
    kumiaiin_cd,
    kumiaiin_name,
    tsuiseki_address,
    tel1,
    tel2,
    gps_taiki_jotai_kbn_name,
    upload_files_id,
  } = data || {}

  const { shortestDistanceRoute } = useOsrm(latLng, hatchiLatLng)

  const { distance, duration } = shortestDistanceRoute || {}

  return (
    <>
      <Card>
        <Card.Content>
          <Custom.Image.KumiaiinAvator
            floated='right'
            id={upload_files_id}
            kumiaiin_cd={kumiaiin_cd}
            size='mini'
          />
          <Card.Header>{unsoten_name}</Card.Header>
          <Card.Meta>{`${tanso_name} ${kumiaiin_name}`}</Card.Meta>
          <Card.Description>
            <List>
              <List.Item>
                <List.Icon name='marker' />
                <List.Content>
                  <List.Header>{tsuiseki_address}</List.Header>
                  <List.Header>
                    <Label content={gps_taiki_jotai_kbn_name} />
                  </List.Header>
                  <List.Description>
                    <p>
                      <span>
                        {`発地まで ${
                          Math.round((_.toNumber(distance) / 1000) * 10) / 10
                        }Km`}
                        {`（約${
                          Math.round((_.toNumber(duration) / 60) * 10) / 10
                        }分）`}
                      </span>
                    </p>
                    <p>{`${tsuiseki_datetime} 更新`}</p>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Card.Description>
          <Button
            primary
            fluid
            content='この運送店にする'
            onClick={() => onSubmitUnsoten(_.assign({}, condition, data))}
          />
        </Card.Content>
        <Card.Content extra>
          <Icon name='phone' />
          {tel1}
          <Icon name='mobile alternate' />
          {tel2}
        </Card.Content>
      </Card>
    </>
  )
}

const HonjitsuYoteiModal = props => {
  const { isOpenModal, onClickConfirm, kumiaiin_id } = props

  const [honjitsuYoteiData, setHonjitsuYoteiData] = useState([])

  const callGetApi = async () => {
    const { data, error } = await Axios.ajax.get(
      '/api/staff/haisha/haiso_yotei',
      {
        params: {
          kumiaiin_id,
        },
      }
    )
    if (error) return
    if (data.length !== 0) setHonjitsuYoteiData(data)
    else setHonjitsuYoteiData([])
  }

  const rows = _.times(honjitsuYoteiData.length, i => (
    <StickyGrid.Row key={i}>
      <StickyGrid.Cell width='100px'>
        {honjitsuYoteiData[i].is_haisha_data ? (
          <Label as='a' color='red'>
            配車
          </Label>
        ) : (
          <Label as='a' color='blue'>
            個人
          </Label>
        )}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='200px'>
        {honjitsuYoteiData[i].hatchi_address1}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='200px'>
        {honjitsuYoteiData[i].chakuchi_address1}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='140px'>
        {honjitsuYoteiData[i].hikitori_date}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='100px'>
        {honjitsuYoteiData[i].hikitori_time}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='140px'>
        {honjitsuYoteiData[i].kanryo_date}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='100px'>
        {honjitsuYoteiData[i].kanryo_time}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='600px'>
        {honjitsuYoteiData[i].biko}
      </StickyGrid.Cell>
    </StickyGrid.Row>
  ))

  return (
    <Modal
      closeOnEscape={true}
      closeOnDimmerClick={true}
      dimmer='inverted'
      onMount={callGetApi}
      onClose={onClickConfirm}
      open={isOpenModal}
      size='large'
    >
      <Modal.Header>本日配車予定一覧</Modal.Header>
      <Modal.Content image scrolling>
        <Grid>
          <Grid.Column>
            <StickyGrid height='40vh' gridWidth='1580px'>
              <StickyGrid.HeaderRow>
                <StickyGrid.HeaderCell width='100px'></StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='200px'>
                  発地住所
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='200px'>
                  着地住所
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='140px'>
                  引取日
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='100px'>
                  引取時間
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='140px'>
                  完了日
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='100px'>
                  完了時間
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='600px'>
                  備考
                </StickyGrid.HeaderCell>
              </StickyGrid.HeaderRow>
              {rows}
            </StickyGrid>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClickConfirm} content='確認' />
      </Modal.Actions>
    </Modal>
  )
}

const YokujitsuYoteiModal = props => {
  const { isOpenModal, onClickConfirm, kumiaiin_id } = props

  const [yokujitsuYoteiData, setYokujitsuYoteiData] = useState([])

  const callGetApi = async () => {
    const tomorrow = moment().add(1, 'days').format('YYYY/MM/DD')
    const { data, error } = await Axios.ajax.get(
      '/api/staff/haisha/haiso_yotei',
      {
        params: {
          kumiaiin_id,
          criteria_date: tomorrow,
        },
      }
    )
    if (error) return
    setYokujitsuYoteiData(data)
  }

  const rows = _.times(yokujitsuYoteiData.length, i => (
    <StickyGrid.Row key={i}>
      <StickyGrid.Cell width='100px'>
        {yokujitsuYoteiData[i].is_haisha_data ? (
          <Label as='a' color='red'>
            配車
          </Label>
        ) : (
          <Label as='a' color='blue'>
            個人
          </Label>
        )}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='200px'>
        {yokujitsuYoteiData[i].hatchi_address1}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='200px'>
        {yokujitsuYoteiData[i].chakuchi_address1}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='140px'>
        {yokujitsuYoteiData[i].hikitori_date}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='100px'>
        {yokujitsuYoteiData[i].hikitori_time}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='140px'>
        {yokujitsuYoteiData[i].kanryo_date}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='100px'>
        {yokujitsuYoteiData[i].kanryo_time}
      </StickyGrid.Cell>
      <StickyGrid.Cell width='600px'>
        {yokujitsuYoteiData[i].biko}
      </StickyGrid.Cell>
    </StickyGrid.Row>
  ))

  return (
    <Modal
      closeOnEscape={true}
      closeOnDimmerClick={true}
      dimmer='inverted'
      onMount={callGetApi}
      onClose={onClickConfirm}
      open={isOpenModal}
      size='large'
    >
      <Modal.Header>翌日配車予定一覧</Modal.Header>
      <Modal.Content image scrolling>
        <Grid>
          <Grid.Column>
            <StickyGrid height='40vh' gridWidth='1580px'>
              <StickyGrid.HeaderRow>
                <StickyGrid.HeaderCell width='100px'></StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='200px'>
                  発地住所
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='200px'>
                  着地住所
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='140px'>
                  引取日
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='100px'>
                  引取時間
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='140px'>
                  完了日
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='100px'>
                  完了時間
                </StickyGrid.HeaderCell>
                <StickyGrid.HeaderCell width='600px'>
                  備考
                </StickyGrid.HeaderCell>
              </StickyGrid.HeaderRow>
              {rows}
            </StickyGrid>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClickConfirm} content='確認' />
      </Modal.Actions>
    </Modal>
  )
}
