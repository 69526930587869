import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import Standard from './Standard'

const Buttony = props => {
  const {
    checked: _checked,
    label,
    buttonProps,
    indeteminateProps,
    positiveProps,
    negativeProps,
    readOnly,
    onChange,
    onClick,
    name,
    size,
    fluid,
    enableIndeterminate,
    insertEmptyLabel,
    ...other
  } = props

  const layered = checked =>
    enableIndeterminate && (checked === null || checked === undefined)
      ? undefined
      : checked && checked === 'false'
      ? false
      : checked
      ? true
      : false

  const [checked, setChecked] = useState(layered(_checked))

  // props checkedが変わったら
  useEffect(() => {
    setChecked(layered(_checked))
  }, [_checked, enableIndeterminate])

  const handleClick = e => {
    const nextChecked = enableIndeterminate
      ? checked === null || checked === undefined
        ? true
        : checked
        ? false
        : undefined
      : !checked

    setChecked(nextChecked)
    if (onChange) onChange(e, { name, checked: nextChecked })
    if (onClick) onClick(e, { name, checked: nextChecked })
  }

  return (
    <div className='field'>
      {insertEmptyLabel && <label>　</label>}

      {checked === null || checked === undefined ? (
        <Button
          {...buttonProps}
          {...indeteminateProps}
          as='span'
          onClick={handleClick}
          content={label}
          disabled={readOnly}
          size={size}
          fluid={fluid}
        />
      ) : checked ? (
        <Button
          {...buttonProps}
          {...positiveProps}
          as='span'
          onClick={handleClick}
          content={label}
          disabled={readOnly}
          size={size}
          fluid={fluid}
        />
      ) : (
        <Button
          {...buttonProps}
          {...negativeProps}
          as='span'
          onClick={handleClick}
          content={label}
          disabled={readOnly}
          size={size}
          fluid={fluid}
        />
      )}
      <Standard
        {...other}
        name={name}
        style={{ display: 'none' }}
        checked={checked}
        readOnly={readOnly}
      />
    </div>
  )
}

export default Buttony

Buttony.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  buttonProps: PropTypes.object,
  positiveProps: PropTypes.object,
  negativeProps: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  enableIndeterminate: PropTypes.bool,
  insertEmptyLabel: PropTypes.bool,
}

Buttony.defaultProps = {
  buttonProps: {},
  positiveProps: {
    icon: 'check',
    positive: true,
  },
  negativeProps: {
    icon: 'close',
    negative: true,
  },
  indeteminateProps: {
    icon: 'minus',
  },
  onChange: () => {},
  onClick: () => {},
  enableIndeterminate: false,
  insertEmptyLabel: false,
}
