import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import {
  Axios,
  BaseForm as Form,
  Input,
  Dropdown,
  Checkbox,
  DataTable,
  Grid,
  Table,
  ConditionWrapper,
  Segment,
  Text,
} from 'component/base'
import { Custom } from 'component/pieces'
import { useUserInfo } from 'component/hook'

export const HaishaSeikyuShiharai = withRouter(props => {
  const {
    history,
    enableQueryCondition,
    multiple,
    selectedId,
    onSelectChange,
    refresh,
  } = props

  const query = useMemo(
    () =>
      queryString.parse(window.location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    [window.location.search]
  )

  const path = useMemo(() => window.location.pathname, [
    window.location.pathname,
  ])

  const { active_haiso_center_id } = useUserInfo()

  const defaultCondition = useMemo(() => {
    const from_date = moment().add('months', -1).format('YYYY/MM/DD')
    const to_date = moment().format('YYYY/MM/DD')

    return {
      haiso_center_id: active_haiso_center_id,
      kanryo_date_from: from_date,
      kanryo_date_to: to_date,
      deleted: false,
    }
  }, [])

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [condition, setCondition] = useState(
    _.assign({}, defaultCondition, enableQueryCondition ? query : {})
  )

  useEffect(() => {
    setSelectedIds(
      multiple
        ? selectedId && _.isArray(selectedId)
          ? selectedId
          : []
        : selectedId
        ? [selectedId]
        : []
    )
  }, [selectedId])

  useEffect(() => {
    onSelectChange(
      selectedIds,
      _.filter(data, row =>
        _.find(selectedIds, id => row.seikyu_shiharai_id === id)
      )
    )
  }, [selectedIds])

  useEffect(() => {
    setCondition(condition =>
      enableQueryCondition
        ? _(condition)
            .assign(query)
            .assign({ haiso_center_id: active_haiso_center_id })
            .value()
        : _(condition)
            .assign({ haiso_center_id: active_haiso_center_id })
            .value()
    )
  }, [defaultCondition, enableQueryCondition, query])

  useEffect(() => {
    callApi(condition)
    setSelectedIds([])
  }, [condition, refresh])

  const handleConditionChange = (condition, isOnClear) => {
    setCondition(isOnClear ? defaultCondition : condition)
    if (enableQueryCondition)
      isOnClear
        ? history.push(`${path}`)
        : history.push(`${path}?${queryString.stringify(condition)}`)
  }

  const callApi = async condition => {
    setLoading(true)
    const { data, error } = await Axios.ajax.get(
      '/api/staff/haisha/seikyu/list',
      {
        params: { ...condition },
      }
    )
    setLoading(false)
    if (error) return
    setData(data)
  }

  const handleChangeSelect = (selectedId, selected) => {
    setSelectedIds(selectedIds =>
      selected
        ? multiple
          ? _.union(selectedIds, [selectedId])
          : [selectedId]
        : _.filter(selectedIds, id => id !== selectedId)
    )
  }

  const TableRowWithProps = useCallback(
    props => (
      <TableRow
        {...props}
        history={history}
        handleChangeSelect={handleChangeSelect}
      />
    ),
    [history, handleChangeSelect]
  )

  return (
    <>
      <Segment basic loading={loading} style={{ padding: '0em' }}>
        <ConditionWrapper
          defaultCondition={defaultCondition}
          condition={condition}
          onConditionChange={handleConditionChange}
          FormElement={SearchElement}
          enableSidebar={false}
          compact
        >
          <DataTable
            name='haishaSeikyuShiharai'
            size='mini'
            sortable
            filterable
            tableHeight='80vh'
            tableHeader={TableHeader}
            tableRow={TableRowWithProps}
            data={data}
            bottomControl={<></>}
            defaultSortColumnName='id'
            defaultSortDirection='descending'
            selectedIds={selectedIds}
          />
        </ConditionWrapper>
      </Segment>
    </>
  )
})

HaishaSeikyuShiharai.propTypes = {
  enableQueryCondition: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSelectChange: PropTypes.func,
}

HaishaSeikyuShiharai.defaultProps = {
  enableQueryCondition: false,
  multiple: false,
  selectedValue: '',
  onSelectChange: () => {},
}

const TableHeader = props => {
  const { column, direction, withOrder, handleSort } = props

  return withOrder(
    <Table.Row>
      <Table.HeaderCell style={{ width: '3.0rem' }}>#</Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'haisha_jotai_kbn' ? direction : null}
        onClick={() => handleSort('haisha_jotai_kbn')}
      >
        状態
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'kakutei_date' ? direction : null}
        onClick={() => handleSort('kakutei_date')}
      >
        確定日
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '12.0rem' }}
        sorted={column === 'haiso_center_name' ? direction : null}
        onClick={() => handleSort('haiso_center_name')}
      >
        配送センター
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'seikyu_shiharai_id' ? direction : null}
        onClick={() => handleSort('seikyu_shiharai_id')}
      >
        請求支払番号
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '6.0rem' }}
        sorted={column === 'juchu_shubetsu_kbn' ? direction : null}
        onClick={() => handleSort('juchu_shubetsu_kbn')}
      >
        受注種別
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '20.0rem' }}
        sorted={column === 'title' ? direction : null}
        onClick={() => handleSort('title')}
      >
        タイトル
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'iraisha_name' ? direction : null}
        onClick={() => handleSort('iraisha_name')}
      >
        依頼者
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '16.0rem' }}
        sorted={column === 'seikyusaki_name' ? direction : null}
        onClick={() => handleSort('seikyusaki_name')}
      >
        請求先
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '10.0rem' }}
        sorted={column === 'uketsuke_date' ? direction : null}
        onClick={() => handleSort('uketsuke_date')}
      >
        受付日
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'haisha_kensu_gokei' ? direction : null}
        onClick={() => handleSort('haisha_kensu_gokei')}
      >
        配車件数合計
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'takuhai_kosu_gokei' ? direction : null}
        onClick={() => handleSort('takuhai_kosu_gokei')}
      >
        宅配個数合計
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'shiharai_zeikomi_gaku_gokei' ? direction : null}
        onClick={() => handleSort('shiharai_zeikomi_gaku_gokei')}
      >
        税込支払額合計
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'seikyu_zeikomi_gaku_gokei' ? direction : null}
        onClick={() => handleSort('seikyu_zeikomi_gaku_gokei')}
      >
        税込請求額合計
      </Table.HeaderCell>
      <Table.HeaderCell
        style={{ width: '8.0rem' }}
        sorted={column === 'is_keiri_kamoku_kakuninyo' ? direction : null}
        onClick={() => handleSort('is_keiri_kamoku_kakuninyo')}
      >
        要経理科目確認
      </Table.HeaderCell>
    </Table.Row>
  )
}

const TableRow = props => {
  const { withOrder, history, data, selectedIds, handleChangeSelect } =
    props || {}

  const {
    seikyu_shiharai_id,
    // haisha_jotai_kbn,
    haisha_jotai_kbn_name,
    // haiso_center_id,
    haiso_center_name,
    // juchu_shubetsu_kbn,
    juchu_shubetsu_kbn_name,
    title,
    // iraisha_id,
    // is_ichigen,
    iraisha_name,
    seikyusaki_name,
    uketsuke_date,
    haisha_kensu_gokei,
    takuhai_kosu_gokei,
    shiharai_zeikomi_gaku_gokei,
    seikyu_zeikomi_gaku_gokei,
    kakutei_date,
    // seikyu_kbn,
    // seikyu_kbn_name,
    // seikyusaki_kbn,
    // seikyusaki_kbn_name,
    // shukin_kbn,
    // shukin_kbn_name,
    is_keiri_kamoku_kakuninyo,
    seikyu_shiharai_sakujo_id,
  } = data || {}

  const [isSelected, setIsSelected] = useState(
    selectedIds.indexOf(seikyu_shiharai_id) >= 0 ? true : false
  )

  const checkboxChange = (event, { value, checked }) => {
    event.stopPropagation()
    setIsSelected(checked)
    handleChangeSelect(value, checked)
  }

  return withOrder(
    <Table.Row
      positive={isSelected}
      onDoubleClick={() => {
        history.push(`/staff/haisha/seikyu/edit/${seikyu_shiharai_id}`)
      }}
    >
      <Table.Cell
        style={{ width: '3.0rem' }}
        textAlign='center'
        content={
          <Checkbox
            checked={isSelected}
            onChange={checkboxChange}
            value={seikyu_shiharai_id}
            fitted
          />
        }
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={seikyu_shiharai_sakujo_id ? '削除' : haisha_jotai_kbn_name}
      />
      <Table.Cell style={{ width: '10.0rem' }} content={kakutei_date} />
      <Table.Cell style={{ width: '12.0rem' }} content={haiso_center_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={seikyu_shiharai_id} />
      <Table.Cell
        style={{ width: '6.0rem' }}
        content={juchu_shubetsu_kbn_name}
      />
      <Table.Cell style={{ width: '20.0rem' }} content={title} />
      <Table.Cell style={{ width: '16.0rem' }} content={iraisha_name} />
      <Table.Cell style={{ width: '16.0rem' }} content={seikyusaki_name} />
      <Table.Cell style={{ width: '10.0rem' }} content={uketsuke_date} />
      <Table.Cell
        style={{ width: '8.0rem', textAlign: 'right' }}
        content={<Text.Comma content={haisha_kensu_gokei} />}
      />
      <Table.Cell
        style={{ width: '8.0rem', textAlign: 'right' }}
        content={<Text.Comma content={takuhai_kosu_gokei} />}
      />
      <Table.Cell
        style={{ width: '8.0rem', textAlign: 'right' }}
        content={<Text.Comma content={shiharai_zeikomi_gaku_gokei} />}
      />
      <Table.Cell
        style={{ width: '8.0rem', textAlign: 'right' }}
        content={<Text.Comma content={seikyu_zeikomi_gaku_gokei} />}
      />
      <Table.Cell
        style={{ width: '8.0rem' }}
        content={is_keiri_kamoku_kakuninyo ? '要' : ''}
      />
    </Table.Row>
  )
}

const SearchElement = props => {
  const { condition } = props

  return (
    <>
      <Grid.Column style={{ display: 'none' }}>
        <Custom.Dropdown.HaisoCenterCurrent
          value={condition.haiso_center_id}
          inputAs={Dropdown}
          label='配送センター'
          name='haiso_center_id'
          clearable
          fluid
        />
      </Grid.Column>

      <Grid.Column>
        <Custom.Dropdown.HaishaJotai
          inputAs={Dropdown}
          value={condition.haisha_jotai_kbn}
          label='配車状態'
          name='haisha_jotai_kbn'
          fluid
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.JuchuShubetsu
          inputAs={Dropdown}
          value={condition.juchu_shubetsu_kbn}
          label='受注種別'
          name='juchu_shubetsu_kbn'
          fluid
          clearable
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.Iraisha
          inputAs={Dropdown}
          value={condition.iraisha_id}
          label='依頼者'
          name='iraisha_id'
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={condition.iraisha_name}
          label='依頼者名'
          name='iraisha_name'
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={condition.seikyusaki_name}
          label='請求先名'
          name='seikyusaki_name'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Textbox.Standard
          inputAs={Input}
          value={condition.title}
          label='タイトル'
          name='title'
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.SeikyuKbn
          inputAs={Dropdown}
          value={condition.seikyu_kbn}
          label='請求区分'
          name='seikyu_kbn'
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.SeikyusakiKbn
          inputAs={Dropdown}
          value={condition.seikyusaki_kbn}
          label='請求先区分'
          name='seikyusaki_kbn'
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Custom.Dropdown.ShukinKbn
          inputAs={Dropdown}
          value={condition.shukin_kbn}
          label='集金区分'
          name='shukin_kbn'
          clearable
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          value={condition.is_keiri_kamoku_kakuninyo}
          label='要経理科目確認'
          name='is_keiri_kamoku_kakuninyo'
          size='mini'
        />
      </Grid.Column>
      <Grid.Column>
        <Form.Checkbox.Buttony
          name='deleted'
          label='削除状態を表示'
          size='mini'
          checked={condition.deleted}
          enableIndeterminate
        />
      </Grid.Column>
    </>
  )
}
