import Store from './store'

const store = new Store()
let id = 0

const notice = (options, onClose, onClick) => {
  id += 1
  store.add(Object.assign({ id, onClose, onClick }, options))
}

export { notice, store }
