import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Axios,
  Icon,
  Card,
  useKeycloak,
  Grid,
  Header,
  Dimmer,
  Loader,
  Image,
} from 'component/base'
import { getMediaUrl } from 'config'
import { Embed } from 'semantic-ui-react'

export const VideoCard = props => {
  const { fileId, fileName } = props

  const [data, setData] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(true)

  const { keycloak } = useKeycloak()
  const { token } = keycloak || {}

  const mediaUrl = getMediaUrl()

  useEffect(() => {
    const callApi = async () => {
      setLoading(true)
      const { data, error } = await Axios.ajax.get(
        `${mediaUrl}/downloadUrl?Authorization=${token}&key=${fileId}`
      )
      if (error) {
        setErrorMessage('ファイルダウンロードでエラーが発生しました')
        return
      }
      if (!data.url) {
        setErrorMessage(data.message)
      }
      setData(data)
      setLoading(false)
    }

    callApi()
  }, [fileId, token])

  const { url, imgUrl } = data || {}

  return (
    <>
      {!errorMessage ? (
        <Card>
          <Embed
            url={url}
            placeholder={imgUrl}
            iframe={{ allowFullScreen: true }}
          />
          <Dimmer active={loading}>
            <Loader>Loading</Loader>
          </Dimmer>
        </Card>
      ) : (
        <Grid>
          <Grid.Row>
            <Grid.Column width={2}>
              <Icon size='big' name='file outline' color='teal' />
            </Grid.Column>
            <Grid.Column width={14}>
              <Card.Header>{fileName}</Card.Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Header
              as='h4'
              icon={
                errorMessage === 'progressing' ? 'sync' : 'exclamation circle'
              }
              color={errorMessage === 'progressing' ? 'green' : 'red'}
              content={
                errorMessage === 'progressing' ? '動画変換中' : errorMessage
              }
            />
          </Grid.Row>
        </Grid>
      )}
    </>
  )
}

VideoCard.propTypes = {
  // file upload endpoint url
  fileId: PropTypes.string.isRequired,
  fileName: PropTypes.string,
}

VideoCard.defaultProps = {
  fileName: '',
}
