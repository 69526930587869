import React, { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { subscribeToNewUCs } from 'graphql/subscriptions'
import { List, Label/*, Button, Dropdown*/ } from 'component/base'
import { useUserInfo } from 'component/hook'

const myConversations = /* GraphQL */ `
query myConversations {
  me {
    conversations {
      userConversations {
        conversationId
        userId
        unreadCount
        conversation {
          name
          id
          createdAt
          userConversations {
            conversationId
            lastReadTime
            unreadCount
            userId
            user {
              displayName
              id
              realm
              registered
              username
            }
          }
        }
        user {
          username
          registered
          realm
          id
          displayName
        }
      }
    }
  }
}
`;
const gqlMyConversations = gql(myConversations)
const gplSubscribeToNewUCs = gql(subscribeToNewUCs)

const ConversationListItem = props => {
  const {id, name, active, onClick, unreadCount} = props

  return (
    <>
      <List.Item
        onClick={()=>onClick(id)}
        active={active}
      >
        <List.Content>
          {name}
          {unreadCount ? (<Label circular content={unreadCount}/>) : <></>}
        </List.Content>
      </List.Item>
    </>
  )
}


export const ConversationNav = props => {

  const { conversationId, onClick/*, onClickCreateConversation*/ } = props || {}
  const { data, loading, refetch, subscribeToMore } = useQuery(gqlMyConversations)
  const { userInfo } = useUserInfo()

  const userConversations = useMemo(()=>(
    data ? data.me.conversations.userConversations : []
  ), [data]) 

  const subscribeToNewUC = () => subscribeToMore({
    document: gplSubscribeToNewUCs,
    variables: { userId: userInfo.sub },
    updateQuery: (prev) => {
      refetch()
      return prev
    }
  })

  useEffect(()=>{
    if (!loading) subscribeToNewUC()
  },[loading])
  

  return (
    <>
      {/* 当面チャットを利用しないのでconversaionの追加ボタンは隠す
      <Dropdown
        item
        floating
        icon=''
        pointing='top left'
        className='icon'
        trigger={
          <>
            <Button icon='plus'/>
          </>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={()=>onClickCreateConversation('single')}>
            <Dropdown.Text>
              新しいチャットをはじめる
            </Dropdown.Text>
          </Dropdown.Item>
          <Dropdown.Item onClick={()=>onClickCreateConversation('group')}>
            <Dropdown.Text>
              新しいグループチャットをはじめる
            </Dropdown.Text>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      */}
      {!loading ? (
        <List selection animated verticalAlign='middle'>
          {userConversations.map((item, index) => {
            const { conversation, unreadCount } = item
            const { name, id, userConversations: userList } = conversation || {}
            console.log(item, name, userList)
            const target = userList.filter(i => i.userId !== userInfo.sub)
            return (
              <ConversationListItem
                id={id}
                key={id}
                name={name || target[0].user.displayName}
                active={conversationId === id}
                onClick={onClick}
                unreadCount={unreadCount}
              />
            )
          })}
        </List>
      ) : (
        <></>
      )}
    </>
  )
}


